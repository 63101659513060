<template>
    <test-form v-model:config="config" v-model="value" title="Tabs w/ title">
        <template #additional-actions>
            <v-btn @click="makeErrors()">Mach ma Fehler</v-btn>
        </template>
    </test-form>
</template>

<script lang="ts" setup>
import TestForm from "../../components/TestForm.vue";
import { VBtn } from "vuetify/components";
import { ref } from "vue";
import { type FormConfig } from "../../../src/config/Form";
import setFormErrors from "../../../src/lib/form/setFormErrors";

const value = ref<Record<string, unknown>>({
    b0: "asdf",
});
const config = ref<FormConfig>({
    tab1: {
        type: "tab",
        label: "Tab A",
        children: {
            a1: { type: "text", label: "Vorname", required: true },
            a2: { type: "text", label: "Nachname", required: true },
            a3: { type: "email", label: "E-Mail" },
            a4: { type: "textarea", label: "Adresse" },
        },
    },
    tab2: {
        type: "tab",
        label: "Tab B",
        children: {
            b0: { type: "text", label: "Error", errors: ["error from config"] },
            b1: { type: "textarea", label: "Anmerkungen", required: true },
        },
    },
});
function makeErrors() {
    const cfg = config.value;
    setFormErrors(cfg, { "tab1.a1": ["Machs mal richtig, Junge!"] });
    config.value = cfg;
}
</script>
