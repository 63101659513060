<template>
    <pre>{{ model }}</pre>
    <v-autocomplete
        v-model="model"
        :items="options"
        chips
        closable-chips
        color="blue-grey-lighten-2"
        item-title="label"
        item-value="value"
        label="Select"
        multiple
    />
</template>

<script lang="ts">
import { VAutocomplete } from "vuetify/components";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AutocompleteTest",
    components: {
        VAutocomplete,
    },
    data() {
        return {
            options: [
                { value: "1", label: "X1" },
                { value: "2", label: "X2" },
                { value: "3", label: "X3" },
            ],
            model: null,
        };
    },
});
</script>
