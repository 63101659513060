import { toRaw } from "vue";

function cloneJson<T>(obj: T): T {
    if (obj == null) {
        return obj;
    }
    return JSON.parse(JSON.stringify(obj));
}

function toRawDeep<T>(obj: T): T {
    const val = toRaw(obj);

    if (Array.isArray(val)) {
        return val.map(toRawDeep) as T;
    }

    if (val === null) return null as T;

    if (typeof val === "object") {
        const entries = Object.entries(val).map(([key, val]) => [key, toRawDeep(val)]);

        return Object.fromEntries(entries);
    }

    return val;
}

function cloneStructured<T>(obj: T): T {
    return structuredClone(toRawDeep(obj));
}

export const nonReactiveClone = typeof structuredClone === "function" ? cloneStructured : cloneJson;
