import {
    computed,
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    ref,
    type Ref,
} from "vue";
import type { NormalizedTableColumnConfig, TableRow } from "../../config/Table";

type DetailsData = {
    enabled: DeepReadonly<Ref<boolean>>;
    visible: DeepReadonly<Ref<boolean>>;
    columns: Readonly<Ref<ReadonlyArray<Readonly<NormalizedTableColumnConfig["details"]>>>>;
    show(): void;
    hide(): void;
    toggle(): void;
};

const DetailInjectionKey = Symbol.for("LumUi:LTable:details") as InjectionKey<DetailsData>;

export function provideDetails(
    enabled: DeepReadonly<Ref<boolean>>,
    currentRow: DeepReadonly<Ref<TableRow | null>>,
    columns: Readonly<Ref<readonly NormalizedTableColumnConfig[]>>,
) {
    const visible = ref(false);

    const detailColumns = computed<NormalizedTableColumnConfig["details"][]>(() => {
        const details: NormalizedTableColumnConfig["details"][] = [];
        for (const [key, col] of Object.entries(columns.value)) {
            details[key] = col["details"];
        }
        return details;
    });

    function show() {
        if (!enabled.value || !currentRow.value) {
            visible.value = false;
            return;
        }
        visible.value = true;
    }

    function hide() {
        visible.value = false;
    }

    function toggle() {
        if (visible.value) {
            hide();
        } else {
            show();
        }
    }

    const data = {
        enabled,
        visible,
        show,
        hide,
        toggle,
        columns: detailColumns,
    };

    provide(DetailInjectionKey, data);
    return data;
}

export function useDetails(): DetailsData {
    const details = inject(DetailInjectionKey);
    if (!details) {
        throw Error("provideDetails must be called in a parent component");
    }
    return details;
}
