import { type App, inject, type InjectionKey, type Plugin, type Ref, unref } from "vue";

export function useTranslationMapping() {
    const translationMapping = inject(translationMappingInjectionKey);
    if (!translationMapping) {
        throw Error("could not inject lumUi. Was the library initialized?");
    }
    return translationMapping;
}

export type TranslationMapping = {
    /** the locale use as fallback by the mapping function */
    readonly fallbackLocale: string;
    locales: string[];
    mappedLocales: string[];
    mapLocale(l: string | Ref<string>): string;
    lookupLocale(k: string | Ref<string>): string;
};
export const translationMappingInjectionKey = Symbol.for(
    "LumUi:TranslationMappings",
) as InjectionKey<TranslationMapping>;

/**
 * @internal used by index.ts, you probably don't want to call this
 */
export function createTranslationMappingPlugin(
    fallbackLocale: string,
    localeKeyMap: Record<string, string> | undefined,
): Plugin {
    const translationMapping = {
        fallbackLocale,
        locales: localeKeyMap ? Object.keys(localeKeyMap) : [],
        mappedLocales: localeKeyMap ? Object.values(localeKeyMap) : [],
        /** get backend locale from frontend locale */
        mapLocale: localeKeyMap
            ? (l: string | Ref<string>): string => {
                  const locale = unref(l);
                  if (localeKeyMap == undefined || localeKeyMap[locale] == undefined) {
                      throw Error(
                          "Mapping for locale " +
                              locale +
                              " in not defined in the lumui config." +
                              " available locales: " +
                              Object.keys(localeKeyMap).join(", "),
                      );
                  }
                  return localeKeyMap[locale];
              }
            : (l: string | Ref<string>): string => unref(l),
        /** get the frontend locale from a backend locale */
        lookupLocale: localeKeyMap
            ? (k: string | Ref<string>): string | null => {
                  const key = unref(k);
                  for (const l in localeKeyMap) {
                      if (localeKeyMap[l] == key) {
                          return l;
                      }
                  }
                  return null;
              }
            : (k: string | Ref<string>): string => unref(k),
    };

    return {
        install(app: App): void {
            app.provide(translationMappingInjectionKey, translationMapping);
        },
    };
}
