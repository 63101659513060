import md5 from "md5";

export interface Formatter {
    format(value: unknown, context?: Record<string, any>): string;
}

export interface FormatterConstructor {
    new (settings?: Record<string, unknown>, locale?: string): Formatter;
}

declare let Formatter: FormatterConstructor;

const constructorMap = new Map<string, FormatterConstructor>();
const aliases = new Map<string, string>();

export function registerFormatters(formatters: Record<string, FormatterConstructor>) {
    for (const [key, value] of Object.entries(formatters)) {
        if (constructorMap.has(key)) {
            console.info("formatter: Overriding " + key + " formatter");
        }
        constructorMap.set(key, value);
    }
}

const formatterModules: Record<string, Record<string, FormatterConstructor>> = import.meta.glob(
    "./formatter/*.ts",
    { eager: true },
);
for (const k in formatterModules) {
    const name = k.replace(/^.*\/|\.ts$/g, "");
    const module = formatterModules[k];
    if ("default" in module) {
        constructorMap.set(name.toLowerCase(), module.default);
    } else if (name in module) {
        constructorMap.set(name.toLowerCase(), module[name]);
    } else {
        console.warn("formatter: No formatter found in " + k, Object.keys(module));
    }
    if ("aliases" in module) {
        if (!Array.isArray(module.aliases)) {
            throw Error("formatter: alias export of " + name + " must be array");
        }
        for (const alias of module.aliases) {
            if (aliases.has(alias)) {
                const otherName = aliases.get(alias);
                throw Error(
                    `formatter: duplicate alias: ${alias} -> ${name} vs. ${alias} -> ${otherName}`,
                );
            }
            if (constructorMap.has(alias)) {
                throw Error(
                    `formatter invalid alias ${alias} for ${name} there already is a formatter with that name.`,
                );
            }
            aliases.set(alias, name);
        }
    }

    for (const name in module) {
        const key = name == "DateFormatter" ? "Date" : name;
        constructorMap[key.toLowerCase()] = module[name];
    }
}

const instanceMap = {};

function getHash(name: string, settings?: Record<string, any>) {
    return name + "_" + md5(JSON.stringify(settings));
}

export default function create(
    name: string,
    settings?: Record<string, any>,
    locale?: string,
): Formatter {
    if (!name) {
        throw Error("cannot create formatter without name");
    }
    name = name.toLowerCase();
    const resolvedAlias = aliases.get(name);
    if (resolvedAlias) {
        name = resolvedAlias;
    }
    const hash = getHash(name, settings);
    if (!instanceMap[hash]) {
        const constructor = constructorMap.get(name);
        if (!constructor) {
            throw new Error(`Constructor for ${name} not found`);
        }
        instanceMap[hash] = settings
            ? new constructor(settings, locale)
            : new constructor(undefined, locale);
    }
    return instanceMap[hash];
}

export function getColumnFormatter(
    type: string,
    locale: string,
    settings: Record<string, unknown>,
) {
    return create(type, settings, locale);
}

export function getCustomColumnFormatter(
    formatter: Record<string, Record<string, unknown>> | string,
    locale: string,
) {
    const keys = Object.keys(formatter);
    if (keys.length != 1) {
        throw Error("formatter must have exactly one key");
    }
    const type = keys[0];
    const settings = formatter[type];
    return create(type, settings, locale);
}
