<template>
    <v-input
        v-if="isVisible"
        :class="{
            'v-input--disabled': isDisabled,
            'form-row-collection': true,
            required: isRequired,
        }"
        :disabled="isDisabled"
        :error-messages="aggregatedErrors"
    >
        <div class="d-flex flex-column w-100">
            <v-label
                v-if="theLabel"
                :color="config.errors && config.errors.length > 0 ? 'error' : ''"
                :focused="true"
            >
                {{ theLabel }}
            </v-label>
            <div
                v-for="(childConfig, index) in config.children"
                :key="index"
                class="form-row-collection-rows d-flex flex-column"
            >
                <div class="d-flex flex-row">
                    <l-form-row
                        v-if="isFormRow(childConfig)"
                        :id="id + '-' + index"
                        :config="childConfig"
                        :element-path="path(theName, index)"
                        :form-values="formValues"
                        :model-value="modelValue?.[index] ?? null"
                        :name="theName + '[' + index + ']'"
                        class="flex-grow-1"
                        hide-details="auto"
                        @update:config="cfg => updateChildConfig(index, cfg)"
                        @update:model-value="v => updateChildValue(index, v)"
                    />
                    <l-form-rows
                        v-else
                        :config="childConfig"
                        :element-path="path(theName, index)"
                        :form-values="formValues"
                        :value="modelValue?.[index] ?? childConfig.value ?? {}"
                        class="flex-grow-1"
                        @partial-update:value="v => updateChildValue(index, v)"
                        @partial-update:config="cfg => updateChildConfig(index, cfg)"
                        @update:model-value="v => updateChildValue(index, v)"
                        @partial-update:model-value="v => updateChildValue(index, v)"
                    />
                    <v-btn
                        v-if="allowDelete"
                        color="grey-darken-5"
                        icon="$close"
                        variant="plain"
                        @click="removeItem(index)"
                    />
                </div>
            </div>
            <v-btn v-if="allowAdd" @click="() => addItem()">
                {{ addLabel ?? t("lumui.form.collection.add") }}
            </v-btn>
        </div>
    </v-input>
</template>

<script lang="ts">
import { VBtn, VLabel, VInput } from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import {
    type FormConfigCollection,
    type FormConfigRowNoCollection,
    type FormConfigRowsNoCollection,
} from "../config/Form";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import { formConfigCollectionSchema } from "../config/Form.zod";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import { useI18n } from "vue-i18n";
import LFormRow from "./LFormRow.vue";
import LFormRows from "./LFormRows.vue";

/**
 * renders a collection of fields.
 *
 * #### Config
 *
 * | key                    | type                       | required | default        | description |
 * |------------------------|----------------------------|----------|----------------|-------------|
 * | type                   | `String`                   | yes      | `"select"`     | always `"select"` |
 * | label                  | `String`, `false`          | no       | `false`        | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`        | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`        | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`        | field is rendered.  |
 * | prototype              | `Object`                   | yes      | -              | configuration for child fields |
 * | allow_add              | `Boolean`                  | no       | `false`        | user can add fields to the collection |
 * | add_label              | `String`                   | no       | `"Hinzufügen"` | label for add button |
 * | allow_delete           | `Boolean`                  | no       | `false`        | user can remove fields from the collection |
 *
 * @example
 * ```vue
 * {
 *   "type": "collection",
 *   "label": "Tags",
 *   "allow_add": true,
 *   "allow_delete": true,
 *   "prototype": {
 *     "type": "text",
 *     "label": "",
 *     "value": "",
 *     "required": true,
 *     "disabled": false
 *    },
 * }
 * ```
 */
export default defineComponent({
    components: {
        // Die müssen async nachgeladen werden, da es sonst Abhängigkeitszyklen gibt
        LFormRow,
        LFormRows,
        VLabel,
        VBtn,
        VInput,
    },
    inheritAttrs: false,
    props: {
        elementPath: {
            type: Array as PropType<Array<string>>,
            required: false,
            default: () => [],
        },
        modelValue: {
            type: Object,
            required: false,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigCollection>(formConfigCollectionSchema),
    },
    emits: ["update:modelValue", "partialUpdate:value", "partialUpdate:config"],
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        return {
            t,
            locale,
            ...useDefaults(props, emit),
        };
    },
    computed: {
        theName() {
            return this.name ?? "unnamed";
        },
        addLabel() {
            return this.config.add_label;
        },
        allowAdd() {
            return this.config.allow_add ? this.config.allow_add : false;
        },
        allowDelete() {
            return this.config.allow_delete ? this.config.allow_delete : false;
        },
    },
    methods: {
        isFormRow(
            x: FormConfigRowNoCollection | FormConfigRowsNoCollection,
        ): x is FormConfigRowNoCollection {
            return "type" in x;
        },
        path(name: string, index?: string | number) {
            const path = this.elementPath?.slice() || [];
            path.push(name);
            if (index !== undefined) {
                path.push(index + "");
            }
            return path;
        },
        /** adds an item to the collection */
        addItem(index?: string | number) {
            index ??= Math.max(...Object.keys(this.config.children).map(x => Number(x)), -1) + 1;
            const cfg = nonReactiveClone(this.config.prototype);
            this.updateChildConfig(index + "", cfg);
        },
        /**
         * removes the item identified by the index
         */
        removeItem(index: number | string) {
            const children = this.config.children;
            delete children[index];
            this.updateChildValue(index, undefined);
            this.updateChildConfig(index, undefined);
        },
        updateChildValue(index, value) {
            this.$emit("partialUpdate:value", { [index]: value });
        },
        updateChildConfig(index, value) {
            this.$emit("partialUpdate:config", { children: { [index]: value } });
        },
    },
});
</script>

<style scoped lang="scss">
.form-row-collection-rows:not(:first-of-type) {
    padding-top: 1em;
    border-top: 1px solid silver;
}
</style>

<style>
.form-row-collection .v-input__details {
    padding-left: 1em;
    padding-right: 1em;
}
</style>
