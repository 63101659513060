export class Truncate {
    protected readonly maxLength: number;
    protected readonly truncationSuffix: string;

    constructor(settings: { maxLength: number, truncationSuffix: string }) {
        if (typeof settings.maxLength === 'undefined') {
            throw new Error('Setting maxlength is required');
        }
        this.maxLength = settings.maxLength;
        this.truncationSuffix = settings.truncationSuffix || '…';
    }

    format(value) {
        const t = typeof value;
        if (!value || t === 'undefined') {
            return '';
        }

        if (t !== "string") {
            if (t === "number") {
                value = String(value);
            } else if (typeof value.toString === 'undefined') {
                throw new Error('Can not convert ' + t + ' to string');
            } else {
                value = value.toString();
            }
        }

        if (value.length > this.maxLength) {
            return value.substring(0, this.maxLength - this.truncationSuffix.length) + this.truncationSuffix;
        } else {
            return value;
        }
    }
}
