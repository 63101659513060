<template>
    <v-input
        v-if="isVisible"
        :class="{
            'v-input--disabled': isDisabled,
            'form-row-ordered-choice': true,
            'my-2': true,
            required: isRequired,
        }"
        :disabled="isDisabled"
        :messages="t('lumui.form.ordered_choice.drag')"
        :error-messages="aggregatedErrors"
    >
        <div class="d-flex flex-column w-100">
            <v-label
                v-if="theLabel"
                :color="config.errors && config.errors.length > 0 ? 'error' : ''"
                :focused="true"
            >
                {{ theLabel }}
            </v-label>
            <v-list density="compact">
                <div ref="dnd">
                    <v-list-item v-for="item in selectedItems" :key="item.value" class="list-item">
                        <template #prepend>
                            <v-tooltip location="start">
                                <template #activator="{ props: tooltipProps }">
                                    <span class="list-item-handle mr-2" v-bind="tooltipProps"
                                        >::</span
                                    >
                                </template>
                            </v-tooltip>
                        </template>
                        <v-list-item-title class="form-row-ordered-choice-item">
                            {{ striphtml(item.label) }}
                        </v-list-item-title>
                        <template #append>
                            <v-tooltip>
                                <template #activator="{ props: tooltipProps }">
                                    <v-btn
                                        color="grey-lighten-1"
                                        class="ml-3"
                                        icon="$remove"
                                        variant="plain"
                                        density="compact"
                                        v-bind="tooltipProps"
                                        @click="remove(item.value)"
                                    />
                                </template>
                                {{ t("lumui.form.ordered_choice.remove_item") }}
                            </v-tooltip>
                        </template>
                    </v-list-item>
                </div>
                <v-list-item v-if="selectedItems.length === 0" class="list-no-items">
                    <v-list-item-title>
                        <i class="text--secondary">{{ t("lumui.form.ordered_choice.no_data") }}</i>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item class="list-add-item">
                    <v-list-item-title>
                        <v-autocomplete
                            :id="name + '-ac'"
                            ref="ac"
                            v-model:search="acSearch"
                            :model-value="acValue"
                            :custom-filter="
                                (_value, query, item) =>
                                    !!item &&
                                    'title' in item &&
                                    typeof item.title == 'string' &&
                                    !items.includes(item.value) &&
                                    item.title
                                        .toLocaleLowerCase()
                                        .indexOf(query.toLocaleLowerCase()) > -1
                            "
                            :items="availableOptions"
                            :item-title="x => striphtml(x.label)"
                            :placeholder="t('lumui.form.ordered_choice.select_placeholder')"
                            autocomplete="off"
                            variant="filled"
                            hide-details
                            prepend-inner-icon="add"
                            style="font-size: 0.8125rem"
                            @update:model-value="e => selected(e)"
                        >
                            <template #no-data>
                                <div class="mx-3 text--disabled">
                                    {{ t("lumui.form.ordered_choice.no_data") }}
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
    </v-input>
</template>

<script lang="ts">
import Sortable, { type SortableEvent } from "sortablejs";
import {
    VAutocomplete,
    VBtn,
    VList,
    VListItem,
    VListItemTitle,
    VInput,
    VLabel,
    VTooltip,
} from "vuetify/components";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import { useI18n } from "vue-i18n";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import { formConfigOrderedChoiceSchema } from "../config/Form.zod";
import { defineComponent, type PropType } from "vue";
import type { FormConfigOrderedChoice, OrderedChoiceOptionValue } from "../config/Form";
import { assertDefined } from "../lib/assert";

/**
 * Input element for ordered multi select choices.
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | multiOptions           | `Array`                    | yes      |            | Array of option objects: {value: "val", label: {de: "label"}}
 */
export default defineComponent({
    components: { VList, VListItem, VListItemTitle, VAutocomplete, VBtn, VInput, VLabel, VTooltip },
    props: {
        ...makeDefaultProps<FormConfigOrderedChoice>(formConfigOrderedChoiceSchema),
        modelValue: {
            type: Array as PropType<Array<OrderedChoiceOptionValue>>,
            default: undefined,
        },
    },
    emits: ["update:modelValue", "update:config"],
    setup(props, { emit }) {
        const { t } = useI18n();
        return {
            t,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            acValue: null as OrderedChoiceOptionValue | null,
            acSearch: undefined as string | undefined,
            items: [] as any[],
        };
    },
    computed: {
        selectedItems() {
            let res: any[] = [];
            for (let i = 0; i < this.items.length; i++) {
                let val = this.items[i];
                for (let j = 0; j < this.config.multiOptions.length; j++) {
                    // noinspection EqualityComparisonWithCoercionJS
                    if (this.config.multiOptions[j].value == val) {
                        res.push(this.config.multiOptions[j]);
                        break;
                    }
                }
            }
            return res;
        },
        availableOptions() {
            let res: any[] = [];
            for (let i = 0; i < this.config.multiOptions.length; i++) {
                let item = this.config.multiOptions[i];
                if (!this.items.includes(item.value)) {
                    res.push(item);
                }
            }
            return res;
        },
    },
    watch: {
        // config() {
        //     this.updateItems();
        // },
    },
    mounted() {
        this.updateItems();
        Sortable.create(this.$refs.dnd as HTMLDivElement, {
            onStart(evt) {
                evt.item.style.border = "1px solid #BDBDBD";
            },
            onEnd: this.onEnd.bind(this),
        });
    },
    methods: {
        onEnd(evt: SortableEvent) {
            evt.item.style.border = "";
            assertDefined(evt.oldIndex);
            assertDefined(evt.newIndex);
            this.items = this.move(this.items, evt.oldIndex, evt.newIndex);
            this.$emit("update:modelValue", this.items);
        },
        updateItems() {
            if (this.config.value !== null && typeof this.config.value === "object") {
                const newConfig = nonReactiveClone(this.config);
                newConfig.value = Object.values(this.config.value);
                this.$emit("update:config", newConfig);
            }
            this.items = this.config.value ?? [];
        },
        striphtml(x: string): string {
            let div = document.createElement("div");
            div.innerHTML = x;
            return div.textContent ?? div.innerText ?? "";
        },
        remove(value: OrderedChoiceOptionValue): void {
            this.items.splice(this.items.indexOf(value), 1);
            this.$emit("update:modelValue", this.items);
        },
        selected(n: OrderedChoiceOptionValue) {
            if (!this.items.includes(n)) {
                this.items.push(n);
                this.$emit("update:modelValue", this.items);
            }
            this.$nextTick(() => {
                this.acSearch = "";
                this.acValue = null;
            });
        },
        move(arr: any[], oldIndex: number, newIndex: number) {
            while (oldIndex < 0) {
                oldIndex += arr.length;
            }
            while (newIndex < 0) {
                newIndex += arr.length;
            }
            if (newIndex >= arr.length) {
                let k = newIndex - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            return arr;
        },
    },
});
</script>

<style scoped lang="scss">
.list-item-handle {
    width: 24px;
    display: inline-block;
    text-align: center;
    opacity: 0.5;
    flex: none;
}

.list-item {
    user-select: none;
    cursor: move;
}

.list-item:active {
    cursor: grabbing;
}

.list-no-items,
.list-item {
    border-top: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}

.list-add-item {
    border: 1px solid #eeeeee;
}

.list-add-item input {
    margin-top: 2px;
    font-size: 0.8125rem;
}
</style>

<style lang="scss">
.form-row-ordered-choice > div.v-input__details {
    padding-left: 16px;
    padding-right: 16px;
}
</style>
