<template>
    <v-container>
        <h1>CSV Export formula escaping</h1>
        <l-table :data="data" :loading="false" />
    </v-container>
</template>

<script>
import { VContainer } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "CsvFormula",
    components: { LTable, VContainer },
    data() {
        return {
            data: {
                columns: [
                    {
                        key: "id",
                        hidden: true,
                    },
                    {
                        key: "node",
                        type: "localized",
                        label: "Organisationseinheit",
                        export: {
                            hidden: false,
                            width: 10,
                        },
                    },
                    {
                        key: "personnelNo",
                        label: "Benutzer-ID",
                        export: {
                            hidden: false,
                            width: 15,
                        },
                    },
                    {
                        key: "name",
                        label: "Name",
                        export: {
                            hidden: false,
                            width: 15,
                        },
                    },
                    {
                        key: "login",
                        label: "Login",
                    },
                    {
                        key: "email",
                        label: "E-Mail",
                    },
                    {
                        key: "deleted",
                        label: "Gelöscht",
                        type: "bool",
                    },
                    {
                        key: "status",
                        label: "Status",
                        formatter: {
                            KeyValue: [
                                {
                                    1: "aktiv",
                                    2: "inaktiv",
                                    3: "kein Zugang",
                                },
                                {
                                    class: "status-",
                                },
                            ],
                        },
                        filter: {
                            select: {
                                key: "status",
                                label: "Status",
                                options: {
                                    1: "aktiv",
                                    2: "inaktiv",
                                    3: "kein Zugang",
                                },
                            },
                        },
                    },
                    {
                        key: "actions",
                        type: "action",
                        actions: [],
                    },
                ],
                filter: [
                    {
                        key: "ID",
                        hidden: true,
                    },
                    {
                        key: "node",
                        type: "localized",
                        label: "Organisationseinheit",
                    },
                    {
                        key: "personnelNo",
                        label: "Benutzer-ID",
                    },
                    {
                        key: "name",
                        label: "Name",
                    },
                    {
                        key: "login",
                        label: "Login",
                    },
                    {
                        key: "email",
                        label: "E-Mail",
                    },
                    {
                        key: "deleted",
                        label: "Gelöscht",
                        type: "bool",
                    },
                    {
                        key: "status",
                        label: "Status",
                        formatter: {
                            KeyValue: [
                                {
                                    1: "aktiv",
                                    2: "inaktiv",
                                    3: "kein Zugang",
                                },
                                {
                                    class: "status-",
                                },
                            ],
                        },
                        filter: {
                            select: {
                                key: "status",
                                label: "Status",
                                options: {
                                    1: "aktiv",
                                    2: "inaktiv",
                                    3: "kein Zugang",
                                },
                            },
                        },
                    },
                ],
                rows: [
                    {
                        id: 1,
                        path: "1-",
                        lastname: "Lum",
                        firstname: "Admin",
                        personnelNo: null,
                        login: "lum_admin",
                        email: "error@lum-software.de",
                        status: 1,
                        deleted: false,
                        node: {
                            de: "asa-enterprise",
                            fr: "asa-enterprise",
                            it: "asa-enterprise",
                        },
                        name: "Lum, Admin",
                    },
                    {
                        id: 2,
                        path: "1-2-",
                        lastname: "Reporter",
                        firstname: "Harry",
                        personnelNo: "123",
                        login: "harry_reporter",
                        email: "n.efthymiou@lum-software.de",
                        status: 1,
                        deleted: false,
                        node: {
                            de: "Acme Inc.",
                            fr: "Acme Inc.",
                            it: "Acme Inc.",
                        },
                        name: "Reporter, Harry",
                    },
                    {
                        id: 3,
                        path: "1-2-",
                        lastname: "Sibe",
                        firstname: "=A1",
                        personnelNo: "=A1",
                        login: "sibe",
                        email: "n.efthymiou@lum-software.de",
                        status: 1,
                        deleted: false,
                        node: {
                            de: "Acme Inc.",
                            fr: "Acme Inc.",
                            it: "Acme Inc.",
                        },
                        name: "Sibe, =A1",
                    },
                    {
                        id: 4,
                        path: "1-2-",
                        lastname: "Kopas",
                        firstname: "Kopas",
                        personnelNo: null,
                        login: "kopas",
                        email: "n.efthymiou@lum-software.de",
                        status: 1,
                        deleted: false,
                        node: {
                            de: "Acme Inc.",
                            fr: "Acme Inc.",
                            it: "Acme Inc.",
                        },
                        name: "Kopas, Kopas",
                    },
                    {
                        id: 5,
                        path: "1-2-",
                        lastname: "LV",
                        firstname: "LV",
                        personnelNo: null,
                        login: null,
                        email: "n.efthymiou@lum-software.de",
                        status: 3,
                        deleted: false,
                        node: {
                            de: "Acme Inc.",
                            fr: "Acme Inc.",
                            it: "Acme Inc.",
                        },
                        name: "LV, LV",
                    },
                    {
                        id: 6,
                        path: "1-2-",
                        lastname: "Tester",
                        firstname: "test 11094",
                        personnelNo: null,
                        login: null,
                        email: "n.efthymiou@lum-software.de",
                        status: 3,
                        deleted: false,
                        node: {
                            de: "Acme Inc.",
                            fr: "Acme Inc.",
                            it: "Acme Inc.",
                        },
                        name: "Tester, test 11094",
                    },
                ],
                exports: [],
            },
        };
    },
};
</script>

<style scoped></style>
