import { debouncedWatch, useStorage } from "@vueuse/core";
import { nextTick, onMounted } from "vue";
import { useRouter } from "vue-router";
import { nonReactiveClone } from "../../lib/nonReactiveClone";
import { type Filter, isEmptyFilterValue } from "./filter";
import { type Sort } from "./sort";
import type { Virtual } from "./virtual";

type PersistentData = {
    sort?: {
        column: string;
        direction: "asc" | "desc";
    };
    filter?: Record<string, unknown>;
    firstVisibleRow?: number;
};

function isLegacyStorage(filters: any): filters is Array<{ key: string; value: string }> {
    if (!Array.isArray(filters)) {
        return false;
    }
    for (const f of filters) {
        if (typeof f !== "object" || !Object.hasOwn(f, "key") || !Object.hasOwn(f, "value")) {
            return false;
        }
    }
    return true;
}

function migrate(data: Array<{ key: string; value: string }>): PersistentData {
    const result: PersistentData = {
        filter: {},
    };

    for (const row of data) {
        if (!isEmptyFilterValue(row.value)) {
            result[row.key] = row.value;
        }
    }
    return result;
}

export function usePersistence(
    storageKey: string | null,
    filter: Filter,
    sort: Sort,
    virtual: Virtual,
) {
    const router = useRouter();

    if (!storageKey) {
        const route = router.currentRoute.value;
        storageKey = String(route.name) ?? route.path.replace(/\//gi, "_");
    }
    const store = useStorage<PersistentData>(storageKey, {}, sessionStorage);

    debouncedWatch(
        [filter.values, sort.criteria, virtual.firstVisibleRow],
        ([fv, sc, fvr]) => {
            const data: PersistentData = {};
            if (Object.keys(fv).length > 0) {
                data.filter = nonReactiveClone(fv);
            }
            if (sc) {
                data.sort = nonReactiveClone(sc);
            }

            data.firstVisibleRow = fvr;
            store.value = data;
        },
        { debounce: 250 },
    );

    onMounted(() => {
        let data = nonReactiveClone(store.value);
        if (isLegacyStorage(data)) {
            console.info(
                "table/persistence: migrating old data from session store",
                JSON.stringify(data),
            );
            store.value = migrate(data);
            console.info("table/persistence: new data", JSON.stringify(data));
            data = store.value;
        }

        if (data.sort) {
            sort.sort(data.sort.column, data.sort.direction);
        }
        if (data.filter) {
            for (const [key, value] of Object.entries(data.filter)) {
                filter.set(key, value);
            }
        }

        if (data.firstVisibleRow != undefined) {
            const first = data.firstVisibleRow;

            //
            void nextTick(() => {
                virtual.scrollToRow(first);
            });
        }
    });
}
