<template>
    <v-alert v-if="error === 'error_no_camera'" type="error">
        {{ t("lumui.qr_scanner.error_no_camera") }}
    </v-alert>
    <v-alert v-else-if="error" type="error"> Error: {{ error }}</v-alert>
    <div v-if="!error" id="video-frame">
        <video ref="video" :class="{ mirror: mirror }" />
    </div>
    <div id="config">
        <l-form-row-autocomplete
            id="cameraId"
            ref="cameraId"
            v-model="cameraId"
            :config="{
                type: 'select',
                label: t('lumui.qr_scanner.camera'),
                options: cameraOptions,
            }"
            :required="true"
            name="cameraId"
        />
        <l-form-row-switch
            id="mirror"
            v-model="mirror"
            :config="{ label: t('lumui.qr_scanner.mirror'), type: 'switch' }"
            name="mirror"
        />
        <l-form-row-switch
            v-if="hasFlash"
            id="torch"
            v-model="flash"
            :config="{ label: t('lumui.qr_scanner.torch'), type: 'switch' }"
            name="torch"
        />
    </div>
</template>

<script lang="ts">
import { VAlert } from "vuetify/components";
import QrScanner from "qr-scanner";
import { defineAsyncComponent, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    components: {
        // proper import causes "Cannot access LFormRowAutocomplete before initialization".
        // probably has to do with component registration
        LFormRowAutocomplete: defineAsyncComponent(() => import("./LFormRowAutocomplete.vue")),
        LFormRowSwitch: defineAsyncComponent(() => import("./LFormRowSwitch.vue")),
        VAlert,
    },
    props: {
        /** cameraId and mirror settings are save under this key if localStorage is available */
        localStorageKey: {
            type: String,
            default: "LQrScanner",
        },
        /** stop scanning when the first QR is found */
        stopOnDetection: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["detected", "active"],
    setup() {
        const { t } = useI18n();
        return {
            t,
        };
    },
    data() {
        return {
            active: false,
            qrScanner: null as QrScanner | null,
            error: null as Error | string | null,
            mirror: false,
            flash: false,
            hasFlash: false,
            showConfig: false,
            cameraId: undefined as string | undefined,
            cameraOptions: new Array<{ label: string; value: string }>(),
        };
    },
    computed: {
        haveLocalStorage() {
            return "localStorage" in window;
        },
        videoEl(): HTMLVideoElement {
            return this.$refs.video as HTMLVideoElement;
        },
    },
    watch: {
        cameraId(n) {
            if (this.qrScanner) {
                this.qrScanner.setCamera(n);
            }
            this.save();
        },
        flash() {
            if (this.qrScanner && this.hasFlash) {
                this.qrScanner.toggleFlash();
            }
        },
        mirror() {
            this.save();
        },
    },
    async mounted() {
        this.load();
        const hasCamera = await QrScanner.hasCamera();
        if (!hasCamera) {
            this.error = "error_no_camera";
            return;
        }
        const cameras = await QrScanner.listCameras(true);
        if (cameras.length < 1) {
            this.error = "error_no_camera";
            return;
        }
        cameras.forEach(x => this.cameraOptions.push({ label: x.label, value: x.id }));

        if (!this.cameraId) {
            this.cameraId = cameras[0].id ?? undefined;
        }
        this.qrScanner = new QrScanner(this.$refs.video as HTMLVideoElement, this.detected, {
            maxScansPerSecond: 20,
            highlightScanRegion: true,
            highlightCodeOutline: true,
            preferredCamera: this.cameraId,
        });

        await this.start();
    },
    unmounted() {
        if (this.qrScanner) {
            this.qrScanner.stop();
        }
        this.qrScanner = null;
    },
    methods: {
        /** @private */
        load() {
            if (!this.haveLocalStorage) {
                return;
            }
            const settingsStr = localStorage.getItem(this.localStorageKey);
            if (!settingsStr) {
                return;
            }
            const settings = JSON.parse(settingsStr);
            if (!settings) {
                console.error("LQrScanner: settings could not be parsed");
                return;
            }
            this.mirror = settings.mirror;
            this.cameraId = settings.cameraId ?? undefined;
        },
        /** @private */
        save() {
            if (!this.haveLocalStorage) {
                return;
            }
            const settings = {
                mirror: this.mirror,
                cameraId: this.cameraId,
            };
            localStorage.setItem(this.localStorageKey, JSON.stringify(settings));
        },
        stop() {
            try {
                if (this.qrScanner) {
                    this.qrScanner.stop();
                    /** @event active when scanning is ongoing or stopped */
                    this.$emit("active", false);
                }
            } catch (e: any) {
                this.error = e;
            }
        },
        async start() {
            if (!this.qrScanner) {
                throw Error("No qrScanner present while start");
            }
            try {
                await this.qrScanner.start();
                this.hasFlash = await this.qrScanner.hasFlash();
                this.flash = this.hasFlash ? this.qrScanner.isFlashOn() : false;
                this.videoEl.style.transform = "none";
                this.$emit("active", true);
            } catch (e: any) {
                this.error = e;
            }
        },
        /** @private */
        detected(result) {
            if (this.stopOnDetection) {
                this.stop();
            }
            /** @event detected qr code content, when one is detected */
            this.$emit("detected", result.data);
        },
    },
});
</script>

<style scoped>
video.mirror,
canvas.mirror {
    transform: scaleX(-1) !important;
}

video,
canvas {
    transform: scaleX(1) !important;
}

#video-frame {
    position: relative;
    border: 1px solid #999;
    width: 640px;
    height: 480px;
}

#video-frame video {
    max-width: 100%;
}

#video-frame canvas {
    max-width: 100%;
}

#config {
    width: 640px;
}
</style>
