function disableForm(config: Record<string, any>) {

    for (const i in config) {
        const child = config[i];
        if (typeof child.children !== 'undefined') {
            disableForm(child.children);
        } else {
            child.disabled = true;
        }
    }
}

export default disableForm;
