<template>
    <v-text-field
        :id="name"
        model-value="modelValue"
        type="hidden"
        :class="[config.class, 'hidden']"
        :disabled="isDisabled"
        :name="name"
        @update:model-value="e => $emit('update:modelValue', e)"
    />
</template>

<script lang="ts">
import { VTextField } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import { defineComponent } from "vue";
import type { FormConfigHidden } from "../config/Form";
import { formConfigHiddenSchema } from "../config/Form.zod";

export default defineComponent({
    components: { VTextField },
    props: {
        modelValue: {
            type: String,
            required: false,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigHidden>(formConfigHiddenSchema),
    },
    emits: ["update:modelValue", "update:config", "update:error"],
    setup(props, { emit }) {
        return {
            ...useDefaults(props, emit),
        };
    },
});
</script>

<style scoped>
.hidden {
    display: none;
}
</style>
