import { createVuetify as theirCreateVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa";
import { md3 } from "vuetify/blueprints";
import "vuetify/styles";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { type I18n, useI18n } from "vue-i18n";

// @todo i18n
export function createVuetify(
    i18n: I18n<any, Record<string, unknown>, Record<string, unknown>, string, false>,
) {
    return theirCreateVuetify({
        locale: {
            adapter: createVueI18nAdapter({ i18n, useI18n }),
        },
        icons: {
            aliases,
            sets: { fa },
            defaultSet: "fa",
        },
        blueprint: md3,
    });
}
