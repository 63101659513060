<template>
    <v-container>
        <v-card>
            <v-toolbar>
                <v-toolbar-title> Locale Sortierung</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <l-table
                    :data="tableData"
                    info-disabled
                    :show-filter="showFilter"
                    @filter_closed="showFilter = false"
                />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import {
    VBtn,
    VCard,
    VCardText,
    VContainer,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "Localesort",
    components: {
        LTable,
        VContainer,
        VToolbar,
        VToolbarTitle,
        VToolbarItems,
        VCard,
        VSpacer,
        VCardText,
        VBtn,
    },
    data() {
        return {
            showFilter: true,
            tableData: {
                columns: [
                    {
                        key: "id",
                        hidden: true,
                    },
                    {
                        key: "locale",
                        label: "Locale Spalte",
                        type: "localized",
                    },
                    {
                        key: "desc",
                        label: "Beschreibung1",
                    },
                ],
                filter: [
                    {
                        key: "locale",
                        label: "Locale Spalte",
                    },
                ],
                rows: [
                    {
                        id: 1,
                        locale: {
                            de: "Foo",
                            en: "Foo",
                        },
                        desc: "Deutsche und Englische Übersetzung",
                    },
                    {
                        id: 2,
                        locale: {
                            de: "Bar",
                            en: "Bar",
                        },
                        desc: "Deutsche und Englische Übersetzung",
                    },
                    {
                        id: 3,
                        locale: {
                            de: "Baz",
                        },
                        desc: "Deutsche Übersetzung",
                    },
                    {
                        id: 4,
                        locale: {
                            en: "Bum",
                        },
                        desc: "Englische Übersetzung",
                    },
                    {
                        id: 5,
                        locale: {},
                        desc: "Gar kein Text",
                    },
                ],
            },
        };
    },
};
</script>
