<template>
    <v-text-field
        :id="'filter_' + config.key"
        :clearable="clearable"
        :label="config.label"
        :model-value="value"
        aria-autocomplete="none"
        autocomplete="off"
        hide-details
        @update:model-value="v => $emit('update:modelValue', v)"
    />
</template>

<script lang="ts">
import { VTextField } from "vuetify/components";
import { defineComponent, type PropType } from "vue";

/**
 * Renders a text filter
 * @internal
 */
export default defineComponent({
    components: { VTextField },
    props: {
        value: {
            type: String,
            required: false,
            default: undefined,
        },
        config: {
            type: Object as PropType<{ key: string; label: string; clearable?: boolean }>,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    computed: {
        clearable() {
            if (Object.hasOwn(this.config, "clearable")) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        },
    },
});
</script>
