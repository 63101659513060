import { inject, type InjectionKey, type Plugin } from "vue";

type ComponentExistsFn = (name: string) => boolean;

const componentExistsKey = Symbol.for("LumUi:ComponentExists") as InjectionKey<ComponentExistsFn>;

/**
 * @internal
 */
export function createComponentExistsPlugin(): Plugin {
    return {
        install(app) {
            app.provide(componentExistsKey, (name: string) => app.component(name) != undefined);
        },
    };
}

export function useComponentExists(): ComponentExistsFn {
    const componentExists = inject(componentExistsKey);
    if (!componentExists) {
        throw new Error("Could not inject ComponentExists.");
    }
    return componentExists;
}
