<template>
    <test-form v-model="value" title="Large Autocomplete" :config="cfg"></test-form>
</template>

<script lang="ts" setup>
import TestForm from "../../components/TestForm.vue";
import { ref } from "vue";
import type { FormConfig, SelectOptionText } from "../../../src/config/Form";

function generateOptions(): SelectOptionText[] {
    const options: SelectOptionText[] = [];
    for (let i = 0; i < 10000; i++) {
        options.push({ value: i, text: "Option " + i /*, disabled: i % 10 === 0 */ });
    }
    return options;
}

const value = ref({
    f0: 174,
});

const cfg: FormConfig = {
    f0: {
        label: "large autocomplete",
        type: "select",
        options: generateOptions(),
    },
    f1: {
        label: "large autocomplete multiple + chips",
        type: "select",
        options: generateOptions(),
        multiple: true,
        chips: true,
        html_options: false,
    },
};
</script>
