<template>
    <TestForm v-model="data" :config="form" :loading="false" title="Ordered choice" />
</template>
<script lang="ts">
import type { FormConfig } from "../../../src/config/Form";
import TestForm from "../../components/TestForm.vue";

const form: FormConfig = {
    gefahrItems: {
        type: "orderedChoice",
        label: "Gewählte Sätze",
        value: ["6", "26"],
        required: true,
        disabled: false,
        errors: [],
        multiOptions: [
            {
                label: "Text zu Kategorie Gefahr für Mensch und Umwelt, Gruppe Gabelstapler",
                value: "6",
            },
            {
                label: "Text zu Kategorie Gefahr für Mensch und Umwelt, Gruppe allgemein",
                value: "7",
            },
            {
                label: "Mitarbeitende können Verbrennungen an heissen Oberflächen erleiden.",
                value: "26",
            },
            {
                label: "Wärmeerzeugende Geräte können Brände verursachen.",
                value: "27",
            },
            {
                label: "Quetschen, Einklemmen von Körper- und anderen Teilen.",
                value: "28",
            },
            {
                label: "Getroffen werden von pendelnder, umkippender, abstürzender Last.",
                value: "39",
            },
            {
                label: "Getroffen werden von herabfallendem Hebezeug oder Teilen der Aufhängevorrichtung.",
                value: "42",
            },
            {
                label: "Verletzen von Händen und Füssen beim Anschlagen und Führen der Last.",
                value: "43",
            },
            {
                label: "Einsetzen von ungeeigneten Anschlagmitteln.",
                value: "44",
            },
            {
                label: "Abstürzen des Lagerguts.",
                value: "56",
            },
            {
                label: "Kollision eines Fahrzeugs (Stapler, Kommissioniergerät) mit Lagergestell.",
                value: "57",
            },
            {
                label: "Kollision von Fahrzeugen und Personen.",
                value: "58",
            },
            {
                label: "Abstürzen von Personen.",
                value: "59",
            },
            {
                label: "Kippen von Lagergestellen.",
                value: "60",
            },
            {
                label: "Angefahren, Überfahren werden von Eisenbahnfahrzeugen.",
                value: "102",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Gequetscht werden z.B. beim Kuppeln oder sich Aufhalten in Engstellen.</p>',
                value: "103",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Abstürzen von Eisenbahnfahrzeugen bei Tätigkeiten in der Höhe.</p>',
                value: "104",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Stolpern, Stürzen beim Umhergehen in der Gleisanlage.</p>',
                value: "105",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Elektrisiert werden durch die Fahrleitung.</p>',
                value: "106",
            },
            {
                label: "Quetsch- und Schergefahren\r\ndurch Beschickungsvorgänge von Antriebs- oder Förderelementen.",
                value: "123",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Besonders gefährdet sind die Personen, die sich innerhalb des Schutzbereichs befinden oder hineingreifen.</p>',
                value: "124",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Weitere Gefahren sind im elektrischen, pneumatischen sowie im mechanischen Bereich zu sehen.</p>',
                value: "125",
            },
            {
                label: "<p>Mechanische Gefahrstellen an den Antrieben und zwischen Knetwerkzeug und Knetkessel.</p>",
                value: "153",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1; tab-stops: 20.0pt;">Einzugstelle zwischen rotierendem Kessel und festen Maschinenteilen.</p>',
                value: "154",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1; tab-stops: 20.0pt;">Quetschstellen beim automatischen Spannen des Kesselwagens.</p>',
                value: "155",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1; tab-stops: 20.0pt;">Fussgefährdung durch Laufrollen.</p>',
                value: "156",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1; tab-stops: 20.0pt;">Emission durch Mehlstaubaustritt.</p>',
                value: "157",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Einzugstelle zwischen Förderband und Umlenkwalzen.</p>',
                value: "167",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Einzugstelle bei der Bandauflaufstelle der Umlenkrollen.</p>',
                value: "168",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Gefährdung durch Mehlstaubentwicklung (allergische Erkrankungen).</p>',
                value: "169",
            },
            {
                label: "Gesundheitsschäden durch Einwirkung von Reinigungsmitteln.",
                value: "170",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1; tab-stops: 18.0pt;">Quetsch- und Schergefahr durch den Pressstempel. </p>',
                value: "175",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Schlag durch aufspringenden Deckel.</p>',
                value: "176",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Gefahr für Hände und Arme durch Erfasstwerden vom Rührwerkzeug.</p>',
                value: "180",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Gefahr durch herausspritzendes Produkt bei zu hoher Drehzahl.</p>',
                value: "181",
            },
            {
                label: "Gefahr durch elektrischen\r\nStrom.",
                value: "189",
            },
            {
                label: "Gefahren durch heisse\r\nFlüssigkeiten.",
                value: "190",
            },
            {
                label: "Gefahren durch den Umgang\r\nmit dem Reinigungsmittel.",
                value: "191",
            },
            {
                label: "Brand-, Verpuffungs- und\r\nExplosionsgefahr.",
                value: "202",
            },
            {
                label: "Die beim Erwärmen\r\nentstehenden Dämpfe von Pfannen können Verbrennungen verursachen. ",
                value: "203",
            },
            {
                label: "Es besteht Verbrennungsgefahr\r\ndurch die heisse Pfanne und Spritzer.",
                value: "204",
            },
            {
                label: "Es besteht Brandgefahr\r\ndurch überhitztes Fett.",
                value: "205",
            },
            {
                label: "Bei unkontrolliertem Ausströmen von brennbaren oder sauerstoffangereicherten Gasen besteht Brand- und Explosionsgefahr.",
                value: "206",
            },
            {
                label: "Mitarbeitende und Kunden\r\nkönnen Verbrennungen an heissen Oberflächen erleiden.",
                value: "218",
            },
            {
                label: "Wärmeerzeugende Geräte\r\nkönnen Brände verursachen.",
                value: "219",
            },
            { label: "Ausrutschen, stolpern.", value: "232" },
            {
                label: "Absturz von Personen.",
                value: "233",
            },
            {
                label: "Von herunterfallenden Gegenständen getroffen\r\nwerden.",
                value: "234",
            },
            {
                label: "Quetschen, Einklemmen, Einziehen von Körper- und anderen Teilen.",
                value: "245",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Ausrutschen, Stürzen aus der Kabine.</p>',
                value: "246",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Berühren unter Spannung stehender Teile, die im Fehlzustand Spannung führen.</p>',
                value: "247",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Fehlende Übereinstimmung mit der Ergonomie des Menschen.</p>',
                value: "248",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Erfrieren aufgrund blockierter Ausgänge.</p>',
                value: "254",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Erfrieren aufgrund einer nicht funktionierenden Alarmorganisation.</p>',
                value: "255",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Gesundheitsschädigungen infolge tiefer Temperaturen.</p>',
                value: "256",
            },
            {
                label: '<p style="line-height: normal; font-style: normal; font-weight: normal; margin-top: 0cm; margin-bottom: 0pt; mso-pagination: none; mso-layout-grid-align: none; mso-list: l0 level1 lfo1;">Austritt von Kühl- und Kältemittel.</p>',
                value: "257",
            },
            {
                label: "Bei unkontrolliertem Ausströmen von gefährlichen Gasen besteht Vergiftungs- und Erstickungsgefahr.",
                value: "274",
            },
            {
                label: "Weitere Gefahren bestehen im elektrischen, pneumatischen sowie mechanischen Bereich.",
                value: "275",
            },
            {
                label: "Wegen des hohen Gewichtes der Gasflaschen besteht beim Hantieren und Transport ein beträchtliches Verletzungsrisiko.",
                value: "285",
            },
            {
                label: "Druckgasflaschen stehen unter hohen Drücken (bis 200 bar) und besitzen beim Bersten oder beim Abschlagen der Armatur ein massives Zerstörungspotential.",
                value: "286",
            },
            {
                label: "Quetschgefahr\r\nbeim Schliessen des Maschinendeckels.",
                value: "297",
            },
            {
                label: "Verbrennungsgefahr an\r\nheissen Teilen.",
                value: "298",
            },
            {
                label: "Bei den Arbeiten an den genannten Maschinen\r\nbestehen Verletzungsgefahren bei der Benützung, beim Auf- und Abbau sowie bei\r\nden Reinigungsarbeiten, u.a. durch:<br><ul><li>scharfe Teile</li><li>Quetsch- und Scherstellen</li><li>elektrischen Strom.</li></ul>",
                value: "305",
            },
            {
                label: "Beim Positionieren, absetzen und kippen der Six-Pack-Paletten können Personen oder Körperteile eingeklemmt werden.",
                value: "316",
            },
            {
                label: "Beim Entfernen der Palettenumwicklungsfolie, beim Einfahren in den Palettenabschieber, beim Abschieben der 1/2 Palettenladung sowie beim Bewegen ungesicherter befüllter Rollbehälter können Personen durch herunterstürzende Six-Packs getroffen werden.",
                value: "317",
            },
            {
                label: "Beim Abschieben der 1/2 Six-Pack-Ladungen vom Palett können Personen oder Körperteile eingeklemmt werden.",
                value: "318",
            },
            {
                label: "Umkippen der\r\nHubarbeitsbühne.",
                value: "326",
            },
            {
                label: "Einklemmen von Personen zwischen der verfahrbaren Hubarbeitsbühne und feststehenden Teilen der Umgebung.",
                value: "327",
            },
            {
                label: "Gefahr durch hohe Lärmbelastung.",
                value: "339",
            },
            {
                label: "Gefahr durch nicht ergonomische Arbeitshaltung.",
                value: "345",
            },
            {
                label: "Gefahr durch Schneiden und Stechen.",
                value: "346",
            },
            {
                label: "Gefahr\r\ndurch Erfassen/Einziehen in die Walzen.",
                value: "347",
            },
            {
                label: "Gefahr\r\ndurch Quetschen.",
                value: "348",
            },
            {
                label: "Gefahr\r\ndurch Kippen der PET-Ballen.",
                value: "349",
            },
            {
                label: "Das Verkanten von Werkstücken kann zum Reissen\r\ndes Bandsägeblattes führen und schwere Verletzungen verursachen.",
                value: "350",
            },
            {
                label: "Es bestehen schwerste Schnittverletzungsgefahren\r\ndurch das laufende Sägeblatt.",
                value: "351",
            },
            {
                label: "Im\r\nMehlsilo herrscht durch den Feinstaub Explosionsgefahr. ",
                value: "369",
            },
            {
                label: "Aufgewirbelter\r\nStaub kann zünd- und explosionsfähig sein. ",
                value: "370",
            },
            {
                label: "Der\r\nBereich des Silos ist deshalb Explosionsschutzzone.",
                value: "371",
            },
            {
                label: "Durch den geöffneten Zugang besteht die Gefahr des Absturzes.",
                value: "372",
            },
            {
                label: "Im Behälter kann sich eine sauerstoffarme Atmosphäre bilden.",
                value: "373",
            },
            {
                label: "Der Feinstaub kann zu Reizungen der Atemwege führen.",
                value: "374",
            },
            { label: "", value: "380" },
            {
                label: "Schneiden, Quetschen.",
                value: "381",
            },
            {
                label: "Brandgefahr durch\r\nVerwendung ungeeigneter Gefässe.",
                value: "392",
            },
            {
                label: "Um-/Absturz des Staplers.",
                value: "402",
            },
            {
                label: "Überfahren der Rampenkante.",
                value: "403",
            },
            {
                label: "Abrutschen beim Auf- und\r\nAbsteigen.",
                value: "404",
            },
            {
                label: "Anfahren von Personen.",
                value: "405",
            },
            {
                label: "Getroffen werden von\r\nungesicherter, herunterfallender Ladung.",
                value: "406",
            },
            {
                label: "Beim Bewegen von Elektrodeichselhubgeräten\r\nbesteht die Gefahr von Anfahren/Hineinfahren in Waren und/oder Personen, was\r\n grosse Gefahr von stumpfen Verletzungen birgt.",
                value: "412",
            },
            {
                label: "Transportieren von Gegenständen mit einem hohen\r\nSchwerpunkt kann ein Umstürzen und somit eine Unfallgefahr für den Menschen\r\ndarstellen.",
                value: "413",
            },
            {
                label: "Auf Fussquetschungen beim Absenken achten.",
                value: "414",
            },
            {
                label: "Beim Bewegen der Geräte/Maschinen besteht die\r\nGefahr von Anfahren/Hineinfahren in Waren und/oder Personen, was eine grosse\r\nGefahr von stumpfen Verletzungen birgt.",
                value: "421",
            },
            {
                label: "Personen dürfen nicht damit\r\ntransportiert werden.",
                value: "422",
            },
            {
                label: "Aufgeheizte Geräte können\r\nzu Verbrennungen führen.",
                value: "432",
            },
            {
                label: "Überhitzte Fette und Öle\r\nkönnen sich selbst entzünden.",
                value: "433",
            },
            {
                label: "Verspritztes heisses Fett\r\nkann zu Verbrennungen führen oder einen Brand auslösen.",
                value: "434",
            },
            {
                label: "Für schwangere Mitarbeitende\r\nist die Gefährdung durch die Magnetfeld-Exposition zu beachten und die\r\nMutterschutzverordnung einzuhalten.",
                value: "435",
            },
            {
                label: "Personen mit\r\nHerzschrittmachern müssen den Frequenzbereich des Induktionsfelds beachten.",
                value: "436",
            },
            {
                label: "Gefahren durch Abstürzen.",
                value: "446",
            },
            {
                label: "Herabfallen der Last oder\r\nTeilen.",
                value: "447",
            },
            {
                label: "Quetsch- und Scherstellen\r\nbeim Bewegen der Hebebühne bei unsachgemässem Verhalten.",
                value: "448",
            },
            {
                label: "Abstürzen\r\nbei geöffnetem Rolltor und nicht angedocktem Fahrzeug oder Wechselbrücke.",
                value: "460",
            },
            {
                label: "Abstürzen\r\nvon Personen oder Flurförderzeugen durch nicht gegen Wegrollen gesichertes\r\nFahrzeug.",
                value: "461",
            },
            {
                label: "Stürzen\r\nzwischen Ladebrücken und Ladeflächen durch nicht bestimmungsgemäss aufgelegte\r\nLadebrücke.",
                value: "462",
            },
            {
                label: "Seitliches\r\nAbstürzen von der Ladebordwand, wenn diese auf die Ladebrücke aufgelegt ist.",
                value: "463",
            },
            {
                label: "Stolpern\r\nund Umknicken durch Höhenunterschied zwischen angehobener oder abgesenkter\r\nLadebrücke und den angrenzenden Verkehrsflächen.",
                value: "464",
            },
            {
                label: "Umstürzen\r\nvon Flurförderzeugen durch Höhenunterschied zwischen angehobener oder\r\nabgesenkter Ladebrücke und den angrenzenden Verkehrsflächen.",
                value: "465",
            },
            {
                label: "Von der mitgeführten Ladung\r\nkönnen Gefahren ausgehen.",
                value: "466",
            },
            {
                label: "Überlastung\r\ndes Rückens beim Heben und Tragen des Ladebleches.",
                value: "474",
            },
            {
                label: "Abstürzen\r\nvon der Laderampe während des Auflegens des Ladebleches.",
                value: "475",
            },
            {
                label: "Abstürzen\r\nvom wegrutschenden Ladeblech.",
                value: "476",
            },
            {
                label: "Stolpern\r\nan den Übergängen Ladeblech/Laderampe bzw. Ladeblech/Ladefläche bei starren\r\nLadeblechen (ohne Klapplippen).",
                value: "477",
            },
            {
                label: "Stolpern\r\nüber Stolperstellen, die sich seitlich zwischen Ladeblech und Laderampe ergeben.",
                value: "478",
            },
            {
                label: "Seitliches\r\nAbstürzen durch zu schmales Ladeblech.",
                value: "479",
            },
            {
                label: "Umstürzen\r\ndes hochkant abgestellten Ladebleches.",
                value: "480",
            },
            {
                label: "Sturz von Rampe in Pressbereich",
                value: "489",
            },
            {
                label: "Abstürzen von\r\nContainern und Mulden",
                value: "490",
            },
            {
                label: "Getroffen\r\nwerden von Ladegut",
                value: "491",
            },
            {
                label: "Getroffen\r\nwerden von aufspringenden Türen und Klappen",
                value: "492",
            },
            {
                label: "Dieses Gerät darf nur von\r\ngeschultem Personal benützt werden.",
                value: "500",
            },
            {
                label: "Das Gerät darf nur zur\r\nZubereitung von Speisen verwendet werden.",
                value: "501",
            },
            {
                label: "Heisse Geräteteile und\r\nheisses Fett/Öl.",
                value: "506",
            },
            {
                label: "Quetsch- und Scherstellen\r\nan mechanischen Hebeeinrichtungen.",
                value: "507",
            },
            {
                label: "Flüssigkeiten, die ohne\r\nFettabscheider in die Kanalisation gelangen, können zu Schäden durch\r\nVerstopfung der Abflussleitungen führen",
                value: "508",
            },
            {
                label: "Die eingesetzte Heizenergie\r\n(elektrischer Strom).",
                value: "509",
            },
            {
                label: "Gefahrbringender Nachlauf der Maschine.",
                value: "514",
            },
            {
                label: "Gefahr von Schnittverletzungen durch Werkzeug\r\nund Späne.",
                value: "521",
            },
            {
                label: "Verletzungen durch herunterfallende Werkstücke\r\nund andere Gegenstände.",
                value: "522",
            },
            {
                label: "Gehörschäden bei Lärmbelastung grösser als 85 dB\r\nA.",
                value: "525",
            },
            {
                label: "Gefahr eines Hängetraumas",
                value: "526",
            },
            {
                label: "Erdrückt\r\noder verletzt werden durch umkippende Platten.",
                value: "527",
            },
            {
                label: "Rückenschäden\r\ndurch den Plattentransport von Hand.",
                value: "528",
            },
            {
                label: "Fuss-\r\nund Zehenverletzungen durch hinunterfallende Platten.",
                value: "529",
            },
            {
                label: "Verletzungsgefahr durch\r\nRückschläge von Partikeln (Augen- und Gesichtsverletzung).",
                value: "533",
            },
            {
                label: "Eindringen von Luft in Körper durch\r\nHautverletzungen.",
                value: "534",
            },
            {
                label: "Gehörschäden\r\ndurch Lärm von Blasdüsen.",
                value: "535",
            },
            {
                label: "Kippgefahr bei zu grossen Steigungen.",
                value: "537",
            },
            {
                label: "Kippgefahr bei schneller Kurvenfahrt.",
                value: "538",
            },
            {
                label: "Vergiftungsgefahr bei Betrieb des Gerätes in\r\ngeschlossenen Räumen.",
                value: "539",
            },
            {
                label: "Gefährdung durch Einziehen an der Kettenführung.",
                value: "540",
            },
            {
                label: "Gefährdungen durch Lärm\r\nkönnen entstehen, wenn die Kompressoranlage als auch Hilfsaggregate nicht\r\nregelmässig gewartet werden.",
                value: "545",
            },
            {
                label: "Gefahren für Personen gehen\r\nvon den Beschickungsvorgängen aus, wenn sich Personen im Schutzbereich\r\nbefinden.",
                value: "546",
            },
            {
                label: "Unabsichtliches Auslösen\r\nder Geräte kann Stichverletzungen verursachen.",
                value: "550",
            },
            {
                label: "Hineinfallen in den Einfüllbereich der Maschine.",
                value: "562",
            },
            {
                label: "Gefahr durch umschlagende oder wegfliegende Schrottteile.",
                value: "563",
            },
            {
                label: "Quetsch- und Scherstellen an Übergabestellen, scharfe Ecken und Kanten",
                value: "564",
            },
            {
                label: "Gefährdungen durch herausgepresste Flüssigkeiten bei unsachgemässer Befüllung",
                value: "565",
            },
            {
                label: "Gefahr durch herabfallende Pressabschnitte",
                value: "566",
            },
            {
                label: "Durch das unsachgemässe Befüllen des Containers mit Abfall, der nicht für den Presscontainer\r\ngeeignet ist (z.B. Behälter mit Flüssigkeit), kann dieser auslaufen und ggf. die Behälterwand\r\nangreifen.",
                value: "567",
            },
            {
                label: "Inhalt/Behälter einer anerkannten Abfallentsorgungsanlage zuführen.",
                value: "576",
            },
            { label: "<p>asdfasdf</p>", value: "577" },
        ],
        order: 1,
    },
};
export default {
    name: "FormOrderedChoice",
    components: { TestForm },
    data() {
        return {
            data: {},
            form,
        };
    },
};
</script>
