import type { Formatter } from "../formatter";

export class MimeIcon implements Formatter {
    format(value: unknown): string {
        if (value == undefined || typeof value == "string") {
            return '<i class="fa ' + this.getIcon(value) + '"></i>';
        }
        throw Error("formatter/MimeIcon: invalid value " + JSON.stringify(value));
    }

    getIcon(value: string | undefined | null) {
        if (!value) {
            return "fa-file";
        }
        const [cat, type] = value.split("/");
        if (cat === "image") {
            return "fa-file-image";
        }
        if (cat === "text") {
            return "file-alt";
        }
        if (cat === "video") {
            return "fa-file-video";
        }
        if (cat === "audio") {
            return "fa-file-audio";
        }
        if (value === "inode/directory") {
            return "fa-folder";
        }
        if (cat === "application" && typeof type !== "undefined") {
            switch (type) {
            case "pdf":
                return "fa-file-pdf";
            case "msword":
            case "vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "vnd.openxmlformats-officedocument.wordprocessingml.template":
            case "application/vnd.ms-word.document.macroEnabled.12":
            case "application/vnd.ms-word.template.macroEnabled.12":
            case "vnd.oasis.opendocument.text":
            case "vnd.oasis.opendocument.text-template":
            case "vnd.oasis.opendocument.text-web":
                return "fa-file-word";
            case "vnd.ms-excel":
            case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "vnd.openxmlformats-officedocument.spreadsheetml.template":
            case "vnd.ms-excel.sheet.macroEnabled.12":
            case "vnd.ms-excel.template.macroEnabled.12":
            case "vnd.ms-excel.addin.macroEnabled.12":
            case "vnd.ms-excel.sheet.binary.macroEnabled.12":
            case "vnd.oasis.opendocument.spreadsheet":
            case "vnd.oasis.opendocument.spreadsheet-template":
            case "vnd.oasis.opendocument.chart":
                return "fa-file-excel";
            case "vnd.openxmlformats-officedocument.presentationml.presentation":
            case "vnd.openxmlformats-officedocument.presentationml.template":
            case "vnd.openxmlformats-officedocument.presentationml.slideshow":
            case "vnd.oasis.opendocument.presentation":
            case "vnd.oasis.opendocument.presentation-template":
            case "vnd.ms-powerpoint.addin.macroEnabled.12":
            case "vnd.ms-powerpoint.presentation.macroEnabled.12":
            case "vnd.ms-powerpoint.template.macroEnabled.12":
            case "vnd.ms-powerpoint.slideshow.macroEnabled.12":
                return "fa-file-powerpoint";
            case "application/x-rar-compressed":
            case "application/vnd.rar":
            case "application/zip":
            case "application/x-zip-compressed":
            case "application/gzip":
                return "fa-file-archive";
            }
        }
        return "fa-file";
    }
}
