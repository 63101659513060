<template>
    <test-form v-model:config="formConf" v-model="data" title="WYSIWYG" />
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "FormWysiwyg",
    components: { TestForm },
    data() {
        return {
            formConf: {
                "text": {
                    "type": "wysiwyg",
                    "label": "Text",
                    "order": 1,
                    "value": "<i>asdf</i>"
                },
                "ref": {
                    "type": "textarea",
                    "label": "Ref textarea",
                    "value": "asdf"
                }
            },
            data: {},
        }
    }
}
</script>
