<template>
    <v-row class="mx-2" style="max-height: 100%" @keydown="e => keyHandler(e)">
        <v-col style="max-height: 92dvh; overflow-y: scroll" cols="7">
            <asa-form
                ref="formRef"
                :config="config"
                :model-value="modelValue"
                :abort-btn="false"
                v-bind="props"
                @update:model-value="e => update(e)"
                @update:config="e => $emit('update:config', e)"
            >
                <template #title>
                    {{ title }}
                </template>
                <template v-if="description" #form-before>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div class="pb-5" v-html="description" />
                </template>
                <template v-for="(_slot, name) in allSlots" :key="name" #[name]="scope">
                    <slot :name="name" v-bind="scope || {}" />
                </template>
            </asa-form>
        </v-col>
        <v-col cols="5" style="height: 92dvh">
            <v-tabs v-model="tab" bg-color="grey-lighten-2">
                <v-tab value="value"> Model Value</v-tab>
                <v-tab value="config"> Config</v-tab>
                <v-tab value="props"> Props</v-tab>
            </v-tabs>
            <v-window v-model="tab">
                <v-window-item value="config">
                    <v-input
                        :error="configError !== null"
                        :error-messages="configError !== null ? [configError] : []"
                    >
                        <div>
                            <codemirror
                                style="max-height: 85dvh"
                                :extensions="extensions"
                                :model-value="configJson"
                                @update:model-value="sourceUpdated"
                            />
                        </div>
                    </v-input>
                </v-window-item>
                <v-window-item value="value">
                    <v-input
                        :class="{ 'bg-red-lighten-5': !!valueError }"
                        :error="valueError !== null"
                        :error-messages="valueError !== null ? [valueError] : []"
                    >
                        <div class="w-100">
                            <codemirror
                                style="max-height: 85dvh"
                                :extensions="extensions"
                                :model-value="modelValueJson"
                                @update:model-value="valueUpdated"
                            />
                        </div>
                    </v-input>
                </v-window-item>
                <v-window-item value="props">
                    <v-input
                        :class="{ 'bg-red-lighten-5': !!propsError }"
                        :error="propsError !== null"
                        :error-messages="propsError !== null ? [propsError] : []"
                    >
                        <div class="w-100">
                            <codemirror
                                style="max-height: 85dvh"
                                :extensions="extensions"
                                :model-value="propsJson"
                                @update:model-value="propsUpdated"
                            />
                        </div>
                    </v-input>
                </v-window-item>
            </v-window>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { VCol, VRow, VTab, VTabs, VWindow, VWindowItem, VInput } from "vuetify/components";
import AsaForm from "../../src/component/LForm.vue";
import { Codemirror } from "vue-codemirror";
import { keymap } from "@codemirror/view";
import { linter } from "@codemirror/lint";
import { json5, json5ParseLinter } from "codemirror-json5";
import Json5 from "json5";
import { defaultKeymap, deleteLine } from "@codemirror/commands";
import { defineComponent, type PropType, toRaw, useSlots } from "vue";
import type { FormConfig } from "../../src/config/Form";

const jsonOpts = {
    space: 2,
    quote: '"',
};

export default defineComponent({
    name: "TestForm",
    components: { AsaForm, Codemirror, VRow, VCol, VTab, VTabs, VWindow, VWindowItem, VInput },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: "",
        },
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
        },
        modelValue: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    emits: ["update:modelValue", "update:config"],
    setup() {
        return {
            allSlots: useSlots(),
        };
    },
    data() {
        return {
            tab: "value",
            editorValue: "",
            configJson: "{}",
            configError: null,
            modelValueJson: "{}",
            valueError: null as null | string,
            timeout: -1,
            props: {},
            propsJson: "{}",
            propsError: null,
        };
    },
    computed: {
        form(): InstanceType<typeof AsaForm> | undefined {
            return this.$refs.formRef as InstanceType<typeof AsaForm> | undefined;
        },
        extensions() {
            const keys = [
                ...defaultKeymap,
                {
                    key: "Shift-Delete",
                    run: deleteLine,
                },
            ];
            return [keymap.of(keys), json5(), linter(json5ParseLinter())];
        },
    },
    watch: {
        $attrs: {
            handler(attrs) {
                this.props = toRaw(attrs);
                let myAttrs: string | null = null;
                try {
                    myAttrs = Json5.stringify(Json5.parse(this.propsJson));
                } catch (e) {
                    // do nothing
                }
                if (!myAttrs || myAttrs !== Json5.stringify(attrs)) {
                    this.propsJson = Json5.stringify(attrs, jsonOpts);
                }
                this.propsError = null;
            },
            immediate: true,
            deep: true,
        },
        config: {
            handler(cfg) {
                if (
                    this.configError ||
                    Json5.stringify(Json5.parse(this.configJson)) !== Json5.stringify(cfg)
                ) {
                    this.configJson = Json5.stringify(cfg, jsonOpts);
                }
                this.configError = null;
            },
            immediate: true,
            deep: true,
        },
        modelValue: {
            handler(value) {
                if (
                    this.valueError ||
                    Json5.stringify(Json5.parse(this.modelValueJson)) !== Json5.stringify(value)
                ) {
                    this.modelValueJson = Json5.stringify(value, jsonOpts);
                }
                this.valueError = null;
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        sourceUpdated(e: string) {
            this.configJson = e;
            if (this.timeout !== -1) {
                clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.updateConfig();
            }, 1000);
        },
        updateConfig() {
            try {
                const cfg = Json5.parse(this.configJson);
                this.$emit("update:config", cfg);
                this.configError = null;
            } catch (e: any) {
                this.configError = e.message;
            }
        },
        valueUpdated(e: string) {
            this.modelValueJson = e;
            if (this.timeout !== -1) {
                clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.updateValue();
            }, 1000);
        },
        updateValue() {
            try {
                this.editorValue = Json5.parse(this.modelValueJson);
                this.$emit("update:modelValue", toRaw(this.editorValue));
                this.valueError = null;
            } catch (e: any) {
                this.valueError = e.message ?? null;
            }
        },
        propsUpdated(e: string) {
            this.propsJson = e;
            if (this.timeout !== -1) {
                clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.updateProps();
            }, 1000);
        },
        updateProps() {
            try {
                this.props = Json5.parse(this.propsJson);
                this.$emit("update:modelValue", toRaw(this.props));
                this.propsError = null;
            } catch (e: any) {
                this.propsError = e.message ?? null;
            }
        },
        update(e: any) {
            this.$emit("update:modelValue", e);
        },
        keyHandler(e: KeyboardEvent) {
            if (e.ctrlKey && e.key == "s") {
                // Ctrl+s ignorieren, weil das hart vertratet ist in Nikos fingern
                e.preventDefault();
            }
        },
    },
    expose: ["form"],
});
</script>

<style>
codemirror .cm-focused {
    outline: none !important;
}
</style>
