<template>
    <component
        :is="componentType"
        v-if="componentExists(componentType)"
        :id="id"
        :config="config"
        :name="id"
        class="my-2"
        @update:model-value="e => $emit('update:modelValue', e)"
        @partial-update:value="e => $emit('partialUpdate:value', e)"
        @partial-update:config="e => $emit('partialUpdate:config', e)"
        @update:config="e => $emit('update:config', e)"
    />
    <v-alert v-else type="error">Unknown component {{ componentType }}</v-alert>
</template>

<script lang="ts">
import { VAlert } from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import type { FormConfigRow } from "../config/Form";
import { useComponentExists } from "../composables/ComponentExists";

export default defineComponent({
    components: { VAlert },
    inheritAttrs: true,
    props: {
        id: {
            type: String,
            required: true,
        },
        config: {
            type: Object as PropType<FormConfigRow>,
            required: true,
            validator(config) {
                if (typeof config === "object" && config !== null && !("type" in config)) {
                    console.error("LFormRow: config.type must be set to a string value");
                    return false;
                }
                return true;
            },
        },
    },
    emits: ["update:config", "update:modelValue", "partialUpdate:value", "partialUpdate:config"],
    setup() {
        return {
            componentExists: useComponentExists(),
        };
    },
    computed: {
        componentType(): string {
            const typeMap = {
                select: "LFormRowAutocomplete",
                treenode: "LFormRowTreeNode",
                image: "LFormRowFile",
                wysiwyg: "LFormRowRichText",
                colorpicker: "LFormRowColor",
                signatur: "LFormRowSignature",
            };
            const t = this.config.type || "text";
            if (t in typeMap) {
                return typeMap[t];
            }
            return "LFormRow" + t.charAt(0).toUpperCase() + t.slice(1);
        },
    },
});
</script>

<style lang="scss">
.required > label:after,
.required .v-field-label:after {
    content: "\00a0*";
}
</style>
