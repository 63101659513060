import { useI18n } from "vue-i18n";
import type { Formatter } from "../formatter";

export class Float implements Formatter {
    protected readonly i18n: ReturnType<typeof useI18n>;

    constructor(
        _settings: undefined,
        readonly locale: string,
    ) {
        this.i18n = useI18n();
    }

    format(value: unknown): string {
        if (typeof value !== "string") {
            throw Error("Invalid value passed to Float formatter" + JSON.stringify(value));
        }
        const parsedValue = Number.parseFloat(value);
        if (isNaN(parsedValue)) {
            return "";
        } else {
            return this.i18n.n(parsedValue, { locale: this.locale });
        }
    }
}
