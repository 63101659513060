const dataURItoBlob = dataURI => {
    const bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
            ? atob(dataURI.split(",")[1])
            : unescape(dataURI.split(",")[1]);
    const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
};

export function resizeImage(file: File, maxSize: number): Promise<File> {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement("canvas");

    const resize = () => {
        let { width, height } = image;

        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            throw new Error("Could not get 2d context");
        }
        ctx.drawImage(image, 0, 0, width, height);

        const dataUrl = canvas.toDataURL(file.type);

        return new File([dataURItoBlob(dataUrl)], file.name, {
            lastModified: new Date().getTime(),
            type: file.type,
        });
    };

    return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
            reject(new Error("Not an image"));
            return;
        }

        reader.onload = readerEvent => {
            image.onload = () => resolve(resize());
            if (!readerEvent.target) {
                return;
            }
            image.src = <string>readerEvent.target.result;
        };

        reader.readAsDataURL(file);
    });
}
