<template>
    <test-form v-model="value" v-model:config="cfg" title="Asa user" />
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "PlaygroundForm",
    components: {
        TestForm,
    },
    data() {
        return {
            cfg: {
                f: { type: "text" }
            },
            value: {}
        };
    },
};
</script>
