import { useI18n } from "vue-i18n";

export class Locale {
    protected i18n = useI18n();

    format(value: unknown): string {
        if (!value) {
            return "";
        }
        if (typeof value !== "string") {
            console.error("formatter/Locale: invalid value type " + typeof value);
            return "#error#";
        }
        const prefix = "lumui.locale.lang.";
        const k = prefix + value;
        const trans = this.i18n.t(k);
        if (trans.startsWith(prefix)) {
            // not translated
            return value;
        } else {
            return trans;
        }
    }
}
