export default {
    "$vuetify.badge": "Abzeichen",
    "$vuetify.open": "Öffnen",
    "$vuetify.close": "Schließen",
    "$vuetify.confirmEdit.ok": "OK",
    "$vuetify.confirmEdit.cancel": "Abbrechen",
    "$vuetify.dataIterator.noResultsText": "Keine Elemente gefunden",
    "$vuetify.dataIterator.loadingText": "Lade Elemente...",
    "$vuetify.dataTable.itemsPerPageText": "Zeilen pro Seite:",
    "$vuetify.dataTable.ariaLabel.sortDescending": "Absteigend sortiert.",
    "$vuetify.dataTable.ariaLabel.sortAscending": "Aufsteigend sortiert.",
    "$vuetify.dataTable.ariaLabel.sortNone": "Nicht sortiert.",
    "$vuetify.dataTable.ariaLabel.activateNone": "Aktivieren um Sortierung zu entfernen.",
    "$vuetify.dataTable.ariaLabel.activateDescending": "Aktivieren um absteigend zu sortieren.",
    "$vuetify.dataTable.ariaLabel.activateAscending": "Aktivieren um aufsteigend zu sortieren.",
    "$vuetify.dataTable.sortBy": "Sortiere nach",
    "$vuetify.dataFooter.itemsPerPageText": "Elemente pro Seite:",
    "$vuetify.dataFooter.itemsPerPageAll": "Alle",
    "$vuetify.dataFooter.nextPage": "Nächste Seite",
    "$vuetify.dataFooter.prevPage": "Vorherige Seite",
    "$vuetify.dataFooter.firstPage": "Erste Seite",
    "$vuetify.dataFooter.lastPage": "Letzte Seite",
    "$vuetify.dataFooter.pageText": "{0}-{1} von {2}",
    "$vuetify.dateRangeInput.divider": "bis",
    "$vuetify.datePicker.itemsSelected": "{0} selected",
    "$vuetify.datePicker.range.title": "Daten auswählen",
    "$vuetify.datePicker.range.header": "Daten eingeben",
    "$vuetify.datePicker.title": "Datum auswählen",
    "$vuetify.datePicker.header": "Datum eingeben",
    "$vuetify.datePicker.input.placeholder": "Datum eingeben",
    "$vuetify.noDataText": "Keine Daten vorhanden",
    "$vuetify.carousel.prev": "Vorheriges Bild",
    "$vuetify.carousel.next": "Nächstes Bild",
    "$vuetify.carousel.ariaLabel.delimiter": "Element {0} von {1}",
    "$vuetify.calendar.moreEvents": "{0} mehr",
    "$vuetify.calendar.today": "Today",
    "$vuetify.input.clear": "{0} leeren",
    "$vuetify.input.prependAction": "{0} vorangestellte Aktion",
    "$vuetify.input.appendAction": "{0} angehängte Aktion",
    "$vuetify.input.otp": "Bitte OTP-Zeichen {0} eingeben",
    "$vuetify.fileInput.counter": "{0} Dateien",
    "$vuetify.fileInput.counterSize": "{0} Dateien ({1} gesamt)",
    "$vuetify.timePicker.am": "AM",
    "$vuetify.timePicker.pm": "PM",
    "$vuetify.pagination.ariaLabel.root": "Seitennavigation",
    "$vuetify.pagination.ariaLabel.next": "Nächste Seite",
    "$vuetify.pagination.ariaLabel.previous": "Vorherige Seite",
    "$vuetify.pagination.ariaLabel.page": "Gehe zu Seite {0}",
    "$vuetify.pagination.ariaLabel.currentPage": "Aktuelle Seite, Seite {0}",
    "$vuetify.pagination.ariaLabel.first": "Erste Seite",
    "$vuetify.pagination.ariaLabel.last": "Letzte Seite",
    "$vuetify.stepper.next": "Weiter",
    "$vuetify.stepper.prev": "Zurück",
    "$vuetify.rating.ariaLabel.item": "Bewertung {0} von {1}",
    "$vuetify.loading": "Laden...",
    "$vuetify.infiniteScroll.loadMore": "Mehr laden",
    "$vuetify.infiniteScroll.empty": "Nichts weiteres"
};