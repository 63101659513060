<template>
    <div>
        <test-form v-model="value" v-model:config="config" title="Signatur" />
        <img style="border: 1px solid black; min-width: 50px; min-height: 50px" :src="value.sig" />
    </div>
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "Signature",
    components: { TestForm },
    data() {
        return {
            value: {},
            config: {
                sig: {
                    type: "signatur",
                    label: "Unterschrift",
                    value: "",
                    required: true
                }
            }
        };
    }
}
</script>

