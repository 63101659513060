<template>
    <asa-form ref="form" :config="config" :model-value="value">
        <template #additional-actions>
            <v-btn @click="makeErrors()">Mach mal das falsch ist</v-btn>
        </template>
    </asa-form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { type FormConfig } from "../../../src/config/Form";
import { assertDefined } from "../../../src/lib/assert";
import AsaForm from "../../../src/component/LForm.vue";
import { type FormErrors } from "../../../src/lib/form/setFormErrors";
import { VBtn } from "vuetify/components";

const value = ref({});
const config = ref<FormConfig>({
    f1: { type: "text", label: "Foo" },
});
const form = ref<InstanceType<typeof AsaForm> | undefined>();

const errors: FormErrors = {
    f1: ["Error"],
};

function makeErrors() {
    const f = form.value;
    assertDefined(f);
    f?.setErrors(errors);
}
</script>
