<template>
    <v-container>
        <h1>Boolean</h1>
        true =
        <asa-formatter type="bool" :value="true" />
        <br />
        1 =
        <asa-formatter type="bool" :value="1" />
        <br />
        false =
        <asa-formatter type="bool" :value="false" />
        <br />
        0 =
        <asa-formatter type="bool" :value="0" />
    </v-container>
</template>

<script>
import AsaFormatter from "../../../src/component/LFormatter.vue";
import { VContainer } from "vuetify/components";

export default {
    name: "FormatterBool",
    components: {
        AsaFormatter,
        VContainer,
    },
};
</script>
