import type { Formatter } from "../formatter";
import escape from "../escape";

export default class Text implements Formatter {
    format(value: unknown): string {
        if (!value) {
            return "";
        }
        return escape(String(value));
    }
}

export const aliases = ["textarea"] as const;
