<template>
    <div v-if="isVisible">
        <v-input
            ref="inputField"
            :class="{ required: isRequired }"
            :avoid-drag="avoidDrag"
            :disabled="isDisabled || isSaving"
            :error="error"
            :error-messages="aggregatedErrors"
            :label="theLabel"
            :messages="msg"
            :required="isRequired"
            :rules="validationRules"
            class="input--file"
        >
            <div class="d-flex flex-column w-100">
                <v-label :color="error ? 'error' : ''" :focused="true">
                    {{ theLabel }}
                </v-label>
                <div
                    v-if="canUpload"
                    :class="{ disabled: disabled, hover: dragOver }"
                    class="dropbox"
                    @drop="onDrop"
                    @dragover.prevent="dragOver = true"
                    @dragleave.prevent="dragOver = false"
                >
                    <input
                        ref="file"
                        :accept="acceptValues"
                        :multiple="multiple"
                        class="input-file"
                        name="files"
                        type="file"
                        @change="(e: Event) => handleFilesChanged(e)"
                    />
                    <p v-if="isSaving">
                        {{ i18n.t("lumui.form.file.uploading", { count: fileCount }) }}
                        <v-progress-circular indeterminate small />
                    </p>
                    <p v-else @click="launchFilePicker()">
                        <template v-if="display.smAndDown.value || avoidDrag">
                            {{ i18n.t("lumui.form.file.select_file") }}
                        </template>
                        <template v-else>
                            {{ i18n.t("lumui.form.file.drag") }}
                        </template>
                    </p>
                </div>
                <template v-if="isFailed">
                    <v-alert :value="true" type="error">
                        {{ i18n.t("lumui.form.file.error") }}<br />
                        <pre>{{ uploadError }}</pre>
                    </v-alert>
                </template>
                <v-alert
                    v-for="(file, i) in removedFiles"
                    :key="i"
                    :value="true"
                    class="my-2"
                    type="warning"
                >
                    {{
                        i18n.t("lumui.form.file.warning.removed_file", {
                            name: file.name,
                            type: file.type,
                            acceptedTypes: printAccept,
                        })
                    }}
                </v-alert>
            </div>
        </v-input>

        <template v-for="(item, index) in files" :key="index + '-' + item.name">
            <div class="d-flex my-2">
                <div class="preview">
                    <img
                        v-if="isImage(item)"
                        :alt="item.name"
                        :src="base64(item)"
                        @click="preview(item)"
                    />
                    <v-icon v-else> $file</v-icon>
                </div>
                <div class="pr-2 align-self-center mx-3 flex-grow-1">
                    <span class="subheading">{{ item.name }}</span>
                    <v-chip :rounded="true" class="ma-2" variant="outlined">
                        {{ formattedFileSize(item) }}
                    </v-chip>
                </div>
                <div class="text-no-wrap align-self-center">
                    <v-btn :disabled="isDisabled" class="ma-0" icon @click="remove(item)">
                        <v-icon>$delete</v-icon>
                        <v-tooltip
                            :text="i18n.t('lumui.form.file.remove')"
                            activator="parent"
                            location="bottom"
                        />
                    </v-btn>
                    <v-btn v-if="isImage(item)" class="my-0 mx-1" icon @click="preview(item)">
                        <v-icon>$preview</v-icon>
                        <v-tooltip
                            :text="i18n.t('lumui.form.file.preview')"
                            activator="parent"
                            location="bottom"
                        />
                    </v-btn>
                    <v-btn class="ma-0" icon @click="download(item)">
                        <v-icon>$download</v-icon>
                        <v-tooltip
                            :text="i18n.t('lumui.form.file.download')"
                            activator="parent"
                            location="bottom"
                        />
                    </v-btn>
                </div>
            </div>
        </template>

        <l-dialog v-model="dialog">
            <v-card flat>
                <v-toolbar class="flex-grow-0" color="primary" dark>
                    <v-toolbar-title>
                        <slot :config="config" name="title">
                            {{ previewFile?.name ?? "" }}
                        </slot>
                    </v-toolbar-title>
                    <v-spacer />

                    <template #append>
                        <v-icon @click="dialog = false"> $close</v-icon>
                    </template>
                </v-toolbar>

                <v-card-text id="scrollarea">
                    <template v-if="previewFile">
                        <img
                            v-if="isImage(previewFile)"
                            :alt="previewFile.name"
                            :src="base64(previewFile)"
                        />
                        <p v-else>
                            {{ i18n.t("lumui.form.file.previewError", { name: previewFile.name }) }}
                        </p>
                    </template>
                </v-card-text>
            </v-card>
        </l-dialog>
        <v-divider />
    </div>
</template>

<script lang="ts">
import { filesize } from "filesize";
import {
    VAlert,
    VBtn,
    VCard,
    VCardText,
    VChip,
    VDivider,
    VIcon,
    VInput,
    VLabel,
    VToolbar,
    VToolbarTitle,
    VProgressCircular,
    VTooltip,
    VSpacer,
} from "vuetify/components";
import { resizeImage } from "../lib/resizeImage";
import { saveAs } from "file-saver";
import { defineComponent, type PropType } from "vue";
import { useDisplay } from "vuetify";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import {
    type AcceptFileFilter,
    type FileValue,
    type FormConfigFile,
    isDataUri,
} from "../config/Form";
import { formConfigFileSchema } from "../config/Form.zod";
import LDialog from "./LDialog.vue";
import { useI18n } from "vue-i18n";
import { nonReactiveClone } from "../lib/nonReactiveClone";

enum Status {
    INITIAL = 0,
    SELECTED = 1,
    SAVING = 2,
    SUCCESS = 3,
    FAILED = 4,
}

const defaultAccept: AcceptFileFilter = {
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    pdf: "application/pdf",
};

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type 'file' or 'image' |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | maxFileSize            | `Number`                   | no       | 52428800   | maximum file size |
 * | avoidDrag              | `Boolean`                  | no       | `false`    | ???               |
 * | accept                 |  `Object`                  | no       | `{png: 'image/png', jpeg: 'image/jpeg', jpg: 'image/jpg', pdf: 'application/pdf'}` | allowed mime types |
 * | multiple               | `Boolean`                  | no       | `false`   | Allow multiple file uploads |
 * | maxImageSize            | `Number`                   | no       | 0   | maximum image width / height |
 */
export default defineComponent({
    components: {
        LDialog,
        VCard,
        VCardText,
        VToolbar,
        VToolbarTitle,
        VIcon,
        VBtn,
        VAlert,
        VChip,
        VInput,
        VDivider,
        VLabel,
        VProgressCircular,
        VTooltip,
        VSpacer,
    },
    props: {
        /**
         * An array of file objects `{name: "filename", src: "base64 encoded file content", size: "file size" }`
         * **Note:** Passing a string, is probably broken
         */
        modelValue: {
            type: [Object, Array] as PropType<FileValue | FileValue[]>,
            default: undefined,
        },
        /** allow more than one file to be uploaded */
        isMultiple: {
            type: Boolean,
            default: null,
        },
        /**
         * the mime types to allow. the keys are used for hint and error message,
         * but have no significance for validation
         */
        accept: {
            type: Object as PropType<AcceptFileFilter>,
            default: null,
            required: false,
        },
        /**
         * Maximum file size allowed in bytes
         */
        maxFileSize: {
            type: Number,
            default: null, //50 MB
        },
        /**
         * Maximum image width / height allowed in pixel
         */
        maxImageSize: {
            type: Number,
            default: null,
        },
        ...makeDefaultProps<FormConfigFile>(formConfigFileSchema),
    },
    emits: ["update:modelValue", "update:error"],
    setup(props, { emit }) {
        const i18n = useI18n();
        const { smAndDown } = useDisplay();
        return {
            i18n,
            display: { smAndDown },
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            files: [] as FileValue[],
            fileCount: 0,
            removedFiles: [] as Array<{ name: string; type: string }>,
            uploadError: null as string | null,
            currentStatus: Status.INITIAL as Status,
            previewFile: null as FileValue | null,
            dialog: false,
            msg: [] as string[],
            dragOver: false,
        };
    },
    computed: {
        fileElement(): HTMLInputElement {
            return this.fileElement as HTMLInputElement;
        },
        inputFieldElement(): HTMLInputElement {
            return this.inputFieldElement as HTMLInputElement;
        },
        canUpload(): boolean {
            return !this.isDisabled && (this.multiple || !this.hasFiles);
        },
        multiple(): boolean {
            return this.isMultiple || this.config.multiple || false;
        },
        hasFiles() {
            return this.files.length > 0;
        },
        isInitial() {
            return this.currentStatus === Status.INITIAL;
        },
        isSelected() {
            return this.currentStatus === Status.SELECTED;
        },
        isSaving() {
            return this.currentStatus === Status.SAVING;
        },
        isSuccess() {
            return this.currentStatus === Status.SUCCESS;
        },
        isFailed() {
            return this.currentStatus === Status.FAILED;
        },
        formattedMaxFileSize() {
            return filesize(this.myMaxFileSize);
        },
        acceptedTypes() {
            return this.accept || this.config.accept || defaultAccept;
        },
        acceptValues() {
            return Object.values(this.acceptedTypes).join(",");
        },
        printAccept() {
            return Object.keys(this.acceptedTypes).join(", ");
        },
        validationRules() {
            if (this.rules) {
                return this.rules;
            }
            return [
                () => !this.isRequired || this.hasFiles || this.i18n.t("lumui.form.row.required"),
            ];
        },
        avoidDrag() {
            return this.config.avoidDrag || false;
        },
        myMaxFileSize(): number {
            return this.maxFileSize || this.config.maxFileSize || 50 * 1024 * 1024;
        },
        myMaxImageSize(): number {
            return this.maxImageSize || this.config.maxImageSize || 0;
        },
        myAccept(): AcceptFileFilter {
            return this.accept || this.config.accept || defaultAccept;
        },
    },
    watch: {
        modelValue() {
            if (this.modelValue) {
                if (!Array.isArray(this.modelValue)) {
                    this.files = [this.modelValue];
                } else {
                    this.files = this.modelValue;
                }
            } else {
                this.files = [];
            }
        },
    },
    mounted() {
        let msg = this.i18n.t("lumui.form.file.maxSize", { size: this.formattedMaxFileSize });
        msg += " ";
        msg +=
            this.acceptValues !== "*"
                ? this.i18n.t("lumui.form.file.extensions.accepted", { types: this.printAccept })
                : this.i18n.t("lumui.form.file.extensions.all");
        this.msg = [msg];
        this.reset();
    },
    methods: {
        /** @private */
        onDrop: function (e: DragEvent) {
            e.stopPropagation();
            e.preventDefault();
            this.dragOver = false;
            if (!(e.target instanceof HTMLElement) || !e.dataTransfer) {
                return;
            }
            this.filesChange(e.dataTransfer.files);
        },
        /** trigger the file selection dialog */
        launchFilePicker() {
            this.fileElement.click();
        },
        /** clear the field */
        reset() {
            this.currentStatus = Status.INITIAL;
            this.files = [];
            this.uploadError = null;
        },
        /** @private */
        save(formData: File[]) {
            // upload data to the server
            this.currentStatus = Status.SAVING;

            this.upload(formData)
                .then(x => {
                    const files = this.multiple ? this.files.concat(x) : x;
                    this.currentStatus = Status.SUCCESS;
                    this.$emit("update:modelValue", nonReactiveClone(files));
                })
                .catch(err => {
                    console.error("LFormRowFile", err);
                    this.uploadError = err.response;
                    this.currentStatus = Status.FAILED;
                });
        },
        /** @private */
        async upload(
            formData: File[],
        ): Promise<{ name: string; mimetype: string; size: number; src: string }[]> {
            const promises = formData.map(async x => {
                if (this.isImage(x) && this.myMaxImageSize > 0) {
                    let f = await resizeImage(x, this.myMaxImageSize);
                    let file = await this.readFile(f);
                    return {
                        name: f.name,
                        mimetype: f.type,
                        size: f.size,
                        src: file,
                    };
                }
                let file1 = await this.readFile(x);
                return {
                    name: x.name,
                    mimetype: x.type,
                    size: x.size,
                    src: file1,
                };
            });
            return Promise.all(promises);
        },
        /** @private */
        readFile(file: File): Promise<string> {
            return new Promise<string>(resolve => {
                const fReader = new FileReader();
                fReader.onload = () => {
                    if (typeof fReader.result !== "string") {
                        // does not happen, as readAsDataURL returns string, but the api is a bit broken
                        throw Error("Unexpected file reader result " + typeof fReader.result);
                    }
                    resolve(fReader.result);
                };
                fReader.readAsDataURL(file);
            });
        },
        validateFileSize(size: number): boolean {
            const limit = this.myMaxFileSize;
            if (limit > 0) {
                return size <= limit;
            }
            return true;
        },
        handleFilesChanged(e: Event) {
            if (!e.target || !(e.target instanceof HTMLInputElement) || e.target.files == null) {
                return;
            }
            this.filesChange(e.target.files);
        },
        /** @private */
        filesChange(fileList: FileList) {
            const removedFiles = new Array<{ name: string; type: string }>();
            // handle file changes
            const formData = new Array<File>();
            let size = 0;
            this.fileCount = fileList.length;
            if (!fileList.length) {
                return;
            }
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                if (this.isAccepted(file)) {
                    size += file.size;
                    formData.push(file);
                } else {
                    removedFiles.push({ name: file.name, type: file.type });
                }
            }
            this.removedFiles = removedFiles;
            if (!this.validateFileSize(size)) {
                this.currentStatus = Status.FAILED;
                this.uploadError = this.i18n.t("lumui.form.file.maxSizeError", {
                    size: this.formattedMaxFileSize,
                });
                return;
            }
            this.currentStatus = Status.SELECTED;
            // append the files to FormData
            this.save(formData);
            // Clear the form element 'file' to ensure the onChange handler is responsive
            this.fileElement.value = "";
        },
        /** @private */
        isAccepted(file: File) {
            for (const key in this.myAccept) {
                if (!Object.hasOwn(this.myAccept, key)) {
                    continue;
                }
                let types = this.myAccept[key];
                if (!Array.isArray(types)) {
                    types = [types];
                }
                for (let i = 0; i < types.length; i++) {
                    const type = types[i];
                    const n = type.indexOf("*");
                    if (!file.type && file.name) {
                        if (file.name.split(".").pop()?.toLowerCase() === key.toLowerCase()) {
                            return true;
                        }
                    } else if (file.type && n < 0) {
                        if (file.type.toLowerCase() === type.toLowerCase()) {
                            return true;
                        }
                    } else {
                        const prefix = type.slice(0, n);
                        if (file.type.startsWith(prefix)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        /** @private */
        remove(file: FileValue) {
            const index = this.files.indexOf(file);
            this.files.splice(index, 1);
            this.currentStatus = Status.SUCCESS;
        },
        formattedFileSize(item: FileValue) {
            if (item.size) {
                return filesize(item.size);
            } else if (item.src.length > 0) {
                return filesize(item.src.length);
            } else {
                return this.i18n.t("lumui.form.file.unknown_size");
            }
        },
        /** @private */
        preview(file: FileValue) {
            this.previewFile = file;
            this.dialog = true;
        },
        /** @private */
        download(file: FileValue) {
            saveAs(this.dataURItoBlob(file.src), file.name);
        },
        /** @private */
        base64(file: FileValue) {
            if (file.src == null) {
                return "";
            }
            const type = this.getType(file) !== null ? this.getType(file) : "image/jpg";
            return file.src.indexOf(";base64,") > 0 ? file.src : `data:${type};base64,${file.src}`;
        },
        /** @private */
        isImage(file: FileValue | File): boolean {
            const t = this.getType(file);
            return !!file && t !== null ? t.indexOf("image/") > -1 : false;
        },
        getType(file: File | FileValue): string | null {
            if ("type" in file) {
                return file.type ?? null;
            } else if ("mimetype" in file) {
                return file.mimetype ?? null;
            }
            if ("src" in file && isDataUri(file.src)) {
                return file.src.split(",")[0].split(":")[1].split(";")[0];
            }
            return null;
        },
        /** @private */
        dataURItoBlob(dataURI: string, mimeString?: string) {
            const byteString = atob(dataURI.split(",")[1]);
            if (typeof mimeString === "undefined") {
                mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
            }
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        },
    },
});
</script>

<style scoped>
.preview {
    width: 128px;
    max-height: 128px;
}

/* noinspection CssOverwrittenProperties */
.preview > img {
    cursor: pointer;
    cursor: zoom-in;
    max-width: 128px;
    max-height: 128px;
    margin: auto;
}

.dropbox {
    display: block;
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: rgba(0, 0, 0, 0.06);
    color: dimgray;
    padding: 10px 10px;
    min-height: 100px; /* minimum height */
    position: relative;
    cursor: pointer;
    width: 100%;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover,
.dropbox.hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 25px 0;
    margin: 0;
}

.dropbox.disabled {
    color: #c0c0c0;
    outline: 2px dashed #c0c0c0;
}

.dropbox.disabled:hover {
    color: #c0c0c0;
    cursor: default;
}
</style>
