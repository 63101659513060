<template>
    <v-input
        v-if="isVisible"
        :id="id"
        :disabled="isDisabled"
        :error="error"
        :error-messages="aggregatedErrors"
        :label="theLabel"
        :name="name"
        :required="isRequired"
        :rules="validationRules"
        class="input--colorpicker"
    >
        <div :class="['d-flex', 'flex-column', { required: isRequired }]">
            <v-label :text="theLabel" />
            <v-color-picker
                :disabled="isDisabled"
                :model-value="modelValue"
                elevation="0"
                :mode="mode"
                :modes="[mode]"
                :hide-sliders="isHideSliders"
                :hide-inputs="isHideInputs"
                :hide-canvas="isHideCanvas"
                :show-swatches="isShowSwatches"
                @update:model-value="e => $emit('update:modelValue', e)"
            />
        </div>
    </v-input>
</template>

<script lang="ts">
import { VColorPicker, VInput, VLabel } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigColor } from "../config/Form.js";
import { defineComponent } from "vue";
import { formConfigColorSchema } from "../config/Form.zod";
import { useI18n } from "vue-i18n";

/**
 * Color picker.
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"colorpicker"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | false      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | false      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | false      | field is rendered.  |
 */
export default defineComponent({
    components: { VInput, VColorPicker, VLabel },
    props: {
        /** field value */
        modelValue: {
            type: String,
            required: false,
            default: undefined,
            validator(c: unknown): boolean {
                if (c === undefined) {
                    return true;
                }
                if (typeof c !== "string") {
                    console.error("LFormRowColor: value must be a string, got " + typeof c);
                    return false;
                }
                if (
                    c.match(/^#[0-9A-Fa-f]{3}|[0-9A-Fa-f]{4}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8}?$/) ===
                    null
                ) {
                    console.error("LFormRowColor: Color must have the for #FFF or #FFFFFF");
                    return false;
                }
                return true;
            },
        },
        ...makeDefaultProps<FormConfigColor>(formConfigColorSchema),
    },
    emits: ["update:modelValue", "update:error"],
    setup(props, { emit }) {
        const { t } = useI18n();
        return {
            t,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            viewValue: "",
        };
    },
    computed: {
        isHideInputs() {
            return this.config.hideInputs ?? true;
        },
        isHideCanvas() {
            return this.config.hideCanvas ?? true;
        },
        isShowSwatches() {
            return this.config.showSwatches ?? false;
        },
        isHideSliders() {
            return this.config.hideSliders ?? false;
        },
        validationRules() {
            if (this.rules !== null) {
                return this.rules;
            }
            const required = this.isRequired;
            return [
                (v: unknown) =>
                    !required ||
                    (typeof v === "string" && v.length > 0) ||
                    this.t("lumui.form.row.required"),
            ];
        },
        mode() {
            return this.config.mode ?? "hex";
        },
    },
});
</script>

<style>
.v-color-picker {
    min-width: 300px;
}
</style>
