import type { Formatter } from "../formatter";

export class KeyValue implements Formatter {
    protected map: Record<string, string>;

    constructor(settings: [Record<string, string>]) {
        this.map = settings[0];
    }

    format(value: unknown) {
        if (typeof value != "string") {
            throw Error("formatter/KeyValue: invalid value" + JSON.stringify(value));
        }
        return this.map[value];
    }
}
