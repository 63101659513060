<template>
    <v-container>
        Custom Formatter formats "{{ value }}" as "<AsaFormatter type="custom" :value="value" />"
    </v-container>
</template>

<script setup lang="ts">
import { VContainer } from "vuetify/components";
import AsaFormatter from "../../../src/component/LFormatter.vue";

const value = "asdfqwer";
</script>
