<template>
    <div>
        <test-form v-model="value" v-model:config="config" title="Radio buttons" />
    </div>
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "Radio",
    components: { TestForm },
    data() {
        return {
            value: {},
            config: {
                el: {
                    type: "radio",
                    label: "Radio",
                    required: true,
                    multiOptions: [
                        { label: { de: "A", en: "/ˈeɪ/" }, value: "0" },
                        { label: "B", value: "1" },
                        { label: "C", value: "2" },
                    ],
                },
            },
        };
    },
    methods: {
        itemCmp(a, b) {
            if (a === b) return true;

            if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) {
                // If the values are Date, compare them as timestamps
                return false;
            }

            if (a !== Object(a) || b !== Object(b)) {
                // If the values aren't objects, they were already checked for equality
                // Check if they are equal when they are converted to strings
                return String(a) === String(b);
            }
            const props = Object.keys(a);

            if (props.length !== Object.keys(b).length) {
                // Different number of props, don't bother to check
                return false;
            }

            return props.every(p => this.itemCmp(a[p], b[p]));
        },
    },
};
</script>
