<template>
    <v-container>
        <h1>Time</h1>
        <asa-formatter type="time" value="04:20" />
        <br />
        <asa-formatter type="time" value="13:42" />
    </v-container>
</template>

<script>
import AsaFormatter from "../../../src/component/LFormatter.vue";
import { VContainer } from "vuetify/components";

export default {
    name: "FormatterTime",
    components: {
        AsaFormatter,
        VContainer,
    },
};
</script>
