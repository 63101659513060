function isObject(val: unknown): val is Record<PropertyKey, unknown> {
    return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

export function merge<T extends Record<PropertyKey, unknown>>(...objects: T[]) {
    return objects.reduce((target, source) => {
        if (Array.isArray(source)) {
            throw new TypeError("Arguments provided to merge must be objects, not arrays.");
        }
        for (const key of Object.keys(source)) {
            const resVal = target[key];
            const curVal = source[key];
            if (curVal === undefined) {
                delete target[key];
            } else if (isObject(curVal) && isObject(resVal)) {
                target[key] = merge(resVal, curVal);
            } else {
                target[key] = source[key];
            }
        }
        return target;
    }, {});
}
