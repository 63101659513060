<template>
    <v-field
        v-if="isVisible"
        :id="name"
        :class="{
            'html-field': true,
            'py-5': true,
            'my-5': true,
            'v-text-input': true,
            'v-field--variant-filled': fieldType || config.fieldType || false,
        }"
        :error-messages="aggregatedErrors"
        :label="theLabel"
        :messages="description || config.description || undefined"
        :name="name"
        :required="false"
        :variant="fieldType ? 'filled' : 'plain'"
    >
        <template v-if="theLabel" #label>
            <span class="px-5">{{ theLabel }}</span>
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="mt-4 mx-4" v-html="sanitized" />
    </v-field>
</template>

<script lang="ts">
import { VField } from "vuetify/components";
import HtmlSanitizer from "../lib/HtmlSanitizer.js";
import { defineComponent } from "vue";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigHtml } from "../config/Form";
import { formConfigHtmlSchema } from "../config/Form.zod";

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | description            | `String`                   | no       | `""`       | description |
 * | fieldType              | `Boolean`                  | no       | `true`     | wrap in v-input, as to match input element style |
 */
export default defineComponent({
    components: { VField },
    props: {
        ...makeDefaultProps<FormConfigHtml>(formConfigHtmlSchema),
        /** html string to display. html is sanitized. */
        modelValue: {
            type: String,
            default: undefined,
        },
        /** element description (only used when `fieldType` is `true`) */
        description: {
            type: String,
            required: false,
            default: undefined,
        },
        /** emulate input element style */
        fieldType: {
            type: Boolean,
            required: false,
        },
    },
    setup(props) {
        return {
            ...useDefaults(props, () => void 0),
        };
    },
    data() {
        return {};
    },
    computed: {
        sanitized() {
            return HtmlSanitizer.SanitizeHtml(this.modelValue || this.config.value || "not set");
        },
    },
});
</script>

<style>
.html-field .input {
    display: flex;
    flex: 1 1 auto;
    margin: 0;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    max-height: 32px;
}

.html-field label {
    max-width: 133%;
    transform: translateY(-18px) scale(0.75);
    position: absolute !important;
}
</style>
