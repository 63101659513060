import escape from "../escape";
import type { Formatter } from "../formatter";

type ATarget = "_blank" | "_self" | "_parent" | "_top";

export class Link implements Formatter {
    protected hrefCol: string | null;
    protected target: ATarget | null;

    constructor(settings: { hrefCol?: string; target?: ATarget }) {
        this.hrefCol = settings.hrefCol ?? null;
        this.target = settings.target ?? null;
    }

    format(value: unknown, context?: Record<string, any>): string {
        if (!value) {
            return "";
        }
        if (context == undefined || typeof context != "object") {
            console.error("formatter/Link: expects context as second argument");
            return "#error#";
        }
        const text = String(value);
        const target = this.target ? 'target="' + this.target + '" ' : "";

        if (!this.hrefCol) {
            return "<a " + target + 'href="' + encodeURI(text) + '">' + escape(text) + "</a>";
        }
        if (!context) {
            console.error("formatter/Link: missing context");
            return "#error#";
        }
        const href = String(context[this.hrefCol] ?? "");
        return "<a " + target + 'href="' + encodeURI(href) + '">' + escape(text) + "</a>";
    }
}
