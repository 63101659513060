<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title> Truncate formatter </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
            </v-toolbar-items>
        </v-toolbar>
        <l-table :data="data" :loading="false" :show-filter="showFilter" info-disabled />
    </v-container>
</template>

<script>
import {
    VBtn,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VContainer,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "Hidden",
    components: { LTable, VToolbar, VToolbarTitle, VSpacer, VToolbarItems, VBtn, VContainer },
    data() {
        return {
            showFilter: false,
            data: {
                columns: [
                    {
                        key: "a",
                        label: "A",
                        Formatter: {
                            Truncate: {
                                maxLength: 10,
                            },
                        },
                    },
                    {
                        key: "b",
                        label: "B",
                    },
                ],
                rows: [
                    { a: "012345678901234567890", b: "012345678901234567890" },
                    { a: "a2", b: "b2" },
                ],
                filter: [
                    {
                        key: "a",
                        label: "A",
                        type: "text",
                    },
                    {
                        key: "b",
                        label: "B",
                        hidden: false,
                    },
                ],
            },
        };
    },
};
</script>

<style scoped></style>
