export class NestedSet {
    constructor(protected settings: { label?: string }) {}

    format(value) {
        if (!value) {
            return "";
        }
        const label = this.settings.label ?? "label";
        if (typeof value === "object" && Object.hasOwn(value, label)) {
            return value[label];
        } else {
            return String(value);
        }
    }
}

export const aliases = ["nested_set"];
