<template>
    <div>
        <asa-dialog :retain-focus="false" :no-click-animation="true">
            <asa-form v-model="data" :config="formConf">
                <template #title> WYIWYG editor im dialog </template>
            </asa-form>
        </asa-dialog>
    </div>
</template>

<script lang="ts">
import AsaDialog from "../../../src/component/LDialog.vue";
import AsaForm from "../../../src/component/LForm.vue";
import type { FormConfig } from "../../../src/config/Form";

const formConf: FormConfig = {
    text: {
        type: "wysiwyg",
        label: "Text",
        order: 1,
        linkNewTab: true,
    },
};

export default {
    name: "FormWysiwygDialog",
    components: { AsaForm, AsaDialog },
    data() {
        return {
            formConf,
            data: {},
        };
    },
};
</script>
