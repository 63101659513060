type FilterFunction = (value, filterValue) => boolean;

export function emptyValue(value) {
    return value === "" || value === null || value === undefined;
}

export function emptyFilterValue(v) {
    return v === "" || v === null || v === undefined || (Array.isArray(v) && v.length === 0);
}

export function filterStatus(value: number[], filterValue: string | string[]): boolean {
    const fv = !Array.isArray(filterValue) ? [filterValue] : filterValue;

    for (let i = 0; i < fv.length; i++) {
        for (let j = 0; i < value.length; j++) {
            if (value[j] == parseInt(fv[i])) {
                return true;
            }
        }
    }
    return false;
}

export function filterDate(value: any, filterValue: { from?: string; to?: string }) {
    if (typeof filterValue === "object") {
        const dateVal = new Date(value);
        if (filterValue.from !== undefined && filterValue.to !== undefined) {
            const from = new Date(filterValue.from);
            const to = new Date(filterValue.to);
            return dateVal >= from && dateVal <= to;
        } else if (filterValue.from !== undefined) {
            const from = new Date(filterValue.from);
            return dateVal >= from;
        } else if (filterValue.to !== undefined) {
            const to = new Date(filterValue.to);
            return dateVal <= to;
        } else {
            return true; // filter is cleared
        }
    } else {
        const dateVal = new Date(value).setHours(0, 0, 0, 0);
        const filterVal = new Date(filterValue).setHours(0, 0, 0, 0);

        return dateVal === filterVal;
    }
}

export function getFilterLocalized(localize): FilterFunction {
    return function (value, filterValue) {
        const v = localize(value);
        return filterSubstr(v, filterValue);
    };
}

export function filterLocalized(
    value: Record<string, string> | null,
    filterValue: any,
    localize: (o: Record<string, string>) => string,
) {
    if (value == null) {
        return filterValue != null;
    }
    const v = localize(value);
    if (!v) {
        return false;
    }
    return v.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
}

export function filterSelect(value: any, filterValue: string | string[]) {
    if (Array.isArray(filterValue)) {
        return filterValue.length == 0 || filterValue.includes(value);
    } else if (typeof value === "number") {
        return value === parseInt(filterValue);
    } else {
        return value === filterValue;
    }
}

export function filterSelectMultiple(value, filterValue): boolean {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    if (typeof value === "number") {
        return filterValue.map(x => parseInt(x)).includes(value);
    } else {
        return filterValue.includes(value);
    }
}

export function filterNestedSet(
    value: { root: number; left: number; right: number },
    filterValue: { root: number; left: number; right: number },
) {
    return (
        value.root === filterValue.root &&
        value.left >= filterValue.left &&
        value.right <= filterValue.right
    );
}

export function filterMaterializedPath(value: { path: string }, filterValue: string): boolean {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return value.path.startsWith(filterValue);
}

export function filterSubstr(value, filterValue): boolean {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return String(value).toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
}

export function filterIdentical(value, filterValue): boolean {
    if (emptyFilterValue(filterValue)) {
        return true;
    }
    if (emptyValue(value)) {
        return false;
    }
    return value === filterValue;
}

export function filterBoolean(value: boolean, filterValue: boolean) {
    return value === filterValue;
}

export function filterDefault(value: unknown, filterValue: unknown) {
    if (typeof filterValue !== "string") {
        console.error("filterValue", filterValue);
        return false;
    }
    return String(value).toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
}

export function getFilterGeneric(localize): FilterFunction {
    return function (value, filterValue): boolean {
        if (emptyFilterValue(filterValue)) {
            return true;
        }
        if (emptyValue(value)) {
            return false;
        }
        switch (typeof value) {
            case "boolean":
                return filterIdentical(value, filterValue);
            case "object":
                return filterSubstr(localize(value), filterValue);
            default:
                return filterSubstr(value, filterValue);
        }
    };
}

export function filterUnknown(
    value: unknown,
    filterValue: string | string[],
    localize: (o: Record<string, string>) => string,
): boolean {
    switch (typeof value) {
        case "number":
            return value == Number(filterValue);
        case "boolean":
            return filterBoolean(value, !!filterValue);
        case "object":
            if (isStringRecord(value)) {
                return filterLocalized(value, filterValue, localize);
            } else {
                return filterDefault(value, filterValue);
            }
        default:
            return filterDefault(value, filterValue);
    }
}

function isStringRecord(obj: unknown): obj is Record<string, string> {
    if (!obj) {
        return false;
    }
    if (typeof obj !== "object") {
        return false;
    }

    if (Array.isArray(obj)) {
        return false;
    }

    if (Object.getOwnPropertySymbols(obj).length > 0) {
        return false;
    }

    return Object.getOwnPropertyNames(obj).every(prop => typeof obj[prop] === "string");
}
