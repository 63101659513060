<template>
    <v-navigation-drawer
        :model-value="details.visible.value"
        :scrim="false"
        :temporary="true"
        :width="asideWidth"
        class="elevation-4 overflow-hidden"
        :disable-resize-watcher="true"
        :disable-route-watcher="true"
        location="right"
    >
        <template #prepend>
            <v-toolbar variant="flat">
                <v-app-bar-nav-icon @click="details.hide">
                    <v-icon>$close</v-icon>
                </v-app-bar-nav-icon>
                <v-toolbar-title>
                    {{ t("lumui.table.details") }}
                </v-toolbar-title>

                <v-spacer />

                <l-table-action
                    v-if="current.row.value != null && Object.keys(actions).length > 0"
                    :actions="actions"
                    :row="current.row.value"
                    :eval-context="evalContext"
                    @rowaction="e => emit('click:action', e)"
                >
                    <v-btn icon="$moreVert" />
                </l-table-action>
            </v-toolbar>
        </template>
        <template #append>
            <v-toolbar color="white">
                <v-btn
                    :disabled="!current.hasPrev.value"
                    color="grey-lighten-2"
                    size="small"
                    icon="$prev"
                    @click="() => current.prev()"
                >
                    <v-tooltip activator="parent" location="top">
                        <span>{{ t("lumui.table.previous") }}</span>
                    </v-tooltip>
                </v-btn>

                <v-spacer />
                <div v-if="current.index.value !== null">
                    {{ current.index.value + 1 }} / {{ current.maxIndex.value + 1 }}
                </div>
                <v-spacer />

                <v-btn
                    :disabled="!current.hasNext.value"
                    color="grey-lighten-2"
                    size="small"
                    icon="$next"
                    @click="() => current.next()"
                >
                    <v-tooltip activator="parent" location="top">
                        <span>{{ t("lumui.table.next") }}</span>
                    </v-tooltip>
                </v-btn>
            </v-toolbar>
        </template>
        <template v-if="!loading">
            <!--
                sidebar containing details for the current row
                @props {Object} item - current item
                @props {Object} columns - column definitions for current row
            -->
            <slot
                :columns="columns"
                :detail-columns="details.columns.value"
                :item="current.row.value"
                name="detail"
            >
                <template v-for="column in details.columns.value">
                    <v-card
                        v-if="column.type !== 'action' && !column.hidden"
                        :key="column.key"
                        variant="flat"
                    >
                        <v-card-text>
                            <div class="subheading" style="color: grey">
                                {{ column.label ?? "" }}
                            </div>
                            <div v-if="current.row.value" class="text-body-2">
                                <!--
                                    slot to override default detail column rendering
                                    @props value - current column's value
                                    @props {Object} row - current row
                                    @props {Object} columns - details columns
                                -->
                                <slot
                                    :column="column"
                                    :columns="columns"
                                    :name="'detail_' + column.key"
                                    :row="current.row.value"
                                    :value="current.row.value[column.key]"
                                >
                                    <l-table-column
                                        :column="column"
                                        :row="current.row.value"
                                        :value="current.row.value[column.key]"
                                    />
                                </slot>
                            </div>
                        </v-card-text>
                        <v-divider />
                    </v-card>
                </template>
            </slot>
        </template>
        <template v-else>
            <l-loading :loading="loading" />
        </template>
    </v-navigation-drawer>
</template>

<script lang="ts" setup>
import {
    VNavigationDrawer,
    VTooltip,
    VAppBarNavIcon,
    VIcon,
    VToolbarTitle,
    VSpacer,
    VBtn,
    VToolbar,
    VCard,
    VCardText,
    VDivider,
} from "vuetify/components";
import LTableColumn from "./LTableColumn.vue";
import LLoading from "./LLoading.vue";
import LTableAction from "./LTableAction.vue";
import type { NormalizedTableColumnConfig } from "../config/Table";
import { computed } from "vue";
import { useDisplay } from "vuetify";
import { useCurrent } from "./table/current";
import { useDetails } from "./table/details";
import { useI18n } from "vue-i18n";
import { createEvalRecord } from "../lib/EvalRecord";
import { useSettings } from "../composables/Settings";

const display = useDisplay();

const { t } = useI18n();

const asideWidth = computed(() => {
    return display.smAndDown.value ? display.width.value : display.thresholds.value.sm;
});

const emit = defineEmits({
    "click:action": (_arg: object) => true,
    "update:visible": (_v: boolean) => true,
});

const current = useCurrent();
const settings = useSettings();
const evalContext = computed(() =>
    createEvalRecord(current.row.value ?? {}, settings.table.evalContext),
);
const details = useDetails();

const _props = defineProps<{
    loading: boolean;
    columns: readonly NormalizedTableColumnConfig[];
    actions: Record<string, any>;
}>();
</script>
