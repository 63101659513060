<template>
    <v-tabs
        v-if="hasTabs"
        v-model="activeTabProxy"
        :bg-color="backgroundColor"
        :show-arrows="true"
        color="on-background"
    >
        <v-tab v-for="(tab, index) in tabs" :key="index" :value="'tab-' + index">
            {{ tab.label }}
            <v-icon v-if="hasErrors[index]" class="ml-1" size="small"> $warning</v-icon>
        </v-tab>
    </v-tabs>
</template>

<script lang="ts">
import { VIcon, VTab, VTabs } from "vuetify/components";
/*
 * useForm gehört eigentlich zu den vuetify interner, aber es ist so ziemlich die einzige möglichkeit
 * die ich gefunden habe, herauszufinden wie der Validierungsstand der einzelnen Felder ist.
 */
import { useForm } from "vuetify/lib/composables/form.mjs";
import { defineComponent, type PropType } from "vue";
import { type FormConfig, type FormConfigTab, isFormConfigTab } from "../config/Form";
import { useVModel } from "@vueuse/core";

/**
 * renders the tab headers of children with type "tab"
 * If no tabs exists, nothing is rendered.
 *
 * ## config
 *
 * an object whose elements are element configs
 *
 * @example
 * ```json
 * {
 *   tab1: {
 *     type: "tab",
 *     label: "Tab A",
 *     children: {
 *       a1: {type: "text", label: "Vorname", required: true},
 *       a2: {type: "text", label: "Nachname", required: true},
 *       a3: {type: "email", label: "E-Mail"},
 *       a4: {type: "textarea", label: "Adresse"},
 *     }
 *   },
 *   tab2: {
 *     type: "tab",
 *     label: "Tab B",
 *     children: {
 *       b0: {type: "text", label: "Error", errors: ["error from config"]},
 *       b1: {type: "textarea", label: "Anmerkungen", required: true},
 *     }
 *   },
 * }
 * ```
 */
export default defineComponent({
    components: {
        VTabs,
        VTab,
        VIcon,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        /** element config */
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
        },
        /** id of the active tab */
        activeTab: {
            type: String,
            required: true,
        },
        /** unused */
        color: {
            type: String,
            required: false,
            default: "white",
        },
        /** background color for the tab header */
        backgroundColor: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    emits: ["update:activeTab"],
    setup(props, { emit }) {
        const form = useForm();
        return {
            form,
            activeTabProxy: useVModel(props, "activeTab", emit),
        };
    },
    data() {
        return {
            active: true,
            valid: false,
        };
    },
    computed: {
        tabs(): Record<string, FormConfigTab> {
            const res: Record<string, FormConfigTab> = {};
            for (const i in this.config) {
                const el = this.config[i];
                if (isFormConfigTab(el)) {
                    res[i] = el;
                }
            }
            return res;
        },
        hasTabs(): boolean {
            return Object.keys(this.tabs).length > 0;
        },
        formItemValid() {
            /*
             * formItemValid ist notwendig, damit wir ein reaktives Objekt mit den Validierungsständen
             * der Eingabefelder haben welches wir in hasErrors auswerten können, damit das bei
             * änderungen aktualisiert wird.
             */
            const res = {};
            for (const v of this.form.items.value) {
                // isValid ist null, wenn die validierung noch nicht gelaufen ist
                // für unsere zwecke ist das formular gültig
                res[v.id] = v.isValid ?? true;
            }
            return res;
        },
        hasErrors(): Record<string, boolean> {
            const res: Record<string, boolean> = {};
            outer: for (const [tabId, tab] of Object.entries(this.tabs)) {
                res[tabId] = false;
                for (const childId in tab.children) {
                    if (!this.formItemValid[childId]) {
                        res[tabId] = true;
                        continue outer;
                    }
                }

                if (this.hasChildWithErrors(tab)) {
                    res[tabId] = true;
                }
            }
            return res;
        },
    },
    mounted() {
        if (this.hasTabs && this.activeTab === "") {
            const firstKey = Object.keys(this.tabs).shift();
            if (firstKey) {
                this.activeTabProxy = "tab-" + firstKey;
            }
        }
    },
    methods: {
        hasChildWithErrors(tab: FormConfigTab): boolean {
            for (const c of Object.values(tab.children)) {
                if (c.errors && c.errors.length > 0) {
                    return true;
                }
            }
            return false;
        },
    },
});
</script>
