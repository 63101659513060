<template>
    <test-form v-model="value" v-model:config="config" title="Date" />
</template>

<script>
import TestForm from "../../components/TestForm.vue";
import moment from "moment";

const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
export default {
    name: "Date",
    components: { TestForm },
    data() {
        return {
            value: {},
            config: {
                d1: {
                    type: "date",
                    label: "Date",
                    value: moment().format("YYYY-MM-DD"),
                    required: true,
                    min: yesterday,
                    max: tomorrow,
                },
                d2: {
                    type: "date",
                    label: "Date",
                    required: false,
                },
            },
        };
    },
};
</script>
