function has(_target, _key): boolean {
    // we want to trap all variable access
    return true;
}

function get(target, key): undefined | unknown {
    if (key === Symbol.unscopables) {
        return undefined;
    }
    if (target[key] == undefined) {
        if (typeof target == "object" && "record" in target) {
            if (target.record.__has(key)) {
                return target.record.getData(key, undefined);
            } else {
                throw Error("Access to undefined " + key);
            }
        }
        throw Error("Access to undefined symbol " + key);
    }

    return target[key];
}

export function sandbox(src: string): false | ((context: unknown) => unknown) {
    try {
        const wrappedSrc = "with (ctx) { return " + src + "; };";
        const code = new Function("ctx", wrappedSrc);
        return function (context: unknown): unknown {
            const sandboxProxy = new Proxy(context, { has, get });
            return code(sandboxProxy);
        };
    } catch (e: any) {
        console.error("sandbox: Could not compile:\n\n  " + src + "\n\n" + e.message);
        return false;
    }
}
