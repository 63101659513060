<template>
    <test-form
        v-model="data1"
        v-model:config="formConf1"
        title="Single Field Subformular"
        @save="addErrors()"
    />
    <test-form
        v-model="data2"
        v-model:config="formConf2"
        title="Complex Subformular - Values Async"
    />

    <test-form
        v-model="data3"
        v-model:config="formConf3"
        title="Complex Subformular - Values empty data from form conf"
    />
    <test-form v-model="data4" :config="formConf4" title="UM Problem" />
    <test-form v-model="data5" :config="formConf5" title="data from value" />
</template>

<script lang="ts">
import TestForm from "../../components/TestForm.vue";
import { defineComponent } from "vue";
import type { FormConfig } from "../../../src/config/Form";
import setFormErrors from "../../../src/lib/form/setFormErrors";

const formConf1: FormConfig = {
    tags: {
        type: "collection",
        label: "Tags",
        allow_add: true,
        allow_delete: true,
        prototype: {
            type: "text",
            label: "",
            value: "",
            required: true,
            disabled: false,
        },
        children: {},
        // "order": 1
    },
};

export default defineComponent({
    name: "FormCollection",
    components: { TestForm },
    data(): {
        formConf1: FormConfig;
        data1: object;
        formConf2: FormConfig;
        data2: object;
        formConf3: FormConfig;
        data3: object;
        formConf4: FormConfig;
        data4: object;
        formConf5: FormConfig;
        data5: object;
    } {
        return {
            formConf1,
            data1: {},
            formConf2: {
                stuff: {
                    type: "collection",
                    label: "Stuff2",
                    allow_add: true,
                    allow_delete: true,
                    prototype: {
                        name: {
                            type: "text",
                            label: "Name",
                            value: "",
                            required: true,
                            disabled: false,
                            // "order": 1
                        },
                        description: {
                            type: "text",
                            label: "Description",
                            value: "",
                            required: true,
                            disabled: false,
                            // "order": 2
                        },
                    },
                    children: {},
                    // "order": 1
                },
            },
            data2: {},
            formConf3: {
                stuff: {
                    type: "collection",
                    label: "Stuff3",
                    allow_add: false,
                    allow_delete: false,
                    prototype: {
                        name: {
                            type: "text",
                            label: "Name",
                            value: "",
                            required: true,
                            disabled: false,
                            //          "order": 1
                        },
                        description: {
                            type: "text",
                            label: "Description",
                            value: "",
                            required: true,
                            disabled: false,
                            //        "order": 2
                        },
                    },
                    children: {
                        "0": {
                            name: {
                                type: "text",
                                label: "Name",
                                value: "Foo",
                                required: true,
                                disabled: false,
                                //             "order": 1
                            },
                            description: {
                                type: "text",
                                label: "Description",
                                value: "Da Foo",
                                required: true,
                                disabled: false,
                                //           "order": 2
                            },
                        },
                        "1": {
                            name: {
                                type: "text",
                                label: "Name",
                                value: "Bar",
                                required: true,
                                disabled: false,
                                // "order": 1
                            },
                            description: {
                                type: "text",
                                label: "Description",
                                value: "Da Bar",
                                required: true,
                                disabled: false,
                                //            "order": 2
                            },
                        },
                    },
                    // "order": 1
                },
            },
            data3: {},
            formConf4: {
                requirements: {
                    type: "collection",
                    label: "um.instruction.requirements",
                    allow_add: true,
                    allow_delete: true,
                    prototype: {
                        type: "select",
                        label: "um.label.instruction",
                        value: "",
                        required: true,
                        disabled: false,
                        errors: [],
                        multiple: false,
                        multiOptions: [
                            {
                                label: "[EM] asdfasdf",
                                value: "1",
                            },
                            {
                                label: "[UI] Unterweisung 1",
                                value: "3",
                            },
                            {
                                label: "[EM] Testunterweisung",
                                value: "4",
                            },
                        ],
                    },
                    children: {
                        "0": {
                            type: "select",
                            label: "um.label.instruction",
                            value: "4",
                            required: true,
                            disabled: false,
                            errors: [],
                            multiple: false,
                            multiOptions: [
                                {
                                    label: "[EM] asdfasdf",
                                    value: "1",
                                },
                                {
                                    label: "[UI] Unterweisung 1",
                                    value: "3",
                                },
                                {
                                    label: "[EM] Testunterweisung",
                                    value: "4",
                                },
                            ],
                        },
                    },
                    // "order": 1
                },
            },
            data4: {},
            formConf5: {
                requirements: {
                    type: "collection",
                    label: "um.instruction.requirements",
                    allow_add: true,
                    allow_delete: true,
                    prototype: {
                        type: "select",
                        label: "um.label.instruction",
                        value: "",
                        required: true,
                        disabled: false,
                        errors: [],
                        multiple: false,
                        multiOptions: [
                            { label: "[EM] asdfasdf", value: "1" },
                            { label: "[UI] Unterweisung 1", value: "3" },
                            { label: "[EM] Testunterweisung", value: "4" },
                        ],
                    },
                    children: {
                        "0": {
                            type: "select",
                            label: "um.label.instruction",
                            required: true,
                            disabled: false,
                            errors: [],
                            multiple: false,
                            multiOptions: [
                                { label: "[EM] asdfasdf", value: "1" },
                                { label: "[UI] Unterweisung 1", value: "3" },
                                { label: "[EM] Testunterweisung", value: "4" },
                            ],
                        },
                    },
                    // "order": 1
                },
            },
            data5: {
                requirements: { "0": "1" },
            },
        };
    },
    methods: {
        addErrors() {
            const errors = {
                "tags.0": [],
                "tags.1": ["xxx"],
            };
            setFormErrors(this.formConf1, errors);
        },
    },
});
</script>
