<template>
    <v-select
        :id="'filter_' + config.key"
        :clearable="clearable"
        :items="items"
        :menu-props="{ offsetY: true }"
        :label="config.label"
        :model-value="value"
        hide-details
        item-title="text"
        item-value="value"
        @update:model-value="e => $emit('update:modelValue', e)"
    />
</template>

<script>
import { VSelect } from "vuetify/components";
import { useI18n } from "vue-i18n";

/**
 * Renders a bool filter
 * @internal
 */
export default {
    components: { VSelect },
    props: {
        value: {
            type: Boolean,
            default: undefined,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup() {
        const { t } = useI18n();
        return { t };
    },
    data() {
        return {
            items: [
                {
                    value: true,
                    text: this.t("lumui.formatter.boolean.true"),
                },
                {
                    value: false,
                    text: this.t("lumui.formatter.boolean.false"),
                },
            ],
        };
    },
    computed: {
        clearable() {
            if (Object.hasOwn(this.config, "clearable")) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        },
    },
};
</script>
