<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="formattedValue" />
</template>

<script lang="ts">
import { type Formatter, getColumnFormatter, getCustomColumnFormatter } from "../lib/formatter.js";
import { defineComponent, type PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    props: {
        /** the value that will be formatted */
        value: {
            type: [String, Number, Boolean, Object, Array],
            default: "",
        },
        /**
         * bool, date, datetime, currency, localized, select, checkbox, image,
         * status, statusdetail, striptags, float, text, textarea, locale, time,
         * mimeicon, truncate or link
         */
        type: {
            type: String,
            default: "",
        },
        /** special case for LTable */
        formatter: {
            type: Object,
            default: null,
        },
        /** options to be passed to the formatter. depends on the formatter selected via the type prop. */
        options: {
            type: Object as PropType<Record<string, unknown>>,
            default: () => ({}),
        },
        /** special case for LTable */
        context: {
            type: [Object],
            default: () => ({}),
        },
    },
    setup() {
        const { t, locale } = useI18n();
        return {
            t,
            locale,
        };
    },
    computed: {
        fmt(): Formatter | undefined {
            try {
                if (this.formatter) {
                    return getCustomColumnFormatter(this.formatter, this.locale);
                }

                return getColumnFormatter(this.type || "text", this.locale, this.options);
            } catch (e) {
                console.error("LFormatter:", e);
                return undefined;
            }
        },
        formattedValue() {
            if (!this.fmt) {
                return "#error#";
            }
            let value = this.value;
            if (value === undefined || value === null) {
                return "-"; // fast path
            }

            return this.fmt.format(value, this.context);
        },
    },
});
</script>
