<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title> Hidden Columns</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
            </v-toolbar-items>
        </v-toolbar>
        <l-table
            :loading="false"
            :data="data"
            info-disabled
            :show-filter="showFilter"
            @filter_closed="showFilter = false"
        />
    </v-container>
</template>

<script>
import {
    VBtn,
    VContainer,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "Hidden",
    components: {
        LTable,
        VContainer,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VToolbarItems,
        VBtn,
    },
    data() {
        return {
            showFilter: false,
            data: {
                columns: [
                    {
                        key: "a",
                        label: "A",
                        type: "text",
                    },
                    {
                        key: "b",
                        label: "B",
                        hidden: true,
                    },
                ],
                rows: [
                    { a: "a1", b: "b1" },
                    { a: "a2", b: "b2" },
                ],
                filter: [
                    {
                        key: "a",
                        label: "A",
                        type: "text",
                    },
                    {
                        key: "b",
                        label: "B",
                        hidden: false,
                    },
                ],
            },
        };
    },
};
</script>
