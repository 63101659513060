<template>
    <test-form v-model="value" v-model:config="config" title="Text">
        <template #additional-actions>
            <v-btn @click="makeErrors()">Make errors</v-btn>
        </template>
    </test-form>
</template>

<script lang="ts">
import TestForm from "../../components/TestForm.vue";
import type { FormConfig } from "../../../src/config/Form";
import setFormErrors from "../../../src/lib/form/setFormErrors";
import { VBtn } from "vuetify/components";

const config: FormConfig = {
    f0: {
        type: "text",
        label: "Basic field",
        errors: [],
    },
    f1: {
        type: "text",
        label: "Field w/ description",
        description: "Some field description",
        required: "record.getData('f0', false) == 'asdf'",
    },
    f2: {
        type: "text",
        label: "Field w/ prefix",
        prefix: "Text: ",
    },
    f3: {
        type: "text",
        label: "Field w/ suffix",
        suffix: "…Text",
    },
    f4: {
        type: "text",
        label: "Required field",
        required: true,
    },
    f5: {
        type: "text",
        label: "Max length",
        required: true,
        maxLength: 5,
    },
};
export default {
    name: "TextField",
    components: { TestForm, VBtn },
    data() {
        return {
            value: {},
            config,
        };
    },
    methods: {
        makeErrors() {
            setFormErrors(this.config, {
                f1: ["asdfa"],
                f2: ["Yet another error"],
            });
        },
    },
};
</script>
