<template>
    <v-container>
        <test-form v-model="value" :config="formConf" title="Selects" />
    </v-container>
</template>

<script lang="ts">
import { VContainer } from "vuetify/components";
import { type FormConfig } from "../../../src/config/Form";
import TestForm from "../../components/TestForm.vue";

const formConf: FormConfig = {
    hasLectureInstructions: {
        type: "select",
        label: "um.options.lecture.label",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            {
                label: "um.options.lecture.active",
                value: "1",
            },
            { label: "um.options.lecture.simple", value: "2" },
            {
                label: "um.options.lecture.inactive",
                value: "0",
            },
        ],
        order: 1,
    },
    hasOnlineInstructions: {
        type: "select",
        label: "um.options.content.label",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            {
                label: "um.options.content.basic",
                value: "2",
            },
            {
                label: "um.options.content.extended",
                value: "3",
            },
            { label: "um.options.content.inactive", value: "0" },
        ],
        order: 2,
    },
    hasAnalyzerPro: {
        type: "select",
        label: "um.options.analyzer.label",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            {
                label: "um.options.analyzer.default",
                value: "0",
            },
            {
                label: "um.options.analyzer.pro",
                value: "1",
            },
            { label: "um.options.analyzer.pro_with_jobqueue", value: "2" },
        ],
        order: 3,
    },
    hasAnalyzerInactive: {
        type: "select",
        label: "um.options.analyzer.withInactives",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 4,
    },
    hasProfiles: {
        type: "select",
        label: "um.options.profiles.label",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            {
                label: "um.options.enabled",
                value: "1",
            },
            { label: "um.options.profiles.withCats", value: "2" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 5,
    },
    hasDelayedAssignments: {
        type: "select",
        label: "um.options.delayedAssignment",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 6,
    },
    hasVoluntary: {
        type: "select",
        label: "um.options.voluntary",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 7,
    },
    hasCertificateEditor: {
        type: "select",
        label: "um.options.certificate.label",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            {
                label: "um.options.certificate.basic",
                value: "1",
            },
            { label: "um.options.certificate.pro", value: "2" },
            {
                label: "um.options.certificate",
                value: "0",
            },
        ],
        order: 8,
    },
    hasWithoutPast: {
        type: "select",
        label: "um.options.withoutPast",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 9,
    },
    instructionReference: {
        type: "select",
        label: "um.options.reference.label",
        value: "certificate",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            {
                label: "um.options.reference.certificate",
                value: "certificate",
            },
            {
                label: "um.options.reference.assignment",
                value: "assignment",
            },
            { label: "um.options.reference.both", value: "both" },
        ],
        order: 10,
    },
    hasCollectionCertificates: {
        type: "select",
        label: "um.options.collectionCertificates",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 11,
    },
    hasSignature: {
        type: "select",
        label: "um.options.signature",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 12,
    },
    hasDocuWareExport: {
        type: "select",
        label: "um.options.docuware",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 13,
    },
    hasAnalyzerCertExport: {
        type: "select",
        label: "um.options.analyzerCertExport",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 14,
    },
    hasSurvey: {
        type: "select",
        label: "um.options.survey",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
            },
        ],
        order: 15,
    },
    hasSelfAssignProfile: {
        type: "select",
        label: "um.options.profileSelfAssign",
        value: "0",
        required: true,
        disabled: false,
        multiple: false,
        multiOptions: [
            { label: "um.options.enabled", value: "1" },
            {
                label: "um.options.disabled",
                value: "0",
                disabled: true,
            },
        ],
        order: 16,
    },
};
export default {
    name: "Selects",
    components: { VContainer, TestForm },
    data() {
        return {
            formConf: formConf,
            value: {},
        };
    },
};
</script>
