<template>
    <v-switch
        v-if="isVisible"
        :id="id"
        :model-value="modelValue == true ? 1 : 0"
        :class="[styleClass, { required: isRequired }]"
        :disabled="isDisabled"
        :error="error"
        :error-messages="aggregatedErrors"
        :false-value="0"
        :label="theLabel"
        :required="isRequired"
        :rules="validationRules"
        :true-value="1"
        variant="filled"
        @update:model-value="e => update(e)"
    />
</template>

<script lang="ts">
import { VSwitch } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigSwitch } from "../config/Form";
import { formConfigSwitchSchema } from "../config/Form.zod";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

/**
 * **Note:** this element return `false` if the switch is turned of and `1` when it is turned off.
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 */
export default defineComponent({
    components: { VSwitch },
    props: {
        modelValue: {
            type: [String, Boolean, Number],
            required: false,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigSwitch>(formConfigSwitchSchema),
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const { t } = useI18n();
        return {
            t,
            ...useDefaults(props, emit),
        };
    },
    computed: {
        styleClass() {
            return this.config.class || {};
        },
        validationRules() {
            if (this.rules) {
                return this.rules;
            }
            return [(v: unknown) => !this.isRequired || !!v || this.t("lumui.form.row.required")];
        },
    },
    methods: {
        update(e: any) {
            /*
             * Der emittierte Wert sollte eigentlich ein boolean sein, aber das würde die backward compatibility brechen
             */
            this.$emit("update:modelValue", e);
        },
    },
});
</script>
