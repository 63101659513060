<template>
    <v-text-field
        v-if="isVisible"
        :id="id"
        ref="field"
        :class="[config.class, { required: isRequired }]"
        :clearable="config.clearable ?? false"
        :counter="config.maxLength || undefined"
        :disabled="isDisabled"
        :error="error"
        :error-messages="aggregatedErrors"
        :hide-details="config.hideDetails ?? false"
        :hint="config.description"
        :label="theLabel"
        :model-value="modelValue"
        :name="name"
        :placeholder="config.placeholder"
        :prefix="config.prefix"
        :rules="validationRules"
        :suffix="config.suffix"
        :type="config.type"
        autocomplete="off"
        variant="filled"
        v-bind="extraProps"
        @update:model-value="e => updateValue(e)"
    />
</template>

<script lang="ts">
import { VTextField } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigText } from "../config/Form";
import { formConfigTextSchema } from "../config/Form.zod";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

/**
 * #### Config
 *
 *
 * | key                    | type                       | required | default     | description |
 * |------------------------|----------------------------|----------|-------------|-------------|
 * | type                   | `String`                   | yes      |             | field type  |
 * | label                  | `String`, `false`          | no       | `false`     | fields label |
 * | description            | `String`                   | no       | `""`        | field description |
 * | class                  | `String`                   | no       | `null`      | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`     | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`     | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`     | field is rendered.  |
 * | prefix                 | `String`                   | no       | `undefined` | Adds prefix to field. |
 * | suffix                 | `String`                   | no       | `undefined` | Adds suffix to field. |
 * | maxLength              | `Number`                   | no       | `false`     | Limit input to maxLength chars. Also display length counter. |
 * | placeholder            | `String`                   | no       | `""`        | placeholder displayed inside the field, when value is empty |
 * | hideDetails            | `String`, `Bool`           | no       | `undefined` | When set to "auto" details are only rendered when necessary |
 */
export default defineComponent({
    components: {
        VTextField,
    },
    props: {
        ...makeDefaultProps<FormConfigText>(formConfigTextSchema),
        modelValue: {
            type: String,
            required: false,
            default: undefined, // muss undefined seien, damit useDefaults() den wert aus der config onMount setzt
        },
        type: {
            type: String,
            required: false,
            default: "text",
            validator: (v: string) => {
                return ["text", "number", "email"].indexOf(v) != -1;
            },
        },
    },
    emits: ["update:modelValue", "update:config", "update:error"],
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        return {
            t,
            locale,
            ...useDefaults(props, emit),
        };
    },
    computed: {
        extraProps(): Record<string, unknown> {
            return {};
        },
        validationRules() {
            if (this.rules) {
                return this.rules;
            }
            if (!this.locale) {
                return;
            }
            const maxLength =
                typeof this.config.maxLength === "string"
                    ? parseInt(this.config.maxLength)
                    : this.config.maxLength ?? false;
            const required = this.isRequired;
            return [
                (v: unknown) =>
                    !required ||
                    (typeof v === "string" && v.length > 0) ||
                    this.t("lumui.form.row.required"),
                (v: unknown) =>
                    !maxLength ||
                    maxLength <= 0 ||
                    (typeof v === "string" && v.length <= maxLength) ||
                    this.t("lumui.form.row.max_length", { maxLength: maxLength }),
            ];
        },
    },
    watch: {
        "config.maxLength"(n, o) {
            if (n != o) {
                this.$nextTick(() => {
                    this.revalidate();
                });
            }
        },
        isRequired(n, o) {
            if (n != o) {
                this.$nextTick(() => {
                    this.revalidate();
                });
            }
        },
    },
    methods: {
        revalidate() {
            const field = this.$refs.field as InstanceType<typeof VTextField> | undefined;
            field?.validate();
        },
        updateValue(e) {
            this.$emit("update:modelValue", e);
            if (this.config.errors && this.config.errors.length > 0) {
                const cfg = nonReactiveClone(this.config);
                cfg.errors = undefined;
                this.$emit("update:config", cfg);
            }
        },
    },
});
</script>
