<template>
    <v-container>
        <h1>Truncate</h1>
        <asa-formatter
            type="truncate"
            :options="{ maxLength: 10, truncationSuffix: '…' }"
            value="012345678901234567890"
        />
        <br />
        <asa-formatter
            type="truncate"
            :options="{ maxLength: 10, truncationSuffix: '###' }"
            value="012345678901234567890"
        />
        <h1>No truncation</h1>
        <asa-formatter
            type="truncate"
            :options="{ maxLength: 10, truncationSuffix: '...' }"
            value="0123456789"
        />
    </v-container>
</template>

<script>
import AsaFormatter from "../../../src/component/LFormatter.vue";
import { VContainer } from "vuetify/components";

export default {
    name: "FormatterTime",
    components: {
        AsaFormatter,
        VContainer,
    },
};
</script>
