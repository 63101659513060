import {
    computed,
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    type Ref,
    ref,
    watch,
} from "vue";
import type { TableRow } from "../../config/Table";

type CurrentData = {
    set(row: DeepReadonly<TableRow>): void;
    setIndex(id: number): void;
    hasPrev: DeepReadonly<Ref<boolean>>;
    hasNext: DeepReadonly<Ref<boolean>>;
    index: DeepReadonly<Ref<number | null>>;
    row: DeepReadonly<Ref<TableRow | null>>;
    maxIndex: DeepReadonly<Ref<number>>;
    next(): void;
    prev(): void;
};

export const CurrentInjectionKey = Symbol.for("LumUi:LTable:current") as InjectionKey<CurrentData>;

export function provideCurrent(rows: DeepReadonly<Ref<TableRow[]>>): CurrentData {
    const index = ref<number | null>(null);
    const maxIndex = computed(() => rows.value.length - 1);
    const row = ref<TableRow | null>(null);

    const hasPrev = computed(
        () => rows.value.length > 0 && index.value !== null && index.value > 0,
    );
    const hasNext = computed(
        () => rows.value.length > 0 && index.value !== null && index.value < rows.value.length - 1,
    );

    function setIndex(idx: number) {
        if (idx < 0 && idx >= rows.value.length) {
            throw Error("Index " + idx + " is out of bounds");
        }

        index.value = idx;
        row.value = rows.value[idx];
    }

    function set(newRow: DeepReadonly<TableRow> | null) {
        if (newRow == null) {
            index.value = null;
            row.value = null;
            return;
        }
        const idx = rows.value.findIndex(x => x == newRow);
        if (idx < 0) {
            index.value = null;
            row.value = null;
            return;
        }
        row.value = newRow;
        index.value = idx;
    }

    function next() {
        if (!hasNext.value) {
            return;
        }
        const idx = index.value !== null ? index.value + 1 : 0;
        setIndex(idx);
    }

    function prev() {
        if (!hasPrev.value || index.value === null) {
            return;
        }

        const idx = index.value > 1 ? index.value - 1 : 0;
        setIndex(idx);
    }

    watch(rows, () => {
        const r = row.value;
        if (r == null) {
            return;
        }
        set(r);
    });

    const data = {
        row,
        index,
        maxIndex,
        hasNext,
        hasPrev,
        set,
        setIndex,
        next,
        prev,
    };
    provide(CurrentInjectionKey, data);
    return data;
}

export function useCurrent(): CurrentData {
    const data = inject(CurrentInjectionKey);
    if (!data) {
        throw Error("provideCurrent must be called in a parent component");
    }

    return data;
}
