export default function base64ToBlob(base64) {
    const data = base64.split(',')[1];
    const mime = base64.split(';')[0];
    const _encoding = base64.split(',')[0].split(';')[1];

    const binary_string = window.atob(data);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return new Blob([bytes.buffer], { type: mime });
}
