<template>
    <v-container>
        <h1>Date</h1>
        <v-table>
            <thead>
                <tr>
                    <th class="text-left">Unformatted Value</th>
                    <th class="text-left">Formatted Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(date, i) in dates" :key="i">
                    <td>{{ date }}</td>
                    <td>
                        <asa-formatter type="date" :value="date" />
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { VContainer, VTable } from "vuetify/components";
import AsaFormatter from "../../../src/component/LFormatter.vue";

export default {
    name: "FormatterDate",
    components: {
        AsaFormatter,
        VTable,
        VContainer,
    },
    data() {
        return {
            dates: [
                "2023-01-01",
                "2023-01-01",
                "2023-01-01",
                "2023-01-01",
                "2022-12-20",
                "2022-12-20",
                "2022-12-20",
                "2022-12-20",
                "2022-12-20",
            ],
        };
    },
};
</script>

<style scoped></style>
