import { sandbox } from "../lib/Sandbox";
import type { EvalRecord } from "../lib/EvalRecord";
import { unref } from "vue";

export function computeEvalBoolean(
    prop: string,
    props: Record<string, unknown> & {
        config: Record<string, unknown>;
        formValues: EvalRecord<any>;
    },
    defaultValue: boolean,
): boolean {
    const propValue = unref(props[prop]);
    const configValue = unref(props.config[prop]);
    const r = propValue ?? configValue ?? defaultValue;

    if (typeof r === "string") {
        try {
            const code = sandbox(r);
            if (!code) {
                return defaultValue;
            }
            return code(props.formValues) == true;
        } catch (e) {
            console.error("DefaultProps: Could not evaluate " + prop + " code:", e);
            return defaultValue;
        }
    } else if (typeof r === "boolean") {
        return r;
    }
    throw Error(prop + " must be string or boolean. got " + typeof r);
}
