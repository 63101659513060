<template>
    <v-container>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>{{ data2.length / 1000 }}k Rows</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
                </v-toolbar-items>
            </v-toolbar>
            <l-table
                :data="config"
                :show-filter="showFilter"
                @filter_closed="showFilter = false"
            ></l-table>
        </v-card>
    </v-container>
</template>

<script setup lang="ts">
import {
    VContainer,
    VCard,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VToolbarItems,
    VBtn,
} from "vuetify/components";
import type { TableConfig } from "../../../src/config/Table";
import LTable from "../../../src/component/LTable.vue";
import { ref } from "vue";

const showFilter = ref(true);

const data2: Array<{ id: number; label: string; b: boolean; d: Date; t: Date }> = [];
for (let i = 0; i < 50000; i++) {
    data2.push({
        id: i,
        label: "String " + i,
        b: Math.random() > 0.5,
        d: new Date(),
        t: new Date(),
    });
}
const config: TableConfig = {
    columns: [
        { key: "id", label: "Id" },
        { key: "label", label: "Label" },
        { key: "b", label: "Boolean", type: "bool" },
        { key: "d", label: "Date", type: "date" },
        { key: "t", label: "Time", type: "time" },
    ],
    rows: data2,
    filter: [{ key: "id" }, { key: "label" }, { key: "b" }, { key: "d" }],
};
</script>
