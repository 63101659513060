import { createApp } from "vue";
import "@fortawesome/fontawesome-pro/css/all.css";
import App from "./App.vue";
import router from "./plugins/router";
import createLumUi from "./plugins/lumui";
import store from "./plugins/store.js";
import { createVuetify } from "./plugins/vuetify";
import TestForm from "./components/TestForm.vue";
import { createI18n } from "vue-i18n";
import { createTranslationLoaderPlugin, type TranslationMessages } from "../src";

// Es müssen alle moment locales importiert werden, die die applikation braucht
import "moment";
import "moment/dist/locale/de";
import "moment/dist/locale/de-ch";
import "moment/dist/locale/et";
import "moment/dist/locale/lv";
import "moment/dist/locale/fi";

import vuetifyDe from "../src/translations/base/de";
import lumUiDe from "../src/translations/de";
import de from "./translations/de";

// vue app erstellen
const app = createApp(App);
app.component("TestForm", TestForm);
const i18n = createI18n({
    locale: "de-CH",
    fallbackLocale: ["de-CH"],
    legacy: false,
});

// createVuetify wrapt die createVuetify von vuetify
const vuetify = createVuetify(i18n);
app.use(i18n);
app.use(vuetify);
app.use(store);
app.use(router);

// lumui plugin erstellen
createLumUi(app, vuetify, i18n.global.locale, store);

// optional: the locale loader dynamicaly loads locales, when they are needed
function getLocaleLoader(l: string) {
    const baseLocale = l.split("-")[0];
    return () =>
        Promise.all([
            /*
             * die vuetify nachrichten müssen vor verarbeitet werden.
             * s. build/importVuetifyTranslations.mjs
             */
            import(`../src/translations/base/${baseLocale}.ts`)
                .then(m => m.default)
                .catch(console.error),
            import(`../src/translations/${baseLocale}.ts`)
                .then(m => m.default)
                .catch(console.error),
            import(`./translations/${baseLocale}.ts`).then(m => m.default).catch(console.error),
        ])
            .then(([a, b, c]): TranslationMessages => {
                return { ...a, ...b, ...c };
            })
            .catch(e => {
                throw Error("Failed to load translation messages for locale " + l, { cause: e });
            });
}

const { plugin: translationLoader, setLocale } = createTranslationLoaderPlugin(i18n, {
    "de-CH": { ...vuetifyDe, ...lumUiDe, ...de },
    "en-US": getLocaleLoader("en-US"),
    "et-EE": getLocaleLoader("et-EE"),
    "lv-LV": getLocaleLoader("lv-LV"),
    "fi-FI": getLocaleLoader("fi-FI"),
    "lt-LT": getLocaleLoader("lt-LT"),
});
app.use(translationLoader);
setLocale("de-CH");

app.mount("#app");
