import moment from "moment";
import type { Formatter } from "../formatter";

export class DateTime implements Formatter {
    protected fmt: string;

    constructor(_settings: undefined, locale?: string) {
        if (!locale) {
            throw new Error("formatter/DateTime: locale required");
        }
        const localeData = moment.localeData(locale);
        this.fmt = localeData.longDateFormat("L") + " " + localeData.longDateFormat("LT");
    }

    format(value: unknown): string {
        if (!value) {
            return "";
        }
        if (typeof value !== "string" && !(value instanceof Date)) {
            console.error("formatter/DateTime: Invalid value type", value);
            return "#error#";
        }
        const d = moment(value, value instanceof Date ? undefined : "YYYY-MM-DD H:mm");
        d.utcOffset(0, true);
        if (!d.isValid()) {
            console.error("formatter/DateTime: Invalid date", value);
            return "#error#";
        }
        return d.format(this.fmt);
    }
}
