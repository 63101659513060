<template>
    <v-autocomplete
        :model-value="value"
        :items="items as MaterializedPathFilterItem[]"
        item-title="label"
        item-value="path"
        :label="config.label"
        :clearable="clearable"
        hide-details
        @update:model-value="e => $emit('update:modelValue', e)"
    >
        <template #selection="{ item }">
            <v-list-item>
                {{ item.raw.label }} <small>{{ item.raw.parents }}</small>
            </v-list-item>
        </template>
        <template #item="{ item, props }">
            <v-list-item
                :class="{ 'v-item--disabled v-list-item--disabled': item.raw.disabled ?? false }"
                v-bind="props"
                :title="item.raw.label"
                :subtitle="item.raw.parents ?? ''"
            />
        </template>
    </v-autocomplete>
</template>

<script lang="ts">
import { VAutocomplete, VListItem } from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import type {
    MaterializedPathFilterItem,
    TableFilterMaterializedPathConfig,
} from "../config/Table";

type Item = Required<MaterializedPathFilterItem>;

/**
 * Renders a NestedSet filter
 * @internal
 */
export default defineComponent({
    components: { VAutocomplete, VListItem },
    props: {
        value: {
            type: String,
            default: undefined,
        },
        config: {
            type: Object as PropType<TableFilterMaterializedPathConfig>,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            placeholder: "…",
            byId: new Map<string, Item>(),
            items: new Array<Item>(),
            nextIndex: 0,
            parentsTimeout: undefined as undefined | ReturnType<typeof setTimeout>,
        };
    },
    computed: {
        clearable() {
            return true;
        },
    },
    watch: {
        config: {
            handler(newVal) {
                this.byId.clear();
                for (const item of newVal.filter.materialized_path) {
                    const n = item.path.lastIndexOf("-", item.path.length - 2);
                    const id = item.path.substring(n + 1, item.path.length - 1);
                    if (this.byId.has(id)) {
                        console.warn("LTableFilterMaterializedPath: duplicate id " + id);
                    }
                    this.byId.set(id, item);
                }
                this.items = newVal.filter.materialized_path.sort((a: Item, b: Item) => {
                    if (a == b) {
                        return 0;
                    } else if (a < b) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                this.nextIndex = 0;
                if (this.parentsTimeout) {
                    clearTimeout(this.parentsTimeout);
                }
                this.parentsTimeout = setTimeout(this.getParentString, 0);
            },
            immediate: true,
        },
    },
    methods: {
        getParent(item: MaterializedPathFilterItem) {
            const parentIds = item.path.split("-");
            parentIds.pop(); // remove empty string
            parentIds.pop(); // remove own id

            const parts = new Array<string>();

            for (const pid of parentIds) {
                const parent = this.byId.get(pid);
                if (!parent) {
                    console.warn("LFableFilterMaterializedPath: Parent not found");
                    parts.push("???");
                } else {
                    parts.push(parent.label);
                }
            }
            return parts.join(" / ");
        },
        getParentString() {
            if (this.nextIndex >= this.items.length) {
                return;
            }

            // wir werden hier 20 Einträge berechnen und dann unterbrechen, damit das UI nicht einfriert
            const blockSize = 20;
            const n = Math.min(this.items.length, this.nextIndex + blockSize);
            for (let i = this.nextIndex; i < n; i++) {
                const item = this.items[this.nextIndex];
                if (item.parents) {
                    continue;
                }
                item.parents = this.getParent(item);
                this.items[this.nextIndex] = item;
                ++this.nextIndex;
            }

            // setTimeout(x, 0) sorgt dafür, dass wir nach dem zeichen des nächsten Frames wieder dran kommen
            window.setTimeout(this.getParentString, 0);
        },
    },
});
</script>
