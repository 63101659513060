<template>
    <v-app id="toplevel" full-height>
        <v-app-bar>
            <template #prepend>
                <v-app-bar-nav-icon @click="drawer = !drawer" />
            </template>
            <v-app-bar-title>
                LumUI -
                <span>
                    {{ $route.meta.title }}
                </span>
            </v-app-bar-title>
            <v-toolbar-items>
                <v-spacer />
                <v-select
                    :model-value="i18n.locale.value"
                    :items="locales"
                    @update:model-value="(v: string) => setLocale(v)"
                />
            </v-toolbar-items>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer">
            <v-list v-model:opened="open" density="compact">
                <v-list-group value="Form" prepend-icon="fas fa-input-pipe">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            <v-list-item-title> Forms</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="r in $router.getRoutes().filter(getRouteFilter(/^form_/))"
                        :key="r.name"
                        :to="{ name: r.name }"
                    >
                        <v-list-item-title>
                            {{ r.meta.title || r.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-group value="Tables" prepend-icon="fas fa-table">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            <v-list-item-title> Tables</v-list-item-title>
                        </v-list-item>
                    </template>

                    <v-list-item
                        v-for="r in $router.getRoutes().filter(getRouteFilter(/^table_/))"
                        :key="r.name"
                        :to="{ name: r.name }"
                    >
                        <v-list-item-title>
                            {{ r.meta.title || r.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-group value="Formatter" prepend-icon="fa fa-brush">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            <v-list-item-title> Formatter</v-list-item-title>
                        </v-list-item>
                    </template>

                    <v-list-item
                        v-for="r in $router.getRoutes().filter(getRouteFilter(/^formatter_/))"
                        :key="r.name"
                        :to="{ name: r.name }"
                    >
                        <v-list-item-title>
                            {{ r.meta.title || r.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-group value="Misc" prepend-icon="fas fa-browser">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            <v-list-item-title> Misc</v-list-item-title>
                        </v-list-item>
                    </template>

                    <v-list-item
                        v-for="route in $router.getRoutes().filter(getRouteFilter(/^misc_/))"
                        :key="route.name"
                        :to="{ name: route.name }"
                    >
                        <v-list-item-title>
                            {{ route.meta.title || route.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group value="Test" prepend-icon="fas fa-vials">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            <v-list-item-title> Component test </v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="route in $router.getRoutes().filter(getRouteFilter(/^test_/))"
                        :key="route.name"
                        :to="{ name: route.name }"
                    >
                        <v-list-item-title>
                            {{ route.meta.title || route.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group value="Playground" prepend-icon="fas fa-refresh">
                    <template #activator="{ props }">
                        <v-list-item v-bind="props">
                            <v-list-item-title> Playground</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="route in $router.getRoutes().filter(getRouteFilter(/^playground_/))"
                        :key="route.name"
                        :to="{ name: route.name }"
                    >
                        <v-list-item-title>
                            {{ route.meta.title || route.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script lang="ts">
import {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAppBarTitle,
    VList,
    VListGroup,
    VListItem,
    VListItemTitle,
    VMain,
    VNavigationDrawer,
    VSelect,
    VSpacer,
    VToolbarItems,
} from "vuetify/components";
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";
import { type RouteRecordNormalized, RouterView } from "vue-router";
import { useTranslationLoader } from "../src/plugins/TranslationLoader";
import { useTranslationMapping } from "../src/composables/TranslationMapping";

export default defineComponent({
    components: {
        VApp,
        VAppBar,
        VAppBarTitle,
        VList,
        VListGroup,
        VListItem,
        VListItemTitle,
        VMain,
        VNavigationDrawer,
        VSelect,
        VSpacer,
        VAppBarNavIcon,
        VToolbarItems,
        RouterView,
    },
    setup() {
        const i18n = useI18n();
        const { setLocale } = useTranslationLoader();
        const { locales } = useTranslationMapping();
        return {
            i18n,
            locales,
            setLocale,
        };
    },
    data() {
        return {
            open: ["Form"],
            currentLocale: null,
            drawer: true,
        };
    },
    computed: {},
    methods: {
        getRouteFilter(prefix: RegExp) {
            return function (r: RouteRecordNormalized) {
                if (typeof r.name == "string") {
                    return r.name.match(prefix);
                } else {
                    return false;
                }
            };
        },
        handleError(e: any) {
            console.error("Boundary error", e);
        },
    },
});
</script>
