<template>
    <v-tabs v-model="active">
        <v-tab v-for="locale in availableLocales" :key="locale" :ripple="true" :value="locale">
            {{ localeName(locale) }}
        </v-tab>
    </v-tabs>
    <v-card flat>
        <v-window v-model="active">
            <v-window-item
                v-for="locale in availableLocales"
                :key="locale"
                :eager="true"
                :value="locale"
            >
                <!--
                  Content of the tabs. Current locale for the current tab
                  is passed via the locale prop.

                  @prop {string} locale - the locale of the tab being rendered
                -->
                <slot :locale="locale" :locale-key="mapLocale(locale)" name="default" />
            </v-window-item>
        </v-window>
    </v-card>
</template>

<script lang="ts">
import { VCard, VTab, VTabs, VWindow, VWindowItem } from "vuetify/components";
import { useI18n } from "vue-i18n";
import { defineComponent, type PropType } from "vue";
import { useTranslationMapping } from "../composables/TranslationMapping";

/** creates a tabbed container based on the available languages. */
export default defineComponent({
    components: { VTabs, VTab, VWindow, VWindowItem, VCard },
    props: {
        currentLocale: {
            type: String,
            required: true,
        },
        availableLocales: {
            type: Array as PropType<ReadonlyArray<string>>,
            required: true,
        },
        overrideLocales: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: { "tab-activated": (_arg: { locale: string }) => true },
    setup() {
        const { mapLocale } = useTranslationMapping();
        return {
            i18n: useI18n(),
            mapLocale,
        };
    },
    data() {
        return {
            active: null,
        };
    },
    watch: {
        active(l: string) {
            /** Fired when a tab is activated */
            this.$emit("tab-activated", { locale: l });
        },
    },
    methods: {
        localeName(locale: string): string {
            const key = "lumui.locale." + locale;
            if (this.i18n.te(key)) {
                return this.i18n.t(key);
            }
            return locale;
        },
    },
});
</script>
