<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title> Filters & Persistent Filters</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
            </v-toolbar-items>
        </v-toolbar>
        <l-table
            ref="table"
            :loading="false"
            :data="data"
            :show-filter="showFilter"
            info-disabled
            storage-key="test_table_filter_storage"
            :items-per-page-options="[10, 50, 100]"
            @filter_closed="showFilter = false"
        />
        <v-btn @click="setFilter"> set filter</v-btn>
        <v-btn @click="clearFilter"> clear filter</v-btn>
    </v-container>
</template>

<script>
import {
    VBtn,
    VContainer,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "TableFiltersDynamic",
    components: {
        LTable,
        VContainer,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VToolbarItems,
        VBtn,
    },
    data() {
        return {
            showFilter: true,
            data: {
                columns: [
                    {
                        key: "string",
                        label: "string",
                    },
                    {
                        key: "boolean",
                        label: "boolean",
                        type: "bool",
                    },
                    {
                        key: "date",
                        label: "date",
                        type: "date",
                    },
                    {
                        key: "time",
                        label: "time",
                        type: "time",
                    },
                ],
                rows: [
                    {
                        id: 0,
                        string: "0 Null",
                        boolean: false,
                        date: "2023-01-01",
                        time: "00:00:01",
                    },
                    {
                        id: 1,
                        string: "1 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 2,
                        string: "2 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 3,
                        string: "3 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 4,
                        string: "4 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 5,
                        string: "5 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 6,
                        string: "6 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 7,
                        string: "7 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 8,
                        string: "8 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 9,
                        string: "9 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 10,
                        string: "10 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 11,
                        string: "11 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 12,
                        string: "12 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 13,
                        string: "13 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 14,
                        string: "14 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 15,
                        string: "15 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 16,
                        string: "16 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 17,
                        string: "17 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 18,
                        string: "18 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 19,
                        string: "19 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 20,
                        string: "20 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 21,
                        string: "21 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 22,
                        string: "22 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 23,
                        string: "23 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 24,
                        string: "24 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 25,
                        string: "25 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 26,
                        string: "26 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 27,
                        string: "27 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 28,
                        string: "28 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 29,
                        string: "29 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 30,
                        string: "30 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 31,
                        string: "31 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 32,
                        string: "32 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 33,
                        string: "33 Eins",
                        boolean: true,
                        date: "1980-01-01",
                        time: "01:00:00",
                    },
                    {
                        id: 34,
                        string: "34 Zwei",
                        boolean: false,
                        date: "1980-01-02",
                        time: "02:00:00",
                    },
                    {
                        id: 35,
                        string: "35 Drei",
                        boolean: false,
                        date: "1980-01-03",
                        time: "03:00:00",
                    },
                    {
                        id: 36,
                        string: "36 Vier",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                    {
                        id: 37,
                        string: "37 Last",
                        boolean: true,
                        date: "1980-01-04",
                        time: "04:00:00",
                    },
                ],
                filter: [
                    {
                        key: "string",
                        label: "string",
                    },
                ],
            },
        };
    },
    methods: {
        setFilter() {
            this.$refs.table.filter.set("string", "Zwei");
        },
        clearFilter() {
            this.$refs.table.filter.set("string");
        },
    },
};
</script>

<style scoped></style>
