<template>
    <test-form v-model:config="cfg" v-model="value" title="html" />
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "FormHtml",
    components: { TestForm },
    data() {
        return {
            value: {},
            cfg: {
                a: {
                    name: "a",
                    type: "html",
                    label: "Html content",
                    fieldType: false,
                    value: "<div><b>Mitarbeiterschulung</b> (Testuser, AMAG bis zum 17.11.2021)<br>klaus; Essen gehen (Muster, Hans bis zum 22.12.2021)<hr>jkhw jhwq lkcj lkj lkj lkj lkj ö</div>"
                },
                b: {
                    name: "b",
                    type: "html",
                    label: "Html content",
                    description: "asdf",
                    fieldType: true,
                    value: "<div><b>Mitarbeiterschulung</b> (Testuser, AMAG bis zum 17.11.2021)<br>klaus; Essen gehen (Muster, Hans bis zum 22.12.2021)<hr>jkhw jhwq lkcj lkj lkj lkj lkj ö</div>"
                }
            }
        }
    },
}
</script>
