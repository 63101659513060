<template>
    <div class="ma-5">
        <div v-for="(icon, alias) in icons" :key="alias" class="mb-2">
            <v-icon :title="icon" class="mr-2">
                {{ icon }}
            </v-icon>
            <span class="text--disabled">${{ alias }}</span>
        </div>
    </div>
</template>

<script>
import { VIcon } from "vuetify/components";

export default {
    name: "Icons",
    components: { VIcon },
    computed: {
        icons() {
            return this.sort(this.$vuetify.icons.aliases);
        },
    },
    methods: {
        sort(obj) {
            return Object.keys(obj)
                .sort()
                .reduce((res, key) => {
                    res[key] = obj[key];
                    return res;
                }, {});
        },
    },
};
</script>
