<template>
    <v-tooltip location="top">
        <template #activator="tooltips">
            <v-img
                v-if="file && isImage(file) && file.hasOwnProperty('src')"
                :src="base64(file)"
                aspect-ratio="1.7"
                class="clickable"
                v-bind="tooltips.props"
                @click="download(file)"
            />
            <a
                v-else-if="file && isFile(file) && file.hasOwnProperty('src')"
                href="#"
                v-bind="tooltips.props"
                @click.prevent="download(file)"
            >
                {{ file.originalName }}
            </a>
            <a v-else-if="file" :href="file.src" target="_blank" v-bind="tooltips.props">
                {{ file }}
            </a>
        </template>
        <span>{{ t("lumui.table_columns.save") }}</span>
    </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { saveAs } from "file-saver";
import { useI18n } from "vue-i18n";
import { useApiCaller } from "../plugins/ApiCaller";
import { VTooltip, VImg } from "vuetify/components";

type LTableFile = {
    src: string;
    originalName: string;
    mimetype?: string;
};

export default defineComponent({
    components: { VTooltip, VImg },
    props: {
        /**
         * column definition
         * @type {Object}
         */
        column: {
            type: Object,
            required: true,
        },
        /**
         * columns value
         */
        value: {
            type: [String, Number, Boolean, Object, Array],
            required: false,
            default: null,
        },
        /**
         * current row
         * @type {Object}
         */
        row: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    async setup() {
        const vuex = await import("vuex");
        if (!vuex) {
            throw Error("could not import vuex");
        }
        const store = vuex.useStore();
        if (
            store.getters["currentNodeType"] == undefined ||
            store.getters["currentNodePath"] == undefined
        ) {
            throw Error("store does not provide getters currentNodeType and currentNodePath");
        }
        const { t } = useI18n();
        const call = useApiCaller();
        return { t, call, store };
    },
    data() {
        return {
            file: null as LTableFile | null,
        };
    },
    watch: {
        value() {
            this.getFile();
        },
    },
    mounted() {
        this.getFile();
    },
    methods: {
        /** @private */
        async getFile() {
            this.file = null;
            if (typeof this.value !== "object" || Array.isArray(this.value)) {
                console.error("LTableColumnFile: Invalid file object", this.value);
                return;
            }
            if (Object.hasOwn(this.value, "id")) {
                const value = parseInt(this.value.id);
                if (!isNaN(value) && value > 0) {
                    if (this.store.getters.currentNodeType === "smartfolder") {
                        this.file = await this.call(
                            "api.core.asset.lookup.getSmartfolderFileData",
                            {
                                id: value,
                                path: this.store.getters.currentNodePath,
                            },
                        );
                    } else {
                        this.file = await this.call("api.core.asset.lookup.getFileData", {
                            id: value,
                        });
                    }
                }
            } else if (Object.hasOwn(this.value, "url")) {
                this.file = this.value.url;
            }
        },
        /** @private */
        isImage(file: LTableFile) {
            return file.mimetype !== undefined && file.mimetype.indexOf("image") >= 0;
        },
        /** @private */
        isFile(file: LTableFile) {
            return file.mimetype !== undefined && file.mimetype.indexOf("image") < 0;
        },
        /** @private */
        base64(file: LTableFile) {
            return file.src.indexOf(";base64,") > 0
                ? file.src
                : `data:${file.mimetype};base64,${file.src}`;
        },
        /** @private */
        download(file: LTableFile) {
            saveAs(this.dataURItoBlob(file.src), file.originalName);
        },
        /** @private */
        dataURItoBlob(dataURI: string, mimeString?: string) {
            const byteString = atob(dataURI.split(",")[1]);
            if (typeof mimeString === "undefined") {
                mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
            }
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        },
    },
});
</script>

<style scoped></style>
