export class Radio {
    constructor(protected map) {
    }

    format(value) {
        let label = '';
        if (!Array.isArray(this.map)) {
            return label;
        }
        this.map.forEach((option) => {
            if (option.value === value) {
                label = option.text ?? option.label ?? '';
            }
        });
        return label;
    }
}
