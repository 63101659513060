import { useDebounce, useMutationObserver, useResizeObserver } from "@vueuse/core";
import {
    computed,
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    reactive,
    ref,
    type Ref,
    toRaw,
    watch,
} from "vue";
import type { TableRow } from "../../config/Table";

export type Virtual = {
    atTop: Readonly<Ref<boolean>>;
    contentHeight: Readonly<Ref<number>>;
    paddingTop: Readonly<Ref<number>>;
    paddingBottom: Readonly<Ref<number>>;
    rows: Readonly<Ref<readonly IndexedTableRow[]>>;
    firstVisibleRow: Readonly<Ref<number>>;
    scrollToRow(n: number): void;
    scrollHandler(e: any): void;
    itemResizeHandler(row: DeepReadonly<TableRow>, height: number): void;
};

const minBuffer = 10;
const defaultRowSize = 52;
const defaultHeaderSize = 56;

type IndexedTableRow = {
    index: number;
    row: TableRow;
};

const VirtualInjectionKey = Symbol.for("LumUi:LTable:virtual") as InjectionKey<Virtual>;

export function provideVirtual(
    container: Readonly<Ref<HTMLElement | undefined>>,
    allRows: Readonly<Ref<readonly TableRow[]>>,
): Virtual {
    const table = ref<HTMLTableElement>();
    const header = ref<HTMLTableSectionElement>();
    const footer = ref<HTMLTableSectionElement>();

    const indexedRows = computed<readonly IndexedTableRow[]>(() =>
        allRows.value.map((row, index) => ({ row, index })),
    );

    watch(
        container,
        w => {
            const t = (table.value = w?.querySelector("table") ?? undefined);
            header.value = t?.querySelector("thead") ?? undefined;
            footer.value = t?.querySelector("tfoot") ?? undefined;
        },
        { immediate: true },
    );

    useMutationObserver(
        table,
        () => {
            const t = table.value;
            header.value = t?.querySelector("thead") ?? undefined;
            footer.value = t?.querySelector("tfoot") ?? undefined;
        },
        { childList: true },
    );

    function heightTracker(ref: Ref<number>) {
        return entries => {
            let h = 0;
            for (const e of entries) {
                for (const bs of e.borderBoxSize ?? []) {
                    h += bs.blockSize ?? 0;
                }
            }
            ref.value = h;
        };
    }

    const headerHeight = ref(defaultHeaderSize);
    useResizeObserver(header, heightTracker(headerHeight), { box: "border-box" });

    const footerHeight = ref(defaultHeaderSize);
    useResizeObserver(footer, heightTracker(footerHeight), { box: "border-box" });

    const wrapperHeight = ref(0);
    useResizeObserver(container, heightTracker(wrapperHeight), { box: "border-box" });

    const contentHeight = computed(() => {
        const h = wrapperHeight.value - headerHeight.value - footerHeight.value;
        return Math.max(h, 0);
    });

    const buffer = computed(() =>
        Math.max(Math.ceil(contentHeight.value / defaultRowSize), minBuffer),
    );

    const sizes = reactive(new Array<number>());
    const sizeMap = reactive(new Map<TableRow, number>());
    const rowIdx = reactive(new Map<TableRow, number>());

    const estimatedSize = computed(() => {
        let total = 0;
        for (let i = 0; i < sizes.length; i++) {
            total += sizes[i];
        }
        return total;
    });

    watch(
        allRows,
        (newRows: readonly TableRow[]) => {
            sizes.length = newRows.length;
            sizes.fill(defaultRowSize, 0, newRows.length);
            rowIdx.clear();
            for (const idx of newRows.keys()) {
                rowIdx.set(toRaw(newRows[idx]), idx);
            }
            for (const [row, h] of sizeMap.entries()) {
                const idx = rowIdx.get(row);
                if (idx == undefined) {
                    sizeMap.delete(row);
                } else {
                    sizes[idx] = h;
                }
            }
            if (container.value) {
                container.value.scrollTop = 0;
            }
        },
        { immediate: true },
    );

    const scrollTop = ref(0);

    function scrollHandler(e) {
        scrollTop.value = e.target.scrollTop;
    }

    const debounceScrollTop = useDebounce(scrollTop, 20);

    const calc = computed(() => {
        const st = debounceScrollTop.value;
        const topBoundary = st;
        let idx = 0;
        let currentHeight = 0;
        for (; idx < sizes.length; idx++) {
            const nextHeight = currentHeight + sizes[idx]; // top edge
            if (nextHeight > topBoundary) {
                break;
            }
            currentHeight = nextHeight;
        }
        // idx is first visible line
        const firstVisibleRow = idx;

        let pt = currentHeight;
        let first = idx;
        for (let b = 0; first > 0 && b < buffer.value; b++) {
            pt -= sizes[idx];
            first--;
        }
        const atTop = first == 0;

        const bottomBoundary = st + contentHeight.value;
        for (; idx < sizes.length; idx++) {
            const nextHeight = currentHeight + sizes[idx];
            currentHeight = nextHeight; // we want to bottom edge here, so we break after setting
            if (nextHeight > bottomBoundary) {
                break;
            }
        }
        let last = idx;
        let pb = estimatedSize.value - currentHeight;
        for (let b = 0; last < sizes.length && b < buffer.value; b++) {
            last++;
            pb -= sizes[last];
        }

        if (pt <= 0 && first != 0) {
            console.warn("table/virtual: Unexpected padding-top", pt, "st", st);
        }

        const visibleRows = indexedRows.value.slice(first, last + 1);
        return {
            atTop,
            pt,
            pb,
            visibleRows,
            firstVisibleRow,
        };
    });

    const rows = computed(() => calc.value.visibleRows);
    const paddingTop = computed(() => calc.value.pt);
    const paddingBottom = computed(() => calc.value.pb);
    const atTop = computed(() => calc.value.atTop);
    const firstVisibleRow = computed(() => calc.value.firstVisibleRow);

    function itemResizeHandler(row: TableRow, h: number) {
        const r = toRaw(row);
        const idx = rowIdx.get(r);
        if (idx == undefined) {
            console.warn("table/virtual: size update for unknown row", r);
            return;
        }
        if (h == sizes[idx]) {
            return;
        }
        sizes[idx] = h;
        sizeMap.set(row, h);
    }

    function scrollToRow(index: number): void {
        let h = 0;
        for (let i = 0; i < sizes.length && i <= index; i++) {
            h += sizes[i];
        }
        const c = container.value;
        if (!c) {
            return;
        }
        c.scrollTo(0, h);
    }

    const data: Virtual = {
        atTop,
        contentHeight,
        paddingTop,
        paddingBottom,
        rows,
        firstVisibleRow,
        scrollToRow,
        scrollHandler,
        itemResizeHandler,
    };
    provide(VirtualInjectionKey, data);
    return data;
}

export function useVirtual(): Virtual {
    const data = inject(VirtualInjectionKey);
    if (!data) {
        throw Error("provideVirtual must be called in a parent component");
    }
    return data;
}
