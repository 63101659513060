import { useI18n } from "vue-i18n";
import type { Formatter } from "../formatter";

export default class Bool implements Formatter {
    protected i18n = useI18n();

    constructor() {}

    format(value: unknown): string {
        return value
            ? this.i18n.t("lumui.formatter.boolean.true")
            : this.i18n.t("lumui.formatter.boolean.false");
    }
}
