<template>
    <test-form v-model:config="configs.a" v-model="values.a" :title="titles.a" />
    <test-form v-model:config="configs.b" v-model="values.b" :title="titles.b" />
    <test-form v-model:config="configs.c" v-model="values.c" :title="titles.c" />
    <test-form v-model:config="configs.d" v-model="values.d" :title="titles.d" />
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "Radio",
    components: { TestForm },
    data() {
        return {
            titles: {
                a: "Password mit bestätigung",
                b: "Password ohne bestätigung",
                c: "Optionales password mit bestätigung",
                d: "Optionales password ohne bestätigung",
            },
            values: {
                a: { p1: "" },
                b: { p2: "" },
                c: { p3: "" },
                d: { p4: "" },
            },
            configs: {
                a: {
                    p1: {
                        type: "password",
                        label: "Passwort required confirm",
                        value: "",
                        required: true,
                    },
                },
                b: {
                    p2: {
                        type: "password",
                        label: "Passwort required no confirm",
                        value: "",
                        required: true,
                        no_confirm: true,
                    },
                },
                c: {
                    p3: {
                        type: "password",
                        label: "Passwort optional confirm",
                        value: "",
                        required: false,
                    },
                },
                d: {
                    p4: {
                        type: "password",
                        label: "Passwort optional no confirm",
                        value: "",
                        required: false,
                        no_confirm: true,
                    },
                },
            },
        };
    },
};
</script>
