<template>
    <thead>
        <tr>
            <th v-if="selection.enabled.value" style="width: 1%">
                <v-checkbox-btn
                    :indeterminate="!selection.allSelected.value && selection.someSelected.value"
                    :model-value="selection.allSelected.value"
                    density="compact"
                    @update:model-value="e => (e ? selection.addAll() : selection.removeAll())"
                />
            </th>
            <template v-for="col in columns" :key="col.key">
                <slot
                    v-if="!col.hidden"
                    :column="col"
                    :columns="columns"
                    :name="'header_' + col.key"
                >
                    <th
                        :class="{ clickable: col.sortable, sorted: isSorted(col.key) }"
                        @click="isSortable(col.key) ? toggleSort(col.key) : undefined"
                    >
                        <div class="d-flex justify-space-between">
                            <span>{{ col.label }}</span>
                            <v-icon
                                v-if="col.sortable"
                                :icon="getSortIcon(col.key)"
                                class="sortIcon"
                            />
                        </div>
                    </th>
                </slot>
            </template>
            <th v-if="expansion.enabled.value || details.enabled.value || haveActions" />
        </tr>
        <tr v-if="loading" class="l-table-progress">
            <th :colspan="columnCount">
                <v-progress-linear :indeterminate="true" height="2" />
            </th>
        </tr>
    </thead>
</template>

<script lang="ts" setup>
import { VCheckboxBtn, VIcon, VProgressLinear } from "vuetify/components";
import type { NormalizedTableColumnConfig } from "../config/Table";
import { useSort } from "./table/sort";
import { useSelection } from "./table/selection";
import { useExpansion } from "./table/expansion";
import { useDetails } from "./table/details";

function getSortIcon(column: string) {
    const c = sortCriteria.value;
    if (!c || c.column != column || c.direction == "asc") {
        return "$sortAsc";
    } else {
        return "$sortDesc";
    }
}

const { isSorted, isSortable, toggle: toggleSort, criteria: sortCriteria } = useSort();
const selection = useSelection();
const expansion = useExpansion();
const details = useDetails();

const _props = defineProps<{
    columns: readonly NormalizedTableColumnConfig[];
    columnCount: number;
    haveActions: boolean;
    loading: boolean;
}>();
</script>

<style lang="scss" scoped>
th .sortIcon {
    opacity: 0;
    transition: opacity 0.3s;
}

th:hover .sortIcon {
    opacity: 0.5;
    transition: opacity 0.3s;
}

th.sorted .sortIcon {
    opacity: 1;
}

tr.l-table-progress th {
    border: none !important;
    height: auto !important;
    padding: 0 !important;
    opacity: 1;
}

thead,
thead > tr,
thead > tr > td,
thead > tr > th {
    transition-property: none !important;
}
</style>
