<template>
    <component
        :is="componentName"
        v-if="componentName && componentExists(componentName)"
        :column="column"
        :value="value"
        :row="row"
    />
    <template v-else>
        <l-formatter
            :context="row"
            :formatter="column.formatter"
            :options="column.options"
            :type="column.type"
            :value="value"
        />
    </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LFormatter from "./LFormatter.vue";
import { useComponentExists } from "../composables/ComponentExists";

/**
 * used to render LTable columns
 * @internal
 * @private
 */
export default defineComponent({
    components: {
        LFormatter,
    },
    props: {
        /**
         * column definition
         * @type {Object}
         */
        column: {
            type: Object,
            required: true,
        },
        /**
         * columns value
         */
        value: {
            type: [String, Number, Boolean, Object, Array],
            required: false,
            default: null,
        },
        /**
         * current row
         */
        row: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    setup() {
        const { t } = useI18n();
        const componentExists = useComponentExists();
        return { t, componentExists };
    },
    computed: {
        componentName() {
            if (!this.column.type) {
                return undefined;
            }
            const type = this.column.type[0].toUpperCase() + this.column.type.slice(1);
            return "LTableColumn" + type;
        },
    },
});
</script>
