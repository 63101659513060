import type { Formatter } from "../formatter";

export default class MaterializedPathFormatter implements Formatter {
    constructor() {}

    format(value: unknown): string {
        if (!value) {
            return "";
        }
        if (typeof value === "object" && Object.hasOwn(value, "label")) {
            return value["label"];
        } else {
            return String(value);
        }
    }
}

export const aliases = ["materialized_path"] as const;
