import { type FormatterConstructor, registerFormatters } from "./lib/formatter";
import VueSignaturePad from "vue-signature-pad";
import type { createVuetify } from "vuetify";
import { vue3Debounce } from "vue-debounce";
import defaultIcons from "./icons";
import { type App, type Component, type Ref, watch } from "vue";
import {
    createSettingsPlugin,
    type SettingsFormEvalContext,
    type SettingsTableEvalContext,
} from "./composables/Settings";
import moment from "moment";
import { createTranslationMappingPlugin } from "./composables/TranslationMapping";
import { createComponentExistsPlugin } from "./composables/ComponentExists";
import { aliases } from "./aliases";

let installed = false;
export type AsyncTranslationLoader = () => Promise<Record<string, string>>;
export type TranslationLoader = AsyncTranslationLoader | Record<string, string>;
export type TranslationLoaders<Languages extends string> = Record<Languages, TranslationLoader>;

function assertNotNull<T>(x: NonNullable<T> | null | undefined): T {
    if (x === null || x === undefined) {
        throw Error("Unexpected undefined value");
    }
    return x;
}

type VuetifyPlugin = ReturnType<typeof createVuetify>;
export type LumUiConfig<Languages extends string> = {
    table?: {
        persistentFilters?: boolean;
        evalContext?: SettingsTableEvalContext;
    };
    form?: {
        evalContext?: SettingsFormEvalContext;
    };
    /** use vue-i18n locale ref */
    locale: Ref<string>;
    fallbackLocale: Languages;
    vuetify: VuetifyPlugin;
    localeKeyMap?: {
        [k in Languages]: string;
    };
    formatter?: Record<string, FormatterConstructor>;
    icons?: Partial<typeof defaultIcons | Record<string, string>>;
    /** @default true */
    legacyAliases?: boolean;
};

const requireComponent = import.meta.glob<{ default: Component }>("./component/*.vue", {
    eager: true,
    // import: "default", // does not work, as LTable cannot be importer
});

function setMomentLocale(l: string) {
    const newLocale = l.toLocaleLowerCase();
    const baseLocale = newLocale.split("-")[0];
    moment.locale(newLocale);
    if (moment.locale() == newLocale) {
        return;
    }
    if (newLocale != baseLocale) {
        moment.locale(newLocale);
        if (moment.locale() == newLocale) {
            return;
        }
    }

    moment.locale(baseLocale);
    if (moment.locale() == baseLocale) {
        return;
    }

    if (newLocale != baseLocale) {
        console.error(
            "plugin: failed to set moment locale to either" +
                newLocale +
                " or " +
                baseLocale +
                ". Did you import 'moment/dist/locale/" +
                newLocale +
                " or 'moment/dist/locale/'" +
                baseLocale +
                "'?",
        );
    } else {
        console.error(
            "plugin: failed to set moment locale to either" +
                newLocale +
                ". Did you import 'moment/dist/locale/" +
                newLocale +
                "'?",
        );
    }
}

export const plugin = {
    install: function <Locales extends string>(app: App, options: LumUiConfig<Locales>) {
        if (installed) {
            return;
        }
        installed = true;
        app.directive("debounce", vue3Debounce({ lock: true }));

        options.vuetify.icons.aliases = Object.assign(
            {},
            options.vuetify.icons.aliases,
            defaultIcons,
            options.icons,
        );

        const settingsPlugin = createSettingsPlugin({
            table: {
                persistentFilters: options.table?.persistentFilters ?? true,
                evalContext: options.table?.evalContext ?? {},
            },
            form: {
                evalContext: options.form?.evalContext ?? {},
            },
        });
        app.use(settingsPlugin);

        const translationMappingPlugin = createTranslationMappingPlugin(
            options.fallbackLocale,
            options.localeKeyMap,
        );
        app.use(translationMappingPlugin);

        if (options.formatter) {
            registerFormatters(options.formatter);
        }

        const componentExistsPlugin = createComponentExistsPlugin();
        app.use(componentExistsPlugin);

        watch(options.locale, setMomentLocale, { immediate: true });

        app.use(VueSignaturePad);

        for (const fileName in requireComponent) {
            const componentConfig = requireComponent[fileName].default;
            const name = assertNotNull(fileName.split("/").pop());
            const componentName = name.replace(/\.\w+$/, "");
            if (app.component(componentName)) {
                console.warn("plugin: " + componentName + " is overridden");
                continue;
            }
            app.component(componentName, componentConfig);
            const comp = app.component(componentName);
            if (!comp) {
                console.error(
                    "plugin: Cannot get component " + componentName + " after registration",
                );
            }
        }

        if (options.legacyAliases ?? true) {
            for (const [alias, componentName] of Object.entries(aliases)) {
                if (alias && componentName) {
                    const component = app.component(componentName);
                    if (!component) {
                        console.error(
                            "plugin: component " +
                                componentName +
                                " does not exist. cannot add alias " +
                                alias,
                        );
                        continue;
                    }
                    console.info("plugin: Aliasing " + alias + " to " + componentName);
                    app.component(alias, component);
                }
            }
        }
    },
};
