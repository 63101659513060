<template>
    <template v-if="range">
        <div class="d-flex">
            <l-form-row-date
                :config="formConfig.from"
                :model-value="value?.from"
                :name="config.key + '-from'"
                @update:model-value="e => updateValue(e, value?.to)"
            />
            <div class="ma-auto pa-2 text-disabled font-weight-bold">&mdash;</div>
            <l-form-row-date
                :config="formConfig.to"
                :model-value="value?.to"
                :name="config.key + '-to'"
                @update:model-value="e => updateValue(value?.from, e)"
            />
        </div>
    </template>
    <template v-else>
        <l-form-row-date
            :config="formConfig"
            :model-value="value"
            :name="config.key"
            @update:model-value="e => $emit('update:modelValue', e)"
        />
    </template>
</template>

<script>
// import {VDatePicker, VMenu, VTextField} from 'vuetify/components';
// import Moment from 'moment'
// import {mapGetters} from "vuex";

import LFormRowDate from "./LFormRowDate.vue";
import moment from "moment";

/**
 * Renders a date filter
 * @internal
 */
export default {
    components: { LFormRowDate },
    // components: {VMenu, VTextField, VDatePicker},
    props: {
        value: {
            type: [Object, String],
            required: false,
            default: undefined,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:modelValue"],

    computed: {
        range() {
            return this.config.filter?.date?.range ?? false;
        },
        formConfig() {
            const filterConfig = this.config.filter.date;
            const label = filterConfig.label ?? this.config.label ?? "";
            if (filterConfig.range ?? false) {
                return {
                    from: {
                        type: "date",
                        label: label + " von",
                        min: this.formatDate(filterConfig.min),
                        max: this.fromDateMax(filterConfig.max, this.value?.to),
                        clearable: true,
                    },
                    to: {
                        type: "date",
                        label: label + " bis",
                        min: this.toDateMin(filterConfig.min, this.value?.from),
                        max: this.formatDate(filterConfig.max),
                        clearable: true,
                    },
                };
            } else {
                return {
                    type: "date",
                    label,
                    min: filterConfig.min
                        ? moment(filterConfig.min).format("YYYY-MM-DD")
                        : undefined,
                    max: filterConfig.max
                        ? moment(filterConfig.max).format("YYYY-MM-DD")
                        : undefined,
                };
            }
        },
    },
    // data() {
    //     return {
    //         date: null,
    //         modal: false,
    //         viewValue: null,
    //         min: undefined,
    //         max: undefined,
    //         range: false,
    //     }
    // },
    methods: {
        formatDate(date) {
            if (!date) {
                return undefined;
            }
            return moment(date).format("YYYY-MM-DD");
        },
        fromDateMax(configMax, toValue) {
            let res = undefined;
            if (toValue === undefined) {
                res = configMax;
            } else if (configMax === undefined) {
                res = new Date(toValue);
            } else {
                const d1 = new Date(toValue);
                const d2 = new Date(configMax);
                res = d1 < d2 ? d1 : d2;
            }
            return this.formatDate(res);
        },
        toDateMin(configMin, fromValue) {
            let res = undefined;
            if (fromValue === undefined && fromValue !== "") {
                res = configMin;
            } else if (configMin === undefined) {
                res = new Date(fromValue);
            } else {
                const d1 = new Date(fromValue);
                const d2 = new Date(configMin);
                res = d1 > d2 ? d1 : d2;
            }
            return this.formatDate(res);
        },
        updateValue(from, to) {
            if (from !== undefined || to !== undefined) {
                this.$emit("update:modelValue", { from, to });
            } else {
                this.$emit("update:modelValue", undefined);
            }
        },
    },
};
</script>
