import type { Formatter } from "../formatter";

export class Currency implements Formatter {
    private readonly currency: string = "sFr.";

    constructor(settings: unknown) {
        if (!settings || !(typeof settings == "object")) {
            throw Error("formatter/Currency: settings are required");
        }
        if ("currency" in settings) {
            const c = settings.currency;
            if (typeof c != "string") {
                throw Error("formatter/Currency: invalid currency type " + typeof c);
            }
            this.currency = c;
        }
    }
    format(value: unknown): string {
        if (typeof value != "string") {
            throw Error("formatter/Currency: invalid value " + JSON.stringify(value));
        }
        const parsedValue = Number.parseFloat(value);
        if (isNaN(parsedValue)) {
            return "";
        } else {
            return parsedValue.toFixed(2) + " " + this.currency;
        }
    }
}
