<template>
    <v-container>
        <h1>Datetime</h1>
        <v-table>
            <thead>
                <tr>
                    <th class="text-left">Unformatted Value</th>
                    <th class="text-left">Formatted Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(date, i) in dates" :key="i">
                    <td>{{ date }}</td>
                    <td>
                        <asa-formatter type="datetime" :value="date" />
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script lang="ts">
import { VContainer, VTable } from "vuetify/components";
import AsaFormatter from "../../../src/component/LFormatter.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        AsaFormatter,
        VTable,
        VContainer,
    },
    data() {
        return {
            dates: [
                "2023-01-01T01:01:01",
                "2023-01-01 01:01:01",
                "2023-01-01T01:01:01Z",
                "2023-01-01 01:01:01Z",
                "2022-12-20 08:00:00",
                "2022-12-20 08:00",
                "2022-12-20 08:00Z",
                "2022-12-20T08:00",
                "2022-12-20T08:00Z",
            ],
        };
    },
});
</script>

<style scoped></style>
