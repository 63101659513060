<template>
    <v-container>
        <h1>locale</h1>
        <asa-formatter type="locale" value="french" />
        <br />
        <asa-formatter type="locale" value="german" />
        <br />
        <asa-formatter type="locale" value="xxx" />
        <br />
    </v-container>
</template>

<script>
import AsaFormatter from "../../../src/component/LFormatter.vue";
import { VContainer } from "vuetify/components";

export default {
    name: "FormatterTime",
    components: {
        AsaFormatter,
        VContainer,
    },
};
</script>
