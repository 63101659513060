<template>
    <v-input
        v-if="isVisible"
        v-click-outside="focusLost"
        :class="{ 'v-input--disabled': isDisabled, required: isRequired }"
        :disabled="isDisabled"
        :error="error"
        :error-messages="aggregatedErrors"
    >
        <v-field
            ref="field"
            :active="true"
            :class="cssClasses"
            :focused="focused"
            :label="theLabel"
            :single-line="false"
        >
            <div style="position: absolute; top: 2px; right: 10px">
                <v-btn color="black" size="small" variant="outlined" @click="clear">
                    <v-icon size="small"> $delete</v-icon>
                    <v-tooltip activator="parent" location="bottom">
                        {{ t("lumui.form.signature.clear") }}
                    </v-tooltip>
                </v-btn>
                <v-btn color="black" size="small" variant="outlined" @click="selectFile">
                    <v-icon size="small"> $upload</v-icon>

                    <v-tooltip activator="parent" location="bottom">
                        {{ t("lumui.form.signature.upload") }}
                    </v-tooltip>
                </v-btn>
            </div>
            <div class="pad">
                <vue-signature-pad
                    ref="signaturePad"
                    :options="getOptions()"
                    style="border-top: 1px dashed rgba(0, 0, 0, 0.2)"
                />
            </div>
        </v-field>
    </v-input>
    <input
        ref="fileElement"
        accept="image/*"
        style="display: none"
        type="file"
        @change="imgUpload"
    />
</template>

<script lang="ts">
// noinspection TypeScriptCheckImport
import { VueSignaturePad } from "vue-signature-pad";
import { VBtn, VField, VIcon, VTooltip, VInput } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import { defineComponent } from "vue";
import type { FormConfigSignature } from "../config/Form";
import { formConfigSignatureSchema } from "../config/Form.zod";
import { useI18n } from "vue-i18n";

// this is not very precise, but accurate enough for our usage.
type SignaturePad = {
    resizeCanvas(): void;
    saveSignature(): { isEmpty: boolean; data: string };
    undoSignature(): void;
    fromDataURL(
        data: string,
        options?: Record<string, any>,
        callback?: (e?: string | Event) => void,
    ): void;
    lockSignaturePad(): void;
    openSignaturePad(): void;
    isEmpty(): boolean;
    clearSignature(): void;
    $refs: {
        signaturePadCanvas: HTMLCanvasElement;
    };
};

/**
 * Unterschriftenfeld
 *
 * #### config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 *
 * @todo has no validation
 */
export default defineComponent({
    components: { VBtn, VIcon, VField, VTooltip, VInput, VueSignaturePad },
    props: {
        modelValue: {
            type: String,
            default: undefined,
            validator(v) {
                const res = formConfigSignatureSchema.shape["value"].safeParse(v);
                if (!res.success) {
                    console.error("LFormRowSignature: invalid dataUri passed to modelValue");
                    return false;
                }
                return true;
            },
        },
        ...makeDefaultProps<FormConfigSignature>(formConfigSignatureSchema),
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const { t } = useI18n();
        return {
            t,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            focused: false,
        };
    },
    computed: {
        signaturePad(): SignaturePad {
            return this.$refs.signaturePad as SignaturePad;
        },
        field(): InstanceType<typeof VField> {
            return this.$refs.textField as InstanceType<typeof VField>;
        },
        fileElement(): HTMLInputElement {
            return this.$refs.fileElement as HTMLInputElement;
        },
        cssClasses() {
            return {
                "v-field--disabled": this.isDisabled,
                "v-field-label--active": true,
                "signature-pad": true,
                focused: this.focused,
            };
        },
    },
    watch: {
        isDisabled: {
            handler() {
                this.handleDisabled();
            },
        },
        modelValue(newValue) {
            if (!this.focused) {
                this.signaturePad.clearSignature();
                if (newValue) {
                    if (!formConfigSignatureSchema.shape["value"].safeParse(newValue).success) {
                        console.error(
                            "LFormRowSignature: not loading signature because data uri validation failed",
                        );
                        return;
                    }

                    this.signaturePad.fromDataURL(newValue);
                }
            }
        },
    },
    mounted() {
        this.signaturePad.clearSignature();
        this.handleDisabled();

        window.addEventListener("resize", this.onResizeCallback);
        this.onResizeCallback();
    },
    unmounted() {
        if (this.onResizeCallback) {
            window.removeEventListener("resize", this.onResizeCallback, false);
        }
    },
    methods: {
        getOptions() {
            return {
                onBegin: () => {
                    this.focused = true;
                },
                onEnd: () => {
                    this.emitInput();
                },
            };
        },
        isEmpty(): boolean {
            return this.signaturePad.isEmpty();
        },
        handleDisabled() {
            if (this.isDisabled) {
                this.signaturePad.lockSignaturePad();
            } else {
                this.signaturePad.openSignaturePad();
            }
        },
        onResizeCallback() {
            this.$nextTick(() => this.signaturePad.resizeCanvas());
        },
        focusLost() {
            this.focused = false;
        },
        selectFile() {
            this.fileElement.click();
        },
        /* @private */
        emitInput() {
            const { data } = this.signaturePad.saveSignature();
            /**
             * user input
             */
            this.$emit("update:modelValue", data);
        },
        /** clear signature */
        clear() {
            this.signaturePad.clearSignature();
            this.emitInput();
        },
        /* @private */
        imgUpload() {
            if (!this.fileElement.files) {
                return;
            }
            const file = this.fileElement.files[0];
            const reader = new FileReader();
            this.signaturePad.clearSignature();

            reader.addEventListener(
                "load",
                () => {
                    const img = new Image();
                    img.onload = () => {
                        const ratio = img.width / img.height;
                        const canvas = this.signaturePad.$refs.signaturePadCanvas;
                        let width = img.width;
                        let height = img.height;

                        if (width > canvas.width) {
                            height = height * (canvas.width / width);
                            width = canvas.width;
                        }

                        if (height > canvas.height) {
                            width = width * (canvas.height / height);
                            height = canvas.height;
                        }

                        const result = reader.result;
                        if (typeof result != "string") {
                            throw Error("reader result should be string " + typeof result);
                        }

                        this.signaturePad.fromDataURL(
                            result,
                            {
                                ratio: ratio,
                                width: width,
                                height: height,
                            },
                            (e?: string | Event) => {
                                if (e) {
                                    console.error("LFormRowSignature", e);
                                    return;
                                }
                                this.emitInput();
                            },
                        );
                    };

                    img.src = String(reader.result);
                },
                false,
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        },
    },
});
</script>

<style scoped>
.pad {
    padding-top: 40px;
    width: 100%;
    user-select: none;
    height: 300px;
}

.pad:before {
    bottom: 20%;
    content: "";
    left: 5%;
    right: 5%;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border: 0 solid rgba(1, 0, 0, 0.42);
    border-top-width: 1px;
}

.pad:hover:before {
    bottom: 20%;
    content: "";
    left: 5%;
    right: 5%;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border-color: rgba(0, 0, 0, 0.87);
    border-style: solid;
    border-width: 1px 0 0 0;
}
</style>
