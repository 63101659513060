<template>
    <v-container fluid>
        <v-row
            v-for="(config, id) in configs"
            :id="id + '_row'"
            :key="id"
            style="border-bottom: 1px solid lightgray"
        >
            <v-col cols="8">
                <span class="text-subtitle-1 text-uppercase">{{ config.label ?? "" }}</span>
            </v-col>
            <v-divider vertical />
            <v-col cols="8">
                <span class="text-subtitle-2">Element</span>
                <form-row :id="id" :ref="id" v-model="values[id]" :config="config" />
                <span class="text-subtitle-2">Behavior</span>
                <ul class="text-body-2">
                    <li v-for="(item, idx) in behavior[id]" :key="id + '_' + idx">
                        {{ item }}
                    </li>
                </ul>
            </v-col>
            <v-divider vertical />
            <v-col cols="4">
                <span class="text-subtitle-2">Inspector</span>
                <v-list>
                    <v-list-item two-lines>
                        <v-list-item-subtitle> Value </v-list-item-subtitle>
                        <v-list-item-title>
                            <span :id="id + '_value'">{{ values[id] }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item two-lines>
                        <v-list-item-subtitle> Valid </v-list-item-subtitle>
                        <v-list-item-title>
                            <span :id="id + '_valid'">{{ isValid(id) }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import FormRow from "../../../src/component/LFormRow.vue";
import {
    VCol,
    VContainer,
    VDivider,
    VList,
    VListItem,
    VListItemSubtitle,
    VListItemTitle,
    VRow,
} from "vuetify/components";
import { defineComponent } from "vue";
import type { FormConfigRow } from "../../../src/config/Form";

export default defineComponent({
    name: "TextInput",
    components: {
        FormRow,
        VRow,
        VCol,
        VDivider,
        VContainer,
        VList,
        VListItem,
        VListItemTitle,
        VListItemSubtitle,
    },
    data() {
        return {
            behavior: {
                simple_text: [
                    "Value should match input",
                    "Any input is valid",
                    "Empty input is valid",
                ],
                simple_required_text: [
                    "Value should match input",
                    "Any input is valid",
                    "Empty input is not valid",
                ],
                simple_mail: [
                    "Value should match input",
                    "Only valid e-mail addresses are accepted inputs",
                    "Empty input is valid",
                ],
                simple_number: [
                    "Value should match the input, if input is a valid number. Empty otherwise",
                    "Only valid numbers addresses are accepted inputs",
                    "Empty input is valid",
                ],
                number_stepper: [
                    "Basic validation according to simple_number",
                    "Empty value is invalid",
                    "Klick on stepper arrow 'up' increases number by 10",
                    "Klick on stepper arrow 'down' decreases number by 10",
                    "Maximum input value is 100, stepper should not exceed maximum value, manual input above maximum value should result in valid = false ",
                    "Minimal input value is 10, stepper should not go below minimum value, manual input below minimum value should result in valid = false",
                ],
                simple_url: [
                    "Value should match the input",
                    "Only valid urls are accepted inputs",
                    "Empty input is valid",
                ],
                wysiwyg: [
                    "Empty value is valid",
                    "Input should be wrapped in <p></p> tags",
                    "Bold icon should wrap input in additional <strong></strong> tags",
                    "Italics icon should wrap input in additional <em></em> tags",
                    "Strikethrough icon should wrap input in additional <del></del> tags",
                    "List icon should wrap input in unordered list <ul><li>[input]</li></ul> or ordered list <ol><li>[input]</li></ol>",
                    "Image icon should launch upload dialog, img upload should result in valid img tag",
                    "Klick on image should open edit and delete option, delete option should delete image, edit option should open edit dialog",
                    "First option of link icon should open link creation dialog, valid inputs should result in valid anchor tag reflecting dialog input values",
                    "Second option of link icon should open edit dialog, valid inputs should result in valid anchor tag with updated values from dialog",
                ],
                localized_input: [
                    "Empty input is not valid",
                    "Requires input for all language fields to be valid",
                    "Klick on circle arrow copies current input to all other language fields",
                    "Result value contains object {'languageCode': 'value of language input field, [...]}",
                ],
            },
            configs: {
                simple_text: {
                    name: "simple_text",
                    type: "text",
                    label: "Simple Text Input",
                    required: false,
                } as FormConfigRow,
                simple_required_text: {
                    name: "simple_required_text",
                    type: "text",
                    label: "Simple Required Text Input",
                    required: true,
                } as FormConfigRow,
                simple_mail: {
                    type: "email",
                    label: "E-Mail Input",
                    required: false,
                } as FormConfigRow,
                simple_number: {
                    name: "simple_number",
                    type: "number",
                    label: "Number Input",
                    required: false,
                } as FormConfigRow,
                number_stepper: {
                    name: "number_stepper",
                    type: "number",
                    label: "Number Input with stepper",
                    step: 10,
                    min: 10,
                    max: 100,
                    required: false,
                } as FormConfigRow,
                wysiwyg: {
                    name: "wysiwyg",
                    type: "wysiwyg",
                    label: "WYSIWYG",
                    required: false,
                } as FormConfigRow,
                localized_input: {
                    name: "localized_input",
                    type: "localized",
                    label: "Localized input",
                    child: "text",
                    required: true,
                } as FormConfigRow,
            },
            values: {} as Record<string, any>,
        };
    },
    methods: {
        getElement(id: string): InstanceType<typeof FormRow> {
            return this.$refs[id] as InstanceType<typeof FormRow>;
        },
        isValid(id: string) {
            if (Object.hasOwn(this.$refs, id)) {
                const element = this.getElement(id);
                return element.$el.$children[0].valid ? "true" : "false";
            } else {
                return "n/a";
            }
        },
    },
});
</script>

<style scoped>
span:before {
    content: "\200D";
    display: inline;
}
</style>
