import type { Formatter } from "../formatter";

type Option = { text: string; value: unknown } | { label: string; value: unknown };

function isOption(x: unknown): x is Option {
    return !!x && typeof x === "object" && ("text" in x || "label" in x) && "value" in x;
}

function getLabel(o: Option) {
    if ("text" in o) {
        return o.text;
    }
    return o.label;
}

type Options = Array<Option>;

function isOptionArray(x: unknown): x is Array<Option> {
    return Array.isArray(x) && x.every(isOption);
}

export class Checkbox implements Formatter {
    private options: Options;

    constructor(options: unknown) {
        if (!isOptionArray(options)) {
            throw Error("formatter/Checkbox: invalid options " + JSON.stringify(options));
        }
        this.options = options;
    }

    format(value: unknown): string {
        if (!value) {
            return "";
        }
        const array: unknown[] = Array.isArray(value) ? value : [value];
        let labels = "";
        this.options.forEach(option => {
            if (array.indexOf(option.value)) {
                labels += getLabel(option);
                labels += "<br>";
            }
        });
        return labels;
    }
}
