<template>
    <v-text-field
        v-if="isVisible"
        :id="id || name"
        v-model="model"
        :class="[config.class, { required: isRequired }]"
        :clearable="config.clearable ?? false"
        :counter="maxLength"
        :disabled="isDisabled"
        :error="error || undefined"
        :error-messages="aggregatedErrors"
        :hide-details="config.hideDetails"
        :hint="hint"
        :inputmode="InputType[inputType]"
        :label="theLabel"
        :max="config.max"
        :min="config.min"
        :name="name"
        :placeholder="config.placeholder"
        :prefix="config.prefix"
        :required="required"
        :rules="rules"
        :step="config.step || 'any'"
        :suffix="config.suffix"
        :type="config.type || 'number'"
        autocomplete="off"
    />
</template>

<script lang="ts" setup>
import { VTextField } from "vuetify/components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { FormConfigNumber } from "../config/Form";
import { formConfigNumberSchema } from "../config/Form.zod";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type de from "../translations/de";

/**
 * #### Config
 *
 *
 * | key                    | type                       | required | default     | description |
 * |------------------------|----------------------------|----------|-------------|-------------|
 * | type                   | `String`                   | yes      |             | field type  |
 * | label                  | `String`, `false`          | no       | `false`     | fields label |
 * | description            | `String`                   | no       | `""`        | field description |
 * | class                  | `String`                   | no       | `null`      | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`     | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`     | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`     | field is rendered.  |
 * | prefix                 | `String`                   | no       | `undefined` | Adds prefix to field. |
 * | suffix                 | `String`                   | no       | `undefined` | Adds suffix to field. |
 * | maxLength              | `Number`                   | no       | `false`     | Limit input to maxLenght chars. Also display length counter. |
 * | placeholder            | `String`                   | no       | `""`        | placeholder displayed inside the field, when value is empty |
 * | hideDetails            | `String`, `Bool`           | no       | `undefined` | When set to "auto" details are only rendered when necessary
 * | step                   | `Number`                   | no       | `any`       |
 * | min                    | `Number`                   | no       | `undefined` |
 * | max                    | `Number`                   | no       | `undefined` |
 */

const props = defineProps({
    modelValue: {
        type: [String],
        required: false,
        default: undefined,
    },
    ...makeDefaultProps<FormConfigNumber>(formConfigNumberSchema),
});

const emit = defineEmits(["update:modelValue", "update:error"]);

const { isRequired, isVisible, isDisabled, aggregatedErrors, theLabel } = useDefaults(props, emit);

const { t } = useI18n<keyof typeof de>();

enum InputType {
    decimal,
    number,
}

const inputType = computed<InputType>(() => {
    if (!("step" in props.config)) {
        return InputType.decimal;
    }
    return onlyDigits(String(props.config.step)) ? InputType.number : InputType.decimal;
});

const model = computed({
    get(): string {
        return props.modelValue ?? "";
    },

    set(v: string | null | undefined) {
        emit("update:modelValue", v || "");
    },
});

const rules = computed(() => {
    if (props.rules) {
        return props.rules;
    }
    const rules: Array<(_val: string) => boolean | string> = [];
    if (isRequired.value) {
        rules.push(val => !isEmpty(val) || t("lumui.form.row.required"));
    }
    // Wenn das Attribute step ist eine Ganzzahl, dann sind nur Ganzzahlen erlaubt
    switch (inputType.value) {
        case InputType.number:
            rules.push(val => isEmpty(val) || isNumber(val) || t("lumui.form.row.number"));
            break;
        case InputType.decimal:
            rules.push(val => isEmpty(val) || isDecimal(val) || t("lumui.form.row.number"));
            break;
    }
    if ("min" in props.config) {
        const min =
            typeof props.config.min == "string" ? parseInt(props.config.min) : props.config.min;
        rules.push(
            val =>
                typeof min != "number" ||
                isEmpty(val) ||
                parseInt(val) >= min ||
                t("lumui.form.row.min", { min }),
        );
    }
    if ("max" in props.config) {
        const max =
            typeof props.config.max == "string" ? parseInt(props.config.max) : props.config.max;
        rules.push(
            val =>
                typeof max != "number" ||
                isEmpty(val) ||
                parseInt(val) <= max ||
                t("lumui.form.row.max", { max }),
        );
    }
    return rules;
});
const hint = computed(() => {
    let description = props.config.description || "";
    const minmax = new Array<string>();
    if ("min" in props.config) {
        minmax.push("≥ " + String(props.config.min));
    }
    if ("max" in props.config) {
        minmax.push("≤ " + String(props.config.max));
    }
    if (minmax.length > 0) {
        description += " " + minmax.join(" & ");
    }
    return description.trim();
});

const maxLength = computed(() => {
    const max = props.config.maxLength;
    if (typeof max == "string") {
        return Number.parseInt(max);
    } else if (typeof max == "number") {
        return max;
    }
    return undefined;
});

function onlyDigits(val: string) {
    return /^\d+$/.test(val);
}

function isNumber(val: string) {
    return /^-?\d+$/.test(val);
}

function isDecimal(val: string) {
    return /^-?\d+\.?\d*$/.test(val);
}

function isEmpty(str: string | undefined | null) {
    return !str || str.length === 0;
}
</script>
