import { z } from "zod";

const schema = z.array(z.object({ value: z.unknown(), text: z.string() }));

export class Select {
    readonly options = new Map<any, string>();
    constructor(settings: unknown) {
        const options = schema.parse(settings);
        for (const option of options) {
            this.options.set(option.value, option.text);
        }
    }

    format(value: unknown) {
        if (value == undefined) {
            return "";
        }
        const label: string[] = [];
        const array = Array.isArray(value) ? value : [value];
        for (const v of array) {
            const option = this.options.get(v);
            if (option) {
                label.push(option);
            } else {
                label.push(JSON.stringify(v));
            }
        }

        if (label.length === 0) {
            return "";
        } else if (Array.isArray(value) && value.length > 1) {
            return "<ul><li>" + label.join("</li><li>") + "</li></ul>";
        }
        return label.join("");
    }
}
