<template>
    <v-dialog
        :fullscreen="smAndDown"
        :max-width="width"
        :show="show"
        :scrim="false"
        scrollable
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar>
                <v-toolbar-title>{{ t("lumui.lightbox.title") }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close()">
                    <v-icon>$close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-divider />
            <v-card-text style="position: relative">
                <v-img v-if="image.src" :src="image.src" class="bg-grey-lighten-2">
                    <template #placeholder>
                        <v-layout align-center fill-height justify-center ma-0>
                            <v-progress-circular color="grey-lighten-5" indeterminate />
                        </v-layout>
                    </template>
                </v-img>

                <v-btn
                    :disabled="i <= 0"
                    absolute
                    color="primary"
                    size="small"
                    style="top: 50%; left: 5%"
                    @click="showPrev()"
                >
                    <v-icon dark> $prev</v-icon>
                </v-btn>
                <v-btn
                    :disabled="i + 1 >= images.length"
                    absolute
                    color="primary"
                    size="small"
                    style="top: 50%; right: 5%"
                    @click="showNext()"
                >
                    <v-icon dark> $next</v-icon>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import {
    VBtn,
    VCard,
    VCardText,
    VDialog,
    VDivider,
    VIcon,
    VImg,
    VLayout,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VProgressCircular,
} from "vuetify/components";
import { useI18n } from "vue-i18n";
import { defineComponent, type PropType } from "vue";
import { useDisplay } from "vuetify";

export type LLightboxImage = { src: string; title: string };

export default defineComponent({
    components: {
        VDialog,
        VCard,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VBtn,
        VIcon,
        VDivider,
        VCardText,
        VImg,
        VLayout,
        VProgressCircular,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        images: {
            type: Array as PropType<Array<LLightboxImage>>,
            default: () => [],
        },
        width: {
            type: Number,
            default: 1200,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    emits: ["close"],
    setup() {
        const { t } = useI18n();
        const { smAndDown } = useDisplay();
        return {
            t,
            smAndDown,
        };
    },
    data() {
        return {
            i: 0,
        };
    },
    computed: {
        image(): LLightboxImage {
            if (this.images.length === 0) {
                return { src: "", title: "" };
            }
            return this.images.length > this.i ? this.images[this.i] : this.images[0];
        },
    },
    watch: {
        index() {
            this.i = this.index;
        },
    },
    methods: {
        showNext() {
            if (this.i < this.images.length) {
                this.i++;
            } else {
                this.i = 0;
            }
        },
        showPrev() {
            if (this.i > 0) {
                this.i--;
            } else {
                this.i = this.images.length - 1;
            }
        },
        close() {
            this.$emit("close");
        },
    },
});
</script>
