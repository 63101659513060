export default {
    preview: "fa-solid fa-image",
    download: "fa-solid fa-download",
    upload: "fa-solid fa-cloud-arrow-up",
    delete: "fa-solid fa-trash",
    remove: "fa-solid fa-times",
    expand: "fa-solid fa-chevron-up",
    showDetails: "fa-solid fa-chevron-right",
    collapse: "fa-solid fa-chevron-down",
    prev: "fa-solid fa-chevron-left",
    next: "fa-solid fa-chevron-right",
    up: "fa-solid fa-chevron-up",
    down: "fa-solid fa-chevron-down",
    search: "fa-solid fa-magnifying-glass",
    moreVert: "fa-solid fa-ellipsis-vertical", //unused?
    warning: "fa-solid fa-triangle-exclamation",
    selectAll: "fa-solid fa-check-double",
    clear: "fa-solid fa-times-circle",
    close: "fa-solid fa-times",
    dropUp: "fa-solid fa-caret-up",
    dropDown: "fa-solid fa-caret-down",
    zoomIn: "fa-solid fa-magnifying-glass-plus",
    zoomOut: "fa-solid fa-magnifying-glass-minus",
    setAllToCurrent: "fa-solid fa-rotate-right",
    calendar: "fa-solid fa-calendar",
    event: "fa-solid fa-clock",
    reveal: "fa-solid fa-eye",
    hide: "fa-solid fa-eye-slash",
    add: "fa-solid fa-plus",
    file: "fa-solid fa-file",
    sortAsc: "fa-solid fa-caret-up",
    sortDesc: "fa-solid fa-caret-down",
};
