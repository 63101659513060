<template>
    <v-autocomplete
        :clearable="clearable"
        :items="items"
        :label="config.label"
        :model-value="value"
        hide-details
        item-title="label"
        item-value="key"
        return-object
        @update:model-value="e => $emit('update:modelValue', e)"
    >
        <template #item="{ item, props }">
            <v-list-item
                :class="{ 'v-item--disabled v-list-item--disabled': item.raw.disabled ?? false }"
                :subtitle="item.raw.parents"
                v-bind="props"
            />
        </template>
    </v-autocomplete>
</template>

<script lang="ts">
import { VAutocomplete, VListItem } from "vuetify/components";
import { defineComponent, type PropType } from "vue";

type Item = {
    label: string;
    left: number;
    right: number;
    root: number;
    parents: string;
    disabled?: boolean;
};
/**
 * Renders a NestedSet filter
 * @internal
 */
export default defineComponent({
    components: { VAutocomplete, VListItem },
    props: {
        value: {
            type: Object as PropType<Item>,
            default: undefined,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            items: new Array<Item>(),
            nextIndex: 0,
            parentsTimeout: undefined as undefined | ReturnType<typeof setTimeout>,
        };
    },
    computed: {
        clearable() {
            if (Object.hasOwn(this.config, "clearable")) {
                return !!this.config.clearable;
            } else {
                return true;
            }
        },
    },
    watch: {
        config: {
            handler(newVal) {
                this.items = newVal.filter.nestedset.map(item => {
                    item.key = `${item.root}-${item.left}-${item.right}`;
                    item.parents = "..."; //parents;
                    return item;
                });
                this.nextIndex = 0;
                if (this.parentsTimeout) {
                    clearTimeout(this.parentsTimeout);
                }
                this.parentsTimeout = setTimeout(this.getParentString, 0);
            },
            immediate: true,
        },
    },
    methods: {
        getParentString() {
            if (this.nextIndex >= this.items.length) {
                window.clearTimeout(this.parentsTimeout);
                return;
            }
            const item = this.items[this.nextIndex];
            this.items[this.nextIndex].parents = this.items
                .filter(structure => {
                    return (
                        structure.root === item.root &&
                        structure.left < item.left &&
                        structure.right > item.right
                    );
                })
                .sort((a, b) => {
                    if (a.left > b.left) {
                        return 1;
                    }
                    if (a.left < b.left) {
                        return -1;
                    }
                    return 0;
                })
                .map(i => {
                    return i.label;
                })
                .join(" / ");
            ++this.nextIndex;

            window.setTimeout(this.getParentString, 0);
        },
    },
});
</script>
