<template>
    <v-container>
        <h1>Datums Sortierung</h1>
        <l-table :data="tableData" />
    </v-container>
</template>

<script>
import { VContainer } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "Datesort",
    components: { LTable, VContainer },
    data() {
        return {
            tableData: {
                columns: [
                    {
                        key: "id",
                        hidden: true,
                    },
                    {
                        key: "col1",
                        label: "Column 1 (String)",
                    },
                    {
                        key: "col2",
                        hidden: true,
                        details: {
                            label: "Col 2 (String, Only in Detailsview)",
                            key: "col2",
                            hidden: false,
                        },
                    },
                    {
                        key: "col3",
                        label: "Column 3 (Date)",
                        type: "date",
                    },
                    {
                        key: "action",
                        label: "",
                        type: "action",
                        formatter: "action",
                        actions: {},
                    },
                ],
                rows: [
                    {
                        id: 1,
                        col1: "Lorem Ipsum",
                        col2:
                            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        col3: "1990-10-01",
                        action: [],
                    },
                    {
                        id: 2,
                        col1: "Lorem Ipsum (Deutsch)",
                        col2:
                            "Auch gibt es niemanden, der den Schmerz an sich liebt, sucht oder wünscht, nur, weil er Schmerz ist, es sei denn, es kommt zu zufälligen Umständen, in denen Mühen und Schmerz ihm große Freude bereiten können. Um ein triviales Beispiel zu nehmen, wer von uns unterzieht sich je anstrengender körperlicher",
                        col3: "1990-09-15",
                        action: [],
                    },
                    {
                        id: 3,
                        col1: "Kafka",
                        col2:
                            "Jemand musste Josef K. verleumdet haben, denn ohne dass er etwas Böses getan hätte, wurde er eines Morgens verhaftet. »Wie ein Hund!« sagte er, es war, als sollte die Scham ihn überleben. Als Gregor Samsa eines Morgens aus unruhigen Träumen erwachte, fand er sich in seinem Bett zu einem ungeheueren",
                        col3: "1990-11-30",
                        action: [],
                    },
                ],
            },
        };
    },
};
</script>

<style scoped></style>
