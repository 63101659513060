<template>
    <v-container>
        <h1 class="text-h3">No Data</h1>
        <h2 class="my-5">Default no data message:</h2>
        <l-table :data="table1" storage-key="table_nodata_1" />

        <v-divider />
        <h2 class="my-5">Custom no data message:</h2>
        <l-table :data="table1" storage-key="table_nodata_2">
            <template #no-data>
                <div style="text-align: center">
                    <em>Custom no data message</em>
                </div>
            </template>
        </l-table>

        <v-divider />

        <h2 class="my-5">Default no results message:</h2>
        <l-table :data="table2" :show-filter="true" storage-key="table_nodata_3" />

        <v-divider />

        <h2 class="my-5">Custom no results message:</h2>
        <l-table
            :data="table2"
            :show-filter="true"
            storage-key="table_nodata_4"
            @filter="console.log"
        >
            <template #no-results>
                <div style="text-align: center">
                    <em>Custom no results message</em>
                </div>
            </template>
        </l-table>
    </v-container>
</template>

<script>
import { VContainer, VDivider } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "TableNodata",
    components: { LTable, VContainer, VDivider },
    data() {
        return {
            table1: {
                columns: [{ key: "id", label: "id" }],
                rows: [],
            },
            table2: {
                columns: [{ key: "id", label: "id" }],
                filter: [{ key: "id", type: "int", value: "123", label: "idx" }],
                rows: [{ id: 2 }],
            },
        };
    },
};
</script>
