<template>
    <v-dialog :model-value="true" v-bind="defaultAttrs" :fullscreen="breakpoint">
        <!-- dialog contents -->
        <slot />
    </v-dialog>
</template>

<script lang="ts">
import { VDialog } from "vuetify/components";
import { useDisplay } from "vuetify";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    components: { VDialog },
    inheritAttrs: true,
    props: {
        /** width limit for the dialog. */
        scrollable: {
            type: Boolean,
            default: true,
        },
        persistent: {
            type: Boolean,
            default: true,
        },
        maxWidth: {
            type: [String, Number],
            default: 800,
        },
        fullscreen: {
            type: [Function, Boolean] as PropType<(() => boolean) | boolean>,
            default: undefined,
        },
    },
    setup() {
        const { mobile } = useDisplay();
        return { mobile };
    },
    computed: {
        breakpoint(): boolean {
            const fs = this.fullscreen;
            if (typeof fs === "function") {
                return fs();
            }
            return fs ?? this.mobile;
        },
        /** @private merges attributes set on this component with the default values */
        defaultAttrs() {
            return Object.assign({}, this.$props, this.$attrs);
        },
    },
});
</script>
