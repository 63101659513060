import type { BooleanEvalString } from "./Types";
import { type sandbox } from "../lib/Sandbox";

interface BaseAction {
    key: string;
    label: string;
    icon: string;
    params?: Record<string, string>;
    condition?: boolean | BooleanEvalString;
    visible?: boolean | BooleanEvalString;
}

interface RouteAction extends BaseAction {
    route: string;
}

interface EventAction extends BaseAction {
    action: string;
}

export type TableRowAction = RouteAction | EventAction;

export interface NormalizedTableRowAction {
    key: string;
    label: string;
    icon: string;
    param?: Record<string, string>;
    condition: boolean | ReturnType<typeof sandbox>;
    visible: boolean | ReturnType<typeof sandbox>;
    route?: string;
    action?: string;
}

export type TableOptions =
    | Record<string, unknown>
    | Array<{ text: string; value: unknown; color?: string }>;

export type TableNormalizedOption = {
    value: any;
    text: string;
    color?: string;
};
export type TableNormalizedOptions = Array<TableNormalizedOption>;

export type TableColumnActionConfig = {
    type?: "action";
    label?: undefined | "";
    key: string;
    sortable?: false;
    hidden?: boolean;
    formatter?: "action";
    actions: Array<TableRowAction>;
};

export function isTableColumnAction(x: TableColumnConfig): x is TableColumnActionConfig {
    return (x.type && x.type == "action") || "actions" in x;
}

export type ColumnType =
    | "date"
    | "status"
    | "localized"
    | "number"
    | "bool"
    | "currency"
    | "select"
    | "checkbox"
    | "statusdetail"
    | "striptags"
    | "nestedset"
    | "materialized_path"
    | "text"
    | "image"
    | "time"
    | "boolean";

export type TableGenericColumnConfig = {
    label?: string;
    key: string;
    /** @default "left" */
    align?: "left" | "right" | "center";
    /** @default false */
    hidden?: boolean;
    /** @default true */
    sortable?: boolean;
    /** @default "text" */
    type?: Exclude<ColumnType, "action">;
    formatter?: string | { [type: string]: Record<string, any> };
    options?: TableOptions; // only type = ?
    details?: {
        label?: string;
        key?: string;
        /** @default false */
        hidden?: boolean;
        type?: string;
        formatter?: string;
        options?: TableOptions;
    };
    export?: {
        label?: string;
        key?: string;
        /** @default "left" */
        align?: "left" | "right" | "center";
        /** @default false */
        hidden?: boolean;
        type?: string;
        options?: TableOptions;
        formatter?: string;
        width?: string | number;
        minWidth?: string | number;
        height?: string | number;
        minHeight?: string | number;
    };
};
export type TableColumnConfig = TableColumnActionConfig | TableGenericColumnConfig;

export type NormalizedTableColumnConfig = {
    label: string;
    key: string;
    /** @default "left" */
    align: "left" | "right" | "center";
    /** @default false */
    hidden: boolean;
    /** @default true */
    sortable: boolean;
    /** @default "text" */
    type?: ColumnType | "action";
    formatter: string | undefined;
    options: TableNormalizedOptions | undefined; // only type = ?
    details: {
        label: string;
        key: string;
        /** @default false */
        hidden: boolean;
        type: string;
        formatter: string | undefined;
        options: TableNormalizedOptions | undefined;
    };
    export: {
        label: string;
        key: string;
        /** @default "left" */
        align: "left" | "right" | "center";
        /** @default false */
        hidden: boolean;
        type: string;
        options: TableNormalizedOptions | undefined;
        formatter: string | undefined;
        width?: string | number;
        minWidth?: string | number;
        height?: string | number;
        minHeight?: string | number;
    };
};

export function isTableColumnGenericConfig(x: TableColumnConfig): x is TableGenericColumnConfig {
    return x.type != "action";
}

export function isTableColumnActionConfig(x: TableColumnConfig): x is TableColumnActionConfig {
    return x.type == "action";
}

export interface TableFilterBase {
    type?: string;
    key: string;
    searchable?: true;
    label?: string;
    /** @default false */
    hidden?: boolean;
    value?: any;
}

export interface TableFilterGenericConfig extends TableFilterBase {
    type?: Exclude<string, "date" | "select">;
    filter?: "bool" | "date";
}

export interface TableFilterSelectConfig extends TableFilterBase {
    type?: "select";
    clearable?: boolean;
    filter: {
        select: {
            label?: string;
            multiple?: boolean;
            options: Record<string, string> | { text: string; value: any }[];
        };
    };
}

export interface TableFilterDateConfig extends TableFilterBase {
    type: "date";
    filter?: {
        date?: {
            label?: string;
            range?: boolean;
            min?: string;
            max?: string;
        };
    };
    value?: any;
}

export type NestedSetFilterItem = {
    label: string;
    path: string;
    parents?: string;
    root: number;
    left: number;
    right: number;
    /** @default false */
};

export interface TableFilterNestedSetConfig extends TableFilterBase {
    type: "nestedset";
    filter: {
        nestedset: Array<NestedSetFilterItem>;
    };
}

export interface MaterializedPathFilterItem extends TableFilterBase {
    path: string;
    parents?: string;
    /** @default: false */
    disabled?: boolean;
}

export interface TableFilterMaterializedPathConfig extends TableFilterBase {
    type: "materialized_path";
    filter: {
        materialized_path: Array<MaterializedPathFilterItem>;
    };
}

export type TableRow = {
    [key: string]: any;
    id: number | string;
};

export type TableFilterConfig =
    | TableFilterSelectConfig
    | TableFilterDateConfig
    | TableFilterNestedSetConfig
    | TableFilterMaterializedPathConfig
    | TableFilterGenericConfig;

export type TableConfig = {
    columns: TableColumnConfig[];
    filter: TableFilterConfig[];
    rows: TableRow[];
    defaultSort?: {
        sortBy: string[];
        sortDesc: string[];
    };
};
