<template>
    <v-container>
        <h1>Localized</h1>
        <asa-formatter type="localized" :value="{ de: 'Deutsch', en: 'English' }" />
        <br />
        <asa-formatter type="localized" :value="{ de: 'German Fallback' }" />
        <br />
    </v-container>
</template>

<script>
import AsaFormatter from "../../../src/component/LFormatter.vue";
import { VContainer } from "vuetify/components";

export default {
    name: "FormatterLocalized",
    components: {
        AsaFormatter,
        VContainer,
    },
};
</script>
