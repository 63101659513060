<template>
    <v-dialog v-if="dialog" :model-value="loading" :scrim="false" persistent width="300">
        <v-card flat>
            <v-card-text class="text-center">
                <v-progress-circular class="mt-3" indeterminate />
                <br />
                {{ text || t("lumui.loading.message") }}
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-card v-else-if="loading" class="mt-1" flat>
        <v-card-text class="text-center">
            <v-progress-circular class="mt-3" indeterminate />
            <br />
            {{ text || t("lumui.loading.message") }}
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import { VCard, VCardText, VDialog, VProgressCircular } from "vuetify/components";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

/** displays a loading animation */
export default defineComponent({
    components: { VDialog, VCard, VCardText, VProgressCircular },
    props: {
        /** if true display the animation/dialog */
        loading: {
            type: Boolean,
            default: false,
            required: true,
        },
        /** if true renders the animation in an overlaid dialog */
        dialog: {
            type: Boolean,
            default: false,
        },
        /** The text message to display alongside the animation */
        text: {
            type: String,
            default: null,
            required: false,
        },
    },
    setup() {
        const { t } = useI18n();
        return {
            t,
        };
    },
});
</script>
