<template>
    <!-- eslint-disable vue/no-v-html vue/no-v-text-v-html-on-component -->
    <v-autocomplete
        v-if="isVisible"
        :id="name"
        ref="autocomplete"
        v-model:menu="menu"
        v-model:search="search"
        :class="{ required: isRequired }"
        :chips="chips"
        :clearable="(!isRequired && !isMultiple) || config.clearable"
        :custom-filter="filter"
        :disabled="isDisabled"
        :error-messages="aggregatedErrors"
        :hide-selected="isAsync"
        :hint="config.description"
        :items="options"
        :label="theLabel"
        :loading="isLoading"
        :menu-icon="'$dropDown'"
        :model-value="viewValue"
        :multiple="isMultiple"
        :name="name"
        :no-data-text="noDataText"
        :persistent-hint="true"
        :required="required"
        :return-object="isAsync"
        :rules="rules || []"
        autocomplete="off"
        closable-chips
        debounce-events="onkeydown"
        item-title="label"
        item-value="value"
        @update:model-value="e => updateModelValue(e)"
    >
        <template #item="{ props, item }">
            <v-list-item
                v-bind="{
                    onClick: props.onClick as () => void,
                }"
                :value="props.value"
                :disabled="item.raw.disabled ?? false"
            >
                <template #prepend="ctx">
                    <v-checkbox-btn
                        v-if="isMultiple"
                        :model-value="ctx.isSelected"
                        @update:model-value="ctx.select"
                    />
                    <v-icon v-if="!!item.raw.attr?.icon" :icon="item.raw.attr.icon" size="small" />
                    <v-avatar
                        v-if="!!item.raw.attr?.image"
                        :image="item.raw.attr.image"
                        rounded="false"
                        size="small"
                    />
                </template>
                <v-list-item-title v-if="htmlOptions">
                    <span class="limit-width" v-html="item.raw.htmlLabel" />
                </v-list-item-title>
                <v-list-item-title v-else>
                    <span class="limit-width">{{ item.title }}</span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.raw.group">
                    <span class="limit-width text-caption">{{ item.raw.group }} </span>
                </v-list-item-subtitle>
            </v-list-item>
        </template>
        <template #append-inner>
            <template v-if="isMultiple && hasSelectAll && !isDisabled">
                <v-tooltip location="bottom" style="z-index: 10000">
                    <span>{{ t("lumui.form.autocomplete.select_all") }}</span>
                    <template #activator>
                        <v-btn
                            ref="selectAll"
                            class="ml-1"
                            color="grey-darken-5"
                            density="compact"
                            icon="$selectAll"
                            size="small"
                            variant="plain"
                            @click.stop.prevent="selectAll()"
                        />
                    </template>
                </v-tooltip>
            </template>
            <template v-if="isMultiple && hasSelectAll && !isDisabled">
                <v-tooltip location="bottom" style="z-index: 10000">
                    {{ t("lumui.form.autocomplete.deselect_all") }}
                    <template #activator>
                        <v-btn
                            ref="deselectAll"
                            class="ml-1"
                            color="grey-darken-5"
                            density="compact"
                            icon="$close"
                            size="small"
                            variant="plain"
                            @click.stop.prevent="unselectAll()"
                        />
                    </template>
                </v-tooltip>
            </template>
        </template>
        <template v-if="appendOuterIcon" #append>
            <v-btn
                v-if="appendOuterIcon"
                ref="deselectAll"
                :icon="appendOuterIcon"
                class="ml-1"
                color="grey-darken-5"
                density="compact"
                size="small"
                variant="plain"
                @click.stop.prevent="outerIconClick"
            />
        </template>
        <template v-if="chips" #chip="{ props, item, index }">
            <v-chip :key="index" :rounded="true" close-icon="$clear" v-bind="props">
                <template v-if="item.raw.attr?.icon || item.raw.attr?.image" #prepend>
                    <v-icon v-if="item.raw.attr.icon" :icon="item.raw.attr.icon" />
                    <v-avatar v-if="item.raw.attr.image" :image="item.raw.attr.image" />
                </template>
                <span v-if="htmlOptions" class="limit-width ml-1" v-html="item.title" />
                <span v-else class="limit-width" v-text="item.title" />
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script lang="ts">
import HtmlSanitizer from "../lib/HtmlSanitizer.js";

import {
    VAutocomplete,
    VAvatar,
    VBtn,
    VCheckboxBtn,
    VChip,
    VIcon,
    VListItem,
    VListItemSubtitle,
    VListItemTitle,
    VTooltip,
} from "vuetify/components";
import type { FilterMatch, InternalItem } from "vuetify/composables/filter";
import { defineComponent } from "vue";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type {
    FormConfigSelect,
    MultiLangString,
    SelectOptionLabel,
    SelectOptionText,
} from "../config/Form";
import { formConfigSelectSchema, selectOptionsSchema } from "../config/Form.zod";
import { useI18n } from "vue-i18n";
import { FormatterFactory } from "../index";
import { assertDefined } from "../lib/assert";
import { useApiCaller } from "../plugins/ApiCaller";

const minSearchLength = 3;
export type NormalizedOption = SelectOptionLabel & { htmlLabel: string };

/**
 * #### Config
 *
 * | key                     | type                       | required | default | description |
 * |-------------------------|----------------------------|------|------------|-------------|
 * | type                    | `String`                   | yes  | `"select"` | always `"select"` |
 * | label                   | `String`, `false`          | no   | `false`    | fields label |
 * | class                   | `String`                   | no   | `null`     | css class for custom styling |
 * | required                | `Boolean`, `eval(String)`  | no   | false      | field is required.|
 * | disabled                | `Boolean`, `eval(String)`  | no   | false      | field is disabled.|
 * | visible                 | `Boolean`, `eval(String)`  | no   | false      | field is rendered.  |
 * | description             | `String`                   | no   | null       | hint to display below field |
 * | html_options            | `Boolean`                  | no   | `false`    | treat options as html. (html is sanitized) |
 * | clearable               | `Boolean`                  | no   | `false` for multiple, `true` otherwise | disable vuetify's clearing x |
 * | dataUrl                 | `String`                   | no   | `null`     | fetch options via http request from url |
 * | options                 | `Array`                    | yes  | -          | Array of {value: "", label: ""} objects. Note that "text" is an alias for label here. |
 * | multiOptions            | `Array`                    | no   | -          | Alias of options |
 * | appendOuterIcon         | `String`                   | no   | `null`     | the icon name for an additional button after drop down. |
 * | appendOuterIconCallback | `eval(String)`, `function` | no   | `null`     | called when additional button is clicked. |
 * | multiple                | `Boolean`                  | no   | `false`    | allow multiple selections |
 * | chips                   | `Boolean`                  | no   | `false`    | unused, alway equal to the value of multiple |
 * | select_all              | `Boolean`                  | no   | `false`    | display select all button (only multiple) |
 * | clear                   | `Boolean`                  | no   | `false`    | display clear button (only multiple) |
 *
 */
export default defineComponent({
    components: {
        VAutocomplete,
        VAvatar,
        VBtn,
        VCheckboxBtn,
        VChip,
        VIcon,
        VListItem,
        VListItemSubtitle,
        VListItemTitle,
        VTooltip,
    },
    props: {
        ...makeDefaultProps<FormConfigSelect>(formConfigSelectSchema),
        /** autocomplete value */
        modelValue: {
            type: [String, Array, Number, Object, Boolean],
            default: undefined,
        },
        /** url from which to fetch data (instead of using `config.options` */
        dataUrl: {
            type: String,
            default: null,
            required: false,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const call = useApiCaller();
        return {
            t,
            locale,
            call,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            asyncOptions: [] as NormalizedOption[],
            menu: false,
            search: "",
            hasFocused: false,
            hasInput: false,
            isMounted: false,
            viewValue: null as unknown[] | unknown | null,
            isLoading: false,
            sanitizeCache: {},
        };
    },
    computed: {
        stripTags(): (v: string) => string {
            return FormatterFactory("striptags", {}).format;
        },
        localize(): (v: Record<string, string>) => string {
            return FormatterFactory("localized", {}, this.locale).format;
        },
        htmlOptions(): boolean {
            return this.config.html_options ?? false;
        },
        autocomplete(): InstanceType<typeof VAutocomplete> {
            return this.$refs.autocomplete as InstanceType<typeof VAutocomplete>;
        },
        appendOuterIcon(): string | false {
            if (this.config.appendOuterIcon === undefined) {
                return false;
            }
            // for font awesome icons, we need to patch the 'fa' prefix if it does not exist
            if (this.config.appendOuterIcon.match(/^fa-/)) {
                return "fas " + this.config.appendOuterIcon;
            }
            return this.config.appendOuterIcon;
        },
        chips(): boolean {
            if (this.config.chips !== undefined) {
                console.warn("LFormRowAutocomplete: config.chips is deprecated and no longer used");
            }
            return this.isMultiple;
        },
        isAsync(): boolean {
            return Boolean(this.dataUrl ?? this.config.dataUrl);
        },
        hasSelectAll(): boolean {
            return this.config.select_all ?? true;
        },
        options(): NormalizedOption[] {
            if (this.isAsync) {
                return this.asyncOptions;
            }
            const options = this.config.options ?? this.config.multiOptions ?? [];
            return options.map(this.normalizeOption);
        },
        isMultiple(): boolean {
            return this.config.multiple ?? false;
        },
        theDataUrl(): string | undefined {
            return this.dataUrl ?? this.config.dataUrl;
        },
        noDataText(): string {
            if (this.isAsync && this.search.length < minSearchLength) {
                return this.t("lumui.form.autocomplete.search_hint");
            }
            return this.t("lumui.form.autocomplete.no_data");
        },
    },
    watch: {
        async search(query: string) {
            if (this.isAsync) {
                await this.loadAsync(query);
            }
        },
        modelValue: {
            handler(newVal) {
                if (this.isMultiple) {
                    if (newVal != undefined && !Array.isArray(newVal)) {
                        console.error(
                            "Expecting modelValue to be array when multiple is true. got " +
                                typeof newVal,
                        );
                        newVal = undefined;
                    }
                    this.viewValue = newVal ?? [];
                } else {
                    if (newVal != undefined && Array.isArray(newVal)) {
                        console.error(
                            "Expecting modelValue to not be array when multiple is false",
                        );
                        newVal = undefined;
                    }
                    this.viewValue = newVal;
                }
            },
            immediate: true,
        },
    },
    async mounted() {
        this.isMounted = true;
    },
    methods: {
        handleItemClick(disabled: boolean, onClick: () => void) {
            if (!disabled) {
                onClick();
            }
        },
        isSelected(value: unknown) {
            if (Array.isArray(this.viewValue)) {
                return this.viewValue.includes(value);
            } else {
                return this.viewValue == value;
            }
        },
        normalizeOption(x: SelectOptionText | SelectOptionLabel): NormalizedOption {
            const label = this.getLabel("text" in x ? x.text : x.label);
            const res: NormalizedOption = {
                ...x,
                label: this.htmlOptions ? this.stripTags(label) : label,
                htmlLabel: this.htmlOptions ? this.sanitize(label) : label,
            };
            if (res.attr?.icon && res.attr.icon.match(/^fa-/)) {
                res.attr.icon = "fas " + res.attr.icon;
            }
            return res;
        },
        /** @private */
        sanitize(str: string) {
            if (Object.hasOwn(this.sanitizeCache, str)) {
                return this.sanitizeCache[str];
            }
            const classSetting = HtmlSanitizer.AllowedAttributes["class"];
            HtmlSanitizer.AllowedAttributes["class"] = true;
            const sanitized = HtmlSanitizer.SanitizeHtml(str);
            this.sanitizeCache[str] = sanitized;
            HtmlSanitizer.AllowedAttributes["class"] = classSetting;
            return sanitized;
        },
        /** @private */
        async loadAsync(query: string): Promise<void> {
            try {
                if (query.length >= minSearchLength) {
                    this.isLoading = true;
                    let options: unknown;
                    const dataUrl = this.dataUrl;
                    assertDefined(dataUrl, "expecting dataUrl to be set");
                    if (!dataUrl.match(/^https?:\/\//)) {
                        // @todo Asa specific
                        options = await this.call(dataUrl, { query });
                    } else {
                        const p = await fetch(
                            this.theDataUrl + "?query=" + encodeURIComponent(query),
                        );
                        options = await p.json();
                    }
                    this.asyncOptions = selectOptionsSchema
                        .parse(options)
                        .map(this.normalizeOption);
                }
            } catch (e) {
                console.error("LFormRowAutocomplete", e);
                this.asyncOptions = [];
            } finally {
                this.isLoading = false;
            }
        },
        /** @private */
        getLabel(label: MultiLangString | string): string {
            if (typeof label == "string") {
                return label;
            }
            return this.localize(label);
        },
        /** @private */
        getValue(value: unknown) {
            if (value == null) {
                return this.isMultiple ? [] : null;
            }
            if (typeof value == "object") {
                if (this.isMultiple) {
                    if (this.isAsync) {
                        return value;
                    }
                    const ret: Record<string, unknown> = {};
                    Object.entries(value).forEach(([key, v]) => {
                        if (typeof v == "object") {
                            if ("value" in v) {
                                ret[key] = v;
                            } else if ("id" in v) {
                                ret[key] = v;
                            } else {
                                ret[key] = undefined;
                            }
                        } else {
                            ret[key] = v;
                        }
                    });
                } else if ("value" in value) {
                    return value.value;
                } else if ("id" in value) {
                    return value.id;
                }
                return value;
            }

            return value;
        },
        /** @private */
        onSelectChange(): unknown {
            if (!this.config.changeSelectCallback) {
                return;
            }
            if (typeof this.config.changeSelectCallback === "function") {
                return this.config.changeSelectCallback();
            } else {
                return eval(this.config.changeSelectCallback);
            }
        },
        /** @private */
        outerIconClick(): unknown {
            if (!this.config.appendOuterIconCallback) {
                return;
            }
            if (typeof this.config.appendOuterIconCallback === "function") {
                return this.config.appendOuterIconCallback();
            } else {
                return eval(this.config.appendOuterIconCallback);
            }
        },
        /*
         * get the option with `value` equal to `id`
         */
        getOption(id: string | number): SelectOptionLabel {
            return this.options.filter(option => {
                return option.value == id;
            })[0];
        },
        /** @private */
        unselect(o: unknown): void {
            const value = this.viewValue;
            if (!Array.isArray(value)) {
                throw Error("value is not an array");
            }
            const n = value.indexOf(o);
            if (n < 0) {
                console.warn("LFormRowAutocomplete: cannot unselect item, that is not selected", o);
                return;
            }
            value.splice(n, 1);
            this.viewValue = value;
            this.$emit("update:modelValue", value);
        },
        /**
         * select all items. only use with `config.multiple = true`
         */
        selectAll(): false {
            this.menu = false;
            const value = this.options.map(option => {
                return option.value;
            });
            this.viewValue = value;
            this.$emit("update:modelValue", value);
            return false;
        },
        /**
         * unselect all items. only use with `config.multiple = true`
         */
        unselectAll(): false {
            this.menu = false;
            this.viewValue = [];
            this.$emit("update:modelValue", []);
            return false;
        },
        updateModelValue(val: unknown[] | unknown) {
            this.$emit("update:modelValue", val);
            this.viewValue = val;

            this.onSelectChange();
        },
        /** activate the autocomplete */
        toggleMenu() {
            this.menu = !this.menu;
        },
        filter(
            itemTitle: string,
            queryText: string,
            item?: InternalItem<NormalizedOption>,
        ): FilterMatch {
            const queryParts = queryText.split(" ");

            const itemString = ((item?.raw.group ? (item.raw.group + " ") : "") + itemTitle).toLowerCase()
            let startIndex = 0;
            for (const queryPart of queryParts) {
                startIndex = itemString.indexOf(queryPart.toLowerCase(), startIndex);
                if (startIndex < 0) {
                    return false;
                }
            }

            return true;
        },
    },
});
</script>

<style scoped>
.limit-width {
    display: inline-block;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.v-list-item__prepend > .v-avatar,
.v-list-item__prepend > .v-icon {
    margin-inline-end: 8px !important;
}

/*autocomplete .v-text-field.v-text-field--enclosed .v-text-field__details {*/
/*    margin-bottom: 0;*/
/*}*/

/*.input-checkboxes .label {*/
/*    margin-bottom: -16px*/
/*}*/
</style>
