<template>
    <v-input
        v-if="isVisible"
        :id="id + '-redactor'"
        :class="containerClasses"
        :error="error"
        :error-messages="aggregatedErrors"
        :focused="isFocused"
        :name="name"
        variant="filled"
        :model-value="modelValue"
        :rules="[
            v =>
                !isRequired ||
                (typeof v == 'string' && stripTags(v).trim().length > 0) ||
                i18n.t('lumui.form.row.required'),
        ]"
        @focus="isFocused = true"
    >
        <v-field
            :active="true"
            :disabled="isDisabled"
            :focused="isFocused"
            :label="theLabel"
            :single-line="false"
            class="v-field-label--active"
        >
            <textarea
                :id="name"
                ref="redactor"
                :name="name"
                :value="modelValue"
                placeholder=""
                style="width: 100%"
            />
        </v-field>
    </v-input>
</template>

<script lang="ts">
import { VField, VInput } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps.js";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { FormatterFactory } from "../index";

// noinspection FunctionNamingConventionJS
declare const $R: any;

// @fixme changing config needs to reinitialize redactor
// @fixme changing locale should change tranlsations

/**
 * WYSIWYG html editor
 *
 * **Note:** Assumes that the locale can be read from `this.$store.getters.currentLocale`
 *
 * ## Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | redactor               | `Object`                   | no       | `{lang: this.locale, styles: false, minHeight: "100px", source: false}` | Redactor config |
 *
 */
export default defineComponent({
    components: { VField, VInput },
    props: {
        modelValue: {
            default: undefined,
            type: String,
        },
        /** placeholder */
        placeholder: {
            type: String,
            default: null,
        },
        ...makeDefaultProps<Record<string, any>>(),
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        return {
            i18n: useI18n(),
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            isFocused: false,
            redactor: null as null | any,
            empty: true,
        };
    },
    computed: {
        stripTags() {
            return FormatterFactory("striptags", {}).format;
        },
        containerClasses() {
            return {
                required: this.isRequired,
                redactorContainer: true,
                movedLabel: this.isFocused || !this.empty,
                focused: this.isFocused,
                "primary--text": this.isFocused,
            };
        },
        locale() {
            try {
                const currentLocale = this.i18n.locale.value;
                if (currentLocale && currentLocale.length === 2) {
                    return currentLocale;
                } else if (currentLocale && currentLocale.length !== 2) {
                    return currentLocale.substring(0, 2);
                } else {
                    return "de";
                }
            } catch (e) {
                return "de";
            }
        },
        element(): HTMLTextAreaElement {
            const res = this.$refs.redactor;
            if (!(res instanceof HTMLTextAreaElement)) {
                throw Error("Unexpected type " + typeof res);
            }
            return res;
        },
    },
    watch: {
        isDisabled(disabled) {
            $R(this.element.id, disabled ? "enableReadOnly" : "disableReadOnly");
        },
        modelValue(newValue) {
            if (this.redactor && this.redactor.editor && !this.redactor.editor.isFocus()) {
                this.redactor.source.setCode(newValue);
            }
        },
    },
    async mounted() {
        await this.init();
    },
    methods: {
        /** focuses the editor */
        focus() {
            $R(this.$refs.redactor, "focus");
        },
        /* @private */
        async init() {
            try {
                await import("../lib/redactor/redactor.cjs");
                await import("../lib/redactor/_plugins/tokens/tokens.cjs");
                await import("../lib/redactor/_plugins/table/table.cjs");
                // noinspection TypeScriptCheckImport it works just fine as it is
                await import(`../lib/redactor/_langs/${this.locale}.js`);
            } catch (e) {
                console.warn(
                    `Redactor: Locale "${this.locale}" not found, falling back to default.`,
                );
            }

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const me = this;
            const callbacks = {
                ...me.config.callbacks,
                changed: function (html: string) {
                    me.empty = html.length === 0;
                    me.handleInput(html);
                    return html;
                },
                focus: () => {
                    me.isFocused = true;
                    this.isFocused = true;
                    // this.redactor.editor.toolbar.$toolbar.nodes[0].style.display = 'block';
                },
                blur: () => {
                    me.isFocused = false;
                    this.isFocused = false;
                    // this.redactor.editor.toolbar.$toolbar.nodes[0].style.display = 'none';
                },
            };

            const defaultConfig = {
                buttons: [
                    "html",
                    "format",
                    "bold",
                    "italic",
                    "deleted",
                    "sup",
                    "sub",
                    "underline",
                    "ol",
                    "ul",
                    "lists",
                    "image",
                    "file",
                    "link",
                    "line",
                    "indent",
                    "outdent",
                    "redo",
                    "undo",
                ],
                lang: this.locale,
                styles: false,
                minHeight: "100px",
                source: false,
                imageFigure: false,
                linkNewTab: true,
                plugins: ["table"],
            };

            // extend config
            const additionConfig = {
                callbacks,
                imageUpload(data, files, e, upload) {
                    return new Promise(function (resolve, reject) {
                        const reader = new FileReader();
                        reader.readAsDataURL(files[0]);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    })
                        .then(function (response) {
                            // success
                            upload.complete(response);
                        })
                        .catch(function (response) {
                            // fail
                            upload.complete(response);
                        });
                },
            };

            this.redactor = $R(this.$refs.redactor, {
                ...defaultConfig,
                ...this.config,
                ...additionConfig,
            } as any);
            if (this.modelValue) {
                this.empty = false;
            }
        },
        /* @private */
        handleInput(val: string) {
            /**
             * user input
             * @param {String} val
             */
            this.$emit("update:modelValue", val);
        },
    },
});
</script>

<style>
@import "../lib/redactor/redactor.css";

.redactor-toolbar a.re-button {
    margin-bottom: 2px !important;
    z-index: 5100;
}
.redactor-dropdown {
    z-index: 5200;
}
#redactor-modal {
    z-index: 5300;
}
.redactor-context-toolbar {
    z-index: 5200;
}
.redactor-box {
    width: 100%;
    padding-top: 24px;
}

.redactor-box ol {
    padding-left: 1em;
}

.redactor-in {
    color: black !important;
}

.redactor-box {
    position: relative;
    margin-bottom: 10px;
    z-index: 5000;
}

.redactor-box > label {
    /* top: 70px; */
    z-index: 7;
}

.redactor-toolbar {
    z-index: auto !important;
    padding: 10px 10px 8px 10px !important;
}

.redactor-toolbar > a {
    margin-bottom: 0;
}

.v-field--focused .redactor-toolbar {
    display: block;
    height: auto;
}

.redactor-in {
    padding: 12px 12px 24px 12px;
    height: 170px;
    overflow: auto;
}

.redactor-focus.redactor-styles-on,
.redactor-focus:focus.redactor-styles-on {
    border-color: rgba(0, 0, 0, 0.075) !important;
}

/** make tables visible */
.redactor-in td {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-left: 5px;
    padding-right: 5px;
    border-collapse: collapse;
}
</style>
