import Vuex from "vuex";

export default new Vuex.Store({
    getters: {
        dontShow() {
            return false;
        },
        show() {
            return true;
        },
    },
});
