<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title> Materialized Path Column </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
            </v-toolbar-items>
        </v-toolbar>
        <l-table
            :loading="false"
            :data="data"
            info-disabled
            :show-filter="showFilter"
            @filter_closed="showFilter = !showFilter"
        ></l-table>
    </v-container>
</template>

<script>
import {
    VBtn,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VContainer,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "MaterializedPath",
    components: { LTable, VToolbar, VToolbarTitle, VSpacer, VToolbarItems, VBtn, VContainer },
    data() {
        return {
            data: {
                columns: [
                    {
                        key: "materialized_path",
                        label: "Materialized path Column",
                        type: "materialized_path",
                        precalculatedParents: true,
                    },
                    {
                        key: "label",
                        label: "Label",
                    },
                    {
                        key: "path",
                        label: "Path",
                    },
                ],
                rows: [
                    {
                        materialized_path: { label: "Root A", path: "1-" },
                        label: "Root A",
                        path: "1-",
                    },
                    {
                        materialized_path: { label: "Child A.1", path: "1-2-" },
                        label: "Child A.1",
                        path: "1-2-",
                    },
                    {
                        materialized_path: { label: "Child A.2", path: "1-3-" },
                        label: "Child A.2",
                        path: "1-3-",
                    },
                    {
                        materialized_path: { label: "Root B", path: "4-" },
                        label: "Root B",
                        path: "4-",
                    },
                    {
                        materialized_path: { label: "Child B.1", path: "4-5-" },
                        label: "Child B.1",
                        path: "4-5-",
                    },
                    {
                        materialized_path: { label: "Child B.1.1", path: "4-5-6-" },
                        label: "Child B.1.1",
                        path: "4-5-6-",
                    },
                ],
                filter: [
                    {
                        key: "materialized_path",
                        label: "Materialized Path Column",
                        type: "materialized_path",
                        filter: {
                            materialized_path: [
                                { label: "Root A", path: "1-" },
                                { label: "Child A.1", path: "1-2-" },
                                { label: "Child A.2", path: "1-3-" },
                                { label: "Root B", path: "4-" },
                                { label: "Child B.1", path: "4-5-" },
                                { label: "Child B.1.1", path: "4-5-6-" },
                            ],
                        },
                    },
                ],
            },
            showFilter: false,
        };
    },
};
</script>

<style scoped></style>
