import {
    computed,
    type ComputedRef,
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    reactive,
    type Ref,
} from "vue";
import type { TableRow } from "../../config/Table";

type SelectData = {
    enabled: Readonly<Ref<boolean>>;
    selection: ReadonlySet<TableRow>;
    empty: ComputedRef<boolean>;
    allSelected: ComputedRef<boolean>;
    someSelected: ComputedRef<boolean>;
    includes(row: TableRow): boolean;
    toggle(row: TableRow): void;
    add(row: TableRow): void;
    remove(row: TableRow): void;
    set(rows: Iterable<TableRow>): void;
    addAll(): void;
    removeAll(): void;
};

const SelectInjectionKey = Symbol.for("LumUi:LTable:select") as InjectionKey<SelectData>;

type EmitFunction = (ids: ReadonlyArray<TableRow>) => void;

export function provideSelection(
    enabled: Readonly<Ref<boolean>>,
    allRows: DeepReadonly<Ref<TableRow[]>>,
    emit: EmitFunction,
): SelectData {
    const selection = reactive(new Set<TableRow>());

    function emitSelection() {
        emit(Array.from(selection.values()));
    }

    function includes(row: TableRow): boolean {
        return selection.has(row);
    }

    function add(row: TableRow) {
        selection.add(row);
        emitSelection();
    }

    function remove(row: TableRow) {
        selection.delete(row);
        emitSelection();
    }

    function toggle(row: TableRow) {
        if (selection.has(row)) {
            remove(row);
        } else {
            add(row);
        }
    }

    function addAll() {
        for (const row of allRows.value) {
            selection.add(row);
        }
        emitSelection();
    }

    function removeAll() {
        selection.clear();
        emitSelection();
    }

    function set(selectedRows: Iterable<TableRow>) {
        selection.clear();
        const rows = allRows.value;
        for (const row of selectedRows) {
            if (!rows.includes(row)) {
                console.warn("table/selection: Cannot select unknown row", row);
                continue;
            }
            selection.add(row);
        }
        emitSelection();
    }

    const allSelected = computed(() => allRows.value.length == selection.size);

    const empty = computed(() => selection.size == 0);

    const someSelected = computed(() => selection.size > 0);

    const data: SelectData = {
        enabled,
        selection,
        includes,
        set,
        add,
        remove,
        toggle,
        addAll,
        removeAll,
        allSelected,
        empty,
        someSelected,
    };
    provide(SelectInjectionKey, data);
    return data;
}

export function useSelection(): SelectData {
    const data = inject(SelectInjectionKey);
    if (!data) {
        throw Error("provideSelection must be called in a parent component");
    }

    return data;
}
