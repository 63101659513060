export function assertNotNull<T>(x: T, msg?: string): asserts x is T extends null ? never : T {
    if (x !== null) {
        throw Error(msg ?? "failed to assert that value is not null");
    }
}

export function assertDefined<T>(
    x: T,
    msg?: string,
): asserts x is T extends undefined | null ? never : T {
    if (x === undefined || x === null) {
        throw Error(msg ?? "failed to assert that value is defined");
    }
}

export function assertString(x: any, msg?: string): asserts x is string {
    if (typeof x !== "string") {
        throw Error(msg ?? "Failed to assert that value is string");
    }
}

export function assertNumber(x: any, msg?: string): asserts x is number {
    if (typeof x !== "number") {
        throw Error(msg ?? "Failed to assert that value is number");
    }
}

export function assertBoolean(x: any, msg?: string): asserts x is boolean {
    if (typeof x !== "boolean") {
        throw Error(msg ?? "Failed to assert that value is boolean");
    }
}

export function assertSymbol(x: any, msg?: string): asserts x is symbol {
    if (typeof x !== "symbol") {
        throw Error(msg ?? "Failed to assert that value is symbol");
    }
}
