<template>
    <v-container>
        <v-card>
            <v-toolbar>
                <v-toolbar-title> Filters & Persistent Filters </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
                </v-toolbar-items>
            </v-toolbar>
            <l-table
                :loading="false"
                :data="data"
                :show-filter="showFilter"
                storage-key="test_table_filter_storage"
                :items-per-page-options="[10, 50, 100]"
                @filter_closed="showFilter = false"
            />
        </v-card>
    </v-container>
</template>

<script>
import {
    VBtn,
    VCard,
    VContainer,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "TableFilters",
    components: {
        LTable,
        VCard,
        VContainer,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VToolbarItems,
        VBtn,
    },
    data() {
        return {
            showFilter: true,
            data: {
                columns: [
                    {
                        key: "string",
                        label: "string",
                    },
                    {
                        key: "boolean",
                        label: "boolean",
                        type: "bool",
                    },
                    {
                        key: "select",
                        label: "select",
                    },
                    {
                        key: "date",
                        label: "date",
                        type: "date",
                    },
                    {
                        key: "time",
                        label: "time",
                        type: "time",
                    },
                ],
                filter: [
                    {
                        key: "string",
                        label: "string",
                    },
                    {
                        key: "boolean",
                        label: "boolean",
                        type: "bool",
                    },
                    {
                        key: "select",
                        label: "Select filta",
                        filter: {
                            select: {
                                multiple: true,
                                options: {
                                    foo: "Foo",
                                    bar: "Bar",
                                },
                            },
                        },
                    },
                    {
                        key: "date",
                        label: "date",
                        type: "date",
                        filter: {
                            date: {
                                range: true,
                                max: new Date(),
                            },
                        },
                    },
                ],
                rows: [
                    {
                        string: "Eins",
                        boolean: true,
                        select: "foo",
                        date: "1970-01-01",
                        time: "01:00:00",
                    },
                    {
                        string: "Eins PM",
                        boolean: true,
                        select: "foo",
                        date: "1970-01-01",
                        time: "13:00:00",
                    },
                    {
                        string: "Zwei",
                        boolean: false,
                        select: "foo 2",
                        date: "2022-11-25",
                        time: "02:00:00",
                    },
                    {
                        string: "Drei",
                        boolean: false,
                        select: "bar",
                        date: new Date().toISOString().substring(0, 10),
                        time: "03:00:00",
                    },
                    {
                        string: "Vier",
                        boolean: true,
                        select: "bar",
                        date: "2022-12-24",
                        time: "16:00:00",
                    },
                    {
                        string: "Vier",
                        boolean: true,
                        select: "bar",
                        date: "2023-12-24",
                        time: "4:00:00",
                    },
                ],
            },
        };
    },
};
</script>
