/**
 * String representing a number. empty string is considered valid.
 * @pattern ^((\d+)|(\.\d+)|(\d+\.\d+)|)$
 */
export type NumberString = string;

/** this string is evaluated to a boolean */
export type BooleanEvalString = string;
export type MultiLangString = Record<string, string>;

/** @pattern (^$|^(data:)([\w/+-]*)(;charset=[\w-]+|;base64){0,2},) */
export type DataUri = string;

/** @pattern ^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}={2})$ */
export type Base64 = string;

export type AnyMimeType = "*";

/** @pattern ^#[0-9A-F]{3}([0-9A-F]{3})?$ */
export type ColorString = string;

/** @pattern ^\w+\/[*]$ */
export type AnyMimeTypeCategory = string;

/** @pattern ^(?<type>\w*)\/(?<tree>([\w-]+\.)+)?(?<subtype>[\w-]+)(\+(?<suffix>[\w\-\.]+))?$ */
/** @pattern ^(?<type>\w*)\/(?<tree>([\w-]+\.)+)?(?<subtype>[\w-]+)(\+(?<suffix>[\w\-\.]+))?$ */
export type MimeType = string;

export type MimeTypePattern = MimeType | AnyMimeTypeCategory | AnyMimeType;

/** @pattern ^\d{4}-\d{2}-\d{2}( \d\d:\d\d:\d\d)?$ */
export type DateString = string;

/** @pattern ^([01]?[0-9]|2[123]):[0-5][0-9]$ */
export type TimeString = string;

/** @pattern ^[0-6]?[0-9]$ */
export type MinuteString = string;

export type FileValue = FileBase64 | FileDataUri;

export type AcceptFileFilter = Record<string, MimeTypePattern | Array<MimeTypePattern>>;

export function isFileDataUri(x: FileValue | undefined): x is FileDataUri {
    return x !== undefined && isDataUri(x.src);
}

export function isFileBase64(x: FileValue | undefined): x is FileBase64 {
    return x !== undefined && isBase64(x.src);
}

export function isDataUri(x: string | undefined): x is DataUri {
    return (
        x !== undefined &&
        /^(data:)([\w/+-]*)(;charset=[\w-]+|;base64){0,2},[a-zA-Z0-9+/=]+/.test(x)
    );
}

export function isBase64(x: string | undefined): x is Base64 {
    return (
        x !== undefined &&
        /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}={2})$/.test(x)
    );
}

export interface FileBase64 {
    name: string;
    type?: MimeType;
    mimetype?: MimeType;
    src: Base64;
    size: number;
}

export interface FileDataUri {
    name: string;
    src: DataUri;
    size: number;
}

export type MultiOptions =
    | {
          text: MultiLangString | string;
          value: string | number;
      }
    | {
          label: MultiLangString | string;
          value: string | number;
      }
    | string;

interface Base {
    type: string;
    class?: string | Record<string, boolean>;
    /** @default true */
    visible?: boolean | BooleanEvalString;
    /** @default false */
    disabled?: boolean | BooleanEvalString;
    /** @default false */
    required?: boolean | BooleanEvalString;
    label?: string | false;
    /* initial value, used when not modelValue is passed */
    value?: unknown | null;
    errors?: string[];
    /** unused */
    order?: number;
}

interface TextBase extends Base {
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    maxLength?: number | NumberString | false;
    hideDetails?: boolean | "auto";
    description?: string;
    clearable?: boolean;
}

export interface FormConfigHidden extends Base {
    type: "hidden";
}

export interface FormConfigText extends TextBase {
    type: "text";
    value?: string | null;
}

export function isFormConfigText(x: FormConfigContent): x is FormConfigText {
    return x.type == "text";
}

export interface FormConfigTextarea extends TextBase {
    type: "textarea";
    value?: string | null;
}

export function isFormConfigTextarea(x: FormConfigContent): x is FormConfigTextarea {
    return x.type == "textarea";
}

export interface FormConfigNumber extends TextBase {
    type: "number";
    step?: number | NumberString;
    min?: number | NumberString;
    max?: number | NumberString;
    value?: number | NumberString | null;
}

export function isFormConfigNumber(x: FormConfigContent): x is FormConfigNumber {
    return x.type == "number";
}

export interface FormConfigEmail extends TextBase {
    type: "email";
}

export function isFormConfigEmail(x: FormConfigContent): x is FormConfigEmail {
    return x.type == "email";
}

export interface FormConfigWysiwyg extends Base {
    type: "wysiwyg";
    buttons?: string[];
    lang?: string;
    styles?: boolean;
    minHeight?: string;
    source?: boolean;
    linkNewTab?: boolean;
    plugins?: string[];
    value?: string | null;
}

export function isFormConfigWysiwyg(x: FormConfigContent): x is FormConfigWysiwyg {
    return x.type == "wysiwyg";
}

export interface FormConfigCheckbox {
    type: "checkbox";
    label?: string | false;
    required?: boolean | BooleanEvalString;
    disabled?: boolean | BooleanEvalString;
    visible?: boolean | BooleanEvalString;
    value?: Array<string> | null;
    multiOptions: Array<MultiOptions>;
    errors?: string[];
}

export function isFormConfigCheckbox(x: FormConfigContent): x is FormConfigCheckbox {
    return x.type == "checkbox";
}

export interface FormConfigPassword extends Base {
    type: "password";
    description?: string;
    no_confirm?: boolean;
    value?: string | null;
}

export function isFormConfigPassword(x: FormConfigContent): x is FormConfigPassword {
    return x.type == "password";
}

export interface FormConfigDate extends Base {
    type: "date";
    value?: DateString | undefined | null;
    min?: DateString | undefined;
    max?: DateString | undefined;
}

export interface FormConfigTime extends Base {
    type: "time";
    value?: TimeString | null;
    min?: TimeString;
    max?: TimeString;
}

export interface FormConfigLocalized extends Base {
    type: "localized";
    languages?: string[];
    child?: "text" | "textarea" | "wysiwyg";
    redactor?: Record<string, unknown>;
    value?: MultiLangString | null;
}

export function isFormConfigLocalized(x: FormConfigContent): x is FormConfigLocalized {
    return x.type == "localized";
}

export interface FormConfigColor extends Base {
    type: "colorpicker" | "color";
    value?: ColorString | null;
    /* @default "hex" */
    mode?: "hex" | "hexa";
    /* @default false */
    hideSliders?: boolean;
    /* @default true */
    hideInputs?: boolean;
    /* @default true */
    hideCanvas?: boolean;
    /* @default false */
    showSwatches?: boolean;
}

export function isFormConfigColor(x: FormConfigContent): x is FormConfigColor {
    return x.type == "colorpicker" || x.type == "color";
}

export interface FormConfigSignature extends Base {
    type: "signatur" | "signature";
    label?: string | false;
    value?: DataUri | null;
}

export function isFormConfigSignature(x: FormConfigContent): x is FormConfigSignature {
    return x.type == "signatur" || x.type == "signature";
}

export interface FormConfigFile extends Base {
    type: "file";
    value?: Array<FileValue> | FileValue | null;
    avoidDrag?: boolean;
    accept?: Record<string, MimeTypePattern | Array<MimeTypePattern>>;
    multiple?: boolean;
    maxFileSize?: number;
    maxImageSize?: number;
}

export function isFormConfigFile(x: FormConfigContent): x is FormConfigFile {
    return x.type == "file";
}

export interface FormConfigSwitch extends Base {
    type: "switch";
    value?: "1" | "0" | 1 | 0 | boolean | null;
}

export function isFormConfigSwitch(x: FormConfigContent): x is FormConfigSwitch {
    return x.type == "switch";
}

export type RadioOptionLabel = {
    value: string | number | boolean;
    label: Record<string, string> | string;
};
export type RadioOptionText = {
    value: string | number | boolean;
    text: Record<string, string> | string;
};
export type RadioOptions = Array<RadioOptionLabel> | Array<RadioOptionText> | Array<string>;

export interface FormConfigRadio extends Base {
    type: "radio";
    value?: { value: string | number | boolean } | string | number | boolean | null;
    multiOptions: RadioOptions;
}

export interface FormConfigHtml extends Base {
    type: "html";
    description?: string;
    fieldType?: boolean;
    value?: string | null;
}

export type SelectOptionValue = string | boolean | number | bigint | null;

export interface SelectOptionLabel {
    value: SelectOptionValue;
    label: MultiLangString | string;
    attr?: { icon?: string; image?: string };
    disabled?: boolean;
    group?: string;
}

export interface SelectOptionText {
    value: SelectOptionValue;
    text: MultiLangString | string;
    attr?: { icon?: string; image?: string };
    disabled?: boolean;
    group?: string;
}

export type SelectOptions = Array<SelectOptionLabel | SelectOptionText>;

export type NormalizedSelectOptions = Array<SelectOptionLabel>;

export interface FormConfigSelect extends Base {
    type: "select";
    description?: string;
    /** @default false */
    html_options?: boolean;
    /** @default false */
    clearable?: boolean;
    appendOuterIcon?: string;
    appendOuterIconCallback?: string | (() => void);
    changeSelectCallback?: string | (() => void);
    /** @default false */
    multiple?: boolean;
    chips?: boolean;
    /** @default false */
    select_all?: boolean;
    options?: Array<SelectOptionText> | Array<SelectOptionLabel>;
    multiOptions?: Array<SelectOptionText> | Array<SelectOptionLabel>;
    dataUrl?: string;
}

export type OrderedChoiceOptionValue = SelectOptionValue;
export type OrderedChoiceOption = {
    value: OrderedChoiceOptionValue;
    label: string;
};

export interface FormConfigOrderedChoice extends Base {
    type: "orderedChoice";
    value: Array<SelectOptionValue>;
    multiOptions: Array<OrderedChoiceOption>;
}

export function isFormConfigRadio(x: FormConfigContent): x is FormConfigRadio {
    return x.type == "radio";
}

export interface FormConfigCollection extends Base {
    type: "collection";
    /** @default false */
    allow_add?: boolean | BooleanEvalString;
    /** @default false */
    allow_delete?: boolean | BooleanEvalString;
    /** @default 'Hinzufügen' */
    add_label?: string;
    prototype: FormConfigRowNoCollection | FormConfigRowsNoCollection;
    children: Record<string, FormConfigRowNoCollection | FormConfigRowsNoCollection>;
}

export interface FormConfigTab {
    type: "tab";
    label: string;
    children: FormConfigOrderedRows;
}

export function isFormConfigTab(x: FormConfigContent): x is FormConfigTab {
    return "type" in x && x.type == "tab";
}

export interface FormConfigAccordion {
    type: "accordion";
    label: string;
    children: FormConfigOrderedRows;
}

export function isFormConfigAccordion(x: FormConfigContent): x is FormConfigAccordion {
    return "type" in x && x.type == "accordion";
}

export type FormConfigRowNoCollection =
    | FormConfigHidden
    | FormConfigText
    | FormConfigTextarea
    | FormConfigNumber
    | FormConfigEmail
    | FormConfigCheckbox
    | FormConfigRadio
    | FormConfigPassword
    | FormConfigDate
    | FormConfigTime
    | FormConfigLocalized
    | FormConfigColor
    | FormConfigSignature
    | FormConfigSelect
    | FormConfigSwitch
    | FormConfigFile
    | FormConfigWysiwyg
    | FormConfigHtml
    | FormConfigOrderedChoice;

export type FormConfigRow = FormConfigRowNoCollection | FormConfigCollection;

export type FormConfigRowsNoCollection = Record<string, FormConfigRowNoCollection>;

export function isFormRow(x: FormConfigContent): x is FormConfigRow {
    return "type" in x && !isFormConfigTab(x) && !isFormConfigAccordion(x);
}

/** @zodexclude */
export type FormConfigRowTypes = Pick<FormConfigRow, "type">["type"];

export type FormConfigRows = Record<string, FormConfigRow>;
export type FormConfigOrderedRows = Record<string, FormConfigRow & { order?: number }>;

export type FormConfigContent = FormConfigTab | FormConfigRow | FormConfigAccordion;

export type FormConfig = Record<string, FormConfigContent>;
