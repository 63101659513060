<template>
    <v-container>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>Expansion slot</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
                </v-toolbar-items>
            </v-toolbar>
            <l-table
                ref="tbl"
                :data="config"
                show-expand
                info-disabled
                :show-filter="showFilter"
                @expand="onExpand"
                @filter_closed="showFilter = false"
            >
                <template #expanded-item="{ row }"> Row: {{ row }}</template>
            </l-table>

            <v-card-text>
                <h4>Aufgeklappte Datensätze</h4>
                <p class="text-body-2">
                    {{ expanded }}
                </p>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    :disabled="expanded.size === config.rows.length"
                    @click="expandAll"
                >
                    Alle aufklappen
                </v-btn>

                <v-btn color="primary" :disabled="expanded.size === 0" @click="collapseAll">
                    Alle zuklappen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script lang="ts" setup>
import {
    VBtn,
    VContainer,
    VCard,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VToolbarItems,
    VCardText,
    VCardActions,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";
import { type DeepReadonly, ref } from "vue";
import { type TableConfig, type TableRow, type TableRowAction } from "../../../src/config/Table";
import { assertDefined } from "../../../src/lib/assert";

const showFilter = ref(true);
const config = ref<TableConfig>({
    columns: [
        {
            key: "id",
            hidden: true,
        },
        {
            key: "col1",
            label: "Column 1 (String)",
        },
        {
            key: "col2",
            hidden: false,
            label: "Column 2",
            type: "select",
            details: {
                label: "Col 2 (String, Only in Detailsview)",
                key: "col2",
                hidden: false,
            },
            options: [
                { value: 1, text: "Label 1" },
                { value: 2, text: "Label 2" },
                { value: 3, text: "Label 3" },
                { value: 4, text: "Label 4" },
            ],
        },
        {
            key: "col3",
            label: "Column 3 (Date)",
            type: "date",
        },
        {
            key: "action",
            label: "",
            type: "action",
            formatter: "action",
            actions: [] as TableRowAction[],
        },
    ],
    filter: [
        {
            key: "col1",
            label: "Column 1 (String)",
        },
    ],
    rows: [
        {
            id: 1,
            col1: "Lorem Ipsum",
            col2: 1,
            col3: "1990-10-10",
        },
        {
            id: 2,
            col1: "Lorem Ipsum (Deutsch)",
            col2: 2,
            col3: "1990-09-09",
        },
        {
            id: 3,
            col1: "Kafka",
            col2: 4,
            col3: "1990-11-11",
        },
    ],
});

const expanded = ref<ReadonlySet<TableRow>>(new Set());

const tbl = ref<typeof LTable>();

function onExpand(s: ReadonlySet<DeepReadonly<TableRow>>) {
    expanded.value = s;
}

function expandAll() {
    assertDefined(tbl.value);
    tbl.value.expansion.expandAll();
}

function collapseAll() {
    assertDefined(tbl.value);
    tbl.value.expansion.collapseAll();
}
</script>
