import { type App, type DeepReadonly, inject, type InjectionKey, type Plugin } from "vue";

export type SettingsTableEvalContext = Record<
    string,
    ((...args: any[]) => any) | string | number | boolean | undefined | null
>;
export type SettingsFormEvalContext = SettingsTableEvalContext;

export type Settings = {
    table: {
        persistentFilters: boolean;
        evalContext: SettingsTableEvalContext;
    };
    form: {
        evalContext: SettingsFormEvalContext;
    };
};

export function useSettings(): DeepReadonly<Settings> {
    const settings = inject(SettingsInjectionKey);
    if (!settings) {
        throw Error("Please make sure that the settings plugin is installed");
    }
    return settings;
}

export const SettingsInjectionKey = Symbol.for("LumUi:Settings") as InjectionKey<
    DeepReadonly<Settings>
>;

/**
 * @internal used by the lumui plugin defined in index.ts, you probably don't want to call this
 */
export function createSettingsPlugin(settings: DeepReadonly<Settings>): Plugin<[]> {
    return {
        install(app: App) {
            app.provide(SettingsInjectionKey, settings);
        },
    };
}
