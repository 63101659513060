<template>
    <test-form v-model:config="config" v-model="value" title="Field Length Counter" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TestForm from "../../components/TestForm.vue";
import type { FormConfig } from "../../../src/config/Form";

const config: FormConfig = {
    text: { label: "Textfield", type: "text", maxLength: "255" },
    textarea: { label: "Textarea", type: "textarea", maxLength: "255" },
};

export default defineComponent({
    name: "FormCounter",
    components: { TestForm },
    data() {
        return {
            value: {
                text: "xxx",
                textarea: "xxasdfdas",
            },
            config,
        };
    },
});
</script>
