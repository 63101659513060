import { Status } from './Status';

/** @todo move to asa */
export class Statusdetail {

    constructor(protected map) {
    }

    /*
     * value contains array of status information
     *
     * [
     *  0 => OverallStatus
     *  1 => [ array of status entries for level OK ]
     *  2 => [ array of status entries for level INFORMATION ]
     *  3 => [ array of status entries for level WARNING ]
     *  4 => [ array of status entries for level ALERT ]
     * ]
     *
     * see Entity/Gear for status constants
     *
     */
    format(value) {
        if (value && !Object.hasOwn(value, 'length')) {
            return '';
        }

        let j = 0;
        const length = value.length;
        let i = length - 1;
        let icon = '';

        let text = "";
        const statusIcon = new Status(this.map);
        // looping through each state (highest state first)
        for (value.length - 1; i > 0; i--) {
            // do we have entries for this state?
            if (value[i].length > 0) {
                // display the corresponding state icon
                icon = statusIcon.format([i]);
                // print status entries below the state icon as ordered list
                text += '<ul>';
                for (j = 0; j < value[i].length; j++) {
                    text += '<li>' + icon + '&nbsp;' + value[i][j];
                }
                text += '</ul>';
            }
        }
        return text;
    }
}
