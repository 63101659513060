<template>
    <test-form v-model:config="config" v-model="value" title="Tabs w/o title" no-title />
</template>

<script lang="ts">
import TestForm from "../../components/TestForm.vue";
import { defineComponent } from "vue";
import type { FormConfig } from "../../../src/config/Form";

const config: FormConfig = {
    tab1: {
        type: "tab",
        label: "Tab A",
        children: {
            a1: { type: "text", label: "Vorname", required: true },
            a2: { type: "text", label: "Nachname", required: true },
            a3: { type: "email", label: "E-Mail" },
            a4: { type: "textarea", label: "Adresse" },
        },
    },
    tab2: {
        type: "tab",
        label: "Tab B",
        children: {
            b0: { type: "text", label: "Error", errors: ["error from config"] },
            b1: { type: "textarea", label: "Anmerkungen", required: true },
        },
    },
};

export default defineComponent({
    name: "DialogTabs",
    components: {
        TestForm,
    },
    data() {
        return {
            value: {
                b0: "asdf",
            },
            config,
        };
    },
    mounted() {
        //this.setFormErrors(this.config, { 'unassigned': 'YYY', 'b0': 'XXX' });
    },
});
</script>
