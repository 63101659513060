<template>
    <template v-for="(image, i) in images">
        <v-img
            v-if="image.src"
            :key="i"
            :src="image.src"
            aspect-ratio="1"
            class="bg-grey-lighten-2 clickable"
            @click="showLightbox(i)"
        >
            <template #placeholder>
                <v-layout align-center fill-height justify-center ma-0>
                    <v-progress-circular color="grey-lighten-5" indeterminate />
                </v-layout>
            </template>
        </v-img>
        <div v-else :key="-i" class="bg-grey-lighten-4 pa-4 text-center">
            <v-icon>$error</v-icon>
            <small class="d-block mt-2">{{ image.name }}</small>
        </div>
    </template>

    <l-lightbox
        v-if="images && images.length > 0"
        :images="images"
        :index="lightbox.index"
        :show="lightbox.show"
        @close="closeLightbox"
    />
</template>

<script>
import LLightbox from "./LLightbox.vue";
import { VIcon, VImg, VLayout, VProgressCircular } from "vuetify/components";

export default {
    components: { LLightbox, VImg, VLayout, VIcon, VProgressCircular },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        images: {
            // Objekt-Array mit src und title Attributen
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            lightbox: {
                show: false,
                index: 0,
            },
        };
    },
    methods: {
        closeLightbox() {
            this.lightbox.show = false;
        },
        showLightbox(i) {
            this.lightbox.index = i;
            this.lightbox.show = true;
        },
    },
};
</script>
