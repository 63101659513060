<template>
    <v-layout justify-center row>
        <v-dialog :model-value="value" content-class="centered-dialog" persistent>
            <v-container class="fill-height">
                <v-layout align-center column justify-center>
                    <v-progress-circular
                        :color="progressColor"
                        :size="70"
                        :width="7"
                        indeterminate
                    />
                    <h1 v-if="message != null">
                        {{ message }}
                    </h1>
                </v-layout>
            </v-container>
        </v-dialog>
    </v-layout>
</template>

<script>
import { VContainer, VDialog, VLayout, VProgressCircular } from "vuetify/components";

/**
 * Fullscreen variant of the LLoading component
 * @deprecated use LLoading instead
 */
export default {
    components: { VLayout, VDialog, VContainer, VProgressCircular },
    props: {
        /** determines whether the dialog is shown */
        value: { type: Boolean, default: false },
        /** the message that will be displayed */
        message: { type: String, default: "Loading..." },
        /** color of the progress animation */
        progressColor: { type: String, default: "primary" },
    },
    data: function () {
        return {};
    },
};
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
    background: transparent;
    box-shadow: none;
    border-radius: 6px;
    width: auto;
    color: whitesmoke;
}
</style>
