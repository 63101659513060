import type { Formatter } from "../formatter";

export class Image implements Formatter {
    format(value: unknown): string {
        let rtn = "";
        if (value === null || (Array.isArray(value) && value.length === 0)) {
            return rtn;
        }
        const arrayValue = Array.isArray(value) ? value : [value];

        arrayValue.forEach(item => {
            rtn += Object.hasOwn(item, "src")
                ? '<img src="' + item.src + '" class="img-fluid" />'
                : "";
        });
        return rtn;
    }
}
