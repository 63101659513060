<template>
    <tr
        ref="rowTr"
        :class="{
            odd: ordinal % 2 == 1,
            even: ordinal % 2 == 0,
            expanded: expansion.enabled.value && expansion.isExpanded(row),
            hover,
            'data-row': true,
        }"
        @click="e => rowClick(e)"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <td v-if="selection.enabled.value">
            <v-checkbox-btn
                :model-value="selection.includes(row)"
                density="compact"
                @click.stop.prevent="selection.toggle(row)"
            />
        </td>
        <template v-for="col in visibleColumns" :key="col.key">
            <td>
                <slot :columns="columns" :name="'col_' + col.key" :row="row" :value="row[col.key]">
                    <l-table-column :column="col" :row="row" :value="row[col.key]" />
                </slot>
            </td>
        </template>
        <td v-if="actionsColumnVisible" style="width: 1%">
            <div class="d-flex align-center justify-end" style="width: fit-content">
                <template v-if="expansion.enabled.value">
                    <v-tooltip location="bottom">
                        <template #activator="tooltipProps">
                            <v-btn
                                :icon="expansion.isExpanded(row.item) ? '$collapse' : '$expand'"
                                class="detailButton"
                                color="secondary"
                                size="x-small"
                                v-bind="tooltipProps.props"
                                variant="plain"
                                @click.stop="expansion.toggle(row.item)"
                            />
                        </template>
                        {{
                            expansion.isExpanded(row.item)
                                ? i18n.t("lumui.table.collapse")
                                : i18n.t("lumui.table.expand")
                        }}
                    </v-tooltip>
                </template>
                <l-table-action
                    v-if="!details.enabled.value && Object.keys(actions).length > 0"
                    :actions="actions"
                    :row="row.item ?? {}"
                    :eval-context="evalContext"
                    btn-size="default"
                    icon-size="small"
                    @rowaction="e => emit('row:action', e)"
                />
                <v-tooltip v-if="details.enabled.value" location="bottom">
                    <template #activator="tooltipProps">
                        <v-btn
                            class="detailButton"
                            icon="$next"
                            size="x-small"
                            v-bind="tooltipProps.props"
                            variant="plain"
                            @click="emit('row:clicked', row)"
                        />
                    </template>
                    {{ i18n.t("lumui.table.details") }}
                </v-tooltip>
            </div>
        </td>
    </tr>
    <tr
        v-if="expansion.enabled.value && expansion.isExpanded(row)"
        ref="expandTr"
        :class="{
            odd: ordinal % 2 == 1,
            even: ordinal % 2 == 0,
            'expansion-row': true,
            hover: hover,
        }"
        @click="emit('row:clicked', row)"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <td :colspan="columnCount">
            <slot
                name="expanded-item"
                :columns="columns"
                :row="row"
                :column-count="columnCount"
                :actions="actions"
                :ordinal="ordinal"
            >
                No expanded-item slot provided
            </slot>
        </td>
    </tr>
</template>

<script lang="ts" setup>
import { VCheckboxBtn, VTooltip, VBtn } from "vuetify/components";
import LTableAction from "./LTableAction.vue";
import LTableColumn from "./LTableColumn.vue";
import {
    type NormalizedTableColumnConfig,
    type NormalizedTableRowAction,
    type TableRow,
} from "../config/Table";
import { useI18n } from "vue-i18n";
import { useSelection } from "./table/selection";
import { useExpansion } from "./table/expansion";
import { useDetails } from "./table/details";
import { computed, onUnmounted, ref, unref } from "vue";
import { useResizeObserver } from "@vueuse/core";
import { createEvalRecord } from "../lib/EvalRecord";
import { useSettings } from "../composables/Settings";

export type LTableRowActionEvent = {
    name: string;
    params: Record<string, any>;
};

const props = defineProps<{
    ordinal: number;
    row: TableRow;
    actions: Record<string, NormalizedTableRowAction>;
    columnCount: number;
    columns: readonly NormalizedTableColumnConfig[];
    visibleColumns: readonly NormalizedTableColumnConfig[];
}>();

const emit = defineEmits({
    "update:height": (_height: number) => true,
    "row:clicked": (_row: TableRow) => true,
    "row:action": (_action: LTableRowActionEvent) => true,
});

const i18n = useI18n();
const selection = useSelection();
const expansion = useExpansion();
const details = useDetails();
const settings = useSettings();

const hover = ref(false);

const rowTr = ref<HTMLTableRowElement>();
const expandTr = ref<HTMLTableRowElement>();

// const isExpanded = expansion.isExpanded(props.row);

const evalContext = computed(() => {
    return createEvalRecord(unref(props.row.value), settings.table.evalContext);
});

const observer = useResizeObserver(
    [rowTr, expandTr],
    entries => {
        let height = 0;
        for (const e of entries) {
            height += e.target.getBoundingClientRect().height;
        }
        emit("update:height", height);
    },
    {
        box: "border-box",
    },
);
onUnmounted(observer.stop);

const actionsColumnVisible = computed(() => {
    return (
        expansion.enabled.value || details.enabled.value || Object.keys(props.actions).length > 0
    );
});

function rowClick(e: MouseEvent) {
    const target = e.target;
    if (target && target instanceof HTMLElement && target.tagName == "A") {
        return;
    }
    emit("row:clicked", props.row);
}
</script>

<style lang="scss" scoped>
@use "vuetify/settings";

.l-table tbody tr.even {
    background-color: rgba(var(--v-border-color), calc(var(--v-hover-opacity) / 2));
}

.l-table tbody tr.hover {
    background-color: settings.$table-hover-color;
}

tr.expanded td {
    border-bottom: 0 !important;
}

.data-row {
    min-height: 52px;
}

.expansion-row {
    min-height: 0;
}

tr,
tr td,
tr th {
    transition-property: none;
}
.l-table-loading td,
.l-table-loading th {
    opacity: 0.3;
}
</style>
