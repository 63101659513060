// Generated by ts-to-zod
import { z } from "zod";

export const numberStringSchema = z.string().regex(/^((\d+)|(\.\d+)|(\d+\.\d+)|)$/);

export const booleanEvalStringSchema = z.string();

export const multiLangStringSchema = z.record(z.string());

export const dataUriSchema = z
    .string()
    .regex(/(^$|^(data:)([\w/+-]*)(;charset=[\w-]+|;base64){0,2},)/);

export const base64Schema = z
    .string()
    .regex(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}={2})$/);

export const anyMimeTypeSchema = z.literal("*");

export const colorStringSchema = z.string().regex(/^#[0-9A-F]{3}([0-9A-F]{3})?$/);

export const anyMimeTypeCategorySchema = z.string().regex(/^\w+\/[*]$/);

export const mimeTypeSchema = z
    .string()
    .regex(/^(?<type>\w*)\/(?<tree>([\w-]+\.)+)?(?<subtype>[\w-]+)(\+(?<suffix>[\w\-\.]+))?$/);

export const mimeTypePatternSchema = z.union([
    mimeTypeSchema,
    anyMimeTypeCategorySchema,
    anyMimeTypeSchema,
]);

export const dateStringSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}( \d\d:\d\d:\d\d)?$/);

export const timeStringSchema = z.string().regex(/^([01]?[0-9]|2[123]):[0-5][0-9]$/);

export const minuteStringSchema = z.string().regex(/^[0-6]?[0-9]$/);

export const fileBase64Schema = z.object({
    name: z.string(),
    type: mimeTypeSchema.optional(),
    mimetype: mimeTypeSchema.optional(),
    src: base64Schema,
    size: z.number(),
});

export const fileDataUriSchema = z.object({
    name: z.string(),
    src: dataUriSchema,
    size: z.number(),
});

export const acceptFileFilterSchema = z.record(
    z.union([mimeTypePatternSchema, z.array(mimeTypePatternSchema)]),
);

export const multiOptionsSchema = z.union([
    z.object({
        text: z.union([multiLangStringSchema, z.string()]),
        value: z.union([z.string(), z.number()]),
    }),
    z.object({
        label: z.union([multiLangStringSchema, z.string()]),
        value: z.union([z.string(), z.number()]),
    }),
    z.string(),
]);

const baseSchema = z.object({
    type: z.string(),
    class: z.union([z.string(), z.record(z.boolean())]).optional(),
    visible: z.union([z.boolean(), booleanEvalStringSchema]).optional().default(true),
    disabled: z.union([z.boolean(), booleanEvalStringSchema]).optional().default(false),
    required: z.union([z.boolean(), booleanEvalStringSchema]).optional().default(false),
    label: z.union([z.string(), z.literal(false)]).optional(),
    value: z.unknown().optional().nullable(),
    errors: z.array(z.string()).optional(),
    order: z.number().optional(),
});

const textBaseSchema = baseSchema.extend({
    placeholder: z.string().optional(),
    prefix: z.string().optional(),
    suffix: z.string().optional(),
    maxLength: z.union([z.number(), numberStringSchema, z.literal(false)]).optional(),
    hideDetails: z.union([z.boolean(), z.literal("auto")]).optional(),
    description: z.string().optional(),
    clearable: z.boolean().optional(),
});

export const formConfigHiddenSchema = baseSchema.extend({
    type: z.literal("hidden"),
});

export const formConfigTextSchema = textBaseSchema.extend({
    type: z.literal("text"),
    value: z.string().optional().nullable(),
});

export const formConfigTextareaSchema = textBaseSchema.extend({
    type: z.literal("textarea"),
    value: z.string().optional().nullable(),
});

export const formConfigNumberSchema = textBaseSchema.extend({
    type: z.literal("number"),
    step: z.union([z.number(), numberStringSchema]).optional(),
    min: z.union([z.number(), numberStringSchema]).optional(),
    max: z.union([z.number(), numberStringSchema]).optional(),
    value: z.union([z.number(), numberStringSchema]).optional().nullable(),
});

export const formConfigEmailSchema = textBaseSchema.extend({
    type: z.literal("email"),
});

export const formConfigWysiwygSchema = baseSchema.extend({
    type: z.literal("wysiwyg"),
    buttons: z.array(z.string()).optional(),
    lang: z.string().optional(),
    styles: z.boolean().optional(),
    minHeight: z.string().optional(),
    source: z.boolean().optional(),
    linkNewTab: z.boolean().optional(),
    plugins: z.array(z.string()).optional(),
    value: z.string().optional().nullable(),
});

export const formConfigCheckboxSchema = z.object({
    type: z.literal("checkbox"),
    label: z.union([z.string(), z.literal(false)]).optional(),
    required: z.union([z.boolean(), booleanEvalStringSchema]).optional(),
    disabled: z.union([z.boolean(), booleanEvalStringSchema]).optional(),
    visible: z.union([z.boolean(), booleanEvalStringSchema]).optional(),
    value: z.array(z.string()).optional().nullable(),
    multiOptions: z.array(multiOptionsSchema),
    errors: z.array(z.string()).optional(),
});

export const formConfigPasswordSchema = baseSchema.extend({
    type: z.literal("password"),
    description: z.string().optional(),
    no_confirm: z.boolean().optional(),
    value: z.string().optional().nullable(),
});

export const formConfigDateSchema = baseSchema.extend({
    type: z.literal("date"),
    value: z.union([dateStringSchema, z.undefined()]).optional().nullable(),
    min: z.union([dateStringSchema, z.undefined()]).optional(),
    max: z.union([dateStringSchema, z.undefined()]).optional(),
});

export const formConfigTimeSchema = baseSchema.extend({
    type: z.literal("time"),
    value: timeStringSchema.optional().nullable(),
    min: timeStringSchema.optional(),
    max: timeStringSchema.optional(),
});

export const formConfigLocalizedSchema = baseSchema.extend({
    type: z.literal("localized"),
    languages: z.array(z.string()).optional(),
    child: z.union([z.literal("text"), z.literal("textarea"), z.literal("wysiwyg")]).optional(),
    redactor: z.record(z.unknown()).optional(),
    value: multiLangStringSchema.optional().nullable(),
});

export const formConfigColorSchema = baseSchema.extend({
    type: z.union([z.literal("colorpicker"), z.literal("color")]),
    value: colorStringSchema.optional().nullable(),
    mode: z.union([z.literal("hex"), z.literal("hexa")]).optional(),
    hideSliders: z.boolean().optional(),
    hideInputs: z.boolean().optional(),
    hideCanvas: z.boolean().optional(),
    showSwatches: z.boolean().optional(),
});

export const formConfigSignatureSchema = baseSchema.extend({
    type: z.union([z.literal("signatur"), z.literal("signature")]),
    label: z.union([z.string(), z.literal(false)]).optional(),
    value: dataUriSchema.optional().nullable(),
});

export const formConfigSwitchSchema = baseSchema.extend({
    type: z.literal("switch"),
    value: z
        .union([z.literal("1"), z.literal("0"), z.literal(1), z.literal(0), z.boolean()])
        .optional()
        .nullable(),
});

export const radioOptionLabelSchema = z.object({
    value: z.union([z.string(), z.number(), z.boolean()]),
    label: z.union([z.record(z.string()), z.string()]),
});

export const radioOptionTextSchema = z.object({
    value: z.union([z.string(), z.number(), z.boolean()]),
    text: z.union([z.record(z.string()), z.string()]),
});

export const radioOptionsSchema = z.union([
    z.array(radioOptionLabelSchema),
    z.array(radioOptionTextSchema),
    z.array(z.string()),
]);

export const formConfigRadioSchema = baseSchema.extend({
    type: z.literal("radio"),
    value: z
        .union([
            z.object({
                value: z.union([z.string(), z.number(), z.boolean()]),
            }),
            z.string(),
            z.number(),
            z.boolean(),
        ])
        .optional()
        .nullable(),
    multiOptions: radioOptionsSchema,
});

export const formConfigHtmlSchema = baseSchema.extend({
    type: z.literal("html"),
    description: z.string().optional(),
    fieldType: z.boolean().optional(),
    value: z.string().optional().nullable(),
});

export const selectOptionValueSchema = z
    .union([z.string(), z.boolean(), z.number(), z.bigint()])
    .nullable();

export const selectOptionLabelSchema = z.object({
    value: selectOptionValueSchema,
    label: z.union([multiLangStringSchema, z.string()]),
    attr: z
        .object({
            icon: z.string().optional(),
            image: z.string().optional(),
        })
        .optional(),
    disabled: z.boolean().optional(),
    group: z.string().optional(),
});

export const selectOptionTextSchema = z.object({
    value: selectOptionValueSchema,
    text: z.union([multiLangStringSchema, z.string()]),
    attr: z
        .object({
            icon: z.string().optional(),
            image: z.string().optional(),
        })
        .optional(),
    disabled: z.boolean().optional(),
    group: z.string().optional(),
});

export const selectOptionsSchema = z.array(
    z.union([selectOptionLabelSchema, selectOptionTextSchema]),
);

export const normalizedSelectOptionsSchema = z.array(selectOptionLabelSchema);

export const formConfigSelectSchema = baseSchema.extend({
    type: z.literal("select"),
    description: z.string().optional(),
    html_options: z.boolean().optional().default(false),
    clearable: z.boolean().optional().default(false),
    appendOuterIcon: z.string().optional(),
    appendOuterIconCallback: z
        .union([z.string(), z.function().args().returns(z.void())])
        .optional(),
    changeSelectCallback: z.union([z.string(), z.function().args().returns(z.void())]).optional(),
    multiple: z.boolean().optional().default(false),
    chips: z.boolean().optional(),
    select_all: z.boolean().optional().default(false),
    options: z
        .union([z.array(selectOptionTextSchema), z.array(selectOptionLabelSchema)])
        .optional(),
    multiOptions: z
        .union([z.array(selectOptionTextSchema), z.array(selectOptionLabelSchema)])
        .optional(),
    dataUrl: z.string().optional(),
});

export const orderedChoiceOptionValueSchema = selectOptionValueSchema;

export const orderedChoiceOptionSchema = z.object({
    value: orderedChoiceOptionValueSchema,
    label: z.string(),
});

export const formConfigOrderedChoiceSchema = baseSchema.extend({
    type: z.literal("orderedChoice"),
    value: z.array(selectOptionValueSchema),
    multiOptions: z.array(orderedChoiceOptionSchema),
});

export const fileValueSchema = z.union([fileBase64Schema, fileDataUriSchema]);

export const formConfigFileSchema = baseSchema.extend({
    type: z.literal("file"),
    value: z
        .union([z.array(fileValueSchema), fileValueSchema])
        .optional()
        .nullable(),
    avoidDrag: z.boolean().optional(),
    accept: z.record(z.union([mimeTypePatternSchema, z.array(mimeTypePatternSchema)])).optional(),
    multiple: z.boolean().optional(),
    maxFileSize: z.number().optional(),
    maxImageSize: z.number().optional(),
});

export const formConfigRowNoCollectionSchema = z.union([
    formConfigHiddenSchema,
    formConfigTextSchema,
    formConfigTextareaSchema,
    formConfigNumberSchema,
    formConfigEmailSchema,
    formConfigCheckboxSchema,
    formConfigRadioSchema,
    formConfigPasswordSchema,
    formConfigDateSchema,
    formConfigTimeSchema,
    formConfigLocalizedSchema,
    formConfigColorSchema,
    formConfigSignatureSchema,
    formConfigSelectSchema,
    formConfigSwitchSchema,
    formConfigFileSchema,
    formConfigWysiwygSchema,
    formConfigHtmlSchema,
    formConfigOrderedChoiceSchema,
]);

export const formConfigRowsNoCollectionSchema = z.record(formConfigRowNoCollectionSchema);

export const formConfigCollectionSchema = baseSchema.extend({
    type: z.literal("collection"),
    allow_add: z.union([z.boolean(), booleanEvalStringSchema]).optional().default(false),
    allow_delete: z.union([z.boolean(), booleanEvalStringSchema]).optional().default(false),
    add_label: z.string().optional().default("'Hinzuf\u00FCgen'"),
    prototype: z.union([formConfigRowNoCollectionSchema, formConfigRowsNoCollectionSchema]),
    children: z.record(
        z.union([formConfigRowNoCollectionSchema, formConfigRowsNoCollectionSchema]),
    ),
});

export const formConfigRowSchema = z.union([
    formConfigRowNoCollectionSchema,
    formConfigCollectionSchema,
]);

export const formConfigRowsSchema = z.record(formConfigRowSchema);

export const formConfigOrderedRowsSchema = z.record(
    formConfigRowSchema.and(
        z.object({
            order: z.number().optional(),
        }),
    ),
);

export const formConfigAccordionSchema = z.object({
    type: z.literal("accordion"),
    label: z.string(),
    children: formConfigOrderedRowsSchema,
});

export const formConfigTabSchema = z.object({
    type: z.literal("tab"),
    label: z.string(),
    children: formConfigOrderedRowsSchema,
});

export const formConfigContentSchema = z.union([
    formConfigTabSchema,
    formConfigRowSchema,
    formConfigAccordionSchema,
]);

export const formConfigSchema = z.record(formConfigContentSchema);
