<template>
    <template v-for="(rowConfig, key) in rows" :key="key">
        <l-form-row
            :id="id(key)"
            :config="rowConfig"
            :element-path="path(key)"
            :form-values="formValues"
            :model-value="value ? value[key] : undefined"
            :name="name(key)"
            @update:config="v => $emit('partialUpdate:config', { [key]: v })"
            @update:model-value="v => $emit('partialUpdate:value', { [key]: v })"
            @partial-update:config="v => $emit('partialUpdate:config', { [key]: v })"
            @partial-update:value="v => $emit('partialUpdate:value', { [key]: v })"
        />
    </template>
</template>

<script lang="ts">
import LFormRow from "./LFormRow.vue";
import { defineComponent, type PropType } from "vue";
import { type FormConfig, type FormConfigRows, isFormRow } from "../config/Form";
import type { EvalRecord } from "../lib/EvalRecord";

/**
 * Renders the rows as defined in the config object
 */
export default defineComponent({
    components: {
        LFormRow,
    },
    props: {
        /**
         * rowsPrefix has to be set to a sensible value for compound forms
         */
        elementPath: {
            type: Array as PropType<Array<string>>,
            required: false,
            default: () => [],
        },
        value: {
            type: Object,
            required: true,
            default: undefined,
        },
        formValues: {
            type: Object as PropType<EvalRecord<any>>,
            required: true,
        },
        /**
         * config object. Each key must define the config for one row, as per LFormRow.
         * Tabs and accordions are ignored, as is the errors key.
         */
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
        },
    },
    emits: ["partialUpdate:value", "partialUpdate:config"],
    data() {
        return {};
    },
    computed: {
        rows(): FormConfigRows {
            const rows: FormConfigRows = {};
            for (const key in this.config) {
                const el = this.config[key];
                if (isFormRow(el)) {
                    rows[key] = el;
                }
            }
            return rows;
        },
    },
    methods: {
        name(key) {
            const prefix = this.elementPath.slice();
            prefix.push(key);
            let name = prefix.shift();
            let part: string | undefined;
            while ((part = prefix.shift())) {
                name += "[" + part + "]";
            }
            return name;
        },
        id(key) {
            const prefix = this.elementPath.slice();
            prefix.push(key);
            return prefix.join("-");
        },
        path(key) {
            const path = this.elementPath.slice();
            path.push(key);
            return path;
        },
    },
});
</script>
