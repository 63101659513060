<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title> Custom Filters and disabled pagination </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon="$search" size="large" @click="showFilter = !showFilter" />
            </v-toolbar-items>
        </v-toolbar>
        <l-table
            :loading="false"
            :data="config"
            :show-filter="showFilter"
            storage-key="test_table_filter_storage_custom"
            :items-per-page-options="[10, 50, 100]"
            :disable-pagination="true"
            @filter_closed="filterClosed"
            @filter_resetted="filterResetted"
        >
            <template #filter="props">
                <v-card-text>
                    <v-form>
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-col xs12>
                                    <v-text-field v-model="search" label="Text search" />
                                </v-col>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="props.closeFilter()"> close </v-btn>
                    <v-btn @click="props.resetFilter()"> reset </v-btn>
                </v-card-actions>
            </template>
            <template #col_actions="props">
                <l-table-action
                    v-if="actions !== null"
                    :actions="actions"
                    :row="props.row"
                    :eval-context="props.evalContext"
                    icon-size="small"
                    btn-size="default"
                >
                    <v-btn icon="$showDetails" variant="text" />
                </l-table-action>
            </template>
        </l-table>
    </v-container>
</template>

<script>
import {
    VBtn,
    VCardActions,
    VCardText,
    VCol,
    VContainer,
    VForm,
    VLayout,
    VSpacer,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTextField,
} from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";
import LTableAction from "../../../src/component/LTableAction.vue";

export default {
    name: "TableFiltersCustom",
    components: {
        LTable,
        LTableAction,
        VForm,
        VCol,
        VContainer,
        VLayout,
        VCardText,
        VCardActions,
        VToolbar,
        VToolbarTitle,
        VSpacer,
        VToolbarItems,
        VBtn,
        VTextField,
    },
    data() {
        return {
            search: null,
            showFilter: true,
            config: {
                columns: [
                    {
                        key: "string",
                        label: "string",
                    },
                    {
                        key: "string2",
                        label: "string2",
                    },
                    {
                        key: "boolean",
                        label: "boolean",
                    },
                    {
                        key: "date",
                        label: "date",
                        type: "date",
                    },
                    {
                        key: "time",
                        label: "time",
                        type: "time",
                    },
                    {
                        key: "actions",
                        label: "actions",
                        actions: {
                            edit: {
                                icon: "$edit",
                                route: "#",
                                label: "edit",
                            },
                            delete: {
                                icon: "$delete",
                                route: "#",
                                label: "delete",
                            },
                        },
                    },
                ],
                rows: null,
                filter: [
                    {
                        key: "search",
                        value: "",
                        label: "Suche",
                    },
                ],
            },
            data: [
                {
                    string: "Eins",
                    string2: "Weiterer String",
                    boolean: true,
                    date: "1970-01-01",
                    time: "01:00:00",
                },
                {
                    string: "Zwei",
                    string2: "Nochmals ein String",
                    boolean: false,
                    date: "1970-01-02",
                    time: "02:00:00",
                },
                {
                    string: "Drei",
                    string2: "dDer 3. weitere String",
                    boolean: false,
                    date: "1970-01-03",
                    time: "03:00:00",
                },
                {
                    string: "Vier",
                    string2: "Noch ein String",
                    boolean: true,
                    date: "1970-01-04",
                    time: "04:00:00",
                },
                {
                    string: "Fünf",
                    string2: "Weiterer String",
                    boolean: true,
                    date: "1975-08-13",
                    time: "01:00:00",
                },
                {
                    string: "Sechs",
                    string2: "Nochmals ein String",
                    boolean: false,
                    date: "1970-01-02",
                    time: "02:00:00",
                },
                {
                    string: "Sieben",
                    string2: "dDer 3. weitere String",
                    boolean: false,
                    date: "1970-01-03",
                    time: "03:00:00",
                },
                {
                    string: "Acht",
                    string2: "Noch ein String",
                    boolean: true,
                    date: "1970-01-04",
                    time: "04:00:00",
                },
                {
                    string: "Neun",
                    string2: "Weiterer String",
                    boolean: true,
                    date: "1970-01-01",
                    time: "01:00:00",
                },
                {
                    string: "Zehn",
                    string2: "Nochmals ein String",
                    boolean: false,
                    date: "2020-012-04",
                    time: "19:15:30",
                },
                {
                    string: "Elf",
                    string2: "dDer 3. weitere String",
                    boolean: false,
                    date: "1970-01-03",
                    time: "03:00:00",
                },
                {
                    string: "Zwölf",
                    string2: "Noch ein String",
                    boolean: true,
                    date: "2022-05-04",
                    time: "04:35:00",
                },
            ],
        };
    },
    computed: {
        actions() {
            const actions = this.config.columns.find(column => column.key === "actions");
            if (typeof actions === "undefined") {
                return null;
            }
            return actions.actions;
        },
    },
    watch: {
        search() {
            this.filterRows();
        },
    },
    mounted() {
        this.filterRows();
    },
    methods: {
        filterClosed() {
            this.showFilter = false;
        },
        filterResetted() {
            this.$set(this.textSearchConfig, "value", null);
        },
        filterRows() {
            let rows = JSON.parse(JSON.stringify(this.data));
            if (this.search !== null) {
                rows = rows.filter(item => {
                    return (
                        item.string.toLowerCase().includes(this.search) ||
                        item.string2.toLowerCase().includes(this.search)
                    );
                });
            }
            this.config.rows = rows;
        },
    },
};
</script>
