import { lumUiPlugin, type LumUiConfig } from "../../src/";
import { type createVuetify } from "vuetify";
import type { App, Ref } from "vue";
import type { Store } from "vuex";
import { type Formatter } from "../../src/lib/formatter";

class CustomFormatter implements Formatter {
    format(v: unknown): string {
        return '<span style="color:red;">(-: ' + String(v) + " :-)</span>";
    }
}

export default function createLumUi(
    app: App,
    vuetify: ReturnType<typeof createVuetify>,
    locale: Ref<string>,
    store: Store<unknown>,
) {
    type Locales = "de-CH" | "en-US" | "et-EE" | "lv-LV" | "fi-FI" | "lt-LT";
    const lumuiCfg: LumUiConfig<Locales> = {
        vuetify,
        locale: locale,
        fallbackLocale: "de-CH",
        localeKeyMap: {
            "de-CH": "de",
            "en-US": "en",
            "et-EE": "et",
            "lv-LV": "lv",
            "fi-FI": "fi",
            "lt-LT": "lt",
        },
        icons: {
            //  close: 'fas fa-times-circle'
        },
        table: {
            evalContext: {
                dontShow() {
                    return store.getters.dontShow;
                },
                show() {
                    return true;
                },
            },
        },
        formatter: {
            custom: CustomFormatter,
        },
        form: {
            evalContext: {
                makeItSo() {
                    return true;
                },
                nop() {
                    return false;
                },
            },
        },
    };
    app.use(lumUiPlugin, lumuiCfg);
}
