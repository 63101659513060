<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title> Link Columns</v-toolbar-title>
        </v-toolbar>
        <l-table :data="tableData" :loading="false" />
    </v-container>
</template>

<script lang="ts">
import { VContainer, VToolbar, VToolbarTitle } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";
import type { TableConfig } from "../../../src/config/Table";

const tableData: TableConfig = {
    columns: [
        {
            key: "id",
            hidden: true,
        },
        {
            key: "url",
            hidden: true,
        },
        {
            key: "col1",
            label: "Column 1 (String)",
            formatter: {
                link: {
                    hrefCol: "url",
                },
            },
        },
        {
            key: "col2",
            label: 'target="_blank"',
            formatter: {
                link: {
                    hrefCol: "url",
                    target: "_blank",
                },
            },
        },

        {
            key: "action",
            label: "",
            type: "action",
            formatter: "action",
            actions: [],
        },
    ],
    rows: [
        {
            id: 1,
            url: "https://en.wikipedia.org/wiki/Lorem_ipsum",
            col1: "Lorem Ipsum",
            col2: "blank Lorem Ipsum",
            action: [],
        },
        {
            id: 2,
            url: "https://de.wikipedia.org/wiki/Lorem_ipsum",
            col1: "Lorem Ipsum (Deutsch)",
            col2: "blank Lorem Ipsum (Deutsch)",
            action: [],
        },
        {
            id: 3,
            url: "https://de.wikipedia.org/wiki/Franz_Kafka",
            col1: "Kafka",
            col2: "blank Kafka",
            action: [],
        },
    ],
    filter: [],
};

export default {
    name: "TableLink",
    components: { LTable, VContainer, VToolbar, VToolbarTitle },
    data() {
        return {
            tableData,
        };
    },
};
</script>
