import {
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    reactive,
    type Ref,
    watch,
} from "vue";
import type { TableRow } from "../../config/Table";

type ExpansionData = {
    enabled: Readonly<Ref<boolean>>;
    isExpanded(row: TableRow): boolean;
    expand(row: TableRow): void;
    expandAll(): void;
    collapse(row: TableRow): void;
    collapseAll(): void;
    toggle(row: TableRow): void;
    set(rows: Iterable<TableRow>): void;
};

const expansionInjectionKey = Symbol.for("LumUi:LTable:expansion") as InjectionKey<ExpansionData>;

export function provideExpansion(
    enabled: Ref<boolean>,
    rows: DeepReadonly<Ref<TableRow[]>>,
    emit: (rows: ReadonlySet<TableRow>) => void,
): ExpansionData {
    const expanded = reactive(new Set<TableRow>());

    function isExpanded(row: TableRow) {
        return expanded.has(row);
    }

    function expand(row: TableRow): void {
        expanded.add(row);
        emit(expanded);
    }

    function collapse(row: TableRow): void {
        expanded.delete(row);
        emit(expanded);
    }

    function toggle(row: TableRow): void {
        if (expanded.has(row)) {
            expanded.delete(row);
        } else {
            expanded.add(row);
        }
        emit(expanded);
    }

    function expandAll(): void {
        for (const row of rows.value) {
            expanded.add(row);
        }
        emit(expanded);
    }

    function collapseAll(): void {
        expanded.clear();
        emit(expanded);
    }

    function set(expandRows: Iterable<TableRow>): void {
        expanded.clear();
        for (const row of expandRows) {
            if (!rows.value.includes(row)) {
                console.warn("table/expansion: Cannot expand unknown row:", row);
                continue;
            }
            expanded.add(row);
        }
        emit(expanded);
    }

    watch(rows, newRows => {
        let changed = false;
        for (const e of expanded.values()) {
            if (!newRows.includes(e)) {
                expanded.delete(e);
                changed = true;
            }
        }
        if (changed) {
            emit(expanded);
        }
    });

    const data: ExpansionData = {
        enabled,
        isExpanded,
        expand,
        expandAll,
        collapse,
        collapseAll,
        toggle,
        set,
    };
    provide(expansionInjectionKey, data);
    return data;
}

export function useExpansion(): ExpansionData {
    const data = inject(expansionInjectionKey);
    if (!data) {
        throw Error("provideExpansion must be called in a parent component");
    }
    return data;
}
