<template>
    <test-form v-model:config="formConf" v-model="data" title="Colorpicker" />
</template>

<script lang="ts">
import TestForm from "../../components/TestForm.vue";
import { type FormConfig } from "../../../src/config/Form";

const formConf: FormConfig = {
    picker1: {
        type: "colorpicker",
        label: "Color Picker Example",
        order: 1,
    },
    picker2: {
        type: "colorpicker",
        label: "Color Picker Example 2",
        order: 1,
        hideCanvas: false,
        hideInputs: false,
        mode: "hexa",
    },
};
export default {
    name: "FormColor",
    components: { TestForm },
    data() {
        return {
            formConf,
            data: {
                picker1: "#ff0a00",
                picker2: "#CCE800FF",
            },
        };
    },
};
</script>
