<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit="preventEnter">
        <v-card :flat="flat">
            <v-toolbar
                v-if="!noTitle"
                :class="(flat ? '' : 'elevation-1 ') + 'flex-grow-0'"
                color="primary"
                dark
            >
                <v-toolbar-title>
                    <!--
                        The form title
                        @prop {object} config the forms config object
                     -->
                    <slot :config="config" name="title">
                        {{ title }}
                    </slot>
                </v-toolbar-title>
                <v-spacer />
                <!-- slot rendered after toolbar-title -->
                <slot :config="config" name="title-after" />

                <template v-if="abortBtn" #append>
                    <v-btn :color="onPrimary" icon="$close" @click="abort()" />
                </template>
                <template v-if="hasTabs" #extension>
                    <form-tabs
                        v-model:active-tab="activeTab"
                        :config="config"
                        :render-tabs="true"
                        :value="modelValue"
                    />
                </template>
            </v-toolbar>
            <v-toolbar v-else-if="hasTabs" color="white flex-grow-0" light>
                <form-tabs
                    v-model:active-tab="activeTab"
                    :config="config"
                    :render-tabs="true"
                    :value="modelValue"
                    background-color="white"
                    color="primary"
                    @tab="setActiveTab"
                />
            </v-toolbar>

            <!-- auxiliary slot rendered before the forms v-card-text -->
            <slot :config="config" name="before" />

            <v-card-text v-show="loading">
                <l-loading :loading="loading" />
            </v-card-text>
            <v-card-text v-show="!loading" style="margin-top: 2px">
                <!-- auxiliary slot rendered before the forms content, within the v-card-text -->
                <slot :config="config" name="form-before" />
                <!-- Form errors -->
                <slot :config="config" :form-errors="formErrors" name="form-errors">
                    <template v-if="formErrors">
                        <v-alert
                            v-for="(error, key) in formErrors"
                            :key="key"
                            :value="true"
                            class="mb-4"
                            type="error"
                        >
                            <template v-if="!Array.isArray(error)">
                                {{ error }}
                            </template>
                            <template v-else>
                                <div v-for="(e, i) in error" :key="i">
                                    {{ e }}
                                </div>
                            </template>
                        </v-alert>
                    </template>
                </slot>
                <!--
                    Form content

                    @prop {object} config - Form config
                    @prop {function} validate - validate function
                    @prop {object} value - forms value
                -->

                <slot :active-tab="activeTab" :config="config" :validate="validate">
                    <form-content
                        :active-tab="activeTab"
                        :config="config"
                        :form-values="formValues"
                        :value="valueProxy"
                        @trigger-validation="() => validate()"
                        @partial-update:config="e => mergePartialConfig(e)"
                        @partial-update:value="e => mergePartialValue(e)"
                    />
                </slot>
                <!--
                    auxiliary slot rendered after the forms content, within the v-card-text
                    @props {object} config
                -->
                <slot :config="config" name="form-after" />
            </v-card-text>
            <!--
                auxiliary slot rendered after the forms v-card-text
                @props {object} config
            -->
            <slot :config="config" name="after" />
            <v-card-actions
                :class="{
                    'flex-column': display.mobile.value,
                    'flex-wrap': !display.mobile.value,
                    mobile: display.mobile.value,
                    'form-actions': true,
                }"
            >
                <!--
                    slot containing all actions
                    @props {object} config
                -->
                <slot :config="config" :form-valid="valid" name="actions" :save="save">
                    <span v-show="!loading">
                        <!--
                        emitted when the save button is activated
                        @event save
                        -->
                        <!-- :block="display.mobile.value" -->
                        <v-btn
                            v-if="!readonly"
                            :disabled="
                                loading || valid === false || Object.keys(config).length === 0
                            "
                            class="mx-2 mb-md-2 mb-lg-0"
                            color="success"
                            @click="save"
                        >
                            <!-- label for the save button -->
                            <slot name="save-btn-name">
                                {{ t("lumui.form.save") }}
                            </slot>
                        </v-btn>
                        <v-btn
                            v-if="abortBtn"
                            :block="display.mobile.value"
                            class="mx-2 mb-md-2 mb-lg-0"
                            type="button"
                            @click="abort"
                        >
                            <!-- label for the cancel button -->
                            <slot name="back-btn-name">
                                {{ readonly ? t("lumui.form.close") : t("lumui.form.cancel") }}
                            </slot>
                        </v-btn>
                    </span>
                </slot>
                <!--
                    additional actions
                    @props {object} config
                -->
                <slot :config="config" :form-valid="valid" name="additional-actions" />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
import {
    VAlert,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VForm,
    VSpacer,
    VToolbar,
    VToolbarTitle,
} from "vuetify/components";
import { useI18n } from "vue-i18n";
import { defineComponent, type PropType, readonly } from "vue";
import { createEvalRecord } from "../lib/EvalRecord";
import { useDisplay, useTheme } from "vuetify";
import {
    type FormConfig,
    type FormConfigRows,
    isFormConfigAccordion,
    isFormConfigTab,
} from "../config/Form";
import { useRouter } from "vue-router";
import { merge } from "../lib/merge";
import { useVModel } from "@vueuse/core";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import FormTabs from "./LFormTabs.vue";
import LLoading from "./LLoading.vue";
import FormContent from "./LFormContent.vue";
import setFormErrors, { type FormErrors } from "../lib/form/setFormErrors";
import { useSettings } from "../composables/Settings";
import clearFormErrors from "../lib/form/clearFormErrors";
import { disableForm } from "../index";

/**
 * Renders a standardized form based on a config object.
 *
 * **Slot layout:**
 * ```
 * +------------------------------+
 * | Title                      x |
 * +------------------------------+
 * | before                       |
 * +------------------------------+
 * | +--------------------------+ |
 * | | form-before              | |
 * | +--------------------------+ |
 * | | default                  | |
 * | +--------------------------+ |
 * | | form-after               | |
 * | +--------------------------+ |
 * +------------------------------+
 * | after                        |
 * +------------------------------+
 * | actions                      |
 * | +--------------------------+ |
 * | | additional-actions       | |
 * | +--------------------------+ |
 * +------------------------------+
 * ```
 */
export default defineComponent({
    components: {
        FormTabs,
        LLoading,
        VBtn,
        VCard,
        VCardActions,
        VCardText,
        VForm,
        FormContent,
        VAlert,
        VToolbar,
        VToolbarTitle,
        VSpacer,
    },
    inheritAttrs: false,
    props: {
        /** the value representing the forms content */
        modelValue: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        /** the definition of the form */
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
            default: () => ({}),
        },
        /** make all form fields readonly */
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        /** whether the abort button should be shown */
        abortBtn: {
            type: Boolean,
            required: false,
            default: true,
        },
        /** display the loading animation and hide form content */
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        /** makes the v-card flat. Useful for forms that are not displayed in a dialog. */
        flat: {
            type: Boolean,
            required: false,
            default: false,
        },
        /** don't render the form title */
        noTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: "Untitled",
        },
    },
    emits: ["save", "isValid", "update:config", "update:modelValue"],
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const theme = useTheme();
        const { mobile } = useDisplay();
        const settings = useSettings();
        return {
            t,
            locale,
            router: useRouter(),
            onPrimary: theme.current.value.colors["on-primary"],
            display: { mobile },
            evalContext: settings.form.evalContext,
            valueProxy: useVModel(props, "modelValue", emit, { passive: true }),
            configProxy: useVModel(props, "config", emit, { passive: true }),
        };
    },
    expose: ["setErrors", "clearErrors", "disable", "setActiveTab", "save", "abort"],
    data() {
        return {
            activeTab: "",
            valid: false as boolean | null,
            errorTimeout: 0,
        };
    },
    computed: {
        form(): InstanceType<typeof VForm> {
            return this.$refs.form as InstanceType<typeof VForm>;
        },
        formValues() {
            return createEvalRecord(readonly(this.modelValue), this.evalContext);
        },
        formErrors() {
            return this.config.errors || null;
        },
        hasTabs() {
            if (!this.configProxy) {
                return false;
            }
            for (const key in this.configProxy) {
                if (!Object.hasOwn(this.configProxy, key)) {
                    continue;
                }
                const entry = this.configProxy[key];
                if (Object.hasOwn(entry, "type") && entry.type === "tab") {
                    return true;
                }
            }
            return false;
        },
    },
    watch: {
        locale() {
            this.form.validate();
        },
        config: {
            immediate: true,
            handler() {
                if (this.readonly) {
                    this.configProxy = this.disableElements(this.configProxy);
                }
            },
        },
        valid() {
            if (this.valid && Object.keys(this.configProxy).length !== 0) {
                /**
                 * Emitted when the forms state changes to valid
                 * @event isValid
                 */
                this.$emit("isValid");
            }
        },
    },
    methods: {
        abort() {
            this.router.back();
        },
        async save() {
            const form = this.$refs.form as InstanceType<typeof VForm> | undefined;
            if (form && (await form.validate()).valid) {
                this.$emit("save");
            }
        },
        disableElements<T extends FormConfig | FormConfigRows>(o: T): T {
            const cfg = nonReactiveClone(o);
            for (const row of Object.values(cfg)) {
                if (!isFormConfigTab(row) && !isFormConfigAccordion(row)) {
                    row.disabled = true;
                } else if ("children" in row) {
                    row.children = this.disableElements(row.children);
                }
            }
            return cfg;
        },
        /** @private */
        setActiveTab(e: string): void {
            this.activeTab = e;
        },
        /** @private */
        preventEnter(e: { preventDefault: () => void }) {
            e.preventDefault();
        },
        /** validates form */
        validate() {
            return this.form.validate();
        },
        /** clears form */
        reset() {
            this.form.reset();
        },
        /** clears validation results */
        resetValidation() {
            this.form.resetValidation();
        },
        /** assigns errors to fields */
        setErrors(errors: FormErrors) {
            setFormErrors(this.configProxy, errors);
        },
        clearErrors(): void {
            clearFormErrors(this.configProxy);
        },
        disable(): void {
            disableForm(this.configProxy);
        },
        mergePartialValue(obj: any) {
            this.valueProxy = merge(this.valueProxy, obj);
        },
        mergePartialConfig(obj: FormConfig) {
            this.configProxy = merge<FormConfig>(this.configProxy, obj);
        },
    },
});
</script>

<style lang="scss">
.form-actions {
    .v-btn {
        .v-btn__content {
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-align: left !important;

            .v-icon--left {
                margin-left: 0;
                margin-right: 12px;
            }
        }
    }

    &.mobile {
        .v-btn {
            display: block;
            width: 100%;
        }
    }
}

.v-dialog--scrollable > .v-overlay__content > form {
  height: 100%;
  max-height: 100%;
}

</style>
