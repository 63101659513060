<template>
    <v-select
        :id="'filter_' + config.key"
        :clearable="clearable"
        :items="items"
        :label="config.filter.select.label ?? config.label"
        :model-value="value"
        :multiple="multiple"
        hide-details
        item-title="text"
        item-value="value"
        @update:model-value="e => $emit('update:modelValue', e)"
    >
    </v-select>
</template>

<script lang="ts">
import { VSelect } from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import type { TableFilterSelectConfig } from "../config/Table";

/**
 * Renders a select filter
 * @internal
 */
export default defineComponent({
    components: { VSelect },
    props: {
        value: {
            type: [String, Number, Boolean, Array],
            required: false,
            default: undefined,
        },
        config: {
            type: Object as PropType<TableFilterSelectConfig>,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    computed: {
        clearable() {
            return this.config.clearable ?? true;
        },
        items(): { value: string; text: string }[] {
            const options = this.config.filter.select.options;
            if (Array.isArray(options)) {
                return options;
            } else {
                const items: { value: string; text: string }[] = [];
                for (const k in options) {
                    items.push({ value: k, text: options[k] });
                }
                return items;
            }
        },
        multiple() {
            return this.config.filter.select.multiple ?? false;
        },
    },
});
</script>
