function clearFormErrors(config: Record<string, any>) {
    if (typeof config.errors !== 'undefined') {
        config.errors = [];
    }

    for (const i in config) {
        const child = config[i];
        if (typeof child.children !== 'undefined') {
            child.error = false;
            clearFormErrors(child.children);
        } else {
            child.errors = [];
        }
    }
}
export default clearFormErrors;
