<template>
    <v-expansion-panels v-if="hasAccordion" v-model="activeAccordion" variant="accordion">
        <v-expansion-panel v-for="(element, index) in accordions" :key="index">
            <v-expansion-panel-title :disable-icon-rotate="element.hasOwnProperty('error')">
                {{ element.label }}
                <template v-if="hasErrors" #actions>
                    <v-icon color="error"> $warning</v-icon>
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text eager>
                <l-form-rows
                    :config="element.children"
                    :form-values="formValues"
                    :value="value"
                    @partial-update:value="v => $emit('partial-update:value', v)"
                    @partial-update:config="cfg => $emit('partial-update:config', cfg)"
                />
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script lang="ts">
import LFormRows from "./LFormRows.vue";
import {
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelText,
    VExpansionPanelTitle,
    VIcon,
} from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import { type FormConfig, type FormConfigAccordion, isFormConfigAccordion } from "../config/Form";
import { type EvalRecord } from "../lib/EvalRecord";

/** Renders elements with type accordion in the top level of the `config` object as accordions. */
export default defineComponent({
    components: {
        LFormRows,
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelTitle,
        VExpansionPanelText,
        VIcon,
    },
    props: {
        /** form value */
        value: {
            type: Object,
            required: true,
        },
        /** form config */
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
        },
        formValues: {
            type: Object as PropType<EvalRecord<any>>,
            required: true,
        },
    },
    emits: ["partial-update:value", "partial-update:config"],
    data() {
        return {
            activeAccordion: 0 as number | string,
            valid: false,
        };
    },
    computed: {
        hasErrors(): boolean {
            // @todo: implement some how
            return false;
        },
        accordions(): Record<string, FormConfigAccordion> {
            const res = {};
            for (const i in this.config) {
                const cfg = this.config[i];
                if (isFormConfigAccordion(cfg)) {
                    res[i] = cfg;
                }
            }
            return res;
        },
        hasAccordion(): boolean {
            return Object.keys(this.accordions).length > 0;
        },
    },
    watch: {
        config(n, old) {
            if (old == null) {
                for (const key in this.config) {
                    if (!Object.hasOwnProperty.call(this.config, key)) {
                        continue;
                    }
                    const entry = this.config[key];
                    if (entry.type === "accordion") {
                        this.activeAccordion = key;
                        /** when config is initially set and contains at least one accordion */
                        // this.$emit('triggerValidation');
                        break;
                    }
                }
            }
        },
    },
});
</script>
