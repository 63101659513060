<template>
    <v-container>
        <h1 class="text-h3">Datensätze wählen</h1>
        <l-table ref="tbl" :data="config" show-select :show-filter="true" @select="onselect" />

        <v-btn color="primary" :disabled="selected.length === 0" @click="clear()">
            Alle abwählen
        </v-btn>

        <v-divider class="my-6" />
        <h4>Gewählte Datensätze</h4>
        <p class="text-body-2">
            {{ selected }}
        </p>
    </v-container>
</template>

<script lang="ts" setup>
import { VBtn, VContainer, VDivider } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";
import { ref } from "vue";
import { type TableConfig, type TableRow } from "../../../src/config/Table";
import { assertDefined } from "../../../src/lib/assert";

const config = ref<TableConfig>({
    columns: [
        {
            key: "id",
            hidden: true,
        },
        {
            key: "col1",
            label: "Column 1 (String)",
        },
        {
            key: "col2",
            hidden: false,
            label: "Column 2",
            type: "select",
            details: {
                label: "Col 2 (String, Only in Detailsview)",
                key: "col2",
                hidden: false,
            },
            options: [
                { value: 1, text: "Label 1" },
                { value: 2, text: "Label 2" },
                { value: 3, text: "Label 3" },
                { value: 4, text: "Label 4" },
            ],
        },
        {
            key: "col3",
            label: "Column 3 (Date)",
            type: "date",
        },
        {
            key: "action",
            label: "",
            type: "action",
            formatter: "action",
            actions: [],
        },
    ],
    filter: [
        {
            key: "col1",
            label: "Column 1 (String)",
        },
    ],
    rows: [
        {
            id: 1,
            col1: "Lorem Ipsum",
            col2: 1,
            col3: "1990-10-10",
        },
        {
            id: 2,
            col1: "Lorem Ipsum (Deutsch)",
            col2: 2,
            col3: "1990-09-09",
        },
        {
            id: 3,
            col1: "Kafka",
            col2: 4,
            col3: "1990-11-11",
        },
    ],
});

const selected = ref<TableRow[]>([]);

const tbl = ref<typeof LTable>();

function onselect(s) {
    try {
        selected.value = s;
    } catch (e) {
        console.error(e);
    }
}

function clear() {
    assertDefined(tbl.value);
    tbl.value.selection.set([]);
}
</script>
