import { type App, inject, type InjectionKey } from "vue";

type CallFunc = (apiName: string, params?: any) => Promise<any>;

const ApiCallerInjectionKey = Symbol.for("LumUi:ApiCaller") as InjectionKey<CallFunc>;

export function useApiCaller(): CallFunc {
    const apiCaller = inject(ApiCallerInjectionKey, undefined /* suppress warning when not set */);
    if (!apiCaller) {
        return (apiName: string) => {
            // We only throw an error when the call func is actually used, as it is an optional feature
            throw Error(
                "ApiCaller plugin has not been initialized, but is needed for a feature that is used to call " +
                    apiName,
            );
        };
    }
    return apiCaller;
}

export function createApiCaller(caller: CallFunc) {
    return {
        install(app: App) {
            app.provide(ApiCallerInjectionKey, caller);
        },
    };
}
