<template>
    <v-text-field
        v-if="isVisible"
        :id="name"
        ref="field"
        v-model="model"
        :class="{ required: isRequired }"
        :append-icon="reveal ? '$hide' : '$reveal'"
        :disabled="isDisabled"
        :error-messages="aggregatedErrors"
        :hint="config.description"
        :label="theLabel"
        :name="name"
        :required="isRequired"
        :rules="myRules"
        :type="reveal ? 'text' : 'password'"
        autocomplete="off"
        @click:append="reveal = !reveal"
    />
    <v-text-field
        v-if="isVisible && !config.no_confirm"
        :id="name + '_confirm'"
        ref="confirm"
        v-model="confirm"
        :disabled="isDisabled"
        :label="t('lumui.form.password.repeat')"
        :name="name + '_confirm'"
        :required="!!model"
        :rules="confirmRules"
        :type="reveal ? 'text' : 'password'"
        autocomplete="off"
    />
</template>

<script lang="ts">
import { VTextField } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps.js";
import type { FormConfigPassword } from "../config/Form";
import { formConfigPasswordSchema } from "../config/Form.zod";
import { defineComponent, useModel } from "vue";
import { useI18n } from "vue-i18n";

/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | description            | `String`                   | no       | `""`       | field hint |
 * | no_confirm             | `Boolean`                  | no       | `false`    | do not require confirmation |
 */
export default defineComponent({
    components: { VTextField },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: String,
            required: false,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigPassword>(formConfigPasswordSchema),
    },
    emits: ["update:modelValue"],
    setup(props, ctx) {
        const model = useModel(props, "modelValue");
        const { t } = useI18n();
        const defaults = useDefaults(props, ctx.emit);
        return {
            t,
            model,
            ...defaults,
        };
    },
    data() {
        return {
            confirm: null as null | string,
            reveal: false,
        };
    },
    computed: {
        myRules() {
            if (this.rules) {
                return this.rules;
            }
            const required = this.isRequired;
            type T = typeof this.model;
            return [
                (v: T) =>
                    !required ||
                    (v != undefined && v.length > 0) ||
                    this.t("lumui.form.row.required"),
            ];
        },
        confirmRules(): Array<(v: typeof this.confirm) => true | string> {
            type T = typeof this.confirm;
            return [
                (v: T) =>
                    (!this.isRequired && !this.model) || // wenn nicht required und das passwort leer => valide
                    v == this.model ||
                    this.t("lumui.form.password.not_matching_error"),
            ];
        },
        confirmRef(): InstanceType<typeof VTextField> | undefined {
            return this.$refs.confirm as InstanceType<typeof VTextField> | undefined;
        },
    },
    watch: {
        model() {
            this.confirmRef?.validate(false);
        },
    },
});
</script>
