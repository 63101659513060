import { computed, onMounted, type PropType, unref } from "vue";
import { createEvalRecord, type EvalRecord } from "../lib/EvalRecord";
import { computeEvalBoolean } from "./EvalBoolean";
import type { SafeParseError, Schema } from "zod";
import { nonReactiveClone } from "../lib/nonReactiveClone";

export function makeDefaultProps<ConfigType>(configSchema?: Schema) {
    return {
        name: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: false,
        },
        label: {
            type: [String, Boolean],
            required: false,
            default: undefined,
            validator(label: unknown): boolean {
                if (typeof label == "boolean" && label) {
                    console.error("DefaultProps: Label must be string or false");
                    return false;
                }
                return true;
            },
        },
        required: {
            type: [Boolean, String],
            required: false,
            default: undefined,
        },
        visible: {
            type: [Boolean, String],
            required: false,
            default: undefined,
        },
        disabled: {
            type: [Boolean, String],
            required: false,
            default: undefined,
        },
        formValues: {
            type: Object as PropType<EvalRecord<any>>,
            required: false,
            default: () => createEvalRecord({}, {}),
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        errorMessages: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * empty array means no rules
         * null means default rules for this component
         */
        rules: {
            type: Array as PropType<Array<() => true | string> | null>,
            required: false,
            default: null,
        },
        config: {
            type: Object as PropType<ConfigType>,
            required: true,
            default: () => ({}),
            validator(config: unknown): boolean {
                if (!config) {
                    console.error("DefaultProps: Config is required");
                    return false;
                }
                if (configSchema == undefined) {
                    return true;
                }
                const res = configSchema.safeParse(config);
                if (res.success) {
                    return true;
                }

                const r = res as SafeParseError<unknown>;
                console.group("DefaultProps: Form config error");
                for (const e of r.error.errors) {
                    console.error("DefaultProps: " + e.path.join(".") + ":", e);
                }
                console.warn("DefaultProps: config", config);
                console.groupEnd();
                return false;
            },
        },
    };
}

export function useDefaults(
    props: any,
    emit: (name: "update:modelValue", ...args: unknown[]) => void,
) {
    onMounted(() => {
        if (props.modelValue === undefined && props.config.value !== undefined && emit) {
            const value = nonReactiveClone(props.config.value);
            emit("update:modelValue", value);
        }
    });
    return {
        isRequired: computed(() => {
            return computeEvalBoolean("required", props, false);
        }),
        isDisabled: computed(() => {
            return computeEvalBoolean("disabled", props, false);
        }),
        isVisible: computed(() => {
            return computeEvalBoolean("visible", props, true);
        }),
        theLabel: computed(() => {
            return (props.label ?? props.config.label) || undefined;
        }),
        aggregatedErrors: computed<Array<string>>(() => {
            const propMessages = unref(props.errorMessages);
            const configMessages = unref(props.config.errors);
            if (propMessages && propMessages.length > 0) {
                return propMessages;
            }
            if (configMessages && configMessages.length > 0) {
                return configMessages;
            }
            return [];
        }),
    };
}
