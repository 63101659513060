<template>
    <v-text-field
        v-if="isVisible"
        :id="id"
        ref="picker"
        :append-inner-icon="isRequired ? ('$clear' as any) : undefined"
        :disabled="isDisabled"
        :label="theLabel"
        :max="config.max"
        :min="config.min"
        :model-value="viewValue"
        :name="name"
        :rules="myRules"
        :class="['form-row-time', { required: isRequired }]"
        prepend-inner-icon="$calendar"
        type="time"
        @click="toggleMenu"
        @click:append-inner="clear()"
        @update:model-value="v => $emit('update:modelValue', v)"
    />
    {{ config }}
</template>

<script lang="ts">
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps.js";
import { type FormConfigTime } from "../config/Form";
import { defineComponent, type PropType } from "vue";
import { VTextField } from "vuetify/components";
import { formConfigTimeSchema } from "../config/Form.zod";
import { useI18n } from "vue-i18n";
import moment from "moment";

/**
 * Date element
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"date"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`      | field is rendered.  |
 * | min                    | `String`                   | no       | `undefined`  | Minimum date allowed. Format 'YYYY-MM-DD' |
 * | max                    | `String`                   | no       | `undefined`  | Maximum date allowed. Format 'YYYY-MM-DD' |
 */
export default defineComponent({
    components: { VTextField },
    props: {
        ...makeDefaultProps<FormConfigTime>(formConfigTimeSchema),
        modelValue: {
            type: [String, Object] as PropType<string | Date>,
            required: false,
            default: undefined,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const { t } = useI18n();
        return {
            t,
            ...useDefaults(props, emit),
        };
    },
    computed: {
        showPickerSupported() {
            return "showPicker" in HTMLInputElement.prototype;
        },
        viewValue() {
            const value: string | Date | undefined = this.modelValue;
            if (!value) {
                return "";
            }
            if (value instanceof Date) {
                return moment(value, "UTC").utcOffset(0, true).format("HH:mm");
            }
            return moment(value, "H:mm", "UTC").utcOffset(0, true).format("HH:mm");
        },
        myRules() {
            if (this.rules) {
                return this.rules;
            }
            const required = this.isRequired;
            return [(v: unknown) => !required || !!v || this.t("lumui.form.row.required")];
        },
    },
    methods: {
        toggleMenu() {
            if (!this.showPickerSupported) {
                return;
            }
            const picker = this.$refs.picker as InstanceType<typeof VTextField>;
            const input = picker.$el.querySelector("input");

            input.showPicker();
        },
        clear() {
            this.$emit("update:modelValue", undefined);
        },
    },
});
</script>

<style scoped>
.form-row-time ::-webkit-calendar-picker-indicator {
    display: none !important;
}
</style>
