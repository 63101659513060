import {
    computed,
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    type Ref,
    ref,
} from "vue";
import type { NormalizedTableColumnConfig, TableRow } from "../../config/Table";
import { FormatterFactory } from "../../index";
import { useI18n } from "vue-i18n";

type SortDirection = "asc" | "desc";
type SortCriteria = null | { column: string; direction: "asc" | "desc" };
type SortFunction = <T>(a: T, b: T) => number;

export type Sort = {
    sortableColumnKeys: DeepReadonly<Ref<ReadonlyArray<string>>>;
    functions: DeepReadonly<Ref<Record<string, SortFunction>>>;
    criteria: Readonly<Ref<DeepReadonly<SortCriteria>>>;
    isSorted(column: string): boolean;
    isSortable(column: string): boolean;
    sort(column: string, direction: SortDirection): void;
    toggle(column: string): void;
    clear(): void;
};

export const SortInjectKey = Symbol.for("LumUi:LTable:sort") as InjectionKey<Sort>;

export function provideSort(columns: Readonly<Ref<readonly NormalizedTableColumnConfig[]>>): Sort {
    const i18n = useI18n();
    const localized = FormatterFactory("Localized", { locale: i18n.locale.value });
    const nested = FormatterFactory("NestedSet", {});
    const materializedPath = FormatterFactory("MaterializedPath", {});
    const criteria = ref<SortCriteria>(null);
    const sortableColumns = computed(() => {
        return columns.value.filter(c => c.sortable);
    });
    const sortableColumnKeys = computed(() => {
        return sortableColumns.value.map(c => c.key);
    });
    const functions = computed(() => {
        const funcs: Record<string, SortFunction> = {};
        for (const col of sortableColumns.value) {
            if (!col.sortable) {
                continue;
            }
            switch (col.type) {
                case "time":
                    funcs[col.key] = (a, b) => {
                        const ad = new Date("1970-01-01 " + a + " UTC");
                        const bd = new Date("1970-01-01 " + b + " UTC");
                        return ad < bd ? -1 : 1;
                    };
                    break;
                case "localized":
                    funcs[col.key] = (a, b) => {
                        const aText = localized.format(a).toLowerCase() ?? "";
                        const bText = localized.format(b).toLowerCase() ?? "";
                        return aText < bText ? -1 : 1;
                    };
                    break;
                case "materialized_path":
                    funcs[col.key] = (a, b) => {
                        const aLabel = materializedPath.format(a).toLowerCase();
                        const bLabel = materializedPath.format(b).toLowerCase();

                        return aLabel < bLabel ? -1 : 1;
                    };
                    break;
                case "nestedset":
                    funcs[col.key] = (a, b) => {
                        const aLabel = nested.format(a).toLowerCase();
                        const bLabel = nested.format(b).toLowerCase();

                        return aLabel < bLabel ? -1 : 1;
                    };
                    break;
                default:
                    funcs[col.key] = (a, b) => {
                        let sa, sb;
                        if (typeof a === "string" && typeof b === "string") {
                            sa = a.toLocaleLowerCase();
                            sb = b.toLocaleLowerCase();
                            2;
                        } else {
                            sa = a;
                            sb = b;
                        }
                        return sa == sb ? 0 : sa < sb ? -1 : 1;
                    };
                    break;
            }
        }
        return funcs;
    });

    function sort(column: string, direction: "asc" | "desc") {
        if (!sortableColumnKeys.value.includes(column)) {
            throw Error("Column " + column + " is not sortable");
        }
        criteria.value = { column, direction };
    }

    function toggle(column: string) {
        if (!sortableColumnKeys.value.includes(column)) {
            throw Error("Column " + column + " is not sortable");
        }
        const c = criteria.value;
        if (c == null || c.column !== column) {
            criteria.value = { column, direction: "asc" };
        } else if (c.direction == "asc") {
            criteria.value = { column, direction: "desc" };
        } else {
            criteria.value = null;
        }
    }

    function clear() {
        criteria.value = null;
    }

    function isSorted(column: string) {
        return criteria.value !== null && criteria.value.column == column;
    }

    function isSortable(column: string): boolean {
        return sortableColumnKeys.value.includes(column);
    }

    const sortData = {
        sortableColumnKeys,
        functions,
        criteria,
        isSorted,
        isSortable,
        sort,
        toggle,
        clear,
    };

    provide(SortInjectKey, sortData);
    return sortData;
}

export function useSort() {
    const filter = inject(SortInjectKey);
    if (!filter) {
        throw Error("provideSort must be called in a parent component");
    }
    return filter;
}

export function useSortedRows(
    rows: DeepReadonly<Ref<TableRow[]>>,
    criteria: DeepReadonly<Ref<SortCriteria>>,
    sortFunctions: DeepReadonly<Ref<Record<string, SortFunction>>>,
) {
    return computed<TableRow[]>(() => {
        const r = [...rows.value];
        if (criteria.value == null) {
            return r;
        }
        const { column, direction } = criteria.value;

        const cmp = sortFunctions.value[column];
        if (!cmp) {
            throw Error("No sort function for " + column);
        }

        const dir = direction == "asc" ? 1 : -1;

        return r.sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            return dir * cmp(aValue, bValue);
        });
    });
}
