import { type ComputedRef, inject, type InjectionKey, provide } from "vue";
import type { WritingOptions } from "xlsx";
import type { ColumnType, NormalizedTableColumnConfig, TableRow } from "../../config/Table";
import { type ThemeInstance, useTheme } from "vuetify";
import { assertDefined } from "../../lib/assert";
import type { CellDef, CellHookData } from "jspdf-autotable";

import { saveAs } from "file-saver";
import { type Formatter, getColumnFormatter, getCustomColumnFormatter } from "../../lib/formatter";
import { useI18n } from "vue-i18n";
import type jsPDF from "jspdf";

const exportSpreadsheetFormats = ["xls", "ods", "xlsx", "csv"] as const;
type ExportSpreadsheetFormat = (typeof exportSpreadsheetFormats)[number];

const exportPdfFormats = ["pdf"] as const;
type ExportPdfFormat = (typeof exportPdfFormats)[number];

export const exportFormats = [...exportPdfFormats, ...exportSpreadsheetFormats] as const;
export type ExportFormat = (typeof exportFormats)[number];

type Exporter = {
    exportTable(fmt: ExportFormat): Promise<Blob>;
    exportTableAs(name: string, fmt: ExportFormat): Promise<void>;
};
const exportInjectionKey = Symbol.for("LumUi:LTable:export") as InjectionKey<Exporter>;

export function useExporter() {
    const exporter = inject(exportInjectionKey);
    if (!exporter) {
        throw Error("provideExport must be called in a parent component");
    }
    return exporter;
}

export function provideExporter(
    columnDefinitions: ComputedRef<readonly NormalizedTableColumnConfig[]>,
    tableRows: ComputedRef<readonly TableRow[]>,
): Exporter {
    const { locale } = useI18n();
    const theme = useTheme();

    async function exportTable(fmt: ExportFormat): Promise<Blob> {
        const cols = getExportColumns(columnDefinitions.value);
        const rows = getExportData(columnDefinitions.value, tableRows.value, locale.value);
        switch (fmt) {
            case "xls":
            case "ods":
            case "xlsx":
            case "csv":
                return await exportSpreadsheet(cols, rows, fmt, theme);
            case "pdf":
                return await exportPdf(cols, rows, fmt, theme);
        }
    }
    async function exportTableAs(name: string, fmt: ExportFormat): Promise<void> {
        const filename: string = name + "." + fmt;
        const blob = await exportTable(fmt);
        saveAs(blob, filename);
    }

    const exporter: Exporter = {
        exportTable,
        exportTableAs,
    };
    provide(exportInjectionKey, exporter);
    return exporter;
}

function getExportData(
    columnDefinitions: readonly NormalizedTableColumnConfig[],
    rows: readonly TableRow[],
    locale: string,
): any[][] {
    const formatter = new Array<Formatter | undefined>();
    const header: string[] = [];
    for (const idx in columnDefinitions) {
        const def = columnDefinitions[idx];
        if (def.export.hidden) {
            formatter[def.key] = undefined;
        } else {
            header.push(def.label);

            if (def.export.formatter) {
                formatter[def.key] = getCustomColumnFormatter(def.export.formatter, locale);
            } else {
                formatter[def.key] = getColumnFormatter(
                    def.export.type ?? "text",
                    locale,
                    { options: def.export.options }, // @todo fixme
                );
            }
        }
    }

    const data: any[] = [header];

    for (const rowIdx in rows) {
        const row = rows[rowIdx];
        const rowData: any[] = [];
        for (const colIdx in row) {
            const fmt = formatter[colIdx];
            if (!fmt) {
                continue;
            }
            const value = row[colIdx];
            rowData.push(fmt.format(value, locale));
        }
        data.push(rowData);
    }

    return data;
}

function getExportColumns(
    defs: readonly NormalizedTableColumnConfig[],
): Array<NormalizedTableColumnConfig> {
    return defs.filter(col => col.type != "action" && !col.export.hidden);
}

async function exportSpreadsheet(
    exportCols: NormalizedTableColumnConfig[],
    exportData: any[][],
    type: ExportSpreadsheetFormat,
    _theme: ThemeInstance,
): Promise<Blob> {
    const xlsx = await import("xlsx");
    assertDefined(xlsx, "failed to import xlsx");
    const workbook = xlsx.utils.book_new();

    let data = exportData.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
            const col = exportCols[colIndex];
            // Only pro version supports images [https://sheetjs.com/pro]
            return rowIndex > 0 && typeof col !== "undefined" && col.type === "image" ? "" : cell;
        });
    });

    if (type === "csv") {
        data = data.map(row => {
            return row.map(cell => {
                return typeof cell === "string" ? cell.replace(/^([+\-@=])/, "'$1") : cell;
            });
        });
    }
    const ws = xlsx.utils.aoa_to_sheet(data);
    xlsx.utils.book_append_sheet(workbook, ws, "type");

    const options: WritingOptions = { bookType: type, bookSST: false, type: "array" };
    const spreadsheet = xlsx.write(workbook, options);

    return new Blob([spreadsheet], { type: "application/octet-stream" });
}

type ExportCellData = {
    type: ColumnType;
    data: string[] | null;
    height?: string | number;
} & CellDef;

function exportPdfRenderImages(doc: jsPDF) {
    return (opts: CellHookData): void => {
        const cell = opts.cell;
        const raw = cell.raw as ExportCellData;
        if (cell.section === "body" && raw.type === "image" && raw.data !== null) {
            const y = cell.y + cell.padding("vertical") / 2;
            const rawHeight = !raw.height
                ? undefined
                : typeof raw.height == "string"
                ? parseFloat(raw.height)
                : raw.height;
            const height = (rawHeight ?? cell.height) - cell.padding("vertical");
            let lastWidth = 0;
            raw.data.forEach(img => {
                const image = new Image();
                image.decoding = "sync";
                image.src = img;
                void image.decode();
                const ratio = image.width / image.height;
                const width = isNaN(ratio) ? height : height * ratio;
                const x = cell.x + lastWidth;
                doc.addImage(img, x, y, width, height);
                lastWidth = width + 1;
            });
        }
    };
}

function exportPdfProcessCell(
    columnConfig: NormalizedTableColumnConfig,
    col: any,
    _rowIdx: number,
    colIdx: number,
): ExportCellData {
    if (columnConfig.type == "action") {
        throw Error("Unexpected action column");
    }
    const style = {};
    if (columnConfig.export?.minWidth) {
        style["minCellWidth"] = columnConfig.export.minWidth;
    }
    if (columnConfig.export?.width) {
        style["cellWidth"] = columnConfig.export.width;
    }
    let cellData: null | string[] = null;
    let cellContent = col;
    if (colIdx > 0 && columnConfig.type === "image" && col !== null) {
        style["minCellHeight"] = columnConfig.export?.height ?? 40;
        const indexes = [...col.matchAll(/data:image/gi)].map(a => a.index);
        indexes.forEach(start => {
            const end = col.indexOf('"', start);
            if (end >= 0) {
                if (cellData === null) {
                    cellData = [];
                }
                cellData.push(col.substring(start, end));
                cellContent = "";
            }
        });
    }

    return {
        rowSpan: 1,
        colSpan: 1,
        styles: style,
        content: cellContent,
        type: columnConfig.type ?? "text",
        data: cellData,
        height: columnConfig.export?.height,
    };
}

function exportPdfProcessRow(
    columns: NormalizedTableColumnConfig[],
    row: any[],
    rowIdx: number,
): ExportCellData[] {
    return row.map((col, colIdx) => {
        const columnConfig = columns[colIdx];
        assertDefined(columnConfig);
        return exportPdfProcessCell(columnConfig, col, rowIdx, colIdx);
    });
}

function exportPdfProcessRows(
    columns: NormalizedTableColumnConfig[],
    rows: any[][],
): ExportCellData[][] {
    return rows.map((row, rowIdx) => {
        return exportPdfProcessRow(columns, row, rowIdx);
    });
}

async function exportPdf(
    columns: NormalizedTableColumnConfig[],
    rows: any[][],
    _type: ExportPdfFormat,
    theme: ThemeInstance,
): Promise<Blob> {
    const jspdf = await import("jspdf");
    assertDefined(jspdf, "Failed to import jspdf");
    const autoTable = (await import("jspdf-autotable")).default;
    assertDefined(autoTable, "failed to load autotable");

    const pdf = jspdf.default;
    const doc = new pdf({
        orientation: "l",
    });

    const pdfData = exportPdfProcessRows(columns, rows);

    const header = pdfData.shift();
    assertDefined(header);

    autoTable(doc, {
        headStyles: { fillColor: theme.current.value.colors.primary },
        head: [header],
        styles: {
            fontSize: 9,
        },
        body: pdfData,
        didDrawCell: exportPdfRenderImages(doc),
    });

    return doc.output("blob");
}
