<template>
    <l-dialog v-if="show" v-model="show">
        <v-card>
            <v-toolbar :color="options.color" :title="title" dark>
                <v-btn color="on-primary" icon="$close" @click="cancel()" />
            </v-toolbar>
            <v-card-text v-show="!!message" class="pa-4">
                {{ message }}
            </v-card-text>
            <v-card-actions :class="actionsClass">
                <v-btn
                    :block="display.mobile.value"
                    color="success"
                    type="button"
                    variant="outlined"
                    @click="agree"
                >
                    {{ options.confirmLabel }}
                </v-btn>
                <v-btn
                    :block="display.mobile.value"
                    type="button"
                    variant="outlined"
                    @click="cancel"
                >
                    {{ options.rejectLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </l-dialog>
</template>

<script>
/* eslint-disable no-irregular-whitespace */
import LDialog from "./LDialog.vue";
import { VBtn, VCard, VCardActions, VCardText, VToolbar } from "vuetify/components";
import { useDisplay, useTheme } from "vuetify";
import { useI18n } from "vue-i18n";

/**
 * Displays a promise based confirmation dialog.
 *
 * @example
 * ```vue
 *     <template>
 *       <l-confirm ref="confirm" />
 *     </template>
 *     <script>
 *         export default {
 *             methods: {
 *                 async example() {
 *                     let accepted = await this.$refs.confirm.open('Title', 'Message');
 *                     if (accepted) {
 *                         // do something
 *                     }
 *
 *                 }
 *             }
 *         }
 *     </​script>
 *  ```
 *
 *  @todo :color should not be needed on the close button
 */
export default {
    components: {
        LDialog,
        VCard,
        VToolbar,
        VCardText,
        VCardActions,
        VBtn,
    },
    setup() {
        const theme = useTheme();
        const { t } = useI18n();
        const { mobile } = useDisplay();
        return {
            t,
            display: { mobile },
            onPrimary: theme.current.value.colors["on-background"],
        };
    },
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: "primary",
                width: 290,
                confirmLabel: this.t("lumui.confirm.accept"),
                rejectLabel: this.t("lumui.confirm.reject"),
            },
        };
    },
    computed: {
        show: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.dialog = value;
                if (value === false) {
                    this.cancel();
                }
            },
        },
        actionsClass() {
            return {
                "flex-column": this.display.mobile.value,
                "flex-wrap": !this.display.mobile.value,
                mobile: this.display.mobile.value,
                "form-actions": true,
                "pl-2": !this.display.mobile.value,
                "pt-0": true,
            };
        },
    },
    methods: {
        /**
         * @param {string} title
         * @param {string} message
         * @param {object} options default {color: "primary", width: 290, zIndex: 200}
         * @return {Promise}
         */
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign({}, this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        /** @private */
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        /** @private */
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
