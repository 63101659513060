<template>
    <v-card>
        <v-card-title>Card 1</v-card-title>
        <v-card-text style="height: 500px">
            <v-btn @click="infoVisible1 = !infoVisible1"> Toggle 1 </v-btn>
        </v-card-text>

        <v-navigation-drawer
            v-model="infoVisible1"
            class="elevation-4 overflow-hidden"
            disable-resize-watcher
            disable-route-watcher
            location="right"
        >
            <h1>Number 1</h1>
        </v-navigation-drawer>
    </v-card>

    <v-card>
        <v-card-title>Card 1</v-card-title>
        <v-card-text style="height: 500px">
            <v-btn @click="infoVisible2 = !infoVisible2"> Toggle 2 </v-btn>
        </v-card-text>
        <v-navigation-drawer
            v-model="infoVisible2"
            class="elevation-4 overflow-hidden"
            disable-resize-watcher
            disable-route-watcher
            location="right"
        >
            <h1>Number 2</h1>
        </v-navigation-drawer>
    </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { VCard, VCardText, VCardTitle, VBtn, VNavigationDrawer } from "vuetify/components";

const infoVisible1 = ref(false);
const infoVisible2 = ref(false);
</script>
