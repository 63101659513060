<template>
    <v-window v-if="hasTabs" :model-value="activeTab">
        <v-window-item v-for="(tab, key) in tabs" :key="key" :value="'tab-' + key" eager>
            <l-form-rows
                :config="tab.children"
                :form-values="formValues"
                :value="value"
                @partial-update:value="e => $emit('partial-update:value', e)"
                @partial-update:config="
                    e => $emit('partial-update:config', { [key]: { children: e } })
                "
            />
            <v-divider />
        </v-window-item>
    </v-window>
</template>

<script lang="ts">
import LFormRows from "./LFormRows.vue";
import { VWindow, VWindowItem, VDivider } from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import { type FormConfig, type FormConfigTab, isFormConfigTab } from "../config/Form";
import type { EvalRecord } from "../lib/EvalRecord";

/**
 * renders the content of children with type "tab" containing the grand children.
 * If no tabs exists, nothing is rendered.
 *
 * ## config
 *
 * an object whose elements are element configs
 *
 * @example
 * ```json
 * {
 *   tab1: {
 *     type: "tab",
 *     label: "Tab A",
 *     children: {
 *       a1: {type: "text", label: "Vorname", required: true},
 *       a2: {type: "text", label: "Nachname", required: true},
 *       a3: {type: "email", label: "E-Mail"},
 *       a4: {type: "textarea", label: "Adresse"},
 *     }
 *   },
 *   tab2: {
 *     type: "tab",
 *     label: "Tab B",
 *     children: {
 *       b0: {type: "text", label: "Error", errors: ["error from config"]},
 *       b1: {type: "textarea", label: "Anmerkungen", required: true},
 *     }
 *   },
 * }
 * ```
 */
export default defineComponent({
    components: {
        LFormRows,
        VWindow,
        VWindowItem,
        VDivider,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: Object,
            required: true,
        },
        formValues: {
            type: Object as PropType<EvalRecord<any>>,
            required: true,
        },
        /** element config */
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
        },
        /** id of the active tab */
        activeTab: {
            type: String,
            required: true,
        },
        /** unused */
        color: {
            type: String,
            required: false,
            default: "white",
        },
        /** background color for the tab header */
        backgroundColor: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    emits: ["partial-update:value", "partial-update:config"],
    data() {
        return {
            active: true,
            valid: false,
        };
    },
    computed: {
        tabs(): Record<string, FormConfigTab> {
            const res: Record<string, FormConfigTab> = {};
            for (const i in this.config) {
                const el = this.config[i];
                if (isFormConfigTab(el)) {
                    res[i] = el;
                }
            }
            return res;
        },
        hasTabs(): boolean {
            return Object.keys(this.tabs).length > 0;
        },
    },
    watch: {
        activeTab(val) {
            this.active = val;
        },
    },
});
</script>
