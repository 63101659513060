<template>
    <test-form v-model="value" v-model:config="config" title="HTML Options" />
</template>

<script lang="ts" setup>
import TestForm from "../../components/TestForm.vue";
import { ref } from "vue";
import { type FormConfig } from "../../../src/config/Form";

const value = ref({
    foo: "bar",
});
const config = ref<FormConfig>({
    users: {
        type: "select",
        label: "Teilnehmer",
        chips: true,
        html_options: true,
        value: ["1", "2"],
        required: false,
        disabled: false,
        multiple: true,
        multiOptions: [
            {
                label: '<span style="color: green">Lum, Admin</span>',
                value: "1",
            },
            {
                label: '<span style="color: red">Reporter, Harry</span>',
                value: "2",
            },
        ],
    },
    radios: {
        type: "radio",
        label: "Radio",
        multiOptions: [
            {
                label: '<span style="color: green;">Green</span>',
                value: "1",
            },
            {
                label: '<span style="color: red;">Red</span>',
                value: "2",
            },
        ],
    },
    html: {
        type: "html",
        label: "Labeled",
        value: '<div><strong>strong </strong> non blinky <span style="color: red;"> red </span> Lorem ipsum dolor sit amet</div>',
    },
});
</script>
