<template>
    <v-card-text>
        <v-form>
            <v-container :fluid="true" grid-list-lg>
                <v-row wrap>
                    <template v-for="f in definitions" :key="f.key">
                        <v-col
                            v-if="!(f.hidden ?? false) && (f.searchable ?? false)"
                            class="xs"
                            lg="4"
                        >
                            <component
                                :is="getFilterComponent(f)"
                                :config="f"
                                :value="values[f.key] ?? undefined"
                                @update:model-value="(e: unknown) => set(f.key, e)"
                            />
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </v-form>
    </v-card-text>
    <v-card-actions class="text-right">
        <v-spacer />
        <v-btn :disabled="!active" color="primary" @click="clear()">{{
            t("lumui.table.reset_filters")
        }}</v-btn>
        <v-btn color="primary" @click="emit('filter:close')">{{
            t("lumui.table.close_filters")
        }}</v-btn>
    </v-card-actions>
</template>

<script lang="ts" setup>
import { useFilter } from "./table/filter";
import { type DeepReadonly } from "vue";
import LTableFilterBool from "./LTableFilterBool.vue";
import LTableFilterDate from "./LTableFilterDate.vue";
import LTableFilterText from "./LTableFilterText.vue";
import LTableFilterSelect from "./LTableFilterSelect.vue";
import LTableFilterNestedSet from "./LTableFilterNestedSet.vue";
import type { TableFilterConfig } from "../config/Table";
import { useI18n } from "vue-i18n";
import LTableFilterMaterializedPath from "./LTableFilterMaterializedPath.vue";
import {
    VCardText,
    VForm,
    VContainer,
    VRow,
    VCol,
    VCardActions,
    VSpacer,
    VBtn,
} from "vuetify/components";

const emit = defineEmits(["filter:close"]);
const { definitions, values, set, clear, active } = useFilter();
const { t } = useI18n();

function getFilterComponent(config: DeepReadonly<TableFilterConfig>) {
    if (!("filter" in config) || !config.filter) {
        if (config.type === "bool") {
            return LTableFilterBool;
        } else if (config.type === "date") {
            return LTableFilterDate;
        } else {
            return LTableFilterText;
        }
    }
    if (typeof config.filter === "string") {
        console.warn(
            "LTableFilter: Deprecation: Use 'type' instead of 'filter' to set the filter type",
        );
        const filterName = config.filter.toLowerCase();
        if (filterName === "bool") {
            return LTableFilterBool;
        } else if (filterName === "date") {
            return LTableFilterDate;
        } else {
            console.error("LTableFilter: Unknown filter type");
        }
    } else {
        if ("select" in config.filter) {
            return LTableFilterSelect;
        } else if ("date" in config.filter) {
            return LTableFilterDate;
        } else if ("nestedset" in config.filter) {
            return LTableFilterNestedSet;
        } else if ("materialized_path" in config.filter) {
            return LTableFilterMaterializedPath;
        }
    }
    return LTableFilterText;
}
</script>
