<template>
    <div class="table-container">
        <v-card v-if="showFilter" elevation="0" variant="flat">
            <slot
                :close-filter="() => emit('filter_closed')"
                :filter-values="filter.values.value"
                :filters="filter.definitions.value"
                :reset-filter="filter.clear"
                :set-filter="filter.set"
                name="filter"
            >
                <l-table-filter @filter:close="() => emit('filter_closed')" />
            </slot>
        </v-card>

        <!-- l-table-filter-active-warning :show="filter.active.value && !showFilter" / -->

        <v-table
            :class="['l-table', { 'l-table-loading': loading }]"
            :fixed-footer="true"
            :fixed-header="true"
            height="500"
        >
            <template #wrapper>
                <div
                    ref="container"
                    class="v-table__wrapper"
                    style="height: 500px"
                    @scroll="e => virtual.scrollHandler(e)"
                >
                    <table ref="tableRef" style="min-height: 500px">
                        <l-table-header
                            :column-count="columnCount"
                            :columns="columns"
                            :have-actions="haveActions"
                            :loading="loading"
                        >
                            <template
                                v-for="([name], key) in headerSlots"
                                :key="key"
                                #[name]="scope"
                            >
                                <slot :name="name" v-bind="scope || {}" />
                            </template>
                        </l-table-header>
                        <l-table-rows
                            :actions="actions"
                            :column-count="columnCount"
                            :columns="columns"
                            :loading="loading"
                            :no-data="noData"
                            :no-results="noResults"
                            :selectable="showSelect"
                            :visible-columns="visibleColumns"
                            @row:height="(row, height) => virtual.itemResizeHandler(row, height)"
                            @row:clicked="row => rowClicked(row)"
                            @row:action="action => rowAction(action)"
                        >
                            <template v-for="([name], key) in rowSlots" :key="key" #[name]="scope">
                                <slot :name="name" v-bind="scope || {}" />
                            </template>
                            <template #expanded-item="scope">
                                <slot name="expanded-item" v-bind="scope || {}"></slot>
                            </template>
                        </l-table-rows>
                        <l-table-footer
                            :hide-export="hideExport"
                            :export-formats="exportFormats"
                            :export-name="exportName"
                            :column-count="columnCount"
                            :columns="columns"
                            :loading="loading"
                        />
                    </table>
                </div>
            </template>
        </v-table>
        <l-table-details :actions="actions" :columns="columns" :loading="loading">
            <template #detailActions="scope">
                <slot name="detailActions" v-bind="scope" />
            </template>
            <template #detail="scope">
                <slot name="detail" v-bind="scope" />
            </template>
        </l-table-details>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    type DeepReadonly,
    type PropType,
    ref,
    type Slot,
    toRef,
    useSlots,
    watch,
} from "vue";
import {
    isTableColumnAction,
    type NormalizedTableColumnConfig,
    type NormalizedTableRowAction,
    type TableConfig,
    type TableNormalizedOptions,
    type TableOptions,
    type TableRow,
} from "../config/Table";
import { provideFilter, useFilteredRows } from "./table/filter";
import LTableHeader from "./LTableHeader.vue";
import LTableDetails from "./LTableDetails.vue";
import LTableRows from "./LTableRows.vue";
import LTableFilter from "./LTableFilter.vue";
import LTableFooter from "./LTableFooter.vue";
import { provideSort, useSortedRows } from "./table/sort";
import { provideCurrent } from "./table/current";
import { provideDetails } from "./table/details";
import { provideSelection } from "./table/selection";
import { provideExpansion } from "./table/expansion";
import type { LTableRowActionEvent } from "./LTableRow.vue";
import type Readonly from "../../test/views/form/readonly.vue";
import { provideVirtual } from "./table/virtual";
import { usePersistence } from "./table/persistence";
import { useSettings } from "../composables/Settings";
import { sandbox } from "../lib/Sandbox";
import {
    type ExportFormat,
    exportFormats as availableExportFormats,
    provideExporter,
} from "./table/exporter";
import { VCard, VTable } from "vuetify/components";

const props = defineProps({
    /**
     * Object containing the table data. Layout is roughly:
     * ```json
     * {
     *     columns: [],
     *     filters: [],
     *     rows: [],
     *     defaultSort: {
     *         sortBy: []
     *         sortDesc: []
     *     }
     * }
     * ```
     */
    data: {
        type: Object as PropType<TableConfig>,
        required: true,
        default: () => ({
            columns: [],
            filters: [],
            rows: [],
        }),
    },
    /** data is being loaded */
    loading: {
        type: Boolean,
        default: false,
    },
    /** expand search filters */
    showFilter: {
        type: Boolean,
        default: false,
    },
    /** disable side bar */
    infoDisabled: {
        type: Boolean,
        default: false,
    },
    /** disables table footer */
    hideFooter: {
        type: Boolean,
        default: false,
    },
    /** Filename to be used when export is downloaded */
    exportName: {
        type: String,
        default: "export",
    },
    exportFormats: {
        type: Array as PropType<ExportFormat[]>,
        default: () => availableExportFormats,
        validate(v: unknown) {
            if (!Array.isArray(v)) {
                console.error("LTable: exportFormats must be array");
                return false;
            }
            for (const f of v) {
                if (!availableExportFormats.includes(f)) {
                    console.error("LTable: invalid export format " + f);
                    return false;
                }
            }
            return true;
        },
    },
    /** show row expansion handle */
    showExpand: {
        type: Boolean,
        default: false,
    },
    /** show selectable */
    showSelect: {
        type: Boolean,
        default: false,
    },
    /** @deprecated */
    selected: {
        type: Array as unknown as PropType<never>,
        required: false,
        default: undefined,
        validator: (x: unknown) => {
            if (typeof x === "undefined") {
                return true;
            }
            console.error(
                "LTable: Use the exposed setSelection function to change the selection instead",
            );
            return false;
        },
    },
    /** @deprecated */
    disablePagination: {
        type: Boolean,
        default: false,
        validate() {
            console.warn("LTable: Deprecation: disablePagination is no longer used");
            return true;
        },
    },
    /**
     * identifier for storing grid state in local storage. Per default a
     * key is generated based on the current route.
     */
    storageKey: {
        type: String,
        default: null,
        required: false,
    },
    /** overrides $lumui.table.persistentFilters */
    filtersNonPersistent: {
        type: Boolean,
        default: null,
    },
    /** disables export buttons */
    hideExport: {
        type: Boolean,
        default: false,
    },
    /** @deprecated */
    itemsPerPageOptions: {
        type: Array,
        default: undefined,
        validate() {
            console.warn("LTable: Deprecation: itemsPerPageOptions in no longer used");
        },
    },
});

const settings = useSettings();

const emit = defineEmits({
    filter_closed: () => true,
    filter_resetted: () => true,
    filter: (_values: Record<string, any>) => true,
    select: (_rows: ReadonlyArray<DeepReadonly<TableRow>>) => true,
    expand: (_rows: ReadonlySet<DeepReadonly<TableRow>>) => true,
    /** @deprecated use row:action instead */
    rowaction: (_action: LTableRowActionEvent) => true,
    "row:action": (_action: LTableRowActionEvent) => true,
    "row:clicked": (_row: DeepReadonly<TableRow>) => true,
});

function normalizeOptions(options: TableOptions | undefined): TableNormalizedOptions | undefined {
    if (options == undefined) {
        return options;
    }
    if (Array.isArray(options)) {
        return options;
    }
    const normalized: TableNormalizedOptions = [];
    for (const [key, option] of Object.entries(options)) {
        if (option == undefined) {
            continue;
        }
        if (typeof option == "object") {
            const text =
                "text" in option
                    ? String(option.text)
                    : "label" in option
                    ? String(option.label)
                    : "???";
            const value = "value" in option ? option.value : key;
            const color = "color" in option ? String(option.color) : undefined;
            normalized.push({ text, value, color });
        } else if (typeof option == "string") {
            normalized.push({ text: option, value: key });
        } else {
            console.error("LTable: Invalid option", option);
        }
    }
    return normalized;
}

const columns = computed<readonly NormalizedTableColumnConfig[]>(() => {
    const result = new Array<NormalizedTableColumnConfig>();
    if (!props.data.columns) {
        return result;
    }

    const i = 0;
    for (const c of props.data.columns) {
        if (isTableColumnAction(c)) {
            continue;
        }
        const column = Object.assign(
            {
                key: "__col_" + i + "__",
                label: "",
                align: "left",
                hidden: false,
                slot: c.hidden !== true ? "item." + c.key : null,
                sortable: c.hidden !== true,
                formatter: c.formatter,
                type: "actions" in c ? "action" : "text",
                options: c.type == "checkbox" || c.type == "select" ? {} : undefined,
            },
            c,
            { options: normalizeOptions(c.options) },
        );
        const details = Object.assign(
            {
                label: column.label,
                key: column.key,
                align: column.align,
                hidden: column.hidden,
                type: column.type,
                formatter: column.formatter,
                options: column.options,
            },
            "details" in c ? c.details : {},
            { options: normalizeOptions(c.details?.options ?? column.options) },
        );

        const exp = Object.assign(
            {
                label: column.label,
                key: column.key,
                align: column.align,
                hidden: column.hidden,
                type: column.type,
                formatter: column.formatter,
                options: column.options,
            },
            "export" in c ? c.export : {},
            { options: normalizeOptions(c.export?.options ?? column.options) },
        );
        // @todo fix typings
        result.push({
            ...column,
            details,
            export: exp,
        } as NormalizedTableColumnConfig);
    }
    return result;
});

const visibleColumns = computed(() => columns.value.filter(x => !x.hidden));

/**
 * the number of columns rendered in the table
 */
const columnCount = computed(() => {
    let n = visibleColumns.value.length;
    if (expansion.enabled.value || details.enabled.value || haveActions.value) {
        n++;
    }
    if (selection.enabled.value) {
        n++;
    }
    return n;
});

const actions = computed<Record<string, NormalizedTableRowAction>>(() => {
    const col = props.data.columns.find(x => "actions" in x);
    if (col && "actions" in col) {
        const actions: Record<string, NormalizedTableRowAction> = {};
        for (const [k, a] of Object.entries(col.actions)) {
            const visible = a.visible
                ? typeof a.visible == "string"
                    ? sandbox(a.visible)
                    : Boolean(a.visible)
                : true;
            const condition = a.condition
                ? typeof a.condition == "string"
                    ? sandbox(a.condition)
                    : Boolean(a.condition)
                : true;

            actions[k] = {
                ...a,
                visible,
                condition,
            };
        }
        return actions;
    } else {
        return {};
    }
});

const haveActions = computed(() => {
    return Object.keys(actions.value).length > 0;
});

const filterConfig = computed(() => {
    return props.data?.filter ?? [];
});

const rows = toRef(() => props.data?.rows ?? []);

const noData = computed(() => rows.value.length == 0);

const storageKey = toRef(props, "storageKey");
watch(
    () => props.storageKey,
    () => {
        console.error("LTable: Prop storageKey may not change after initialisation");
    },
);

const persistent = computed(() => {
    if (props.filtersNonPersistent != undefined) {
        return !props.filtersNonPersistent;
    }
    return settings.table.persistentFilters;
});
watch(
    () => props.filtersNonPersistent,
    () => {
        console.error("LTable: Prop filtersNonPersistent may not change after initialisation");
    },
);

const filter = provideFilter(columns, filterConfig);
const filteredRows = useFilteredRows(rows, filter.values, filter.functions);
const noResults = computed(() => filteredRows.value.length == 0);

watch(filter.values, filter => {
    if (Object.keys(filter).length == 0) {
        emit("filter_resetted");
    }
    emit("filter", filter);
});

const sort = provideSort(columns);
const filteredAndSortedRows = useSortedRows(filteredRows, sort.criteria, sort.functions);

const container = ref<HTMLElement>();
const virtual = provideVirtual(container, filteredAndSortedRows);

const current = provideCurrent(filteredAndSortedRows);
const slots = useSlots();

const detailsEnabled = computed(() => !props.infoDisabled);
const details = provideDetails(detailsEnabled, current.row, columns);

function findSlots(slots: Readonly<Record<string, Slot | undefined>>, prefix: string) {
    const res = new Map<string, Slot>();
    for (const name in slots) {
        if (!name.startsWith(prefix)) {
            continue;
        }
        const slot = slots[name];
        if (slot === undefined) {
            continue;
        }
        res.set(name, slot);
    }
    return res;
}

const selection = provideSelection(
    toRef(props, "showSelect"),
    rows,
    (rows: ReadonlyArray<TableRow>) => emit("select", rows),
);
const expansion = provideExpansion(
    toRef(props, "showExpand"),
    rows,
    (rows: ReadonlySet<TableRow>) => emit("expand", rows),
);
const _exporter = provideExporter(columns, filteredAndSortedRows);

const rowSlots = computed(() => findSlots(slots, "col_"));

const headerSlots = computed(() => findSlots(slots, "header_"));

if (persistent.value) {
    usePersistence(storageKey.value, filter, sort, virtual);
}

function rowClicked(row: DeepReadonly<TableRow>) {
    current.set(row);
    if (details.enabled.value) {
        details.show();
    } else if (expansion.enabled.value) {
        expansion.toggle(row);
    }
    emit("row:clicked", row);
}

function rowAction(action: { name: string; params: Record<string, any> }) {
    emit("rowaction", action);
    emit("row:action", action);
}

defineExpose({
    setFilter(key: string, value: unknown) {
        console.warn("LTable: setFilter is deprecated use filter.set instead");
        filter.set(key, value);
    },
    clearFilter(key?: string) {
        console.warn("LTable: clearFilter is deprecated use filter.clear instead");
        filter.clear(key);
    },
    filter: {
        set: filter.set,
        clear: filter.clear,
    },
    selection: {
        set: selection.set,
        selectAll: selection.addAll,
        clearSelection: selection.removeAll,
    },
    expansion: {
        set: expansion.set,
        expandAll: expansion.expandAll,
        collapseAll: expansion.collapseAll,
    },
});
</script>

<style lang="scss" scoped>
@use "vuetify/settings";

.v-table__wrapper {
    overscroll-behavior: none;
    scroll-snap-type: none;
    overscroll-behavior-x: contain;
    transition-property: none !important;

    table {
        transition-property: none !important;
    }
}

.l-table {
    position: relative;
    background-color: settings.$table-background;
}
</style>
