<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="sanitized" />
</template>

<script lang="ts" setup>
import HtmlSanitizer from "../lib/HtmlSanitizer.js";
import { computed } from "vue";

const props = defineProps<{ content: string }>();
const sanitized = computed(() => HtmlSanitizer.SanitizeHtml(props.content));
</script>
