<template>
    <v-text-field
        v-if="isVisible"
        :id="id"
        :class="[config.class ?? '', { required: isRequired }]"
        :clearable="config.clearable || false"
        :counter="config.maxLength || undefined"
        :disabled="isDisabled"
        :error="error"
        :error-messages="aggregatedErrors"
        :hide-details="config.hideDetails || false"
        :hint="config.description || undefined"
        :label="theLabel"
        :model-value="modelValue"
        :name="name"
        :placeholder="config.placeholder || undefined"
        :prefix="config.prefix || undefined"
        :rules="validationRules"
        :suffix="config.suffix || undefined"
        :type="config.type"
        autocomplete="off"
        @error="updateError"
        @update:model-value="e => $emit('update:modelValue', e)"
    />
</template>

<script lang="ts">
import { VTextField } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigEmail } from "../config/Form";
import { formConfigEmailSchema } from "../config/Form.zod";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

/**
 * #### Config
 *
 *
 * | key                    | type                       | required | default     | description |
 * |------------------------|----------------------------|----------|-------------|-------------|
 * | type                   | `String`                   | yes      |             | field type  |
 * | label                  | `String`, `false`          | no       | `false`     | fields label |
 * | description            | `String`                   | no       | `""`        | field description |
 * | class                  | `String`                   | no       | `null`      | css class for custom styling |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`     | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`     | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`     | field is rendered.  |
 * | prefix                 | `String`                   | no       | `undefined` | Adds prefix to field. |
 * | suffix                 | `String`                   | no       | `undefined` | Adds suffix to field. |
 * | maxLength              | `Number`                   | no       | `false`     | Limit input to maxLength chars. Also display length counter. |
 * | placeholder            | `String`                   | no       | `""`        | placeholder displayed inside the field, when value is empty |
 * | hideDetails            | `String`, `Bool`           | no       | `undefined` | When set to "auto" details are only rendered when necessary
 */
export default defineComponent({
    components: {
        VTextField,
    },
    props: {
        ...makeDefaultProps<FormConfigEmail>(formConfigEmailSchema),
        modelValue: {
            type: String,
            required: false,
            default: undefined,
        },
        type: {
            type: String,
            required: false,
            default: "text",
            validator: (v: string) => {
                return ["text", "number", "email"].indexOf(v) != -1;
            },
        },
    },
    emits: ["update:modelValue", "update:config", "update:error"],
    setup(props, { emit }) {
        const { t } = useI18n();
        return {
            t,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            viewValue: "",
        };
    },
    computed: {
        validationRules() {
            if (this.rules != null) {
                return this.rules;
            }
            const maxLength =
                typeof this.config.maxLength == "string"
                    ? Number.parseInt(this.config.maxLength)
                    : this.config.maxLength ?? false;
            const required = this.isRequired;
            return [
                (v: unknown) =>
                    !required ||
                    (typeof v === "string" && v.length > 0) ||
                    this.t("lumui.form.row.required"),
                (v: unknown) =>
                    !maxLength ||
                    (typeof v === "string" && v.length <= maxLength) ||
                    this.t("lumui.form.row.max_length", { maxLength: maxLength }),
                (v: unknown) =>
                    (typeof v === "string" &&
                        !!v.match(
                            /^\w+[\w.+-]*@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/,
                        )) ||
                    this.t("lumui.form.row.invalid_email"),
            ];
        },
    },
    watch: {
        model() {
            if (this.config.errors?.length ?? 0 > 0) {
                this.$emit("update:config", { ...this.config, errors: [] });
            }
        },
    },
    methods: {
        /* @private */
        updateError(e) {
            /**
             * validation error detected
             */
            this.$emit("update:error", e);
        },
    },
});
</script>
