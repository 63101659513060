<template>
    <test-form
        v-for="(_,key) in forms"
        :key="key"
        v-model:config="forms[key]"
        v-model="values[key]"
        :title="'Number example ' + key"
    />
</template>

<script>

import TestForm from "../../components/TestForm.vue";

export default {
    name: "Radio",
    components: {TestForm},
    data() {
        return {
            values: {
                a: {p: "4"},
                b: {p: "-6"},
                c: {p: "980"}
            },
            forms: {
                a: {
                    "p": {
                        "type": "number",
                        "label": "Fließkommazahl",
                        "value": "",
                        "required": true,
                        "step": 0.1,
                        "min": 5,
                        "max": 1239
                    }
                },
                b: {
                    "p": {
                        "type": "number",
                        "label": "Fließkommazahl",
                        "value": "",
                        "required": true,
                        "step": 0.5,
                        "min": 10
                    }
                },
                c: {
                    "p": {
                        "type": "number",
                        "label": "Fließkommazahl",
                        "value": "",
                        "required": true,
                        "step": 1.5,
                        "max": 1000
                    }
                }
            }
        };
    }
}
</script>

