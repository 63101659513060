<template>
    <test-form v-model="value" :config="config" title="Time" />
</template>

<script lang="ts" setup>
import TestForm from "../../components/TestForm.vue";
import { ref } from "vue";
import type { FormConfig } from "../../../src/config/Form";

const value = ref({
    t1: "4:20",
});

const config = ref<FormConfig>({
    t1: { label: "Time", type: "time" },
    t2: { label: "Time", type: "time", value: "4:20" },
    t3: { label: "Time", type: "time", value: "23:59" },
    t4: { label: "Time", type: "time", value: "00:00" },
});
</script>
