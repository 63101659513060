import {
    computed,
    type DeepReadonly,
    inject,
    type InjectionKey,
    provide,
    readonly,
    ref,
    type Ref,
} from "vue";
import {
    type NormalizedTableColumnConfig,
    type TableFilterConfig,
    type TableRow,
} from "../../config/Table";
import {
    filterBoolean,
    filterDate,
    filterDefault,
    filterLocalized,
    filterMaterializedPath,
    filterNestedSet,
    filterSelect,
    filterStatus,
    filterUnknown,
} from "../../lib/filter";
import FormatterFactory from "../../lib/formatter";
import { nonReactiveClone } from "../../lib/nonReactiveClone";
import { useI18n } from "vue-i18n";

function getFilterDefaults(col: NormalizedTableColumnConfig) {
    const filter = {
        defaultFilter: true,
        key: col.key,
        label: col.label,
        type: col.type,
        searchable: true,
        hidden: false,
    };
    switch (col.type) {
        case "select":
            filter["filter"] = {
                select: {
                    options: col.options,
                },
            };
            break;
        case "checkbox":
            filter["filter"] = {
                select: {
                    multiple: true,
                    options: col.options,
                },
            };
            break;
        case "date":
            filter["filter"] = {
                date: {
                    range: true,
                },
            };
            break;
    }
    return filter;
}

function filterRows(
    rows: DeepReadonly<TableRow[]>,
    filterValues: DeepReadonly<Record<string, unknown>>,
    filterFunctions: DeepReadonly<Record<string, (_v: any, _fv: any) => boolean>>,
) {
    const definedFilterValues = Object.fromEntries(
        Object.entries(filterValues).filter(([_, v]) => !isEmptyFilterValue(v)),
    );
    if (Object.keys(definedFilterValues).length == 0) {
        return [...rows];
    }
    return rows.filter(row => {
        try {
            for (const key of Object.getOwnPropertyNames(definedFilterValues)) {
                const filterValue = filterValues[key];
                const filterFunc = filterFunctions[key];
                if (!filterFunc || typeof filterFunc !== "function") {
                    console.error("table/filter: No filter for " + key);
                    continue;
                }

                const value = row[key];
                if (isEmptyFilterValue(value)) {
                    return false;
                }

                if (!filterFunc(value, filterValue)) {
                    return false;
                }
            }
            return true;
        } catch (e) {
            console.error("table/filter: error filtering", row, e);
            return true;
        }
    });
}

export function isEmptyFilterValue(x: unknown) {
    return x === null || x === undefined || x === "" || (Array.isArray(x) && x.length == 0);
}

export function provideFilter(
    columns: Readonly<Ref<readonly NormalizedTableColumnConfig[]>>,
    filterConfig: Readonly<Ref<TableFilterConfig[]>>,
): Filter {
    const i18n = useI18n();
    const definitions = computed<readonly TableFilterConfig[]>(() => {
        const defaults = {};
        const filters: TableFilterConfig[] = [];
        for (const col of columns.value) {
            if (col.type == "time") {
                continue;
            }

            defaults[col.key] = getFilterDefaults(col);
        }
        for (const filter of filterConfig.value) {
            if (filter.key == undefined) {
                console.error("table/filter: Ignoring filter because it has no key", filter);
                continue;
            }
            const searchable = filter.searchable ?? true;
            if (!searchable) {
                continue;
            }
            let type = filter.type;
            if (type == undefined && "filter" in filter && filter.filter !== undefined) {
                if (typeof filter.filter != "string") {
                    if ("select" in filter.filter) {
                        type = "select";
                    } else if ("date" in filter.filter) {
                        type = "date";
                    }
                }
            }
            filters.push({
                ...defaults[filter.key],
                ...filter,
                ...(type ? { type } : {}),
                ...{ searchable },
            });
        }
        return filters;
    });

    function getDefinition(key: string): TableFilterConfig | undefined {
        return definitions.value.find(x => x.key == key);
    }

    const localizedFormatter = computed(() => {
        return FormatterFactory("Localized", { locale: i18n.locale.value });
    });

    function localize(x: Record<string, string>): string {
        return localizedFormatter.value.format(x);
    }

    const functions = computed<Record<string, (_v: unknown, _fv: string) => boolean>>(() => {
        const result = {};
        for (const filter of definitions.value) {
            if (!filter.key || filter.hidden) {
                continue;
            }
            const fu = (v: any, fv: any) => filterUnknown(v, fv, localize);
            const lf = (v: Record<string, string>, fv: string) => filterLocalized(v, fv, localize);
            const filterFuncs = {
                status: filterStatus,
                date: filterDate,
                localized: lf,
                select: filterSelect,
                boolean: filterBoolean,
                text: filterDefault,
                nestedset: filterNestedSet,
                materialized_path: filterMaterializedPath,
                default: fu,
            };
            result[filter.key] = filterFuncs[filter.type ?? "default"] ?? filterFuncs["default"];
        }
        return result;
    });

    const values: Ref<Record<string, unknown>> = ref<Record<string, unknown>>({});

    function set(key: string, value: unknown) {
        const newValues = nonReactiveClone(values.value);
        if (value == null) {
            delete newValues[key];
        } else {
            newValues[key] = value;
        }
        values.value = newValues;
    }

    function clear(key?: string) {
        if (key) {
            set(key, null);
        }
        values.value = {};
    }

    const active = computed(() => {
        return Object.values(values.value).some(val => !isEmptyFilterValue(val));
    });

    const data: Filter = {
        filterRows,
        values: readonly(values),
        definitions,
        functions,
        active,
        getDefinition,
        set,
        clear,
    };

    provide(injectionKey, data);
    return data;
}

export type Filter = {
    filterRows: typeof filterRows;
    values: Readonly<Ref<DeepReadonly<Record<string, unknown>>>>;
    definitions: Readonly<Ref<ReadonlyArray<TableFilterConfig>>>;
    functions: DeepReadonly<Ref<Record<string, (_v: any, _fv: any) => boolean>>>;
    active: DeepReadonly<Ref<boolean>>;
    getDefinition: (key: string) => TableFilterConfig | undefined;
    set(_key: string, _value: unknown): void;
    clear(_key?: string): void;
};
const injectionKey = Symbol.for("LumUi:LTable:filter") as InjectionKey<Filter>;

export function useFilter() {
    const filter = inject(injectionKey);
    if (!filter) {
        throw Error("provideFilter must be called in a parent component");
    }
    return filter;
}

export function useFilteredRows(
    rows: Readonly<Ref<ReadonlyArray<TableRow>>>,
    values: DeepReadonly<Ref<Record<string, unknown>>>,
    filterFunctions: DeepReadonly<Ref<Record<string, (_v: any, _fv: any) => boolean>>>,
) {
    return computed(() => {
        return filterRows(rows.value, values.value, filterFunctions.value);
    });
}
