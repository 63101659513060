<template>
    <test-form v-model:config="config" v-model="value" title="Localized" :loading="false" @save="addError" />
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "FormLocalized",
    components: { TestForm },
    data() {
        return {
            value: {},
            config: {
                f1: {
                    type: 'localized',
                    label: 'Localized w/error',
                    child: 'text',
                    languages: ['de', 'en'],
                    required: true
                },
                f2: {
                    type: 'localized',
                    label: 'Localized w/default langs'
                },
                // f3: {
                //     type: 'localized',
                //     label: 'WYSIWYG',
                //     child: 'wysiwyg',
                //     required: true
                // }
            }
        }
    },
    methods: {
        addError() {
            this.$set(this.config.f1, 'errors', ['Error'])
        }
    },
}
</script>
