<template>
    <div>
        <test-form
            v-for="(_,idx) in configs"
            :key="idx"
            v-model="values[idx]"
            v-model:config="configs[idx]"
            :title="titles[idx]"
        />
    </div>
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    name: "Radio",
    components: { TestForm },
    data() {
        return {
            titles: [
                "example A",
                "example B",
                "example C"
            ],
            values: [
                { p: "4" },
                { p: "-6" },
                { p: "980" }
            ],
            configs: [
                {
                    p: {
                        type: "number",
                        label: "Ganzzahl",
                        value: "",
                        required: true,
                        step: 1,
                        min: 5,
                        max: 1239,
                        clearable: true
                    }
                },
                {
                    p: {
                        type: "number",
                        label: "Ganzzahl",
                        value: "",
                        required: true,
                        step: 5,
                        min: 10
                    }
                },
                {
                    p: {
                        type: "number",
                        label: "Ganzzahl",
                        value: "",
                        required: true,
                        step: 10,
                        max: 1000
                    }
                }
            ]
        };
    }
}
</script>

