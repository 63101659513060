import base64ToBlob from "../base64ToBlob";

/**
 * This is a dummy implementation. Getting files is inherently project specific and each project should override
 * the file formatter with something appropriate.
 */
export class Files {
    format(value: Record<string, { title: string; src?: string }>) {
        let rtn = "";
        if (typeof value === "object" && value !== null) {
            for (const obj of Object.values(value)) {
                let link = "";
                if (obj.src) {
                    const base64 = base64ToBlob(obj.src);
                    link = `<a href="#" @click.prevent="saveAs('${base64}', '${obj.title}')>${obj.title}</a>`;
                } else {
                    link = `<span>${obj.title}</span>`;
                }
                rtn += link + `<br>`;
            }
        }
        return rtn;
    }
}
