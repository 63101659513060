<template>
    <v-card>
        <v-card-text>
            <v-autocomplete
                v-model="data"
                label="Autocomp w/ selection slot"
                class="ma-5"
                :single-line="true"
                :custom-filter="filter"
                :search="search"
                :items="items"
                @update:search="e => updateSearch(e)"
            />
            <pre class="mx-5">Value = {{ data }}</pre>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { VCard, VCardText, VAutocomplete } from "vuetify/components";

const data = ref(null);
const items = ref(generateOptions());
const search = ref("-");

function generateOptions() {
    const options: Array<{ value: number; title: string }> = [];
    for (let i = 0; i < 4000; i++) {
        options.push({ value: i, title: "options " + i });
    }
    return options;
}

function filter(value: string, query: string, item: any): boolean {
    if (query.length < 3) {
        return false;
    }
    return item.title.search(query);
}

function updateSearch(e) {
    search.value = e || "-";
}
</script>
