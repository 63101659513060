<template>
    <v-menu
        v-if="routes.length > 0 && (display.smAndDown.value || routes.length > iconMenuThreshold)"
    >
        <template #activator="menu">
            <v-btn :icon="!isMobile" v-bind="menu.props" variant="plain">
                <span v-if="isMobile">{{ t("lumui.table_action.menu") }}</span>
                <v-icon :end="isMobile">$moreVert</v-icon>
            </v-btn>
        </template>
        <v-list>
            <template v-for="route in routes">
                <v-list-item
                    v-if="'to' in route || 'action' in route"
                    :key="route.key"
                    :disabled="route.disabled"
                    :prepend-icon="prefixIcon(route.icon)"
                    @click="callAction(route)"
                >
                    <v-list-item-title>
                        {{ route.label }}
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
    <template v-else>
        <template v-for="route in routes">
            <v-tooltip v-if="'to' in route || 'action' in route" :key="route.key" location="bottom">
                <template #activator="tooltip">
                    <v-btn
                        :disabled="route.disabled"
                        :icon="prefixIcon(route.icon)"
                        :size="btnSize"
                        density="comfortable"
                        v-bind="tooltip.props"
                        variant="plain"
                        @click="callAction(route)"
                    />
                </template>
                <span>{{ route.label }}</span>
            </v-tooltip>
        </template>
    </template>
</template>

<script lang="ts">
import { VBtn, VIcon, VList, VListItem, VListItemTitle, VMenu, VTooltip } from "vuetify/components";
import { useDisplay } from "vuetify";
import { useRouter } from "vue-router";
import { defineComponent, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useSettings } from "../composables/Settings";
import { type EvalRecord } from "../lib/EvalRecord";
import type { NormalizedTableRowAction } from "../config/Table";

type Action =
    | {
          key: number;
          icon: string;
          label: string;
          to: { name: string; params: Record<string, string> };
          disabled: boolean;
      }
    | {
          key: number;
          icon: string;
          label: string;
          action: { name: string; params: Record<string, string> };
          disabled: boolean;
      };

/**
 * Used by LTable to render the action buttons.
 *
 * @private
 * @internal
 */
export default defineComponent({
    components: {
        VIcon,
        VBtn,
        VTooltip,
        VListItemTitle,
        VListItem,
        VList,
        VMenu,
    },
    props: {
        /** available actions */
        actions: {
            type: Object as PropType<Record<string, NormalizedTableRowAction>>,
            default: () => ({}),
        },
        row: {
            type: Object as PropType<Record<string, unknown>>,
            required: true,
        },
        /** current row */
        evalContext: {
            type: Object as PropType<EvalRecord<any>>,
            required: true,
        },
        /** button size */
        btnSize: {
            type: String,
            default: "small",
            validator(value: string) {
                return ["x-small", "small", "default", "large", "x-large"].includes(value);
            },
        },
        iconSize: {
            type: String,
            default: "default",
            validator(value: string) {
                return ["x-small", "small", "default", "large", "x-large"].includes(value);
            },
        },

        /** icon menu threshold (amount of icons > iconMenuThrs: render icons as menu */
        iconMenuThreshold: {
            type: Number,
            default: 8,
        },
    },
    emits: {
        rowaction: (_action: { name: string; params: Record<string, any> }) => true,
    },
    setup() {
        const router = useRouter();
        const { smAndDown, mobile } = useDisplay();
        const { t } = useI18n();
        const settings = useSettings();
        return {
            t,
            display: {
                smAndDown,
                mobile,
            },
            router,
            settings,
        };
    },
    data() {
        return {
            visible: false,
        };
    },
    computed: {
        isMobile() {
            return this.display.mobile.value;
        },
        visibleActions() {
            return Object.values(this.actions).filter(action =>
                action.visible ? this.evalBooleanExpr(action.visible) : true,
            );
        },
        routes(): Action[] {
            const rtn: Action[] = [];
            let idx = 0;
            this.visibleActions.forEach(action => {
                if (!(action.route || action.action)) {
                    return;
                }

                const params = {};
                if (action.param) {
                    Object.keys(action.param).forEach(key => {
                        const paramKey = action.param ? action.param[key] : undefined;
                        if (paramKey !== undefined) {
                            params[key] = this.row[paramKey];
                        } else {
                            throw new Error(
                                paramKey +
                                    " not found in row with keys " +
                                    Object.keys(this.row).join(", "),
                            );
                        }
                    });
                }

                let disabled: boolean = !this.evalBooleanExpr(action.condition);
                if (action.route) {
                    const route = {
                        key: idx++,
                        label: action.label as string,
                        icon: action.icon as string,
                        to: {
                            name: action.route,
                            params: params,
                        },
                        disabled: disabled,
                    };
                    rtn.push(route);
                } else if (action.action) {
                    const route = {
                        key: idx++,
                        label: action.label,
                        icon: action.icon,
                        action: {
                            name: action.action,
                            params: params,
                        },
                        disabled: disabled,
                    };
                    rtn.push(route);
                }
            });

            return rtn;
        },
    },
    methods: {
        evalBooleanExpr(attribute: unknown): boolean {
            switch (typeof attribute) {
                case "boolean":
                    return attribute;
                case "function":
                    return !!attribute(this.evalContext);
                default:
                    throw new Error("Unknown type in eval " + typeof attribute);
            }
        },
        /** @private */
        callAction(
            route:
                | { action: { name: string; params: Record<string, any> } }
                | { to: { name: string; params: Record<string, any> } },
        ) {
            if ("action" in route) {
                /**
                 * fired if the actions route has an `action` field defined
                 * @param {Object} action
                 */
                this.$emit("rowaction", route.action);
            } else if ("to" in route) {
                this.router.push(route.to);
            }
        },
        /** @private */
        prefixIcon(icon: string) {
            return icon.replace(/^fa-/, "fas fa-");
        },
    },
});
</script>
