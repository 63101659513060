<template>
    <tfoot>
        <tr>
            <th :colspan="columnCount" style="">
                <div class="d-flex justify-space-between">
                    <div>
                        <!-- placeholder for symmetry -->
                    </div>
                    <v-chip v-if="filter.active.value" color="primary" rounded>
                        <v-icon color="on-background" size="small" start> $search</v-icon>
                        <span class="mx-2">
                            {{ t("lumui.table.filters_active") }}
                        </span>
                        <v-tooltip :text="t('lumui.table.close_filters')">
                            <template #activator="{ props }">
                                <v-icon
                                    color="on-background"
                                    end
                                    size="small"
                                    v-bind="props"
                                    @click="filter.clear()"
                                >
                                    $clear
                                </v-icon>
                            </template>
                        </v-tooltip>
                    </v-chip>
                    <v-menu :disabled="exportLoading">
                        <template #activator="activator">
                            <v-btn
                                v-if="!hideExport"
                                v-bind="activator.props"
                                icon="$download"
                                variant="plain"
                                :loading="exportLoading"
                                :disabled="loading || exportLoading"
                            />
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(fmt, i) in exportFormats"
                                :key="i"
                                :title="fmt"
                                :value="fmt"
                                @click="doExport(exportName, fmt)"
                            />
                        </v-list>
                    </v-menu>
                </div>
            </th>
        </tr>
    </tfoot>
</template>

<script lang="ts" setup>
import type { NormalizedTableColumnConfig } from "../config/Table";
import { type ExportFormat, useExporter } from "./table/exporter";
import { ref } from "vue";
import { VChip, VIcon, VTooltip, VMenu, VBtn, VList, VListItem } from "vuetify/components";
import { useFilter } from "./table/filter";
import { useI18n } from "vue-i18n";

const _props = defineProps<{
    loading: boolean;
    columns: readonly NormalizedTableColumnConfig[];
    columnCount: number;
    hideExport: boolean;
    exportName: string;
    exportFormats: ExportFormat[];
}>();

const filter = useFilter();
const { t } = useI18n();
const { exportTableAs } = useExporter();
const exportLoading = ref(false);

async function doExport(exportName: string, fmt: ExportFormat) {
    try {
        exportLoading.value = true;
        await exportTableAs(exportName, fmt);
    } catch (e) {
        console.error("LTableFooter: error while exporting", e);
    } finally {
        exportLoading.value = false;
    }
}
</script>

<style lang="scss" scoped>
@use "vuetify/settings";

.l-table tfoot tr td,
.l-table tfoot tr th {
    background-color: settings.$table-background;
    color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
}

tfoot,
tfoot > tr,
tfoot > tr > td,
tfoot > tr > th {
    transition-property: none !important;
}
</style>
