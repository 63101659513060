import type { I18n } from "vue-i18n";
import moment from "moment";
import { type App, inject, type InjectionKey, type Plugin } from "vue";

export type TranslationMessages = { [key: string]: string | TranslationMessages };

export type AsyncTranslationLoader = () => Promise<TranslationMessages>;
export type TranslationLoader = AsyncTranslationLoader | TranslationMessages;
export type TranslationLoaders<Languages extends string> = Record<Languages, TranslationLoader>;

export type TranslationLoaderApi = { setLocale(l: string): boolean };

export const translationLoaderInjectionKey = Symbol.for(
    "LumUi:TranslationLoader",
) as InjectionKey<TranslationLoaderApi>;

export type TranslationLoaderOptions = {
    i18n: I18n<any, any, any, any, false>;
    loaders: TranslationLoaders<string>;
};

export function useTranslationLoader(): TranslationLoaderApi {
    const tl = inject(translationLoaderInjectionKey);
    if (!tl) {
        throw Error("TranslationLoader has not been provided");
    }
    return tl;
}

export function createTranslationLoaderPlugin<Locale extends string>(
    i18n: I18n<any, any, any, any, false>,
    loaders: TranslationLoaders<Locale>,
): {
    plugin: Plugin;
    setLocale(l: Locale): void;
} {
    const loaded = new Set<string>();
    const locales = Object.keys(loaders) as Locale[];

    async function loadLocale(locale: Locale) {
        if (loaded.has(locale)) {
            console.error("TranslationLoader: Locale already loaded");
            return;
        }
        const loader = loaders[locale];
        if (!loader) {
            console.error(
                "TranslationLoader: Cannot load messaged for " + locale + ". It is not set.",
            );
            return;
        }
        let messages: TranslationLoader;
        if (typeof loader !== "function") {
            messages = loader;
        } else {
            messages = await loader();
        }
        i18n.global.setLocaleMessage(locale, messages);
    }

    function setLocale(locale: Locale) {
        if (!locale) {
            return;
        }
        if (!locales.includes(locale)) {
            console.error(
                "TranslationLoader: trying to set locale to " +
                    locale +
                    " which is not available. Available locales: " +
                    Object.keys(loaders),
            );
            return;
        }
        loadLocale(locale).then(() => {
            moment.locale(locale);
            window.document.documentElement.lang = locale;
            i18n.global.locale.value = locale;
            console.info("TranslationLoader: Changed locale to", i18n.global.locale.value);
        });
    }

    return {
        setLocale,
        plugin: {
            install(app: App) {
                app.provide(translationLoaderInjectionKey, { setLocale });
            },
        },
    };
}
