<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h3">Datensäze aufklappen</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <l-table :data="config" show-expand>
                        <template #expanded-item="props"> Expansion: {{ props }} </template>
                    </l-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts" setup>
import { VCol, VContainer, VRow } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";
import type { TableConfig } from "../../../src/config/Table";

const config: TableConfig = {
    columns: [
        {
            key: "id",
            hidden: true,
        },
        {
            key: "col1",
            label: "Column 1 (String)",
        },
        {
            key: "col2",
            hidden: false,
            label: "Column 2",
            type: "select",
            details: {
                label: "Col 2 (String, Only in Detailsview)",
                key: "col2",
                hidden: false,
            },
            options: [
                { value: 1, text: "Label 1" },
                { value: 2, text: "Label 2" },
                { value: 3, text: "Label 3" },
                { value: 4, text: "Label 4" },
            ],
        },
        {
            key: "col3",
            label: "Column 3 (Date)",
            type: "date",
        },
        {
            key: "action",
            label: "",
            type: "action",
            formatter: "action",
            actions: [],
        },
    ],
    filter: [],
    rows: [
        {
            id: 1,
            col1: "Lorem Ipsum",
            col2: 1,
            col3: "1990-10-10",
            action: [],
        },
        {
            id: 2,
            col1: "Lorem Ipsum (Deutsch)",
            col2: 2,
            col3: "1990-09-09",
            action: [],
        },
        {
            id: 3,
            col1: "Kafka",
            col2: 4,
            col3: "1990-11-11",
            action: [],
        },
    ],
};
</script>
