import {
    type TranslationMapping,
    useTranslationMapping,
} from "../../composables/TranslationMapping";
import type { LocalizedString } from "../LocalizedString";

export class Localized {
    protected translationMapping: TranslationMapping;

    constructor(
        settings: unknown,
        readonly locale: string,
    ) {
        this.translationMapping = useTranslationMapping();
    }

    format(value: LocalizedString | undefined | null): string;
    format(value: unknown): string {
        if (!value) {
            return "";
        }
        const v = value;
        const localeKey = this.locale;
        if (typeof v !== "object") {
            console.error("Localized: expecting object got " + typeof v);
            return "";
        }
        if (Array.isArray(v)) {
            if (v.length == 0) {
                return "";
            }
            console.error("Localized: expecting object got array");
            return "";
        }
        if (localeKey in v) {
            return v[localeKey];
        } else {
            const fallback = this.translationMapping.mapLocale(
                this.translationMapping.fallbackLocale,
            );
            return v[fallback] || JSON.stringify(value);
        }
    }
}
