import clearFormErrors from "./clearFormErrors";
import { z, ZodError } from "zod";
import type { FormConfig } from "../../config/Form";
import type { DeepReadonly } from "vue";

const formErrorSchema = z.record(z.string(), z.array(z.string()));
export type FormErrors = z.infer<typeof formErrorSchema>;

const $set = function (obj, key, value) {
    obj[key] = value;
};

function appendErrors(config, errors) {
    if (!(errors in config)) {
        config.errors = new Array<any>();
    }
    config.errors.push(...errors);
}

function processConfig(config, errors, parent, path) {
    const found = {};
    for (const c in config) {
        const child = config[c];
        const childPath = path !== "" ? path + "." + c : c;
        const isCompound = Object.hasOwn(child, "children");
        if (isCompound) {
            for (const ch in child.children) {
                if (ch === "errors") {
                    continue;
                }
                const grandchild = child.children[ch];
                if (typeof grandchild.type === "undefined") {
                    // compound
                    const childFound = processConfig(
                        grandchild,
                        errors,
                        child,
                        childPath + "." + ch,
                    );
                    Object.assign(found, found, childFound);
                } else {
                    // single field
                    const grandchildPath = childPath + "." + ch;
                    if (typeof errors[grandchildPath] !== "undefined") {
                        if (!grandchild.errors) {
                            // noinspection JSPrimitiveTypeWrapperUsage
                            grandchild.errors = [];
                        }
                        for (const e of errors[grandchildPath]) {
                            grandchild.errors.push(e);
                        }
                        child.error = true;
                        found[grandchildPath] = true;
                    }
                }
            }
        } else if (childPath in errors) {
            if (parent) {
                $set(parent, "error", true);
            }
            if (typeof errors[childPath] !== "undefined") {
                appendErrors(child, errors[childPath]);
                found[childPath] = true;
            }
        }
    }
    // check if there are any errors that could not be assigned
    // if that is the case, assign them to the form element
    if (parent === null) {
        const formErrors: Array<any> = [];
        for (const e in errors) {
            if (!(e in errors) || e in found) {
                continue;
            }
            console.warn(
                "DefaultProps: unassigned formError " + e + " = " + JSON.stringify(errors[e]),
            );
            formErrors.push(errors[e]);
        }
        $set(config, "errors", formErrors);
    }
    return found;
}

export default function (config: FormConfig, errors: DeepReadonly<FormErrors>) {
    if (typeof errors !== "object" || errors === null) {
        throw new Error("errors must be object");
    }
    if (typeof config !== "object" || config === null) {
        throw new Error("config must be object");
    }
    try {
        formErrorSchema.parse(errors);
    } catch (e) {
        if (e instanceof ZodError) {
            throw Error("Invalid error object: " + e);
        } else {
            throw Error("Unknown error while validating form errors");
        }
    }

    clearFormErrors(config);
    processConfig(config, errors, null, "");
}
