<template>
    <asa-form v-model="value" :loading="false" :config="cfg">
        <template #actions>
            <v-btn color="success"> Extra Long Chilly Cheese Burger </v-btn>
            <v-btn color="success"> Just make it overlong already </v-btn>
            <v-btn color="success"> Yet another really long button label </v-btn>
            <v-btn color="success"> Lorem Ipsum dolor sit amet </v-btn>
            <v-btn color="success"> One Button too many </v-btn>
        </template>
    </asa-form>
</template>

<script>
import AsaForm from "../../../src/component/LForm.vue";
import { VBtn } from "vuetify/components";
export default {
    name: "LongButtonLabels",
    components: { AsaForm, VBtn },
    data() {
        return {
            value: {},
            cfg: {
                field: {
                    type: "text",
                    label: "Field",
                },
            },
        };
    },
};
</script>
