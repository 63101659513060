<template>
    <test-form v-model:config="config" v-model="value" title="Tabs w/ title" />
</template>

<script lang="ts" setup>
import TestForm from "../../components/TestForm.vue";
import { ref } from "vue";
import { type FormConfig } from "../../../src/config/Form";

const value = ref<Record<string, unknown>>({
    b0: "asdf"
});
const config = ref<FormConfig>({
    tab1: {
        type: "accordion",
        label: "Accordion A",
        children: {
            a1: { type: "text", label: "Vorname", required: true },
            a2: { type: "text", label: "Nachname", required: true },
            a3: { type: "email", label: "E-Mail" },
            a4: { type: "textarea", label: "Adresse" },
        }
    },
    tab2: {
        type: "accordion",
        label: "Accordion B",
        children: {
            b0: { type: "text", label: "Error", errors: ['error from config'] },
            b1: { type: "textarea", label: "Anmerkungen", required: true },
        }
    },
});
</script>
