import moment from "moment";
import type { Formatter } from "../formatter";

// Date is not a valid class name so we have to work around. the formatter factor handles this.
export default class DateFormatter implements Formatter {
    protected locale: string;

    constructor(settings: unknown, locale?: string) {
        if (!locale) {
            throw Error("formatter/Date: locale is required");
        }
        this.locale = locale;
    }

    format(value: unknown): string {
        if (!value) {
            return "";
        }
        if (typeof value !== "string" && !(value instanceof Date)) {
            console.error("formatter/Date: Invalid value type", value);
            return "#error#";
        }

        const fmt = value instanceof Date ? undefined : "YYYY-MM-DD";
        const d: moment.Moment = moment(value, fmt).utcOffset(0, true);
        if (!d.isValid()) {
            console.error("formatter/Date: invalid value ", value);
            return "#error#";
        }
        return d.format(moment.localeData(this.locale).longDateFormat("L"));
    }
}
