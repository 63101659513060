<template>
    <tbody ref="tbody">
        <tr v-if="noData" class="no-data">
            <td :colspan="columnCount">
                <slot name="no-data">
                    <em class="text-disabled">{{ t("lumui.table.no_data") }}</em>
                </slot>
            </td>
        </tr>
        <tr v-else-if="noResults" class="no-results">
            <td :colspan="columnCount">
                <slot name="no-results">
                    <em class="text-disabled">{{ t("lumui.table.no_filter_results") }}</em>
                </slot>
            </td>
        </tr>
        <template v-else>
            <tr v-if="!virtual.atTop.value">
                <td
                    :colspan="columnCount"
                    :style="{ height: (virtual.paddingTop.value ?? 0) + 'px', border: 0 }"
                />
            </tr>
            <template v-for="{ index, row } in virtual.rows.value" :key="row.id">
                <l-table-row
                    :actions="actions"
                    :column-count="columnCount"
                    :columns="columns"
                    :ordinal="index"
                    :row="row"
                    :visible-columns="visibleColumns"
                    @row:clicked="row => emit('row:clicked', row)"
                    @row:action="action => emit('row:action', action)"
                    @update:height="h => virtual.itemResizeHandler(row, h)"
                >
                    <template #expanded-item="expandProps">
                        <slot name="expanded-item" v-bind="expandProps">
                            No expanded-item slot provided
                        </slot>
                    </template>
                </l-table-row>
            </template>
        </template>
        <!-- make sure we have an empty line at the end -->
        <tr class="padding">
            <td
                :colspan="columnCount"
                :style="{
                    height: (virtual.paddingBottom.value ?? 0) + 'px',
                }"
            />
        </tr>
    </tbody>
</template>

<script lang="ts" setup>
import type { NormalizedTableColumnConfig, TableRow } from "../config/Table";
import LTableRow, { type LTableRowActionEvent } from "./LTableRow.vue";
import { useVirtual } from "./table/virtual";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const _props = defineProps<{
    loading: boolean;
    selectable: boolean;
    noResults: boolean;
    noData: boolean;
    actions: Record<string, any>;
    visibleColumns: readonly NormalizedTableColumnConfig[];
    columns: readonly NormalizedTableColumnConfig[];
    columnCount: number;
}>();

const emit = defineEmits({
    "row:action": (_action: LTableRowActionEvent) => true,
    "row:clicked": (_row: TableRow) => true,
    "row:height": (_row: TableRow, _height: number) => true,
});

const virtual = useVirtual();
</script>

<style lang="scss" scoped>
@use "vuetify/settings";

.l-table tbody tr.even {
    background-color: rgba(var(--v-border-color), calc(var(--v-hover-opacity) / 2));
}

.l-table tbody tr:hover {
    background-color: settings.$table-hover-color;
}

.l-table tbody tr.expansion:hover,
.l-table tbody tr.no-data:hover,
.l-table tbody tr.no-results:hover,
.l-table tbody tr.padding:hover {
    background-color: transparent !important;
}

.l-table-loading th,
.l-table-loading td {
    opacity: 0.3;
}
.no-data td,
.no-results td {
    border-bottom: 0 !important;
}
.no-data td:hover,
.no-results td:hover {
    background-color: transparent;
}

tbody,
tbody > tr,
tbody > tr > td,
tbody > tr > th {
    transition-property: none !important;
}
</style>
