<template>
    <div v-if="isVisible" :class="['input-checkboxes', { required: isRequired }]">
        <v-label v-if="config.label">
            {{ config.label }}
        </v-label>
        <v-checkbox
            v-for="(item, key) in config.multiOptions"
            :key="key"
            :disabled="isDisabled"
            :error="error"
            :error-messages="aggregatedErrors"
            :label="getLabel(item)"
            :model-value="viewValue"
            hide-details="auto"
            :rules="
                rules || [
                    v =>
                        !isRequired ||
                        v.includes(getValue(item)) ||
                        i18n.t('lumui.form.row.checkbox_required'),
                ]
            "
            :value="getValue(item)"
            @update:model-value="e => $emit('update:modelValue', e)"
        />
    </div>
</template>

<script lang="ts">
import { VCheckbox, VLabel } from "vuetify/components";
import { defineComponent, type PropType } from "vue";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigCheckbox, MultiOptions } from "../config/Form";
import { formConfigCheckboxSchema } from "../config/Form.zod";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import { useTranslationMapping } from "../composables/TranslationMapping";
import { useI18n } from "vue-i18n";
import { FormatterFactory } from "../index";

/**
 * #### config
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"checkbox"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | false      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | false      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | true       | field is rendered.  |
 * | multiOptions           | `Array`                    | yes      | -          | Checkbox options. |
 *
 * multiOptions is an `Array` of item either `{value: "id", label: "string"}` or `{value: "id", lable: {de: "string", en: "string"}}` objects.
 * Note that current user locale is assumed to be defined in the options, there is no fallback or further check.
 */
export default defineComponent({
    components: { VCheckbox, VLabel },
    props: {
        /** field value */
        modelValue: {
            type: Array as PropType<Array<string | number | boolean>>,
            required: false,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigCheckbox>(formConfigCheckboxSchema),
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const { mapLocale } = useTranslationMapping();
        return {
            i18n: useI18n(),
            mapLocale,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            i: 0,
        };
    },
    computed: {
        localize() {
            const formatter = FormatterFactory("localized", { locale: this.locale });
            return formatter.format.bind(formatter);
        },
        fallbackLocale() {
            const s = this.i18n.fallbackLocale.value;
            return this.mapLocale(s as string);
        },
        locale() {
            return this.mapLocale(this.i18n.locale.value);
        },
        viewValue() {
            const value = nonReactiveClone(this.modelValue) || [];
            return typeof value === "object" ? value : [value];
        },
    },
    methods: {
        /* @private */
        getLabel(item: MultiOptions): string {
            if (typeof item == "string") {
                return item;
            }
            const label = "label" in item ? item.label : item.text;
            if (typeof label === "object") {
                return this.localize(label);
            } else {
                return label;
            }
        },
        /* @private */
        getValue(item: MultiOptions) {
            return typeof item === "object" ? item.value : item;
        },
    },
});
</script>

<style scoped>
.input-checkboxes .label {
    margin-bottom: -16px;
}
</style>
