<template>
    <asa-dialog>
        <asa-form v-model:config="config" v-model="value">
            <template #title> Form Title </template>
        </asa-form>
    </asa-dialog>
</template>

<script>
import AsaDialog from "../../../src/component/LDialog.vue";
import AsaForm from "../../../src/component/LForm.vue";

export default {
    name: "DialogTabs",
    components: {
        AsaDialog,
        AsaForm,
    },
    data() {
        return {
            value: {
                b0: "asdf",
            },
            config: {
                tab1: {
                    type: "tab",
                    label: "Tab A",
                    children: {
                        a1: { type: "text", label: "Vorname", required: false },
                        a2: { type: "text", label: "Nachname", required: false },
                        a3: { type: "email", label: "E-Mail" },
                        a4: { type: "textarea", label: "Adresse" },
                    },
                },
                tab2: {
                    type: "tab",
                    label: "Tab B",
                    children: {
                        b0: { type: "text", label: "Error" },
                        b1: { type: "textarea", label: "Anmerkungen", required: false },
                    },
                },
            },
        };
    },
    mounted() {
        //  this.setFormErrors(this.config, { 'unassigned': 'YYY', 'tab2.b1': 'XXX' });
    },
};
</script>
