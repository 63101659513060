type RecordData = {
    record: {
        getData(k: string, defaultValue?: unknown): unknown;
    };
};
export type EvalRecord<G extends Record<string, any> | Record<string, never>> =
    | (G & RecordData)
    | RecordData;

export function createEvalRecord<Globals extends Record<string, any>>(
    values: Record<string, unknown>,
    globals: Globals,
): EvalRecord<Globals> {
    if (globals && globals["record"] !== undefined) {
        console.warn(
            "EvalRecord: global key record will be overwritten and will not contain what you expect.",
        );
    }

    return {
        ...globals,
        record: new Proxy(
            {
                __has(k: string): boolean {
                    return k in values;
                },
                getData(k: string, defaultValue?: unknown): unknown {
                    if (typeof values[k] === "undefined") {
                        if (defaultValue === undefined) {
                            console.warn("EvalRecord: record has no key " + k, Object.keys(values));
                        }
                        return defaultValue;
                    }
                    return values[k];
                },
            },
            {
                has(target, p) {
                    return p in target;
                },
                get(target, p) {
                    if (target[p] == undefined) {
                        throw Error("undefined symbol record." + p.toString());
                    }
                    return target[p];
                },
            },
        ),
    };
}
