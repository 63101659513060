<template>
    <v-dialog
        :fullscreen="fullscreen"
        :max-width="width"
        :model-value="show"
        :persistent="persistent"
    >
        <v-card :color="color">
            <v-card-title class="subheading">
                <!-- dialog title -->
                <slot name="title" />
            </v-card-title>

            <v-card-text>
                <!-- dialog content -->
                <slot name="content" />
            </v-card-text>

            <v-card-actions>
                <!-- dialog actions -->
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { VCard, VCardActions, VCardText, VCardTitle, VDialog } from "vuetify/components";

export default {
    components: { VDialog, VCard, VCardActions, VCardText, VCardTitle },
    props: {
        /** whether or not to display the alert */
        show: {
            type: Boolean,
            default: false,
        },
        /** color used for the alert */
        color: {
            type: String,
            default: "",
        },
        /** the width of the alert */
        width: {
            type: Number,
            default: 350,
        },
        /** whether or not clicks outside the alert should be registered */
        persistent: {
            type: Boolean,
            default: true,
        },
        /** cover the whole window */
        fullscreen: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
