<template>
    <template v-if="isVisible">
        <div :class="['input-radios', { required: isRequired }]">
            <v-label v-if="theLabel">
                {{ theLabel }}
            </v-label>
            <v-radio-group
                :id="name"
                :model-value="model"
                :disabled="isDisabled"
                :error="error"
                :error-messages="aggregatedErrors"
                :name="name"
                :required="isRequired"
                :rules="myRules"
                :value-comparator="itemCompare"
                variant="filled"
                @update:model-value="e => updateValue(e)"
            >
                <v-radio
                    v-for="(item, key) in config.multiOptions"
                    :id="name + '-' + key"
                    :key="key"
                    :name="name"
                    :value="getValue(item)"
                >
                    <template #label>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="getLabel(item)" />
                    </template>
                </v-radio>
            </v-radio-group>
        </div>
    </template>
</template>

<script lang="ts">
import HtmlSanitizer from "../lib/HtmlSanitizer.js";
import { VRadio, VRadioGroup, VLabel } from "vuetify/components";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps.js";
import type { FormConfigRadio, RadioOptionLabel, RadioOptionText } from "../config/Form";
import { formConfigRadioSchema } from "../config/Form.zod";
import { defineComponent } from "vue";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import { useI18n } from "vue-i18n";
import { useTranslationMapping } from "../composables/TranslationMapping";
// @todo validate
/**
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | multiOptions           | `Array`                    | yes      |            | Array of {value: "val", lable: {de: "de label"}} |
 */
export default defineComponent({
    components: { VRadio, VRadioGroup, VLabel },
    props: {
        /** field value */
        modelValue: {
            type: [Object, String, Boolean, Number],
            required: false,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigRadio>(formConfigRadioSchema),
    },
    emits: ["update:modelValue", "update:config"],
    setup(props, ctx) {
        const { t, locale } = useI18n();
        const { mapLocale } = useTranslationMapping();
        return {
            t,
            locale,
            mapLocale,
            ...useDefaults(props, ctx.emit),
        };
    },
    data() {
        return {
            viewValue: 1,
        };
    },
    computed: {
        model() {
            const item = this.modelValue;
            return item !== undefined && typeof item === "object" ? item.value : item;
        },
        mappedLocale() {
            return this.mapLocale(this.locale);
        },
        myRules() {
            if (this.rules) {
                return this.rules;
            }

            const required = this.isRequired;
            return [
                (v: unknown) =>
                    !required ||
                    (Array.isArray(v) ? v.length > 0 : v != undefined) ||
                    this.t("lumui.form.row.required"),
            ];
        },
    },
    methods: {
        updateValue(e) {
            this.$emit("update:modelValue", e);
            if (this.config.errors && this.config.errors.length > 0) {
                const cfg = nonReactiveClone(this.config);
                cfg.errors = undefined;
                this.$emit("update:config", cfg);
            }
        },
        /* @private */
        getLabel(item: RadioOptionText | RadioOptionLabel | string) {
            const label =
                typeof item === "object" ? ("label" in item ? item.label : item.text) : item;
            if (typeof label === "object") {
                return HtmlSanitizer.SanitizeHtml(label[this.mappedLocale] ?? "");
            }
            return HtmlSanitizer.SanitizeHtml(label ?? "");
        },
        /* @private */
        getValue(item: RadioOptionText | RadioOptionLabel | string) {
            return typeof item === "object" ? item.value : item;
        },
        /* @private */
        itemCompare(a: unknown, b: unknown) {
            if (a === b) {
                return true;
            }

            if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) {
                // If the values are Date, compare them as timestamps
                return false;
            }

            if (a !== Object(a) || b !== Object(b)) {
                // If the values aren't objects, they were already checked for equality
                // Check if they are equal when they are converted to strings
                return String(a) === String(b);
            }

            if (a == null || b == null) {
                return a == b;
            }

            if (typeof a == "object" && typeof b == "object") {
                const props = Object.keys(a);

                if (props.length !== Object.keys(b).length) {
                    // Different number of props, don't bother to check
                    return false;
                }

                return props.every(p => this.itemCompare(a[p], b[p]));
            }
        },
    },
});
</script>

<style scoped>
.input-radios .label {
    margin-bottom: -16px;
}
</style>
