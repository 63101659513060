export const aliases = {
    AsaAlert: "LAlert",
    AsaConfirm: "LConfirm",
    AsaDialog: "LDialog",
    AsaForm: "LForm",
    AsaFormatter: "LFormatter",
    AsaImage: "LImage",
    AsaLoadingFullscreen: "LLoadingFullscreen",
    AsaMultiLang: "LMultiLang",
    AsaQrScanner: "LQrScanner",
    AsaSpinner: "LSpinner",
    AsaTable: "LTable",
    AsaTableAction: "LTableAction",
    AsaTableColumn: "LTableColumn",
    AsaTableColumnFile: "LTableColumnFile",
    AsaTableFilter: "LTableFilter",
    AsaTableFilterBool: "LTableFilterBool",
    AsaTableFilterDate: "LTableFilterDate",
    AsaTableFilterMaterializedPath: "LTableFilterMaterializedPath",
    AsaTableFilterNestedSet: "LTableFilterNestedSet",
    AsaTableFilterSelect: "LTableFilterSelect",
    AsaTableFilterText: "LTableFilterText",
    FormAccordion: "LFormAccordion",
    FormContent: "LFormContent",
    FormRow: "LFormRow",
    FormRowAutocomplete: "LFormRowAutocomplete",
    FormRowCheckbox: "LFormRowCheckbox",
    FormRowCollection: "LFormRowCollection",
    FormRowColor: "LFormRowColor",
    FormRowDate: "LFormRowDate",
    FormRowEmail: "LFormRowEmail",
    FormRowFile: "LFormRowFile",
    FormRowHidden: "LFormRowHidden",
    FormRowHtml: "LFormRowHtml",
    FormRowLocalized: "LFormRowLocalized",
    FormRowNumber: "LFormRowNumber",
    FormRowOrderedChoice: "LFormRowOrderedChoice",
    FormRowPassword: "LFormRowPassword",
    FormRowRadio: "LFormRowRadio",
    FormRows: "LFormRows",
    FormRowSignature: "LFormRowSignature",
    FormRowSwitch: "LFormRowSwitch",
    FormRowText: "LFormRowText",
    FormRowTextarea: "LFormRowTextarea",
    FormRowTime: "LFormRowTime",
    //FormRowTreeNode: "LFormRowTreeNode",
    FormTabs: "LFormTabs",
    FormTabsContent: "LFormTabsContent",
    Redactor: "LFormRowRichText",
    SanitizedHtml: "LSanitizedHtml",
} as const;
