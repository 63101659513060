import moment from "moment";

export class Time {
    readonly fmt: string;

    constructor(
        settings: { seconds?: true },
        readonly locale?: string,
    ) {
        if (!locale) {
            throw Error("formatter/Time: locale is required");
        }
        if (settings.seconds) {
            this.fmt = moment.localeData(this.locale).longDateFormat("LTS");
        } else {
            this.fmt = moment.localeData(this.locale).longDateFormat("LT");
        }
    }

    format(value: unknown) {
        if (!value) {
            return "";
        }

        if (typeof value !== "string" && !(value instanceof Date)) {
            console.error("formatter/Time: Invalid value type", value);
            return "#error#";
        }

        const d = moment(value, "H:mm:ss").utcOffset(0, true);
        if (!d.isValid()) {
            console.error("formatter/Time: invalid value", value);
            return "#error#";
        }
        return d.format(this.fmt);
    }
}
