<template>
    <test-form v-model="value" v-model:config="config" title="Switch" @save="addError" />
</template>

<script lang="ts">
import TestForm from "../../components/TestForm.vue";
import setFormErrors from "../../../src/lib/form/setFormErrors";
import type { FormConfig } from "../../../src/config/Form";
const config: FormConfig = {
    f1: {
        label: "Switch w/error",
        type: "switch",
    },
    f2: {
        label: "Switch width number value",
        type: "switch",
    },
    f3: {
        label: "Switch width string value",
        type: "switch",
    },
    f4: {
        label: "Switch width string value",
        type: "switch",
        value: "1",
    },
};
export default {
    name: "FormSwitch",
    components: { TestForm },
    data() {
        return {
            value: {
                f1: false,
                f2: 1,
                f3: "1",
            },
            config,
        };
    },
    methods: {
        addError() {
            setFormErrors(this.config, { f1: ["error"] });
        },
    },
};
</script>
