<template>
    <form-tabs-content
        :active-tab="activeTab"
        :config="config"
        :form-values="formValues"
        :render-items="true"
        :value="value"
        @partial-update:value="v => $emit('partialUpdate:value', v)"
        @partial-update:config="cfg => $emit('partialUpdate:config', cfg)"
        @update:active-tab="e => $emit('update:activeTab', e)"
    />
    <form-accordion
        :config="config"
        :form-values="formValues"
        :value="value"
        @partial-update:value="v => $emit('partialUpdate:value', v)"
        @partial-update:config="cfg => $emit('partialUpdate:config', cfg)"
    />
    <l-form-rows
        :config="config"
        :form-values="formValues"
        :value="value"
        @partial-update:value="v => $emit('partialUpdate:value', v)"
        @partial-update:config="cfg => $emit('partialUpdate:config', cfg)"
    />
</template>

<script lang="ts">
import LFormRows from "./LFormRows.vue";
import FormTabsContent from "./LFormTabsContent.vue";
import FormAccordion from "./LFormAccordion.vue";
import type { FormConfig } from "../config/Form";
import { defineComponent, type PropType } from "vue";
import type { EvalRecord } from "../lib/EvalRecord";

/**
 * Renders form content.
 * First all elements with a `type` of 'tab' are rendert as tabs.
 * Then all elements with a `type` of `'accordion'` are rendered as accordions.
 * At last all remaining elements are rendered as rows.
 *
 * While not strictly necessary, forms should only have elements of one of
 * these types as top level elements.
 */
export default defineComponent({
    name: "FormContent",
    components: {
        LFormRows,
        FormTabsContent,
        FormAccordion,
    },
    props: {
        /** form value object */
        value: {
            type: Object,
            required: true,
        },
        formValues: {
            type: Object as PropType<EvalRecord<any>>,
            required: true,
        },
        /** form definition */
        config: {
            type: Object as PropType<FormConfig>,
            required: true,
        },
        /** currently active tab */
        activeTab: {
            type: String,
            default: "",
        },
    },
    emits: ["partialUpdate:value", "partialUpdate:config", "update:activeTab"],
});
</script>
