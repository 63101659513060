import { createRouter, createWebHistory, isNavigationFailure, type RouteRecordRaw } from "vue-router";
import Index from "../components/Index.vue";

const requireViews = import.meta.glob('../views/**/*.vue', { eager: true });

const routes: RouteRecordRaw[] = [];

for (const fileName in requireViews) {
    const routeConfig = requireViews[fileName] as { default: any };
    if (!fileName) {
        continue;
    }
    const routeName = fileName
        .replace('../views/', '')
        .split('/')
        .join('_')
        .replace(/\.\w+$/, '');
    const routePath = fileName
        .replace('../views/', '/')
        .replace(/\.\w+$/, '');
    const title = fileName.split("/").pop()?.replace(/\.\w+$/, '').replace(/_/, ' ') ?? "";

    const route: RouteRecordRaw = {
        path: routePath,
        name: routeName,
        component: routeConfig.default,
        meta: {
            title: title.charAt(0).toLocaleUpperCase() + title.slice(1)
        }
    };
    routes.push(route);
}
routes.push({
    path: "/",
    name: "home",
    component: Index,
    meta: {
        title: ''
    }
});
const router = createRouter({ history: createWebHistory(), routes: routes, strict: true });
router.afterEach((to, from, error) => {
    if (isNavigationFailure(error)) {
        console.error("Navigation failure", error);
    }
});
export default router;
