<template>
    <test-form
        v-model="v"
        v-model:config="config"
        title="Checkboxes"
        description="Examples of interactive checkboxes"
        @save="save"
    />
</template>

<script>
import TestForm from "../../components/TestForm.vue";

export default {
    components: { TestForm },
    data() {
        return {
            v: {},
            config: {
                addons: {
                    type: "checkbox",
                    label: this.$t("customer.order.addons"),
                    multiOptions: [
                        {
                            value: "57d5af4b-10c7-4f7c-b0ac-ded5f8ef550b",
                            label: { de: "Salat", en: "Salad" },
                        },
                        {
                            value: "cdd17247-6baf-4f6d-ba4e-0dd311fa3227",
                            label: { de: "Nachtisch", en: "Dessert" },
                        },
                    ],
                    value: ["57d5af4b-10c7-4f7c-b0ac-ded5f8ef550b"],
                },
                extras: {
                    type: "checkbox",
                    label: this.$t("customer.order.extras"),
                    multiOptions: [
                        { value: "7b6c45f8-526f-4fc7-a7d8-fdb3cb8a4927", label: "Diabetes Typ 1a" },
                    ],
                    value: ["7b6c45f8-526f-4fc7-a7d8-fdb3cb8a4927"],
                    disabled: "record.getData('f1', []).includes('foo')",
                    visible:
                        "record.getData('addons', []).includes('57d5af4b-10c7-4f7c-b0ac-ded5f8ef550b');",
                },
                f1: {
                    type: "checkbox",
                    multiOptions: [{ value: "foo", label: { de: "nur lesen", en: "read only" } }],
                },
            },
        };
    },
    methods: {
        save() {},
    },
};
</script>
