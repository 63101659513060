<template>
    <div>
        <asa-confirm ref="confirmDialog" />
        <v-btn color="error" type="button" @click="ask"> delete </v-btn>
    </div>
</template>

<script>
import { VBtn } from "vuetify/components";
import AsaConfirm from "../../../src/component/LConfirm.vue";

export default {
    name: "MiscConfirm",
    components: {
        VBtn,
        AsaConfirm,
    },
    methods: {
        async ask() {
            const res = await this.$refs.confirmDialog.open("Title", "Do you confirm?", {
                // confirmLabel: 'Yey',
                // rejectLabel: 'Nay'
                color: "#f00",
            });
            alert(res ? "Confirmed" : "Rejected");
        },
    },
};
</script>

<style scoped></style>
