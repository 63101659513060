<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="text-h3">Time column</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>Default Detail View</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <l-table :data="tableData" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VCard, VCardText, VCardTitle, VCol, VContainer, VDivider, VRow } from "vuetify/components";
import LTable from "../../../src/component/LTable.vue";

export default {
    name: "TableTimeCol",
    components: { LTable, VContainer, VRow, VCol, VCard, VCardTitle, VDivider, VCardText },
    data() {
        return {
            tableData: {
                columns: [{ key: "t", type: "time", label: "Die Zeit" }],
                rows: [{ t: "04:20" }, { t: "13:37" }],
            },
        };
    },
};
</script>

<style scoped></style>
